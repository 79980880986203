import * as React from "react";
import Box from "./Box";
import styled, { css, withTheme } from "styled-components/macro";
import Image from "./Image";
import { themeGet } from "@styled-system/theme-get";
import Debug from "debug";
import { memoize } from "lodash";
import { Tooltip } from ".";
import { Theme } from "./Theme";

const log = Debug("app:Kit:Avatar");

export const standardSizes = {
  tiny: 24,
  small: 34,
  medium: 40,
  large: 56,
  giant: 100,
  monster: 250
};

const getInitialsFontSize = (size: number) => {
  if (size <= 24) {
    return Math.ceil(size / 2.2);
  }
  return Math.ceil(size / 2.6);
};

export type AvatarSizes =
  | "tiny"
  | "small"
  | "medium"
  | "large"
  | "giant"
  | "monster";

export interface ImageObjectType {
  id: number;
  public_id: string;
}

export interface Props {
  size?: AvatarSizes;
  ariaHidden?: boolean | "false" | "true";
  rounded?: boolean;
  showText?: boolean;
  tooltipTheme?: string;
  getInitials: (name: string, fallback?: string) => string;
  src?: string;
  img?: ImageObjectType;
  hideTooltip?: boolean;
  crop?: string;
  border?: boolean;
  theme: Theme;
  name: string;
  style?: object;
  customTooltipContent?: React.ReactElement<any>;
}

interface State {
  imageFailedToLoad: boolean;
}

interface ColorMap {
  backgroundColor: string;
  color: string;
}

class Avatar extends React.Component<Props, State> {
  colorMap: Array<ColorMap>;

  constructor(props: Props) {
    super(props);
    this.colorMap = fillAppearances(props.theme.colors);
  }

  static defaultProps = {
    size: "small",
    ariaHidden: "false",
    rounded: true,
    showText: true,
    tooltipTheme: "dark",
    getInitials
  };

  state = {
    imageFailedToLoad: false
  };

  handleError = (err: Error) => {
    log("Error: %s", err.message);
    this.setState({ imageFailedToLoad: true });
  };

  render() {
    const {
      showText,
      ariaHidden,
      src,
      img,
      tooltipTheme,
      customTooltipContent,
      hideTooltip,
      size,
      border,
      crop,
      name,
      getInitials,
      style
    } = this.props;
    const { imageFailedToLoad } = this.state;
    const url = img || src;
    const noImage = !url || imageFailedToLoad;

    const normalizedSize =
      (typeof size === "string" ? standardSizes[size] : size) || 40;

    let initials = getInitials(name);
    const hashValue = hashCode(name);
    const key = hashValue % Object.keys(this.colorMap).length;
    const appearance = this.colorMap[key];

    if (typeof normalizedSize === "number" && normalizedSize <= 24) {
      initials = initials.substring(0, 1);
    }

    const other = !noImage ? { backgroundColor: "transparent" } : {};

    return (
      <Tooltip
        interactive
        interactiveBorder={10}
        theme={tooltipTheme}
        title={customTooltipContent || name}
        disabled={hideTooltip || ariaHidden === "false"}
      >
        <AvatarContainer
          rounded={this.props.rounded}
          aria-hidden={ariaHidden}
          size={normalizedSize}
          style={{
            ...appearance,
            ...style,
            ...other,
            ...(border
              ? {
                  border: "2px solid",
                  borderColor: appearance.color
                }
              : {})
          }}
        >
          {noImage ? (
            <AvatarText size={normalizedSize} aria-label={name}>
              {showText && (
                <div style={{ color: appearance.color }} aria-hidden={true}>
                  {initials}
                </div>
              )}
            </AvatarText>
          ) : (
            <Image
              style={{ display: "block" }}
              img={img}
              width={normalizedSize}
              height={normalizedSize}
              alt={name}
              crop={crop}
              title={name}
              src={src}
              onError={this.handleError}
            />
          )}
        </AvatarContainer>
      </Tooltip>
    );
  }
}

export default withTheme(Avatar);

interface ContainerProps {
  size: number;
  rounded?: boolean;
}

export const AvatarContainer = styled(Box)<ContainerProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  overflow: hidden;
  z-index: 0;
  border-radius: ${props => (props.rounded ? "9999px" : "4px")};
  position: relative;
  display: block;
  background-color: ${themeGet("colors.white")};
`;

interface TextProps {
  size: number;
}

const AvatarText = styled.div<TextProps>`
  top: 0;
  position: absolute;
  font-family: ${themeGet("sansFont")};
  display: flex;
  align-items: center;
  color: ${themeGet("colors.black")};
  justify-content: center;
  line-height: 1;
  ${props => {
    const fontSize = getInitialsFontSize(props.size);

    return css`
      font-size: ${fontSize}px;
      width: ${props.size}px;
      height: ${props.size}px;
      line-height: ${fontSize}px;
    `;
  }};
`;

function getInitials(name: string, fallback = "?") {
  if (!name || typeof name !== "string") return fallback;
  return name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map(v => v[0])
    .join("")
    .toUpperCase();
}

export function hashCode(s: string) {
  const str = String(s);
  let hash = 0;
  let char;
  if (str.trim().length === 0) return hash;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char; // eslint-disable-line no-bitwise
    // Convert to 32bit integer
    hash &= hash; // eslint-disable-line no-bitwise
  }
  return Math.abs(hash);
}

export const fillAppearances = memoize(colors => {
  const filtered = Object.keys(colors).filter(
    c =>
      c !== "white" &&
      c.indexOf("Alpha") < 0 &&
      c !== "borderColor" &&
      c !== "faded" &&
      c !== "black"
  );

  return filtered.map(color => {
    return {
      backgroundColor: colors[color][22],
      color: "white"
    };
  });
});
