// https://github.com/withspectrum/spectrum/blob/9f51467a7120ff2d01571e6170b27d2b31e14dee/src/components/message/view.js

import * as React from "react";
import { Paragraph, Button, Link } from "../../Kit";
import redraft from "redraft";
import linksDecorator from "../links-decorator";
import { convertToRaw } from "draft-js";
import styled, { css } from "styled-components/macro";

const MessageStyled = styled.div`
  ${props => {
    if (props.truncated) {
      return css`
        & > p:last-of-type {
          margin-bottom: 0;
        }
      `;
    }
  }};
`;

const truncateText = (editorState, charCount = 300) => {
  const { blocks } = editorState;
  let index = 0;
  let currentLength = 0;
  let isTruncated = false;

  const truncatedBlocks = [];

  while (!isTruncated && blocks[index]) {
    const block = blocks[index];
    const length = block.text.length;
    if (currentLength + length > charCount) {
      isTruncated = true;
      const truncatedText = block.text.slice(0, charCount - currentLength);
      truncatedBlocks.push({
        ...block,
        text: truncatedText + "..."
      });
    } else {
      truncatedBlocks.push(block);
    }
    currentLength += length + 1;
    index++;
  }

  if (isTruncated) {
    return {
      state: {
        ...editorState,
        blocks: truncatedBlocks
      },
      truncated: true
    };
  }

  return { state: editorState, truncated: false };
};

const MessageParagraph = styled(Paragraph)`
  line-height: 1.25;
  margin-top: 0;
  :last-child {
    margin: 0;
  }
`;

const getMessageFromString = message => {
  if (typeof message === "object") {
    return {
      type: "draftjs",
      value: convertToRaw(message.getCurrentContent())
    };
  }

  try {
    const result = JSON.parse(message);
    return {
      type: "draftjs",
      value: result
    };
  } catch (err) {
    return {
      type: "text",
      value: message
    };
  }
};

// type Props = {
//   message: string | Object,
//   truncate: boolean,
//   onRequestMore?: Function,
//   showTruncateMore: boolean,
//   truncateCount?: number
// };

// type State = {
//   stateTruncate: boolean
// };

export class Body extends React.Component {
  static defaultProps = {
    showTruncateMore: true,
    truncateCount: 300
  };

  state = {
    stateTruncate: this.props.truncate
  };

  render() {
    const props = this.props;
    let { type, value } = getMessageFromString(props.message);
    let truncated = false;

    if (type === "text") {
      return value;
    }

    if (this.state.stateTruncate) {
      let truncatedResult = truncateText(value, this.props.truncateCount);
      value = truncatedResult.state;
      truncated = truncatedResult.truncated;
    }

    return (
      <MessageStyled truncated={truncated}>
        {redraft(value, messageRenderer)}
        {truncated && this.props.showTruncateMore && (
          <Button
            onClick={() => {
              this.setState({ stateTruncate: false });
            }}
            appearance="link"
          >
            Read more
          </Button>
        )}{" "}
      </MessageStyled>
    );
  }
}

// just a helper to add a <br /> after a block
const addBreaklines = children => children.map(child => [child, <br />]);

const messageRenderer = {
  inline: {
    BOLD: (children, { key }) => (
      <span style={{ fontWeight: 700 }} key={key}>
        {children}
      </span>
    ),
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    CODE: (children, { key }) => <code key={key}>{children}</code>
  },
  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => (
      <Link key={key} href={data.url}>
        {children}
      </Link>
    )
  },
  blocks: {
    blockquote: children => <BlockQuote>{addBreaklines(children)}</BlockQuote>,
    "header-one": children => children.map(child => <h1>{child}</h1>),
    "header-two": children => children.map(child => <h2>{child}</h2>),
    // You can also access the original keys of the blocks
    "code-block": (children, { keys }) => (
      <CodeBlock key={keys[0]}>{addBreaklines(children)}</CodeBlock>
    ),
    // or depth for nested lists
    "unordered-list-item": (children, { depth, keys }) => (
      <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
        {children.map(child => (
          <li>{child}</li>
        ))}
      </ul>
    ),
    "ordered-list-item": (children, { depth, keys }) => (
      <ol key={keys.join("|")} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),
    unstyled: (children, { keys }) =>
      children.map((child, index) => (
        <MessageParagraph
          className="MessageParagraph"
          key={keys[index] || index}
        >
          {child}
        </MessageParagraph>
      ))
  },
  decorators: [linksDecorator]
};

export default Body;

const CodeBlock = styled.pre`
background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 14px;
    padding: 20px;
}
`;

const BlockQuote = styled.blockquote`
  border-left: 4px solid #eee;
  margin-left: 0;
  padding-left: 1.5rem;
  line-height: 1.25;
`;
