import * as React from "react";
import debug from "debug";
import { withApollo } from "react-apollo";
import { findIndex } from "lodash";
import toast from "../Kit/Toast";
import Text from "../Kit/Text";
import Alert from "../Kit/Alert";
import Message from "../Input/Message";
import { ThemeProvider } from "styled-components/macro";
import Theme from "../Kit/Theme";
import { imageURL } from "../Kit/Image";
import { standardSizes } from "../Kit/Avatar";
import updateReadStatus from "./update-read-status";
import { notify as pushNotify } from "../PushNotifications/push-notify";
import { ConversationQuery } from "../API/queries/ConversationQuery";
import { ConversationSummary } from "../API/queries/ConversationSummary";
import { RecentConversationsQuery } from "../API/queries/RecentConversations";

const log = debug("app:ChatSubscriptionHandler");

export const addConversationIdToSubscriptions = (client, conversationId) => {
  const data = client.readQuery({
    query: RecentConversationsQuery
  });

  // if we are already subscribed, move it to the top
  const i = data.conversations.indexOf(conversationId);
  if (i > -1) {
    data.conversations.splice(i, 1);
  }

  data.conversations.unshift(conversationId);

  client.writeQuery({
    query: RecentConversationsQuery,
    data
  });
};

export const updateUnreadStatus = (client, reply, belongsToOpenChat) => {
  log("update unread count: %o", reply);
  const { conversationId } = reply;
  const data = client.readQuery({
    query: ConversationSummary,
    variables: {
      id: conversationId,
      first: 1
    }
  });

  if (!belongsToOpenChat) {
    data.conversation.unreadCount = data.conversation.unreadCount + 1;
  }

  data.conversation.replies.edges[0] = {
    __typename: "conversationRepliesConnection",
    node: reply
  };

  log("write data: %o", data);

  client.writeQuery({
    query: ConversationSummary,
    variables: {
      id: conversationId,
      first: 1
    },
    data
  });
};

const AlertWithTheme = props => (
  <ThemeProvider theme={Theme}>
    <Alert {...props} />
  </ThemeProvider>
);

class ChatSubscriptionHander extends React.Component {
  state = {
    visible: !document.hidden
  };

  componentDidMount() {
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
  }

  handleVisibilityChange = () => {
    log("visibility changed: %s", document.hidden);
    this.setState({ visible: !document.hidden });
  };

  componentDidUpdate(next) {
    if (this.props.conversationReply) {
      if (this.props.conversationReply !== next.conversationReply) {
        // new notification received
        this.onNewChat(this.props.conversationReply);
      }
    }
  }

  onNewChat = async reply => {
    log("received new message: %o", reply);
    const { openChats, subscribedChatIds } = this.props;
    const openChatIndex = findIndex(openChats, {
      conversationId: reply.conversationId
    });
    const belongsToOpenChat = openChatIndex > -1;
    const openChat = belongsToOpenChat ? openChats[openChatIndex] : null;

    if (!belongsToOpenChat || !this.state.visible) {
      this.notify(reply);
    }

    const alreadySubscribed =
      subscribedChatIds.indexOf(reply.conversationId) > -1;

    if (alreadySubscribed) {
      this.updateUnreadCount(
        reply,
        belongsToOpenChat && !(openChat && openChat.isCollapsed)
      );
    }

    if (belongsToOpenChat && !(openChat && openChat.isCollapsed)) {
      updateReadStatus(this.props.client, reply.conversationId);
    }

    if (belongsToOpenChat) {
      this.addToOpenChat(reply);
    }

    this.subscribeToConversation(reply);
  };

  subscribeToConversation = reply => {
    const { conversationId } = reply;
    log("subscribe to conversation: %s", conversationId);
    addConversationIdToSubscriptions(this.props.client, conversationId);
  };

  addToOpenChat = reply => {
    log("add to open chat %o", reply);
    const { conversationId } = reply;
    const result = this.props.client.readQuery({
      query: ConversationQuery,
      variables: {
        id: conversationId,
        first: 10
      }
    });

    result.conversation.replies.edges.push({
      __typename: "conversationRepliesConnection",
      node: reply
    });

    this.props.client.writeQuery({
      data: result,
      query: ConversationQuery,
      variables: {
        id: conversationId,
        first: 10
      }
    });
  };

  updateUnreadCount = (reply, belongsToOpenChat) => {
    updateUnreadStatus(this.props.client, reply, belongsToOpenChat);
  };

  notify = reply => {
    log("notify user: %o", reply);

    if (this.state.visible) {
      toast.notify(
        ({ onClose }) => (
          <AlertWithTheme
            onClose={onClose}
            style={{ cursor: "pointer" }}
            onClick={() => {
              log("show chat");
              onClose();
              this.props.onRequestShow(reply.conversationId);
            }}
            img={{
              src: imageURL(reply.user.photo.public_id, standardSizes.medium)
            }}
            title={
              <Text>
                {reply.user.name} wrote{": "}
                <Message
                  truncate
                  truncateCount={110}
                  showTruncateMore={false}
                  message={reply.content}
                />
              </Text>
            }
          />
        ),
        {
          position: "bottom-left",
          duration: 5000
        }
      );
    } else {
      pushNotify({
        title: "Watershed",
        image: imageURL(reply.user.photo.public_id, standardSizes.medium),
        body: `${reply.user.name} sent you a message`
      });
    }
  };

  render() {
    return null;
  }
}

export default withApollo(ChatSubscriptionHander);
