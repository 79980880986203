import * as React from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { pickBy, identity } from "lodash";

// type Props = {
//   location: Object,
//   history: Object,
//   children: Function
// };

class ViewEpisodeRouteTrigger extends React.Component {
  trigger = args => {
    this.props.history.push({
      search: queryString.stringify(
        pickBy(
          {
            theatre: true,
            ...args
          },
          identity
        )
      )
    });
  };

  render() {
    return this.props.children(this.trigger);
  }
}

export default withRouter(ViewEpisodeRouteTrigger);
