import React from "react"; // eslint-disable-line
import styled from "styled-components/macro";
import Flex from "./Flex";

const FlexContainer = styled(Flex)`
  width: 100%;
  max-width: ${props =>
    props.fluid ? "none" : props.theme.breakpoints[2] + "rem"};
  margin: 0 auto;
  max-width: 1300px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-direction: row;
  padding: 0 16px;

  @media screen and (min-width: 40em) {
    padding: 0 24px;
  }

  @media screen and (min-width: 52em) {
    padding: 0 32px;
  }
`;

export const BoxContainer = styled(FlexContainer)`
  display: block;
`;

export default FlexContainer;
