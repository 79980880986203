import React, { useState } from "react";
import styled from "styled-components";
import Raven from "raven-js";
import { Redirect } from "react-router-dom";

import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";

import {
  Header,
  Text,
  onPhone,
  onSmallScreen,
} from "../Elements";
import auth from "../../Auth/auth";
import Timeline from "./timeline";

import { FlexColumn, FooterComponent, Navbar } from "../index";

import "./styles.css";

const Layout = ({ children }) => (
  <div
    style={{
      background: "white",
      fontFamily: "poppins-regular",
      color: "#515151",
    }}
  >
    {children}
  </div>
);

const Container = styled.div`
  max-width: 1440px;
  padding: 3rem 32px;
  margin: 0 auto;

  ${onPhone} {
    padding: 32px 16px;
  }
  ${onSmallScreen} {
    padding: 32px 16px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

const Grid = styled.div`
  ${onPhone} {
    display: block !important;
    margin-top: 1rem !important;
    & * {
      padding-bottom: 1rem;
    }
  }
  // ${onSmallScreen} {
  //   display: block !important;
  //   margin-top: 1rem !important;
  //   & * {
  //     padding-bottom: 1rem;
  //   }
  // }
`;

const CalloutButton = styled.div`
  text-decoration: none;
  padding: 1rem 2rem;
  text-align: center;
  background: #f5821e;
  display: inline-block;
  border-radius: 30px;

  & a {
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
  }

  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 60%);
  }
`;

const ImpactSection = styled(Flex)`
  background: #e0ebf666;
  height: 70vh;
  color: #000;
  ${onPhone} {
    height: 100%;
  }
  ${onSmallScreen} {
    height: 100%;
  }
`;

const ImpactHeader = styled(Flex)`
  background: #e0ebf6;
  height: 100%;
  width: 30%;
  font-size: 70px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: 1rem;
  ${onPhone} {
    width: 100%;
  }
  ${onSmallScreen} {
    width: 100%;
  }
`;

const ImpactBody = styled(Flex)`
  width: 70%;
  height: 100%;
  padding: 3rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 18px;
`;

const ImageWrap = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 100px;
  width: 100px;
  &:hover {
    box-shadow: 0px 2px 11px 5px rgb(0 0 0 / 60%);
  }
`;

const TeamHeader = styled.div`
  font-size: 54px;
  text-align: center;
  color: #f5821e;
  margin-bottom: 4rem;
`;

const MembersContainer = styled(Flex)`
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: start;
`;

const MemberDescBox = styled.div`
  width: 300px;
  padding: 10px;
  font-size: 12px;
`;

const MemberImg = styled.img``;

const CloseBtn = styled.div`
  float: right;
  margin: 0 -15px;
  margin-top: -5px;
  cursor: pointer;
`;

const NewsContainer = styled.div`
  display: flex;
  width: 75%;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 1rem;
  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

const NewsletterText = styled.div`
  font-size: 30px;
  padding-bottom: 3rem;
  max-width: 500px;
`;
const AboutUs = (props) => {
  let [redirectToReferer, setRedirectToReferer] = useState(false);
  let [memberDesc, setMemberDesc] = useState(null);
  let [showMemberDesc, setshowMemberDesc] = useState(false);

  let [bMemberDesc, setBMemberDesc] = useState(null);
  let [showBMemberDesc, setshowBMemberDesc] = useState(false);

  let displayOnHover = (memberId, type) => {
    if (type === 0) {
      // team member
      setMemberDesc(memberId);
      setshowMemberDesc(true);
    } else {
      // team member
      setBMemberDesc(memberId);
      setshowBMemberDesc(true);
    }
  };

  let hideOnHover = () => {
    setMemberDesc(null);
    setshowMemberDesc(false);
    setBMemberDesc(null);
    setshowBMemberDesc(true);
  };

  let login = (provider) => {
    return async () => {
      const subscribe = false;

      try {
        await auth.authenticate(provider, subscribe);
        setRedirectToReferer(true);
      } catch (err) {
        if (err && err.error && err.error.code === "cancelled") {
        }

        Raven.captureException(err);
        console.error("Error", err);
      }
    };
  };

  if (redirectToReferer) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Helmet title="About | VTS at Watershed">
        <meta name="title" content="About | VTS at Watershed" />
        <meta name="description" content="About | VTS at Watershed" />
      </Helmet>
      <Navbar {...props} login={login} />
      <div style={{ background: "#E2F6F3" }}>
        <Container>
          <Grid
            style={{
              display: "grid",
              gridTemplateColumns: "60% 40%",
              gridColumGap: "2rem",
            }}
          >
            <div>
              <Header>About Us</Header>
              <Text>
                <div style={{ marginBottom: "1rem" }}>
                  When we founded Watershed, VTS was inaccessible to the vast majority PK-12 classrooms
                  in the U.S. and abroad. <br /> <br />
                  Though multiple longitudinal studies and thousands of classroom implementations had
                  demonstrated the effectiveness of VTS, dissemination of VTS was expensive, time-intensive, 
                  and otherwise hard to adopt. <br /> <br />
                  As a result, too few learners—and educators—benefited from the profound impact VTS can have
                  on achieving the goals of education. <br /> <br />
                  Watershed is on a mission to change that.
                </div>
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div>
                  <img
                    alt="Watershed"
                    src={require("../images/grey_watershed_logo.png")}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
      <Container
        style={{
          textAlign: "center",
          maxWidth: "1030px",
          marginBottom: "4rem",
        }}
      >
        <div style={{ fontSize: "54px", color: "#000" }}>
          Our <span style={{ color: "#f5821e" }}>Mission</span>
        </div>
        <Header style={{ fontSize: "32px", color: "#000" }}>
          Watershed is on a mission to make VTS accessible in any classroom in the U.S. 
          and abroad. To do so, Watershed employs digital technology and media,
          on-demand adoption models, and social enterprise tactics to make VTS adoption
          as easy, affordable, and attractive as possible.
        </Header>
      </Container>
      <Container>
        <ImpactSection>
          <ImpactHeader>
            The <br />
            Evolution of
            <br />
            <span style={{ color: "#f5821e" }}> VTS</span>
          </ImpactHeader>
          <ImpactBody>
            <div>
              Thirty years ago, Philip Yenawine and Abigail Housen met while Yenawine
              was the Director of Education at MoMA in New York and Housen was a
              cognitive psychologist (Ed.D., Harvard GSE) studying how people think
              when they look at art. Visual Thinking Strategies, or VTS, was born
              of their collaboration.
            </div>
            <div>
              Over the ensuing years, as Housen and others learned of and proved VTS’
              impact through multiple longitudinal studies, what began as a new way
              to teach in museums has steadily become indispensable to PK-12 classrooms.
            </div>
            <div>
              Historically, schools have adopted VTS for its way of engaging all
              students, and its impact on critical thinking, literacy, and
              communication skills. Increasingly, schools adopt VTS to build
              socio-emotional skills, re-infuse the arts in every classroom,
              and meet next generation learning standards.
            </div>
            <div>
              Watershed was founded to make VTS as accessible as possible to PK-12
              schools across the U.S. and abroad.
            </div>
          </ImpactBody>
        </ImpactSection>
      </Container>
      <Container
        style={{ textAlign: "center", maxWidth: "700px", padding: "3rem" }}
      >
        <Header style={{ fontSize: "32px" }}>
          Support our work today.
        </Header>
        <a
          style={{ color: "white" }}
          href="https://donorbox.org/vts-watershed-curriculum-expansion-2023-2024"
        >
          <CalloutButton>Donate Now</CalloutButton>
        </a>
      </Container>
      <div style={{background: "#FFF8F4"}}>
        <Container>
          <div style={{padding: "3rem" }}>
            <Header
              style={{ marginBottom: "0", fontWeight: "600", color: "#f5821e" }}
            >
              Our History
            </Header>
            <Header style={{ color: "#000" }}>Decades in the making</Header>
            <Timeline />
          </div>
        </Container>
      </div>
      <div style={{margin: "3rem 0"}}>
        <TeamHeader>Our Team</TeamHeader>
        <MembersContainer>
          {[
            {
              id: 1,
              image: require("../images/profiles/gretchen.jpg"),
              name: "Gretchen Baudenbacher",
              short:
                "Through her work at museums and non-profit organizations, Gretchen Baudenbacher developed a strong commitment to student-centered and constructivist pedagogy. She believes strongly in collaboration and has leveraged this skill in the development of curriculum materials for Visual Thinking Strategies (VTS), the Educational Video Center (EVC) and Photoville. She is also passionate about supporting educators through professional development and coaching and relishes opportunities to interact with students of all ages.",
              description:
                "Gretchen began her work with VTS in 1999 as the Image and Production Coordinator, and has since led or co-led VTS workshops and programs in NYC and nationally, is a certified VTS Trainer and Coach, and now develops and delivers all of Watershed’s PK-12 VTS professional development and curriculum offerings. Gretchen completed her bachelor’s degree in Art History at the University of Wisconsin, Madison and her master’s degree in Media Ecology at NYU’s Steinhardt School of Education. She is based in Brooklyn, NY.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/gretchen-baudenbacher-387b7713/",
                },
              ],
            },
            {
              id: 2,
              image: require("../images/profiles/nick.jpg"),
              name: "Nick Gardner",
              short:
                "Nick has worked in support of broader dissemination of VTS for nearly 20 years. He co-founded Watershed to employ emerging technologies and social enterprise strategies toward that end. ",
              description:
                "Since its founding, Watershed has developed a comprehensive VTS product portfolio and proprietary LMS - now capable of supporting highly-effective, large-scale VTS adoption in thousands of classrooms for as little as $1 per student. Nick continues to serve as Watershed’s founding Executive Director, supporting the Watershed operations and programs teams and working with the Watershed Board to set and implement the organization’s vision and strategy.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/nicholasdgardner",
                },
              ],
            },
            {
              id: 3,
              image: require("../images/profiles/philip.jpeg"),
              name: "Philip Yenawine",
              short:
                "Philip Yenawine is the co-creator of Visual Thinking Strategies and co-founder of Watershed. Philip and VTS helped revolutionize museum education during and after his time at the NYC MoMA and he continues to support VTS adoption across numerous fields and projects.",
              description:
                "After serving as Creative Director for several years, Philip remains engaged in Watershed’s product development and dissemination work and as an advisor to the Executive Director and Watershed’s Board. In recent years, Philip has authored two books on Visual Thinking Strategies (VTS) in K-12 and Early Childhood classrooms, both published by Harvard Education Press and available online.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/philip-yenawine-6279455",
                },
              ],
            },
          ].map((member) => (
            <FlexColumn style={{ width: "33%", margin: "5rem 0" }}>
              <ImageWrap>
                <MemberImg
                  style={{
                    maxWidth: "100%",
                    cursor: "pointer",
                  }}
                  src={member.image}
                  data-tip
                  data-for={member.name}
                  data-event='click focus'
                />
                <ReactTooltip
                  id={member.name}
                  className="customTheme"
                  effect="solid"
                  delayHide={300}
                  globalEventOff="click"
                >
                  <MemberDescBox>
                    <CloseBtn>
                      {" "}
                      <img src={require("../images/close_btn.svg")} />{" "}
                    </CloseBtn>
                    <div style={{ fontSize: "14px", marginBottom: "2rem" }}>
                      <b>{member.name}</b>
                    </div>
                    <div style={{ marginBottom: "1.5rem" }}>{member.short}</div>
                    <div>{member.description}</div>
                  </MemberDescBox>
                </ReactTooltip>
              </ImageWrap>
              <div>{member.name}</div>
              <div>
                <a href={member.links[0].to} target={"_blank"}>
                  <img src={require("../images/linkedin.png")} />
                </a>
              </div>
              {/* {member.id === memberDesc && showMemberDesc && (
                  <MemberDescBox>
                    <div>
                      <b>{member.name}</b>
                    </div>
                    <div>{member.short}</div>
                    <div>{member.description}</div>
                  </MemberDescBox>
                )} */}
            </FlexColumn>
          ))}
        </MembersContainer>
      </div>

      <div>
        <TeamHeader>Our Board</TeamHeader>
        <MembersContainer>
          {[
            {
              image: require("../images/profiles/justin.png"),
              name: "Justin Hwa",
              short:
                "Justin Hwa is a business leader, corporate innovator, and investor, with deep experience across Europe, the Middle East, Asia Pacific, and North America. He has built, scaled, and led numerous teams and businesses in the tech, marketplaces, ecommerce, and finance fields through his expertise in product & development, strategy, mergers & acquisitions, strategic partnerships, and general management and P&L ownership.",
              description:
                "Most recently, Justin leads eBay’s Commerce Innovations venture studio, based in Berlin. The studio is focused on launching new platforms, products, and businesses that augment and enhance the shopping experience on- and off-eBay. Prior to running Commerce Innovations, Justin led eBay’s M&A and Strategic Partnerships functions for EMEA. He was also an investment banker at Moelis & Company based in New York and Hong Kong, specializing in M&A and restructuring for Fortune 500 companies in Asia Pacific, Europe, and North America.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/justinhwa/",
                },
              ],
            },
            {
              image: require("../images/profiles/selena.jpg"),
              name: "Selina Meere",
              short:
                "Selina has more than two decades experience as a strategic leader who built distinguished marketing and communication teams within New York’s book publishing industry.",
              description:
                "After relocating to Los Angeles, she developed the national brand, voice, and salesforce for an award winning fintech startup and has positioned the narrative for a range of clients across entertainment and direct-to-consumer products. She is currently the Managing Director at Trevanna Tracks, a productivity and workflow SaaS tool for managing sync music licensing in film, television and video games, where she is overseeing all operational activities and driving growth strategy across the organization. She holds a Bachelors in Business from Gettysburg College, a Masters in Journalism from City University in London, and has studied marketing at the Kellogg School of Business at Northwestern",
              links: [
                {
                  type: "LinkedIn",
                  to:
                    "https://www.linkedin.com/in/selina-meere/",
                },
              ],
            },
            {
              image: require("../images/profiles/stephanie.jpg"),
              name: "Dr. Stephanie Varnon-Hughes",
              short:
                "Board Chair Dr. Stephanie Varnon-Hughes is the Director of Cross-Cultural & Interfaith Programs at Claremont Lincoln University.",
              description:
                " She is an award-winning teacher and interfaith leader whose research interests include the history, theories, and practices of inter-religious education, public policy, inequities in public education, and how digital and online resources can make education accessible and learner-focused. Her doctoral dissertation, in inter-religious education, focused on disequilibrium, resilience, and reflective practice as key ingredients for learning.  She was a co-founder and editor-in-chief of The Journal of Inter-Religious Studies, a peer reviewed journal, and its sister publication, State of Formation, an online forum for emerging religious and ethical leaders.  She holds a Ph.D. from Claremont Lincoln University, an M.A. and S.T.M. from Union Theological Seminary and her undergraduate degrees are in English and Education, from Webster University.",
              links: [
                {
                  type: "LinkedIn",
                  to:
                    "https://www.linkedin.com/in/stephanie-varnon-hughes-6a137311",
                },
              ],
            },
          ]
            .sort()
            .map((member) => (
              <FlexColumn style={{ width: "33%", margin: "5rem 0" }}>
                <ImageWrap>
                  <MemberImg
                    style={{
                      maxWidth: "100%",
                      cursor: "pointer",
                    }}
                    src={member.image}
                    data-tip
                    data-for={member.name}
                    data-event='click focus'
                  />
                  <ReactTooltip
                    place="left"
                    id={member.name}
                    className="customTheme"
                    effect="solid"
                    delayHide={300}
                    globalEventOff="click"
                  >
                    <MemberDescBox>
                      <CloseBtn>
                        {" "}
                        <img src={require("../images/close_btn.svg")} />{" "}
                      </CloseBtn>
                      <div style={{ fontSize: "14px", marginBottom: "2rem" }}>
                        <b>{member.name}</b>
                      </div>
                      <div style={{ marginBottom: "1.5rem" }}>
                        {member.short}
                      </div>
                      <div>{member.description}</div>
                    </MemberDescBox>
                  </ReactTooltip>
                </ImageWrap>
                <div>{member.name}</div>
                <div>
                  <a href={member.links[0].to} target={"_blank"}>
                    <img src={require("../images/linkedin.png")} />
                  </a>
                </div>
                {/* {member.id === bMemberDesc && showBMemberDesc && (
                  <MemberDescBox>
                    <div>
                      <b>{member.name}</b>
                    </div>
                    <div>{member.short}</div>
                    <div>{member.description}</div>
                  </MemberDescBox>
                )} */}
              </FlexColumn>
            ))}
        </MembersContainer>
      </div>

      <div style={{ background: "#E2F6F3" }}>
        <Container style={{paddingLeft: "0", maxWidth: "1200px"}}>
          <NewsContainer>
            <NewsletterText>
              Stay up to date on our work and the latest VTS offerings
            </NewsletterText>
            <CalloutButton>
              <a
                style={{ color: "white" }}
                href={`mailto:hello@watershed-ed.org?bcc=bcc@nutshell.com&subject=Please add me to the Watershed Newsletter`}
              >
                Join Our Newsletter
              </a>
            </CalloutButton>
          </NewsContainer>
        </Container>
      </div>
      <FooterComponent {...props}/>
    </Layout>
  );
};

export default AboutUs;