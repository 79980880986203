import { Label } from "./Input";
import styled, { css } from "styled-components/macro";
import React from "react";
import Box from "./Box";
import Text from "./Text";
import { themeGet } from "@styled-system/theme-get";
import { boxShadow } from "./util";

const StyledSelect = styled.select`
  -webkit-appearance: none;
  height: calc(2.25rem + 2px);
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: ${themeGet("colors.black")};
  background-color: white;
  font-family: ${themeGet("sansFont")};
  box-shadow: ${themeGet("elevations.0")};
  background-image: ${props =>
    `linear-gradient(to top, ${props.theme.colors.grayAlpha[2]}, ${
      props.theme.colors.whiteAlpha[4]
    })`};
  background-clip: padding-box;
  border-color: ${themeGet("colors.borderColor")};
  border-width: 1px;
  border-style: solid;
  border-radius: ${themeGet("radii.0")}px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.8ms;
  margin: 0;
  & [disabled] {
    opacity: 0.8;
    background-image: none;
    cursor: not-allowed;
  }
  :focus {
    border-color: ${themeGet("colors.blue.10")};
    box-shadow: ${boxShadow("colors.blue.8", 0.2)};
    outline: 0;
  }

  ${props => {
    if (props.size === "small") {
      return css`
        font-size: ${themeGet("fontSizes.1")}px;
        padding: 0.25rem 1rem;
        height: calc(1.7rem + 2px);
      `;
    }
  }};
`;

const Select = ({
  children,
  size,
  helpText,
  hideLabel,
  onChange,
  value,
  name,
  label
}) => (
  <Box>
    <Label hideLabel={hideLabel} htmlFor={name}>
      {label}
    </Label>
    <Box>
      <StyledSelect size={size} name={name} value={value} onChange={onChange}>
        {children}
      </StyledSelect>
    </Box>
    {helpText && (
      <Box my={1}>
        <Text fontSize={1} faded>
          {helpText}
        </Text>
      </Box>
    )}
  </Box>
);

export default Select;
