import gql from "graphql-tag";

export interface ResourceFragmentType {
  id: number;
  title: string;
  description: string;
  meta: {
    url?: string;
    isSurvey?: boolean;
    videoId?: number;
    video?: {
      id: number;
      url: string;
    };
    galleryId?: number;
    imageId?: number;
    image?: {
      id: number;
      public_id: string;
      width: number;
      height: number;
    };
  };
}

export const ResourceFragment = gql`
  fragment Resource on Resource {
    id
    title
    description
    meta {
      __typename
      ... on ResourceLinkMeta {
        url
        isSurvey
      }
      ... on ResourceSurveyMeta {
        url
        isSurvey
      }
      ... on ResourceVideoMeta {
        videoId
        video {
          id
          url
        }
      }
      ... on ResourceGalleryMeta {
        galleryId
      }
      ... on ResourceImageMeta {
        imageId
        image {
          id
          public_id
          width
          height
        }
      }
    }
  }
`;
