import ReactPlayer from "react-player";
import React from "react";
import debug from "debug";
import { isNumber } from "lodash";

const log = debug("app:UniversalPlayer");

class UniversalPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: this.props.playing
    };
  }

  static defaultProps = {
    playing: false
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.source !== this.props.source) {
  //     this.setState({ playing: false });
  //   }
  // }

  componentDidMount() {
    // return;

    if (isNumber(this.props.initialTime)) {
      log("skip to initial time: %s", this.props.initialTime);
      // this.video.seekTo(this.props.initialTime);
      this._skip = () => {
        // this.video.seekTo(this.props.initialTime);
        // this._skip = null;
        log("calling skip: %s", this.props.initialTime);

        // this.setState({ playing: true });
        // if (!this.props.autoplay) {
        //   this.setState({ playing: false });
        // }
      };
    }
  }

  onSeek = () => {
    if (this._skip) {
      log("after skip called");
      // this.setState({ playing: false });
      this._skip = null;
    }
  };

  seekTo = time => {
    log("seek to: %s", time);
    this.video.seekTo(time);
  };

  render() {
    return (
      <ReactPlayer
        controls
        height="100%"
        playsinline
        playing={this.state.playing}
        width="100%"
        config={{
          vimeo: {
            playerOptions: {
              autoplay: 1
            }
          },
          youtube: {
            playerVars: { showInfo: 0 }
          }
        }}
        style={{
          backgroundColor: "black",
          // objectFit: "cover",
          maxWidth: "100%"
        }}
        onError={this.props.onError}
        onStart={this.props.onStart}
        onSeek={this.onSeek}
        ref={video => (this.video = video)}
        onPlay={() => {
          log("on play");
          this.setState({ playing: true });
        }}
        url={this.props.source}
        onEnded={() => {
          log("on ended");
          if (this.props.onEnded) {
            this.props.onEnded();
          }
        }}
        onReady={() => {
          if (this._skip) {
            this._skip();
          }
          if (this.props.onCanPlay) {
            this.props.onCanPlay();
          }
        }}
        onProgress={({ playedSeconds }) => {
          if (this.props.onTimeUpdate) {
            this.props.onTimeUpdate(playedSeconds);
          }
        }}
      />
    );
  }
}

export default UniversalPlayer;
