import React, { cloneElement } from "react";
import RcDropdown from "rc-dropdown";
import debug from "debug";
import "./Dropdown.css";
import styled from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const log = debug("app:Kit:Dropdown");

export default class Dropdown extends React.Component {
  state = {
    visible: false
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      id: "a" + Date.now()
    };
  }

  static propTypes = {
    trigger: PropTypes.element.isRequired,
    children: PropTypes.element.isRequired
  };

  static defaultProps = {
    mouseEnterDelay: 0.15,
    mouseLeaveDelay: 0.1,
    placement: "bottomCenter"
  };

  change = state => {
    log("visible %s", state);
    this.setState({ visible: state });
  };

  hide = e => {
    e.stopPropagation();
    log("hide dropdown");
    this.setState({ visible: false });
  };

  render() {
    const { trigger, children, unmount } = this.props;

    const overlay = cloneElement(children);

    const dropdownTrigger = cloneElement(trigger, {
      id: this.state.id,
      "aria-haspopup": true,
      "aria-expanded": this.state.visible,
      onClick: e => e.stopPropagation()
    });

    const { visible } = this.state;
    return (
      <RcDropdown
        transitionName="rc-dropdown-slide-up"
        aria-labelledby={this.state.id}
        overlayClassName={"rc-dropdown-menu " + this.props.className || ""}
        overlay={unmount && !visible ? <span /> : overlay}
        align={this.props.align}
        trigger={["click"]}
        onOverlayClick={this.hide}
        onVisibleChange={this.change}
        visible={visible}
      >
        {dropdownTrigger}
      </RcDropdown>
    );
  }
}

const DropdownItem = styled.a`
  display: block;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  width: calc(100% - 8px);
  cursor: pointer;
  text-align: inherit;
  margin: 0 4px;
  text-decoration: none;
  border-radius: ${themeGet("radii.0")}px;
  color: ${themeGet("colors.black")};
  :hover {
    background-color: ${themeGet("colors.blue.4")};
    color: ${themeGet("colors.blue.20")};
  }
  white-space: nowrap;
  background: 0 0;
  border: 0;
`;

Dropdown.Item = DropdownItem;

const DropdownItemLink = DropdownItem.withComponent(Link);

const DropdownItemButton = DropdownItem.withComponent("button");

Dropdown.ItemLink = DropdownItemLink;
Dropdown.ItemButton = DropdownItemButton;

const DropdownDivider = styled.div`
  height: 0px;
  display: block;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${themeGet("colors.borderColor")};
`;

Dropdown.Divider = DropdownDivider;
