import React from "react";
import styled, { css } from "styled-components/macro";
import Box from "./Box";
import { themeGet } from "@styled-system/theme-get";
import Layer from "./Layer";
import Text from "./Text";
import Flex from "./Flex";
import Image from "./Image";
import { CheckCircleIcon, QuestionIcon, DangerIcon, WarningIcon } from "./Icon";
import { CloseButton } from "./IconButton";

const Alert = ({
  onClose,
  children,
  subtext,
  elevation = 1,
  img,
  title = "An error has occurred.",
  ...other
}) => {
  const icon = getIconForType(other.type);

  return (
    <StyledAlert elevation={elevation} role="alert" img={img} {...other}>
      {icon && (
        <Flex mx={2} mt={2}>
          {icon}
        </Flex>
      )}
      {img && (
        <Image
          width={75}
          height={75}
          style={{
            flexBasis: "75px",
            flexGrow: 0,
            flexShrink: 0,
            alignSelf: "center",
            borderRadius: "4px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            marginRight: "16px"
          }}
          {...img}
        />
      )}
      <Box py={2} pr={onClose ? 3 : 2} style={{ textAlign: "left" }}>
        {onClose && (
          <CloseButton
            onClick={e => {
              e.stopPropagation();
              onClose(e);
            }}
            style={{ position: "absolute", top: "10px", right: "8px" }}
          />
        )}
        <Text as="div" lineHeight="1.5" pr={onClose ? 3 : 0} fontSize={1}>
          <Box>
            <Text lineHeight="1.5" fontWeight={500} fontSize={1}>
              {title}
            </Text>
          </Box>
          {children && " "}
          {children}
        </Text>
      </Box>
    </StyledAlert>
  );
};

Alert.defaultProps = {
  type: "default"
};

const getColorForType = type => {
  switch (type) {
    case "success":
      return "green.20";
    case "question":
      return "blue.20";
    case "danger":
      return "red.20";
    case "warning":
      return "yellow.20";
    default:
      return "black";
  }
};

const getIconForType = type => {
  const iconProps = {
    size: 22,
    iconSize: 14,
    color: getColorForType(type)
  };

  switch (type) {
    case "success":
      return <CheckCircleIcon {...iconProps} />;
    case "question":
      return <QuestionIcon {...iconProps} />;
    case "danger":
      return <DangerIcon {...iconProps} />;
    case "warning":
      return <WarningIcon {...iconProps} />;
    default:
      return null;
  }
};

const StyledAlert = styled(Layer)`
  background-color: white;
  overflow: hidden;
  max-width: 650px;
  position: relative;
  display: flex;
  ${props => {
    const color = getColorForType(props.type);
    const colorFn = themeGet("colors." + color);

    if (props.type === "default" && !props.img) {
      return css`
        padding-left: 16px;
      `;
    }

    if (!props.img) {
      return css`
        &:before {
          content: "";
          width: ${themeGet("radii.0")}px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: ${colorFn(props)};
        }
      `;
    }
  }};
`;

export default Alert;
