import { createGlobalStyle } from "styled-components";

import latinModernMono from "./latin-modern-mono.woff";
import sofiaPro from "./sofia-pro.otf";
import sofiaProBold from "./sofia-pro-bold.otf";
import poppinsRegular from "./Poppins-Regular.ttf";
import poppinsBold from "./Poppins-Bold.ttf";
import poppinsExtrabold from "./Poppins-ExtraBold.ttf";
import crimsonTextBold from "./CrimsonText-Bold.ttf";
import crimsonText from "./CrimsonText-Regular.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'latin-modern-mono';
        src: local('latin-modern-mono'), local('latin-modern-mono'),
        url(${latinModernMono}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'sofia-pro';
        src: local('sofia-pro'), local('sofia-pro'),
        url(${sofiaPro}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'sofia-pro-bold';
        src: local('sofia-pro-bold'), local('sofia-pro-bold'),
        url(${sofiaProBold}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'poppins-regular';
        src: local('poppins-regular'), 
            url(${poppinsRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'poppins-bold';
        src: local('poppins-bold'),
        url(${poppinsBold}) format('truetype');
        font-style: normal;
        font-weight: 700;
    }
    @font-face {
        font-family: 'poppins-extrabold';
        src: local('poppins-extrabold'),
        url(${poppinsExtrabold}) format('truetype');
        font-style: normal;
        font-weight: 800;
    }
    @font-face {
        font-family: 'crimsontext-bold';
        src: local('crimsontext-bold'),
        url(${crimsonTextBold}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: 'crimsontext';
        src: local('crimsontext'),
        url(${crimsonText}) format('truetype');
        font-style: normal;
    }
`;
