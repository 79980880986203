import React from "react";
import { RichUtils } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import "./Editor.css";
import { clearEditorContent } from "draftjs-utils";
import "draft-js-linkify-plugin/lib/plugin.css";
import createLinkPlugin from "draft-js-anchor-plugin";
import EditorUtils from "draft-js-plugins-utils";

const linkPlugin = createLinkPlugin();

// const linkifyPlugin = createLinkifyPlugin();

export const getDecorators = () => {
  return null;
};

export default class RichEditorExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plugins: [createLinkifyPlugin({ target: "_blank" }), linkPlugin]
    };
    this.focus = () => this.refs.editor.focus();
    this.onChange = editorState => {
      if (this.props.onChange) {
        this.props.onChange(editorState);
      }
    };
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.onTab = this._onTab.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }

  // componentDidMount() {
  //   if (this.props.autoFocus) {
  //     this.focus();
  //   }
  // }

  static defaultProps = {
    controls: true,
    placeholder: "Type a comment..."
  };
  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }
  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth));
  }
  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
  }
  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
    );
  }
  render() {
    const { className: customClass, editorState } = this.props;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor " + customClass || "";

    return (
      <div className="RichEditor-root">
        {this.props.controls && (
          <div>
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
              onCreateLink={url => {
                const nextState = EditorUtils.createLinkAtSelection(
                  editorState,
                  url
                );
                console.log(nextState);
                this.onChange(nextState);
              }}
            />
          </div>
        )}
        <div className={className} onClick={this.focus}>
          <Editor
            readOnly={this.props.disabled}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            onBlur={this.props.onBlur}
            handleReturn={this.props.submitOnReturn ? this.handleReturn : null}
            placeholder={!this.props.disabled && this.props.placeholder}
            ref="editor"
            spellCheck={true}
            autoCapitalize="sentences"
            autoComplete="on"
            stripPastedStyles={true}
            plugins={this.state.plugins}
          />
        </div>
      </div>
    );
  }

  handleReturn = e => {
    if (!e.shiftKey) {
      this.props.submitOnReturn();
      const cleared = clearEditorContent(this.props.editorState);

      if (this.props.onChange) {
        this.props.onChange(cleared);
      }
      return "handled";
    } else {
      return "not-handled";
    }
  };
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" }
];

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
      <StyleButton
        active={currentStyle.has("LINK")}
        label="Link"
        onToggle={() => {
          const url = window.prompt("Enter the URL for the selected text");
          if (url) {
            props.onCreateLink(url);
          }
        }}
        style={"link"}
      />
    </div>
  );
};

const Link = ({ children, className, entityKey, getEditorState, target }) => {
  const entity = getEditorState()
    .getCurrentContent()
    .getEntity(entityKey);
  const entityData = entity ? entity.get("data") : undefined;
  const href = (entityData && entityData.url) || undefined;

  return (
    <a
      className={className}
      title={href}
      href={href}
      target={target}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
