import React from "react";
import { Query } from "react-apollo";
import debug from "debug";
import { UserPresenceQuery } from "../API/queries/UserPresenceQuery";
import { UserPresenceSubscription } from "../API/subscriptions/UserPresenceSubscription";

const log = debug("app:UserWithPresence");

class UserWithSubscription extends React.Component {
  componentDidMount() {
    this.props.subscribeToPresence();
  }
  render() {
    const { children, ...other } = this.props;
    return children(other);
  }
}

const UserWithQuery = ({ userId, ...other }) => (
  <Query
    variables={{
      userId
    }}
    query={UserPresenceQuery}
  >
    {({ subscribeToMore, data, loading }) => {
      if (!data) return null;

      return (
        <UserWithSubscription
          user={data.user}
          isOnline={data && data.user && data.user.isOnline}
          {...other}
          subscribeToPresence={() =>
            subscribeToMore({
              document: UserPresenceSubscription,
              variables: { userId },
              updateQuery: (prev, { subscriptionData }) => {
                log("received update: %o", subscriptionData);
                if (!subscriptionData.data) return prev;
                log("previous: %o", prev);
                log("received subscription data: %o", subscriptionData);
                const newUserStatus = subscriptionData.data.presence;
                return Object.assign({}, prev, {
                  isOnline: newUserStatus.isOnline
                });
              }
            })
          }
        />
      );
    }}
  </Query>
);

export default UserWithQuery;
