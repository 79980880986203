import gql from "graphql-tag";

export const CreateImageResourceMutation = gql`
  mutation CreateImageResource(
    $imageId: Int!
    $input: CreateImageResourceInputType!
  ) {
    createImageResource(imageId: $imageId, input: $input) {
      id
    }
  }
`;

export const UpdateImageResourceMutation = gql`
  mutation UpdateImageResource(
    $id: Int!
    $input: UpdateImageResourceInputType!
    $imageId: Int
  ) {
    updateImageResource(id: $id, input: $input, imageId: $imageId) {
      id
      title
      description
      meta {
        ... on ResourceLinkMeta {
          url
        }
        ... on ResourceSurveyMeta {
          url
          isSurvey
        }
        ... on ResourceVideoMeta {
          videoId
          video {
            id
            url
          }
        }
        ... on ResourceGalleryMeta {
          galleryId
        }
        ... on ResourceImageMeta {
          imageId
          image {
            id
            public_id
            width
            height
          }
        }
      }
    }
  }
`;
