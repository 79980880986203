import React from "react";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import * as _ from 'lodash';
import "./Gallery.css";
import { Button, Text, Box, withLoading } from "../Kit";
import { imageURL, SIZES } from "../Kit/Image";
import { getThumbnailURL } from "../Kit/Thumbnail";
import ImageGallery from "react-photo-gallery";
import { GalleryQuery } from "../API/queries/GalleryQuery";
import Overlay from "../Kit/Overlay";
import { Gallery as GestureGallery, GalleryImage } from "react-gesture-gallery";
import { CloseButton } from "../Kit/IconButton";

class Gallery extends React.Component {
  state = {
    currentImage: 0
  };

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  };
  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };
  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  render() {
    const { data } = this.props;

    if (!data.gallery) {
      return null;
    }

    const urls = (_.sortBy(data.gallery.galleryImages, ['orderNumber']) || []).map(galleryImage => {
      const url = getThumbnailURL(galleryImage.image, "monster");
      return {
        src: url,
        width: galleryImage.image.width,
        height: galleryImage.image.height
      };
    });

    const lightboxImages = (_.sortBy(data.gallery.galleryImages, ['orderNumber']) || []).map(galleryImage => {
      return {
        caption: galleryImage.image.caption,
        src: imageURL(galleryImage.image.public_id, {
          fetchFormat: "jpg",
          ...SIZES.giant,
          crop: "fit",
          gravity: null
        }),
        ...SIZES.giant
      };
    });

    return (
      <Box mb={this.props.details ? 4 : 2}>
        <Button
          block
          style={{
            borderRadius: this.props.details ? "8xp" : 0,
            paddingRight: "0",
            paddingLeft: "16px",
            textAlign: "left"
          }}
          onClick={() => {
            this.openLightbox(null, { index: 0 });
          }}
          appearance="ghost"
        >
          <Box alignItems="center" display="flex">
            <Box flex="1">
              <Text
                fontSize={this.props.details ? 2 : 0}
                fontWeight={this.props.details ? "bold" : 500}
              >
                {this.props.title}
              </Text>
              <Text fontSize={this.props.details ? 1 : 0} display="block" faded>
                {this.props.description}
              </Text>
            </Box>
            <Box flex="0 0 auto">{this.props.children}</Box>
          </Box>
        </Button>
        <Box px={this.props.details ? 2 : 0} pt={this.props.details ? 1 : 0}>
          <ImageGallery onClick={this.openLightbox} columns={4} photos={urls} />
        </Box>
        <Overlay
          backgroundColor="black"
          isOpen={this.state.lightboxIsOpen}
          onExit={this.closeLightbox}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 1000,
              bottom: 0,
              left: 0
            }}
          >
            <CloseButton
              onClick={() => this.setState({ lightboxIsOpen: false })}
              style={{
                position: "fixed",
                top: "1rem",
                right: "1rem",
                zIndex: 5000
              }}
            />
            {this.state.lightboxIsOpen && (
              <GestureGallery
                enableIndicators={false}
                index={this.state.currentImage}
                onRequestChange={i => {
                  this.setState({ currentImage: i });
                }}
              >
                {lightboxImages.map(image => (
                  <GalleryImage
                    objectFit="contain"
                    key={image.src}
                    src={image.src}
                  />
                ))}
              </GestureGallery>
            )}
          </div>
        </Overlay>
      </Box>
    );
  }
}

const withGallery = graphql(GalleryQuery, {
  options: props => ({
    variables: { id: props.galleryId }
  })
});

export const GalleryWithData = compose(
  withGallery,
  withLoading
)(Gallery);
