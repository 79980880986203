import React from "react";
import styled from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";

import { CloseIcon } from "./Icon";

const IconButton = ({ style, label, onClick, children, ...other }) => (
  <IconButtonStyled style={style} onClick={onClick} label={label} {...other}>
    {children}
  </IconButtonStyled>
);

export default IconButton;

const IconButtonStyled = styled.button`
  padding: 0;
  background: 0 0;
  border: 0;
  cursor: pointer;
  display: flex;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  background-color: transparent;
  color: ${themeGet("colors.gray.8")};
  -webkit-appearance: none;
  border-radius: ${themeGet("radii.0")}px;
  outline: none;
  :focus {
    z-index: 2;
    box-shadow: rgba(1, 108, 209, 0.3) 0px 0px 0px 3px;
    color: ${themeGet("colors.black")};
  }
  @media (hover: hover) {
    :hover {
      color: ${themeGet("colors.black")};
      background-color: ${themeGet("colors.whiteAlpha.8")};
    }
  }
  &[aria-expanded="true"] {
    color: white !important;
    background-color: ${themeGet("colors.gray.4")};
  }
`;

export const CloseButton = ({ style, onClick, label, ...other }) => (
  <IconButton style={style} onClick={onClick} label="close" {...other}>
    <CloseIcon {...other} />
  </IconButton>
);
