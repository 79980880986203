import React from "react";
import { Box } from ".";
import Overlay from "./Overlay";
import { keyframes, css } from "styled-components/macro";
import { animationEasing } from "./Overlay";
import styled from "styled-components/macro";

const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const slideInAnimationFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutAnimationFromRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const AnimatedBox = styled(Box)`
  transform: ${props =>
    props.fromRight ? "translateX(100%)" : "translateX(-100%)"};
  z-index: 1000;
  ${props => {
    if (props.state === "entering" || props.state === "entered") {
      return css`
        animation: ${props.fromRight
            ? slideInAnimationFromRight
            : slideInAnimation}
          240ms ${animationEasing.deceleration} both;
      `;
    } else if (props.state === "exiting") {
      return css`
        animation: ${props.fromRight
            ? slideOutAnimationFromRight
            : slideOutAnimation}
          240ms ${animationEasing.acceleration} both;
      `;
    }
  }};
`;

const Sheet = ({
  fromRight = false,
  w = ["100vw", "400px"],
  bg = "white",
  onCloseComplete = () => {},
  showing,
  children
}) => (
  <Overlay onExited={onCloseComplete} isOpen={showing}>
    {({ state, close }) => (
      <AnimatedBox
        width={w}
        height="100vh"
        position="fixed"
        top="0"
        left={fromRight ? null : "0"}
        boxShadow={3}
        right="0"
        bottom="0"
        fromRight={fromRight}
        aria-hidden={showing}
        bg={bg}
        state={state}
      >
        <Box
          id="Sheet"
          className="Sheet"
          width={1}
          height={"100%"}
          style={{
            overflowY: "scroll",
            position: "relative"
          }}
        >
          {children}
        </Box>
      </AnimatedBox>
    )}
  </Overlay>
);

export default Sheet;
