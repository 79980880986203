import * as React from "react";
import { addConversationIdToSubscriptions } from "./ChatSubscriptionHandler";
import { withApollo } from "react-apollo";
import debug from "debug";

const log = debug("app:HandleNewlyCreatedChats");

class HandleNewlyCreatedChats extends React.Component {
  componentDidMount() {
    this.maybeSubscribeToConversation(this.props.conversationId);
    this.assign();
  }

  assign = () => {
    const isOpenIndex = this.props.chats.chatIsOpen(
      this.props.conversationId,
      this.props.userIds
    );
    if (isOpenIndex > -1) {
      this.props.chats.assignConversationIdToIndex(
        this.props.conversationId,
        isOpenIndex
      );
    }
  };

  maybeSubscribeToConversation(conversationId) {
    const alreadySubscribed =
      this.props.subscribedChatIds.indexOf(conversationId) > -1;
    if (alreadySubscribed) {
      log("already subscribed");
    }

    if (!alreadySubscribed) {
      log("adding new subscription");
      addConversationIdToSubscriptions(this.props.client, conversationId);
    }
  }

  render() {
    return null;
  }
}

export default withApollo(HandleNewlyCreatedChats);
