import React from "react";
import { space } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import Text from "./Text";
import styled from "styled-components/macro";

const HeadingStyled = styled(Text)`
  margin: 0;
  font-family: ${themeGet("headingFont")};
  line-height: 1.25;
  font-weight: ${themeGet("headingFontWeight")};
  ${space};
`;

export const Heading = props => <HeadingStyled as={"h1"} {...props} />;

export default Heading;

export const ResponsiveHeader = ({ children, ...other }) => (
  <Text
    display="block"
    fontWeight={600}
    lineHeight={"1.1"}
    fontSize={[6, 6, 7]}
    maxWidth="50rem"
    mb={2}
    {...other}
  >
    {children}
  </Text>
);

export const ResponsiveTitle = ({ children, ...other }) => (
  <Text
    display="block"
    fontWeight={600}
    fontSize={[4, 4, 5]}
    maxWidth="50rem"
    {...other}
  >
    {children}
  </Text>
);

export const ResponsiveDescription = ({ children, ...other }) => (
  <Text display="block" fontSize={[3, 3, "16px"]} maxWidth="48rem" {...other}>
    {children}
  </Text>
);
