import * as React from "react";
import UserWithPresence from "./UserWithPresence";
import Avatar from "../Kit/Avatar";
import { Box } from "../Kit";
import styled, { css } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import UserCard from "./UserCard";

export const GreenIndicator = styled.div`
  background-color: ${themeGet("colors.green.20")};
  border-radius: 50%;
  height: 11px;
  left: 70%;
  position: absolute;
  top: 70%;
  width: 11px;
  z-index: 1;
  ${props => {
    if (props.size === "large") {
      return css`
        top: 74%;
        left: 74%;
      `;
    }
  }}
`;

const AvatarWithPresence = ({ userId, ...other }) => (
  <UserWithPresence userId={userId}>
    {({ isOnline, user }) => (
      <Box position="relative">
        {isOnline && <GreenIndicator size={other.size} />}
        <Avatar
          {...other}
          ariaHidden={false}
          tooltipTheme="light"
          customTooltipContent={<UserCard user={user} />}
        />
      </Box>
    )}
  </UserWithPresence>
);

export default AvatarWithPresence;
