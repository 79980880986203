import React from "react";
import Layer from "./Layer";
import Box from "./Box";
import styled, { css, keyframes } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import Heading from "./Heading";
import { CloseButton } from "./IconButton";
import Overlay from "./Overlay";

const easing = {
  deceleration: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
  acceleration: `cubic-bezier(0.4, 0.0, 1, 1)`
};

const ANIMATION_DURATION = 600;

const openAnimation = keyframes`
  0% {
    transform: scale(0.4);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const closeAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.4);
    opacity: 0;
  }
`;

export const animation = props => {
  if (props.state === "entering" || props.state === "entered") {
    return css`${openAnimation} ${ANIMATION_DURATION}ms ${
      easing.deceleration
    } both`;
  }

  if (props.state === "exiting") {
    return css`${closeAnimation} ${ANIMATION_DURATION}ms ${
      easing.acceleration
    } both`;
  }
};

export default class Modal extends React.Component {
  static defaultProps = {
    isTinted: true
  };

  componentDidMount() {
    this.focusAfterRender = true;
    document.body.addEventListener("keydown", this.onEscape, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.onEscape, false);
    if (this.returnElement) {
      this.returnElement.focus();
    }
  }

  componentWillMount() {
    this.returnElement = document.activeElement;
  }

  componentDidUpdate() {
    if (this.focusAfterRender) {
      this.focusContent();
      this.focusAfterRender = false;
    }
  }

  contentHasFocus = () => {
    document.activeElement === this.content ||
      this.content.contains(document.activeElement);
  };

  focusContent() {
    this.content && !this.contentHasFocus() && this.content.focus();
  }

  onEscape = e => {
    if (e.keyCode === 27) {
      this.close();
    }
  };

  close = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  render() {
    const {
      isTinted,
      appearance,
      children,
      small,
      contentLabel,
      ...other
    } = this.props;

    // const childs = React.cloneElement(children, {
    //   onRequestClose: this.close
    // });

    return (
      <Overlay appearance={appearance} onExited={this.close} {...other}>
        {({ state }) => (
          <ModalContainer
            appearance={appearance}
            role="dialog"
            tabIndex="-1"
            aria-label={contentLabel}
          >
            <ModalDialog
              small={small}
              className="Modal-dialog"
              state={state}
              role="document"
              innerRef={content => (this.content = content)}
            >
              <ModalContent className="Modal-content">{children}</ModalContent>
            </ModalDialog>
          </ModalContainer>
        )}
      </Overlay>
    );
  }
}

const ModalContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: transparent;
  ${props => {
    if (props.appearance === "white") {
      return css`
        & .Modal-content {
          background-color: transparent;
          border: none;
          box-shadow: none;
        }

        & .Modal-header {
          border: none;
          background: none;
        }
      `;
    }
  }}
`;

const ModalDialog = styled.div`
  pointer-events: none;
  margin: 10px;
  outline: none;
  animation: ${animation};
  position: relative;
  @media screen and (min-width: 567px) {
    max-width: 500px;
    margin: 30px auto;
  }

  @media screen and (min-width: 768px) {
    max-width: 650px;
    margin: 30px auto;
  }

  ${props => {
    if (props.small) {
      return css`
        max-width: 450px !important;
      `;
    }
  }};
`;

const ModalContent = styled(Layer)`
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  /* border-radius: ${themeGet("radii.1")}px; */
  outline: 0;
`;

ModalContent.defaultProps = {
  elevation: 4
};

const ModalHeaderStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${themeGet("space.2")}px;
  align-items: center;
  // background-color: ${themeGet("colors.gray.0")};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${themeGet("colors.borderColor")};
  border-top-left-radius: ${themeGet("radii.1")}px;
  border-top-right-radius: ${themeGet("radii.1")}px;
`;

const ModalHeader = ({ style, title, onRequestClose }) => (
  <ModalHeaderStyled className="Modal-header" style={style}>
    {typeof title === "string" ? (
      <Heading fontSize={3}>{title}</Heading>
    ) : (
      title
    )}
    {onRequestClose && <CloseButton onClick={onRequestClose} />}
  </ModalHeaderStyled>
);

Modal.Header = ModalHeader;

const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: ${themeGet("space.2")}px;
`;

Modal.Body = ModalBody;

const ModalFooter = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${themeGet("space.2")}px;
`;

Modal.Footer = ModalFooter;

export class WithModal extends React.Component {
  state = {
    isOpen: false
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  onClickTarget = e => {
    e.preventDefault();
    this.setState({ isOpen: true });
  };

  render() {
    const child = React.Children.only(this.props.trigger);
    const target = React.cloneElement(child, {
      key: "trigger",
      onClick: this.onClickTarget
    });

    const clonedContent = React.cloneElement(this.props.children, {
      onRequestClose: this.close
    });

    return [
      target,
      <Modal
        key="modal"
        onRequestClose={this.close}
        contentLabel={this.props.title}
        isOpen={this.state.isOpen}
      >
        {!this.props.hideHeader && (
          <Modal.Header title={this.props.title} onRequestClose={this.close} />
        )}
        {clonedContent}
      </Modal>
    ];
  }
}
