import gql from "graphql-tag";
import { QuestionFragment } from "../fragments/QuestionFragment";
import { AnnotationFragment } from "../fragments/AnnotationFragment";

export const StepCommentsQuery = gql`
  query StepComments($id: Int!, $groupId: Int, $enrollmentId: Int) {
    step(id: $id) {
      id
      annotations(groupId: $groupId) {
        id
        ...Annotation
      }
      questions {
        __typename
        id
        ...Question
      }
    }
  }
  ${QuestionFragment}
  ${AnnotationFragment}
`;
