import gql from "graphql-tag";
import { ResourceFragment } from "./ResourceFragment";
import { ImageFragment } from "./ImageFragment";
import { CommentFragment } from "./CommentFragment";

export const AnnotationFragment = gql`
  fragment Annotation on Annotation {
    id
    beginsAt
    content
    createdAt
    permalink
    ratingCount
    likedByViewer
    group {
      id
      role
      section {
        id
        courseId
      }
      viewerEnrollment {
        id
      }
    }
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
    comments {
      id
      ...Comment
    }
    createdBy {
      id
      name
      photo {
        ...Image
      }
    }
  }
  ${CommentFragment}
  ${ResourceFragment}
  ${ImageFragment}
`;
