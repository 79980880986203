import React from "react";
import { Subscribe, Container } from "unstated";
import EventEmitter from "eventemitter3";

// type PlaybackState = {
//   time: number
// };

export class PlaybackContainer extends Container {
  state = {
    time: 0
  };

  update(time) {
    this.setState({ time });
  }

  reset() {
    this.setState({ time: 0 });
  }
}

export function WithPlaybackState({ children }) {
  return <Subscribe to={[PlaybackContainer]}>{children}</Subscribe>;
}

export const emitter = new EventEmitter();
