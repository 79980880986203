import gql from "graphql-tag";
import { UserBasicsFragment } from "../fragments/UserBasicsFragment";
import { EpisodeStepDetailsFragment } from "../fragments/EpisodeStepDetailsFragment";

export const EpisodeStepQuery = gql`
  query EpisodeStep($stepId: Int!, $groupId: Int, $enrollmentId: Int) {
    step(id: $stepId) {
      id
      nextStep {
        id
        title
      }
      previousStep {
        id
        title
      }
      ...EpisodeStepDetails
    }
    viewer {
      id
      ...UserBasics
    }
    group(id: $groupId) {
      id
      role
      viewerEnrollment {
        id
      }
      section {
        id
        mode
        courseId
        title
        shortDescription
        image {
          id
          public_id
        }
      }
    }
  }
  ${EpisodeStepDetailsFragment}
  ${UserBasicsFragment}
`;
