import React from "react"; //eslint-disable-line
import {
  flexWrap,
  flexDirection,
  justifyContent,
  alignItems
} from "styled-system";
import Box from "./Box";
import styled from "styled-components/macro";

export const Flex = styled(Box)`
  display: flex;
  ${flexWrap};
  ${flexDirection};
  ${justifyContent};
  ${alignItems};
`;

export default Flex;
