import React from "react";
import { Box, TabContent, Select } from "../../Kit";
import { AddLink, UpdateLink } from "./AddResource";
import AddVideo, { UpdateVideo } from "./AddVideo";
import AddGallery, { UpdateGallery } from "./AddGallery";
import { AddImage, UpdateImage } from "./AddImage";

// type State = {
//   tab: "add" | "select",
//   type: "video" | "link"
// };

// type Props = {
//   onRequestClose: () => void,
//   showSelect: boolean,
//   courseId: number,
//   publisherId: number
// };

class ResourcePanel extends React.Component {
  state = {
    tab: "add",
    type: "ResourceLinkMeta"
  };

  static defaultProps = {
    showSelect: true
  };

  render() {
    const { tab, type } = this.state;
    const {
      publisherId,
      onRequestClose,
      showSelect,
      courseId,
      edit
    } = this.props;

    const getType = () => {
      if (!edit) return type;
      const {
        meta: { __typename }
      } = edit;
      return __typename;
    };

    let t = getType();

    return (
      <Box>
        <Box>
          {tab === "add" && (
            <Box p={1} pb={0}>
              {showSelect && (
                <Box p={2} pb={0}>
                  <TabContent id="add">
                    <Select
                      label="Resource Type"
                      value={type}
                      onChange={this.onSelectChange}
                    >
                      <option value="ResourceLinkMeta">Link</option>
                      <option value="ResourceSurveyMeta">Survey</option>
                      <option value="ResourceVideoMeta">Video</option>
                      <option value="ResourceGalleryMeta">Gallery</option>
                      <option value="ResourceImageMeta">PDF</option>
                    </Select>
                  </TabContent>
                </Box>
              )}
              <Box>
                {t === "ResourceLinkMeta" &&
                  (edit ? (
                    <UpdateLink
                      resource={edit}
                      onRequestClose={onRequestClose}
                    />
                  ) : (
                    <AddLink
                      resource={edit}
                      courseId={courseId}
                      onRequestClose={onRequestClose}
                    />
                  ))}
                  {t === "ResourceSurveyMeta" &&
                  (edit ? (
                    <UpdateLink
                      resource={edit}
                      onRequestClose={onRequestClose}
                    />
                  ) : (
                    <AddLink
                      resource={edit}
                      courseId={courseId}
                      onRequestClose={onRequestClose}
                    />
                  ))}
                {t === "ResourceVideoMeta" &&
                  (edit ? (
                    <UpdateVideo
                      resource={edit}
                      onRequestClose={onRequestClose}
                    />
                  ) : (
                    <AddVideo
                      courseId={courseId}
                      onRequestClose={onRequestClose}
                    />
                  ))}

                {t === "ResourceGalleryMeta" &&
                  (edit ? (
                    <UpdateGallery
                      courseId={courseId}
                      resource={edit}
                      onRequestClose={onRequestClose}
                    />
                  ) : (
                    <AddGallery
                      courseId={courseId}
                      onRequestClose={onRequestClose}
                    />
                  ))}

                {t === "ResourceImageMeta" &&
                  (edit ? (
                    <UpdateImage
                      publisherId={publisherId}
                      courseId={courseId}
                      resource={edit}
                      onRequestClose={onRequestClose}
                    />
                  ) : (
                    <AddImage
                      publisherId={publisherId}
                      courseId={courseId}
                      onRequestClose={onRequestClose}
                    />
                  ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  onSelectChange = e => {
    const value = e.target.value;
    this.setState({ type: value });
  };
}

export default ResourcePanel;
