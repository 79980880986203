import gql from "graphql-tag";
import { MembershipEventFragment } from "./MembershipEventFragment";
import { EnrollmentEventFragment } from "./EnrollmentEventFragment";
import { CoachEventFragment } from "./CoachEventFragment";
import { AnnotationFragment } from "./AnnotationFragment";
import {
  QuestionFragment,
  QuestionWithoutCompletionFragment
} from "./QuestionFragment";

export const NotificationObjectFragment = gql`
  fragment NotificationObject on NotificationObject {
    id
    createdAt
    entityType
    entityId
    notificationChange {
      id
      user {
        isOnline
        id
        name
        photo {
          id
          public_id
        }
      }
    }
    meta {
      __typename

      ... on StepQuestionCommentEventType {
        comment {
          id
          groupId
          enrollmentId
          group {
            id
            section {
              id
              courseId
            }
            viewerEnrollment {
              id
            }
          }
          question {
            id
            stepId
            ...QuestionWithoutCompletion
          }
        }
      }

      ... on AnnotationCommentEventType {
        comment {
          id
          enrollmentId
          annotation {
            id
            step {
              id
              title
            }
            ...Annotation
          }
        }
      }

      ... on AnnotationEventType {
        annotation {
          id
          enrollmentId
          step {
            id
            title
          }
          ...Annotation
        }
      }

      ... on MembershipEventType {
        membership {
          id
          ...MembershipEvent
        }
      }

      ... on CoachEventType {
        coach {
          id
          ...CoachEvent
        }
      }

      ... on EnrollmentEventType {
        enrollment {
          id
          ...EnrollmentEvent
        }
      }
    }
  }

  ${MembershipEventFragment}
  ${EnrollmentEventFragment}
  ${CoachEventFragment}
  ${AnnotationFragment}
  ${QuestionWithoutCompletionFragment}
`;
