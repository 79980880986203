import gql from "graphql-tag";

export const CourseResourcesQuery = gql`
  query CourseResources($id: Int!) {
    course(id: $id) {
      id
      resources {
        __typename
        id
        title
        description
        meta {
          __typename
          ... on ResourceLinkMeta {
            url
            isSurvey
          }
          ... on ResourceSurveyMeta {
            url
            isSurvey
          }
          ... on ResourceVideoMeta {
            videoId
            video {
              id
              url
            }
          }
          ... on ResourceGalleryMeta {
            galleryId
          }
          ... on ResourceImageMeta {
            imageId
            image {
              id
              public_id
              width
              height
            }
          }
        }
      }
    }
  }
`;
