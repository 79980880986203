import * as React from "react";
import Box from "../Kit/Box";
import Text from "../Kit/Text";
import UserWithPresence from "./UserWithPresence";
import OnlineIndicator from "./OnlineIndicator";
import { CloseButton } from "../Kit/IconButton";
import styled from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import { Badge } from "../Kit/Badge";

export const ChatHeadingButton = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  border-radius: 8px 8px 0 0;
  z-index: 10;
  cursor: pointer;
  outline: none;
  background-color: white;
  align-items: center;
  transition: background-color 0.15s ease;
  display: flex;
  :hover {
    background-color: ${themeGet("colors.gray.2")};
  }
`;

class ChatSummary extends React.Component {
  render() {
    const { participants } = this.props;

    // const isGroup = participants.length > 1

    return (
      <ChatHeadingButton
        role="button"
        onClick={this.props.onRequestToggle}
        border="none"
        px={1}
        borderBottom="1px solid"
        borderColor={this.props.expanded ? "borderColor" : "transparent"}
      >
        <Box width="16px" flex="0 0 16px">
          <UserWithPresence userId={participants[0].user.id}>
            {({ isOnline, user }) => (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {isOnline && <OnlineIndicator />}
              </Box>
            )}
          </UserWithPresence>
        </Box>
        <Text flex="1" textAlign="left" fontWeight="600" mx={1} ellipsis>
          {participants.map((part, i) => {
            const isLast = i === participants.length - 1;
            return part.user.name + (isLast ? "" : ", ");
          })}
        </Text>
        {this.props.unreadCount > 0 && (
          <Badge
            style={{ borderRadius: "2px" }}
            bg="blue.20"
            flex="0 0 auto"
            color="blue.1"
            ml={1}
            mr={1}
          >
            {this.props.unreadCount}
          </Badge>
        )}
        <Box ml="auto" flex="0 0 auto">
          <CloseButton
            onClick={e => {
              e.stopPropagation();
              this.props.onRequestClose();
            }}
          />
        </Box>
      </ChatHeadingButton>
    );
  }
}

export default ChatSummary;
