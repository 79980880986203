import gql from "graphql-tag";

export interface ConversationReplyType {
  id: number;
  content: string;
  userId: number;
  resourceId: number;
  conversationId: number;
  createdAt: string;
  imageId: number;
  image: {
    id: number;
    original_filename: string;
    url: string;
  };
}

export const ConversationReplyFragment = gql`
  fragment ConversationReply on ConversationReply {
    id
    content
    userId
    resourceId
    conversationId
    createdAt
    imageId
    image {
      id
      original_filename
      url
    }
  }
`;
