import moment from "moment";
import { sortBy } from "lodash";

function getDiffDate(existingGroup, message) {
  const lastMessage = existingGroup.messages[existingGroup.messages.length - 1];
  const diff =
    moment(message.node.createdAt).diff(lastMessage.node.createdAt) / 6000;

  return diff;
}

// We group our messages so that they are more readable. We are basically
// emulating standard apple / facebook messaging patterns here.
function groupMessages(messages) {
  let sorted = sortBy(messages, "node.createdAt");
  let groups = [];
  let current = null;

  const belongsToCurrent = message => {
    if (!current) {
      return { belongs: false, showDate: false, showAvatar: true };
    }

    // if the same user, and close in date, return true
    if (current.userId === message.node.userId) {
      const diff = getDiffDate(current, message);
      return { belongs: diff < 10, showDate: diff > 6 * 60, showAvatar: true };
    }

    const diff = current ? getDiffDate(current, message) : 0;
    return { belongs: false, showDate: diff > 6 * 60, showAvatar: true };
  };

  sorted.forEach(message => {
    const { belongs, showDate, showAvatar } = belongsToCurrent(message);

    if (belongs && current) {
      current.messages.push(message);
    } else {
      if (current) {
        groups.push(current);
      }

      current = {
        showDate,
        showAvatar,
        messages: [message],
        createdAt: message.node.createdAt,
        userId: message.node.userId
      };
    }
  });

  if (current) {
    groups.push(current);
  }

  return groups;
}

export default groupMessages;
