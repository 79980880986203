import * as React from "react";
import { isObject } from "lodash";
import cloudinary from "cloudinary-tiny-js";

const cloud_name = "dfyj0s32y";

export const cl = cloudinary({
  cloudName: cloud_name,
  imageTransformDefaults: {
    gravity: "auto",
    format: "auto",
    quality: "auto",
    dpr: window.devicePixelRatio
  }
});

export const SIZES = {
  giant: { width: 1500, height: 1500 },
  cover: { width: 1500, height: 800 },
  landscape: { width: 800, height: 450 },
  portrait: { width: 600, height: 800 },
  placeholder: { width: 50, height: 27 },
  square: { width: 800, height: 800 },
  brand: { height: 30, width: null }
};

const defaultOptions = {
  dpr: window ? window.devicePixelRatio : 1,
  width: 800,
  height: 800,
  crop: "fill"
};

export const imageURL = (imageId, options = {}) =>
  cl(imageId, Object.assign({}, defaultOptions, options));

export const imageURLString = (image, size, options = {}) => {
  let opt = options;
  if (size && SIZES[size]) {
    opt = {
      ...options,
      ...SIZES[size]
    };
  }
  return `url(${imageURL(image.public_id, opt)})`;
};

// type ImageType = {
//   public_id: string
// };

// type ImageProps = {
//   img?: string | ImageType,
//   src?: string,
//   alt?: string,
//   width?: number,
//   height?: number,
//   crop?: string,
//   title?: string,
//   style?: Object,
//   responsive?: boolean
// };

class Image extends React.PureComponent {
  static defaultProps = {
    crop: "fill"
  };

  render() {
    const {
      style,
      img,
      alt,
      title,
      embed,
      src,
      width,
      height,
      crop,
      responsive,
      ...other
    } = this.props;

    const url = isObject(img)
      ? imageURL(img.public_id, {
          dpr: window ? window.devicePixelRatio : 1,
          width,
          height,
          crop
        })
      : this.props.img || this.props.src;

    const imgStyle = style || {};
    if (responsive) {
      imgStyle.width = "100%";
      imgStyle.height = "auto";
    }

    return (
      <img
        style={imgStyle}
        alt={alt}
        title={title}
        width={width + "px"}
        height={height + "px"}
        src={url}
        {...other}
      />
    );
  }
}

export default Image;
