import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import Box from "./Box";

const IconAim = {
  none: null,
  top: "0deg",
  up: "0deg",
  right: "90deg",
  bottom: "180deg",
  down: "180deg",
  left: "270deg"
};

export default class Icon extends PureComponent {
  static propTypes = {
    aim: PropTypes.oneOf(Object.keys(IconAim)).isRequired,
    size: PropTypes.number,
    iconSize: PropTypes.number,
    ariaHidden: PropTypes.bool
  };

  static defaultProps = {
    aim: "none",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    size: 32,
    iconSize: 16,
    ariaHidden: true
  };

  render() {
    const {
      aim,
      color,
      transform,
      size,
      iconSize,
      children,
      ...props
    } = this.props;

    let totalTransform = transform || "";
    if (aim) {
      totalTransform = `${totalTransform} rotate(${IconAim[aim]})`.trim();
    }

    return (
      <IconContainer
        {...props}
        color={color}
        totalTransform={totalTransform}
        size={size}
      >
        <IconSpan aria-hidden={this.props.ariaHidden} size={size}>
          {children}
        </IconSpan>
      </IconContainer>
    );
  }
}

const IconContainer = styled(Box)`
  ${props => {
    return css`
      transform: ${props.totalTransform};
      height: ${props.size}px;
      width: ${props.size}px;
    `;
  }};
`;

const IconSpan = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

export const CloseIcon = props => (
  <Icon {...props}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      fill="gray"
    >
      <path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z" />
    </svg>
  </Icon>
);

export class HeartIcon extends PureComponent {
  render() {
    const { iconWidth, iconHeight, ...props } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.iconWidth || 16}
        height={props.iconHeight || 16}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-heart"
        {...props}
      >
        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
      </svg>
    );
  }
}

export class QuestionIcon extends PureComponent {
  render() {
    const { iconWidth, iconHeight, ...props } = this.props;
    return (
      <Icon {...props}>
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 16 16"
          xmlSpace="preserve"
          width={iconWidth || 16}
          height={iconHeight || 16}
          fill="currentColor"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6v1H7V9c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C5.9 3.6 6.9 3 8 3c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z" />
        </svg>
      </Icon>
    );
  }
}

export class CheckCircleIcon extends PureComponent {
  render() {
    const { iconWidth, iconHeight, ...props } = this.props;
    return (
      <Icon {...props}>
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 16 16"
          xmlSpace="preserve"
          width={iconWidth || 16}
          height={iconHeight || 16}
          fill="currentColor"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
        </svg>
      </Icon>
    );
  }
}

export class DangerIcon extends PureComponent {
  render() {
    const { iconWidth, iconHeight, ...props } = this.props;
    return (
      <Icon {...props}>
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 16 16"
          xmlSpace="preserve"
          width={iconWidth || 16}
          height={iconHeight || 16}
          fill="currentColor"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
        </svg>
      </Icon>
    );
  }
}

export class WarningIcon extends PureComponent {
  render() {
    const { iconWidth, iconHeight, ...props } = this.props;
    return (
      <Icon {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconWidth || 16}
          height={iconHeight || 16}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-alert-triangle"
        >
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
          <line x1="12" y1="9" x2="12" y2="13" />
          <line x1="12" y1="17" x2="12" y2="17" />
        </svg>
      </Icon>
    );
  }
}

export class FacebookIcon extends PureComponent {
  render() {
    const { iconWidth, iconHeight, ...props } = this.props;
    return (
      <Icon {...props}>
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width={iconWidth || 16}
          height={iconHeight || 16}
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <path
            fill="#4267b2"
            d="M288,192v-38.1c0-17.2,3.8-25.9,30.5-25.9H352V64h-55.9c-68.5,0-91.1,31.4-91.1,85.3V192h-45v64h45v192h83V256h56.4l7.6-64H288z"
          />
        </svg>
      </Icon>
    );
  }
}

export class GoogleIcon extends PureComponent {
  render() {
    const { iconWidth, iconHeight, ...props } = this.props;
    return (
      <Icon {...props}>
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width={iconWidth || 16}
          height={iconHeight || 16}
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <path
            fill="#d12518"
            d="M325.862,275.558l-18.187-13.653l-0.063-0.051c-5.827-4.579-9.952-8.313-9.952-14.685c0-6.979,5.049-11.824,10.896-17.436
        l0.466-0.449c20.025-15.171,44.726-34.286,44.726-74.556c0-26.934-11.916-44.729-23.28-57.729h12.969l60.322-33H270.308
        c-25.324,0-62.68,3.225-94.561,28.576l-0.128,0.25c-21.809,18.111-34.828,44.584-34.828,70.691
        c0,21.197,8.706,42.159,23.885,57.447c21.428,21.579,48.302,26.127,67.074,26.127c1.462,0,2.956-0.028,4.47-0.093
        c-0.759,2.969-1.25,6.321-1.25,10.321c0,10.926,3.628,19.301,8.083,26.195c-23.963,1.932-58.148,6.477-84.897,22.278
        c-39.335,22.562-42.396,55.875-42.396,65.551c0,38.207,35.707,76.762,115.479,76.762c91.611,0,139.543-49.792,139.543-98.979
        C370.781,311.966,347.945,293.457,325.862,275.558z M200.485,139.894c0-13.359,3.02-23.457,9.255-30.9
        c6.514-7.852,18.18-13.129,29.028-13.129c19.881,0,32.938,15.008,40.388,27.598c9.199,15.539,14.913,36.095,14.913,53.643
        c0,4.942,0,19.983-10.188,29.796c-6.951,6.686-18.707,11.353-28.59,11.353c-20.503,0-33.453-14.705-40.707-27.041
        C204.189,173.53,200.485,153.109,200.485,139.894z M321.6,367.974c0,27.444-25.212,44.493-65.799,44.493
        c-48.058,0-80.347-20.603-80.347-51.265c0-26.14,21.54-36.789,37.8-42.521c18.944-6.064,44.297-7.305,50.062-7.305
        c3.907,0,6.087,0,8.683,0.229C308.7,336.816,321.6,347.733,321.6,367.974z"
          />
        </svg>
      </Icon>
    );
  }
}

export const ArrowRight = props => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      class="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6" />
    </svg>
  </Icon>
);

export const Play = props => (
  <Icon {...props}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      fill="currentColor"
    >
      <path
        d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20
    c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z"
      />
    </svg>
  </Icon>
);

export const Document = props => (
  <Icon {...props}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      fill="currentColor"
    >
      <path
        d="M288,48H136c-22.092,0-40,17.908-40,40v336c0,22.092,17.908,40,40,40h240c22.092,0,40-17.908,40-40V176L288,48z M272,192
  V80l112,112H272z"
      />
    </svg>
  </Icon>
);

export const Settings = props => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-settings"
    >
      <circle cx="12" cy="12" r="3" />
      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
    </svg>
  </Icon>
);

export const Layers = props => (
  <Icon {...props}>
    <svg
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-layers"
    >
      <polygon points="12 2 2 7 12 12 22 7 12 2" />
      <polyline points="2 17 12 22 22 17" />
      <polyline points="2 12 12 17 22 12" />
    </svg>
  </Icon>
);

export const Hamburger = props => (
  <Icon {...props}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      fill="currentColor"
    >
      <g>
        <g>
          <path d="M64,384h384v-42.666H64V384z M64,277.334h384v-42.667H64V277.334z M64,128v42.665h384V128H64z" />
        </g>
      </g>
    </svg>
  </Icon>
);

export const List = props => (
  <Icon {...props}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      fill="currentColor"
    >
      <g>
        <path
          d="M408,64H104c-22.091,0-40,17.908-40,40v304c0,22.092,17.909,40,40,40h304c22.092,0,40-17.908,40-40V104
      C448,81.908,430.092,64,408,64z M304,368H144v-48h160V368z M368,280H144v-48h224V280z M368,192H144v-48h224V192z"
        />
      </g>
    </svg>
  </Icon>
);

export const Notification = props => (
  <Icon {...props}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={props.iconWidth || 16}
      height={props.iconHeight || 16}
      fill="currentColor"
    >
      <path
        d="M256,464c22.779,0,41.411-18.719,41.411-41.6h-82.823C214.588,445.281,233.221,464,256,464z M390.589,339.2V224.8
	c0-63.44-44.516-117.518-103.53-131.041V79.2c0-17.682-13.457-31.2-31.059-31.2s-31.059,13.518-31.059,31.2v14.559
  c-59.015,13.523-103.53,67.601-103.53,131.041v114.4L80,380.8v20.8h352v-20.8L390.589,339.2z"
      />
    </svg>
  </Icon>
);
