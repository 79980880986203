import Editor, { getDecorators } from "./Editor";
import toMarkdown from "draftjs-to-markdown";
import React from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { mdToDraftjs } from "draftjs-md-converter";
import Box from "../Kit/Box";
import { Label } from "../Kit/Input";
import Layer from "../Kit/Layer";
import debug from "debug";

const log = debug("app:RichText");

const convertToEditorState = value => {
  if (value) {
    log("convert existing state");
    const rawData = mdToDraftjs(value);
    const contentState = convertFromRaw(rawData);
    return EditorState.createWithContent(contentState);
  } else {
    log("mount with empty state");
    return EditorState.createEmpty();
  }
};

// type Props = {
//   onChange: () => void,
//   value: any,
//   controls: boolean,
//   disabled: boolean
// };

class RichText extends React.Component {
  static defaultProps = {
    elevation: 1,
    p: 2,
    value: EditorState.createEmpty()
  };

  static serialize(state) {
    const content = state.getCurrentContent();
    return JSON.stringify(convertToRaw(content));
  }

  static serializeAsMarkdown(state) {
    const content = state.getCurrentContent();
    return toMarkdown(convertToRaw(content));
  }

  static deserializeToString(str) {
    try {
      const json = JSON.parse(str);
      const st = convertFromRaw(json);
      return st.getPlainText();
    } catch (err) {
      return str;
    }
  }

  static deserialize(str) {
    try {
      const json = JSON.parse(str);

      return {
        type: "json",
        value: EditorState.createWithContent(
          convertFromRaw(json)
        )
      };
      // this is a bit shit, but we used to use markdown instead
      // of json. for backwards compat...
    } catch (err) {
      return {
        type: "markdown",
        value: convertToEditorState(str)
      };
    }
  }

  static createEmpty() {
    return EditorState.createEmpty();
  }

  clear = () => {
    this.props.onChange(EditorState.createEmpty());
  };

  render() {
    const {
      label,
      p,
      elevation,
      submitOnReturn,
      controls,
      border,
      style,
      bg,
      autoFocus,
      className
    } = this.props;
    return (
      <Box>
        {label && <Label>{label}</Label>}
        <Layer elevation={elevation} bg={bg} style={style}>
          <Box p={p} width={1} flex={1}>
            <Editor
              border={border}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              autoFocus={autoFocus}
              className={className}
              onBlur={this.props.onBlur}
              submitOnReturn={submitOnReturn}
              controls={controls}
              editorState={this.props.value}
              onChange={this.props.onChange}
            />
          </Box>
        </Layer>
      </Box>
    );
  }
}

export default RichText;
