import gql from "graphql-tag";
import {
  CreateAnnotationMutation,
  UpdateAnnotationMutation,
  LikeAnnotationMutation,
  UnlikeAnnotationMutation
} from "../API/mutations/AnnotationMutations";
import {
  LikeCommentMutation,
  UnlikeCommentMutation,
  CreateAnnotationCommentMutation,
  UpdateAnnotationCommentMutation
} from "../API/mutations/CommentMutations";
import { StepQuestionQuery } from "../API/queries/StepQuestionQuery";
import AnnotationQuery from "../API/queries/AnnotationQuery";
import {
  EpisodeAnonymousQuery,
  EpisodeQuery
} from "../API/queries/EpisodeQuery";
import { StepCommentsQuery } from "../API/queries/StepCommentsQuery";
import { StepResourcesQuery } from "../API/queries/StepResourcesQuery";
import { StatusFragment } from "../API/fragments/StepStatusFragment";
import { EpisodeStepDetailsFragment } from "../API/fragments/EpisodeStepDetailsFragment";
import { UserBasicsFragment } from "../API/fragments/UserBasicsFragment";
import { ResourceFragment as RFrag } from "../API/fragments/ResourceFragment";
import { EpisodeStepQuery } from "../API/queries/EpisodeStepQuery";
import { EpisodeDetailsFragment } from "../API/fragments/EpisodeDetailsFragment";

export const ResourceFragment = RFrag;

export const CommentFragment = gql`
  fragment Comment on Comment {
    id
    createdAt
    ratingCount
    likedByViewer
    content
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
    createdBy {
      id
      name
      photo {
        id
        public_id
      }
    }
  }
  ${ResourceFragment}
`;

export const AnnotationFragment = gql`
  fragment Annotation on Annotation {
    id
    beginsAt
    content
    createdAt
    permalink
    ratingCount
    likedByViewer
    group {
      id
      role
      section {
        id
        courseId
      }
      viewerEnrollment {
        id
      }
    }
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
    comments {
      id
      ...Comment
    }
    createdBy {
      id
      name
      photo {
        id
        public_id
      }
    }
  }
  ${CommentFragment}
  ${ResourceFragment}
`;

export const QuestionFragment = gql`
  fragment Question on StepQuestion {
    __typename
    id
    content
    beginsAt
    type
    createdAt
    completed(enrollmentId: $enrollmentId)
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
    comments(sectionId: $sectionId, groupId: $groupId, filter: $filter) {
      id
      ...Comment
    }
  }
  ${CommentFragment}
  ${ResourceFragment}
`;

export const stepResourcesFragment = gql`
  fragment StepResourcesFragment on Step {
    id
    resources {
      id
      title
      description
      meta {
        ... on ResourceLinkMeta {
          url
          isSurvey
        }
        ... on ResourceVideoMeta {
          videoId
          video {
            id
            url
          }
        }
        ... on ResourceGalleryMeta {
          galleryId
        }
        ... on ResourceImageMeta {
          imageId
          image {
            id
            public_id
            width
            height
          }
        }
      }
    }
  }
`;

export const statusFragment = StatusFragment;

export const stepFragment = EpisodeStepDetailsFragment;

export const viewerFragment = UserBasicsFragment;

export const episodeFragment = EpisodeDetailsFragment;

// Queries

export const stepCommentsQuery = StepCommentsQuery;
export const stepResources = StepResourcesQuery;

export const STEP = EpisodeStepQuery;

export const EPISODE = EpisodeQuery;
export const EPISODE_ANON = EpisodeAnonymousQuery;
export const ANNOTATION = AnnotationQuery;
export const STEP_QUESTION = StepQuestionQuery;

// Mutations **
export const CREATE_ANNOTATION = CreateAnnotationMutation;
export const UPDATE_ANNOTATION = UpdateAnnotationMutation;
export const LIKE_ANNOTATION = LikeAnnotationMutation;
export const LIKE_COMMENT = LikeCommentMutation;
export const UNLIKE_ANNOTATION = UnlikeAnnotationMutation;
export const UNLIKE_COMMENT = UnlikeCommentMutation;
export const CREATE_COMMENT = CreateAnnotationCommentMutation;
export const UPDATE_COMMENT = UpdateAnnotationCommentMutation;
