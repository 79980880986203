import gql from "graphql-tag";
import { NotificationFragment } from "../fragments/NotificationFragment";

export const NotificationSubscription = gql`
  subscription notify {
    notification {
      id
      ...Notification
    }
  }
  ${NotificationFragment}
`;
