import React from "react";
import HorizontalTimeline from "react-horizontal-timeline";


const EXAMPLE = [
  {
    data: "2015-03-22",
    status: "status",
    statusB: "As co-founding director and managing director of Visual Understanding in Education (VUE), Watershed founders raised over $3,000,000 in support for VTS research and dissemination",
    statusE: "In Progress",
    statuses: ["As co-founding director and managing director of Visual Understanding in Education (VUE), Watershed founders raised over $3,000,000 in support for VTS research and dissemination"]
  },
  {
    data: "2016-03-23",
    status: "status",
    statusB: "",
    statusE: "Done",
    statuses: ["Incorporated Watershed Collaborative Inc. as a nonprofit in New York State", "Awarded $250,000 seed grant to develop V1 LMS and course Winter"]
  },
  {
    data: "2017-03-24",
    status: "status",
    statusB: "In Progress 2",
    statusE: "Done",
    statuses: ["Secured federal 501c3 non-profit approval. Developed and piloted V1 of Watershed LMS and VTS Basics", "Raised $40,000 from 350 backers on Kickstarter"]
  },
  {
    data: "2018-03-25",
    status: "status",
    statusB: "In Progress 3",
    statusE: "Done",
    statuses: ["Developed and piloted V2 of Watershed LMS and VTS Basics", "Awarded grants from the New York Community Trust and Agnes Gund to provide VTS Basics free to NYC public schools", "Approved to offer Continuing Teacher and Leader Education (CTLE) credits to educators in New York State"]
  },
  {
    data: "2019-03-25",
    status: "status",
    statusB: "In Progress 3",
    statusE: "Done",
    statuses: ["Partnered with Spokane Public Schools to document applications and integrations of VTS teaching and learning strategies within P-5 literacy instruction"]
  },
  {
    data: "2020-03-25",
    status: "status",
    statusB: "In Progress 3",
    statusE: "Done",
    statuses: ["Introduced new hybrid VTS School Program year-long adoption model"]
  },
  {
    data: "2021-03-25",
    status: "status",
    statusB: "In Progress 3",
    statusE: "Done",
    statuses: ["Introduced new 100% virtual and subscription packages for VTS School Program year-long model"]
  }
];

export default class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curIdx: 0,      
      prevIdx: -1
    };
  }

  //state = { value: 0, previous: 0 };

  render() {    
    const {curIdx, prevIdx} = this.state;
    const curStatus = EXAMPLE[curIdx].statusB;
    const prevStatus = prevIdx >= 0 ? EXAMPLE[prevIdx].statusB : '';
    const statuses = EXAMPLE[curIdx].statuses;

    return (
      <div>
        {/* Bounding box for the Timeline */}
        <div
          style={{
            width: "100%",
            height: "6rem",
            margin: "0 auto",
            marginTop: "20px",
          }}
        >
          <HorizontalTimeline
            styles={{
              background: "#f8f8f8",
              foreground: "#f5821e",
              outline: "#dfdfdf"
            }}
            index={this.state.curIdx}
            indexClick={index => {
              const curIdx = this.state.curIdx;
              this.setState({ curIdx: index, prevIdx: curIdx });
            }}            
            values={EXAMPLE.map(x => x.data)}
            getLabel={function(date) { return date.slice(0, 4); }}
            minEventPadding={70}
            maxEventPadding={70}
          />
        </div>
        <div className="text-center" style={{width: "60%", margin: "0 auto", fontSize: "20px"}}>
          <ul>
          {statuses.map((status) => (
             <li>{status}</li> 
          ))}
          </ul>
        </div>
      </div>
    );
  }
}
