import gql from "graphql-tag";

export const GalleryQuery = gql`
  query Gallery($id: Int!) {
    gallery(id: $id) {
      id
      title
      description
      images {
        id
        public_id
        width
        height
      }
      galleryImages {
        image {
          id
          public_id
          width
          height
        }
        imageId
        imagecollectionId
        orderNumber
      }
    }
  }
`;
