import { ThemeProvider } from "styled-components/macro";
import React, { ReactElement } from "react";
import colors from "./colors.json";

// const breakpoints = [567, 768, 992, 1200, 1400];
const breakpoints = ["40em", "52em", "64em", "76em"];

const space = [0, 8, 16, 24, 32, 96];

const fontSizes = [13, 14, 16, 18, 20, 24, 32, 48, 64, 96];

const sansFont =
  '"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
const serifFont = sansFont;

// Border elevations
const borderShadowColor = colors.grayAlpha[8];
const blurryShadowColor = colors.grayAlpha[4];

const elevations = [
  `0 0 1px ${borderShadowColor}, 0 0 1px 1px ${blurryShadowColor}`,
  `0 1px 2px ${borderShadowColor}, 0 1px 3px 1px ${blurryShadowColor}`,
  `0 1px 3px ${borderShadowColor}, 0 1px 4px 1px ${blurryShadowColor}`,
  `0 0 1px ${borderShadowColor}, 0 8px 10px -4px ${blurryShadowColor}`,
  `0 0 1px ${borderShadowColor}, 0 16px 24px -8px ${blurryShadowColor}`
];

// 0 0 1px rgba(67, 90, 111, 0.25), -2px 3px 20px 4px rgba(67, 90, 111, 0.29)

export type ThemeColors = {
  borderColor: string;
  faded: string;
  black: string;
  gray: Array<string>;
  grayAlpha: Array<string>;
  red: Array<string>;
  redAlpha: Array<string>;
  green: Array<string>;
  greenAlpha: Array<string>;
  blue: Array<string>;
  blueAlpha: Array<string>;
  purple: Array<string>;
  purpleAlpha: Array<string>;
  pink: Array<string>;
  pinkAlpha: Array<string>;
  yellow: Array<string>;
  yellowAlpha: Array<string>;
  turquoise: Array<string>;
  turquoiseAlpha: Array<string>;
  white: Array<string>;
  whiteAlpha: Array<string>;
};

export interface Theme {
  containerPadding: string;
  colors: ThemeColors;
  breakpoints: Array<string>;
  elevations: Array<string>;
  borders: Array<number | string>;
  space: Array<number>;
  fontSizes: Array<number>;
  sansFont: string;
  serifFont: string;
  shadows: Array<string>;
  paragraphFontSize: string;
  headingFontWeight: number;
  headingFont: string;
  bodyFont: string;
  radii: Array<number>;
}

const theme: Theme = {
  containerPadding: "0 32px",
  colors,
  breakpoints,
  elevations,
  borders: [0, "1px solid", "2px solid"],
  space,
  fontSizes,
  sansFont,
  serifFont,
  shadows: elevations,
  headingFontWeight: 500,
  headingFont: sansFont,
  bodyFont: sansFont,
  paragraphFontSize: "1rem",
  radii: [4, 8, 25]
};

export default theme;

export interface Props {
  children: ReactElement<any>;
}

export const ProvideTheme = (props: Props) => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);
