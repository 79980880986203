import React from "react";
import { NavLink } from "react-router-dom";
import Flex from "./Flex";
import styled, { css } from "styled-components/macro";
import PropTypes from "prop-types";
import { themeGet } from "@styled-system/theme-get";

export const TabList = styled(Flex).attrs({
  role: "tablist"
})`
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  padding-left: 0;
  list-style: none;
  width: 100%;
  margin: 0;
  margin-bottom: -1px;
  justify-content: ${props => (props.center ? "center" : "flex-start")};
`;

const activeClassName = "nav-item-active";

export const Tab = ({
  to,
  id,
  exact,
  isActive,
  disabled,
  activeBg,
  children,
  simple,
  onClick
}) => (
  <li>
    <TabLink
      disabled={disabled}
      to={to}
      isActive={isActive}
      exact={exact}
      activeBg={activeBg}
      style={
        activeBg && isActive
          ? {
              backgroundColor: activeBg,
              borderBottomColor: activeBg
            }
          : null
      }
      id={id + "-tab"}
      aria-controls={id}
      onClick={onClick}
      role="tab"
    >
      {children}
    </TabLink>
  </li>
);

const v = (c1, c2) => {
  return props => {
    return props.inverted ? themeGet(c1)(props) : themeGet(c2)(props);
  };
};

const tabStyles = css`
  display: flex;
  font-family: ${themeGet("sansFont")};
  font-size: 1rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  height: 40px;
  align-items: center;
  text-decoration: none;
  outline: none;

  padding: 0 1rem;
  border-radius: 8px 8px 0 0;
  font-weight: 500;
  color: ${v("colors.whiteAlpha.15", "colors.gray.15")};
  outline: none;
  border: none;
  :visited {
    color: ${v("colors.whiteAlpha.15", "colors.gray.15")};
  }
  :hover {
    color: ${v("colors.white", "colors.blue.20")};
    // background-color: ${themeGet("colors.grayAlpha.7")};
    text-decoration: none;
  }
  :active {
    color: ${v("colors.white", "colors.black")};
  }
  :focus {
    outline: none;
    background-color: ${themeGet("colors.grayAlpha.2")};
    color: ${v("colors.white", "colors.blue.20")};
  }
`;

const TabLinkStyled = styled.a.attrs({
  activeClassName
})`
  ${tabStyles};
  &.${activeClassName} {
    color: ${v("colors.white", "colors.black")};
    font-weight: 500;
    border: 1px solid;
    border-color: ${themeGet("colors.borderColor")};
    border-bottom-width: ${props => (props.simple ? 0 : "1px")};
    border-bottom-style: solid;
    border-bottom-color: ${themeGet("colors.white")};
    border-radius: 8px 8px 0 0;
    background-color: transparent;
  }
  ${props => {
    if (props.disabled) {
      return css`
        opacity: 0.2;
        pointer-events: none;
      `;
    }
  }};

  ${props => {
    if (props.activeBg) {
      return css`
        &.nav-item-active {
          background-color: ${props.activeBg};
          border-bottom-color: ${props.activeBg};
        }
      `;
    }
  }}
`;

export const TabLink = props => <TabLinkStyled as={NavLink} {...props} />;

const TabButtonStyled = styled.button`
  background: none;
  ${tabStyles};
  cursor: pointer;
  outline: none;

  ${props => {
    if (props.active) {
      return css`
        font-weight: 500;
        border: ${props => (props.simple ? "none" : "1px solid")};
        border-color: ${themeGet("colors.borderColor")};
        border-bottom-width: ${props => (props.simple ? 0 : "1px")};
        border-bottom-style: solid;
        border-bottom-color: ${themeGet("colors.white")};
        border-radius: 8px 8px 0 0;
        // border-radius: 0;
        // background-color: #fafbfc;
        background-color: transparent;

        ${props => {
          if (props.activeColor) {
            return css`
              color: ${themeGet("colors." + props.activeColor + ".20")};
            `;
          } else {
            return css`
              color: ${themeGet("colors.black")};
            `;
          }
        }};

        ${props => {
          if (props.activeBg) {
            return css`
              background-color: ${props.activeBg};
              border-bottom-color: ${props.activeBg};
            `;
          }
        }};
      `;
    }
  }};

  ${props => {
    if (props.disabled) {
      return css`
        opacity: 0.2;
        pointer-events: none;
      `;
    }
  }};
`;

export const TabButton = ({
  simple,
  disabled,
  style,
  activeBg,
  active,
  activeColor,
  children,
  onClick,
  id
}) => (
  <TabButtonStyled
    disabled={disabled}
    style={style}
    simple={simple}
    onClick={onClick}
    activeBg={activeBg}
    active={active}
    activeColor={activeColor}
    id={id}
  >
    {children}
  </TabButtonStyled>
);

TabButton.propTypes = {
  active: PropTypes.bool.isRequired
};

export const TabContent = ({ id, children }) => (
  <div id={id} role="tabpanel" aria-labelledby={id + "-tab"}>
    {children}
  </div>
);

TabContent.propTypes = {
  id: PropTypes.string.isRequired
};
