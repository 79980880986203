import * as React from "react";
import { throttle } from "lodash";
import debug from "debug";

const log = debug("app:Infinite");

// type Props = {
//   cursor?: string,
//   loadMore: () => void,
//   threshold: number,
//   hasMore: boolean,
//   customScrollContainer?: string,
//   children: React.Node,
//   reverseDirection?: number
// };

export default class Infinite extends React.Component {
  // scrollContainer: any;
  // bound: boolean;
  // pageLoaded: number;

  static defaultProps = {
    threshold: 250,
    customScrollContainer: undefined,
    referseDirection: false
  };

  componentDidMount() {
    this.scrollContainer = this.props.customScrollContainer
      ? document.querySelector(this.props.customScrollContainer)
      : window;
    log("use scroll container: %o", this.scrollContainer);
    this.attachScrollListener();
  }

  componentWillUnmount() {
    this.detachScrollListener();
    this.detachMousewheelListener();
  }

  detachMousewheelListener() {
    this.scrollContainer.removeEventListener(
      "mousewheel",
      this.mousewheelListener,
      false
    );
  }

  detachScrollListener() {
    this.bound = false;
    this.scrollContainer.removeEventListener(
      "scroll",
      this.scrollListener,
      false
    );
    this.scrollContainer.removeEventListener(
      "resize",
      this.scrollListener,
      false
    );
  }

  attachScrollListener() {
    log("attach scroll listener");

    if (!this.props.hasMore) {
      log("no more. detaching listener");
      return;
    }

    if (this.bound) {
      log("already bound. detaching");
      return;
    }

    if (!this.props.cursor) {
      return;
    }

    this.bound = true;

    this.scrollContainer.addEventListener(
      "mousewheel",
      this.mousewheelListener,
      false
    );
    this.scrollContainer.addEventListener("scroll", this.scrollListener, false);
    this.scrollContainer.addEventListener("resize", this.scrollListener, false);

    this.scrollListener();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cursor !== prevProps.cursor) {
      this.attachScrollListener();
    }

    if (!this.props.hasMore) {
      this.detachScrollListener();
    }
  }

  mousewheelListener(e) {
    // Prevents Chrome hangups
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (e.deltaY === 1) {
      e.preventDefault();
    }
  }

  calculateTopPosition = el => {
    if (!el) {
      return 0;
    }
    return el.offsetTop + this.calculateTopPosition(el.offsetParent);
  };

  scrollListener = throttle(() => {
    const el = this.scrollComponent;

    const doc =
      document.documentElement || document.body.parentNode || document.body;

    const scrollTop =
      window.pageYOffset !== undefined ? window.pageYOffset : doc.scrollTop;

    if (this.props.reverseDirection) {
      let top = this.scrollContainer.scrollTop;
      log("top", top);
      if (top < Number(this.props.threshold)) {
        this.detachScrollListener();
        this.props.loadMore((this.pageLoaded += 1));
      }
      return;
    }

    let offset =
      this.calculateTopPosition(el) +
      (el.offsetHeight - scrollTop - window.innerHeight);

    if (offset < Number(this.props.threshold)) {
      this.detachScrollListener();
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      if (typeof this.props.loadMore === "function") {
        log("request loading more");
        this.props.loadMore((this.pageLoaded += 1));
      }
    }
  }, 20);

  render() {
    return (
      <div ref={el => (this.scrollComponent = el)}>{this.props.children}</div>
    );
  }
}
