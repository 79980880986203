import React from "react"; // eslint-disable-line

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

const Cost = ({ amount, children }) => {
  return children(amount === 0 ? "Free" : formatter.format(amount * 0.01));
};

export default Cost;

export const formatCost = amount => formatter.format(amount * 0.01);
