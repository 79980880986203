const GLOBAL_CONSTANTS = {
    TNC_URL: "https://watershed-site-documents.s3.amazonaws.com/Watershed-Terms-of-Use.pdf",
    PROGRAM_MODEL_URL: "https://watershed-site-documents.s3.amazonaws.com/Watershed-VTS-Program-Model.pdf",
    PRIVACY_POLICY_URL: "https://watershed-site-documents.s3.amazonaws.com/Watershed-Privacy-Policy.pdf",
    RESEARCH_SUMMARY_URL: "https://watershed-site-documents.s3.amazonaws.com/Watershed_VTSResearchSummary.pdf",
    WEBSITE_URLS: "https://watershed-site-documents.s3.amazonaws.com/website_urls.json",
    WEBINAR_URL_AUG_16: "https://vts-watershed.org/3QkAvHy",
    WEBINAR_URL_SEP_1: "https://vts-watershed.org/3dcM5G2",
    WEBINAR_URL_SEP_8: "https://vts-watershed.org/3p9VJMs"

}

export default GLOBAL_CONSTANTS;