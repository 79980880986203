import styled from "styled-components/macro";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  cursor: text;
  & .DraftEditor-root {
    margin-top: 0;
  }
`;

export const ChatInputContainer = styled.div`
  display: block;
  flex: none;
  flex-direction: row;
  z-index: inherit;
  line-height: 1.25;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  align-items: flex-end;
  width: 100%;
  margin: 0;
  a {
    text-decoration: underline;
  }

  & .timestamp {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  &.focused .timestamp {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const InputWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 400;
  min-height: 40px;
  max-width: 100%;
  padding: ${props => (props.hasAttachment ? "8px" : "8px 8px")};
  padding-bottom: 4px;
  transition: padding 0.2s ease-in-out;
  border-radius: 24px;
`;

export const Form = styled.form`
  flex: auto;
  display: flex;
  min-width: 1px;
  max-width: 100%;
  align-items: center;
  margin-left: 4px;
  border-radius: 24px;
  background-color: transparent;
  position: relative;
`;
