import React from "react";
import Nav from "../Nav";
import Box from "../Kit/Box";
import NavContents2 from "../Nav/NavContents2";
import styled, { ThemeProvider } from "styled-components/macro";

const brandingTheme = theme => {
  return {
    ...theme,
    headingFontWeight: 700,
    paragraphFontSize: "1.25rem",
    sansFont:
      '"museo-sans", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    bodyFont:
      '"museo-sans", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    serifFont:
      '"museo-sans", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    headingFont:
      '"museo-sans-rounded", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
  };
};

const BrandingLayout = ({ children, auth }) => (
  <ThemeProvider theme={brandingTheme}>
    <React.Fragment>
      <Box width={"100%"}>{children}</Box> 
    </React.Fragment>
  </ThemeProvider>
);

export default BrandingLayout;
