import * as React from "react";
import { Box } from "../Kit";
import { CloseButton } from "../Kit/IconButton";

// type Props = {
//   children: React.Node,
//   onRequestDelete?: () => void
// };

export default class PreviewWrapper extends React.Component {
  render() {
    return (
      <Box
        maxHeight="125px"
        style={{ overflowY: "scroll" }}
        mb={0}
        bg="white"
        position="relative"
        borderRadius={this.props.borderRadius}
        borderBottom="1px solid"
        borderColor="borderColor"
      >
        {this.props.onRequestDelete && (
          <Box style={{ zIndex: 10 }} position="absolute" top="8px" right="8px">
            <CloseButton onClick={this.props.onRequestDelete} />
          </Box>
        )}
        {this.props.children}
      </Box>
    );
  }
}
