import gql from "graphql-tag";
import { ConversationReplyFragment } from "../fragments/ConversationReplyFragment";
import { ConversationFragment } from "../fragments/ConversationFragment";

export const ConversationSummary = gql`
  query ConversationSummary($id: Int, $userIds: [Int], $first: Int) {
    conversation(id: $id, userIds: $userIds) {
      id
      ...Conversation
      replies(first: $first) {
        edges {
          node {
            id
            ...ConversationReply
          }
        }
      }
    }
  }
  ${ConversationReplyFragment}
  ${ConversationFragment}
`;
