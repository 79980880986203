import React from "react";
import { standardSizes } from "./Avatar";
import { cl } from "./Image";
import styled from "styled-components/macro";

// type Image = {
//   id: number,
//   public_id: string
// };

// type Props = {
//   size: "tiny" | "small" | "medium" | "large",
//   img: Image
// };

export const getThumbnailURL = (img, size) =>
  cl(img.public_id, {
    fetchFormat: "auto",
    gravity: null,
    height: standardSizes[size],
    dpr: window ? window.devicePixelRatio : 1
  });

const ThumbnailImage = styled.img`
  border: 1px solid #ccc;
  width: auto;
`;

const Thumbnail = ({ size = "medium", img, style }) => {
  const url = cl(img.public_id, {
    fetchFormat: "auto",
    gravity: null,
    height: standardSizes[size],
    dpr: window ? window.devicePixelRatio : 1
  });

  const w = img.height ? (standardSizes[size] / img.height) * img.width : null;

  return (
    <ThumbnailImage
      height={standardSizes[size]}
      width={w}
      style={{
        ...style,
        width: w ? w + "px" : null
      }}
      src={url}
    />
  );
};

export default Thumbnail;
