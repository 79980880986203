import gql from "graphql-tag";
import { EpisodeDetailsFragment } from "../fragments/EpisodeDetailsFragment";
import { UserBasicsFragment } from "../fragments/UserBasicsFragment";

export const EpisodeQuery = gql`
  query EpisodeQuery($id: Int!, $groupId: Int, $enrollmentId: Int) {
    group(id: $groupId) {
      id
      role
      section {
        id
        title
        courseId
      }
    }
    episode(id: $id) {
      id
      ...EpisodeDetails
    }
    viewer {
      id
      ...UserBasics
    }
  }
  ${EpisodeDetailsFragment}
  ${UserBasicsFragment}
`;

export const EpisodeAnonymousQuery = gql`
  query EpisodeAnonPublic($id: Int!, $groupId: Int, $enrollmentId: Int) {
    group(id: $groupId) {
      id
      role
      section {
        id
        title
        courseId
      }
    }
    episode(id: $id) {
      id
      ...EpisodeDetails
    }
  }
  ${EpisodeDetailsFragment}
`;
