import gql from "graphql-tag";
import { QuestionFragment } from "../fragments/QuestionFragment";

export const StepQuestionQuery = gql`
  query StepQuestion($id: Int!, $groupId: Int, $enrollmentId: Int) {
    group(id: $groupId) {
      id
      role
      section {
        id
        courseId
      }
      viewerEnrollment {
        id
      }
    }
    stepQuestion(id: $id) {
      id
      ...Question
    }
    viewer {
      id
      name
      photo {
        id
        public_id
      }
    }
  }
  ${QuestionFragment}
`;
