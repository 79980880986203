import gql from "graphql-tag";

export const UpdateConversationMember = gql`
  mutation UpdateConversationMember(
    $conversationId: Int!
    $lastRead: String
    $isTyping: Boolean
  ) {
    updateConversationMember(
      conversationId: $conversationId
      lastRead: $lastRead
      isTyping: $isTyping
    ) {
      id
    }
  }
`;
