import gql from "graphql-tag";
import { UserBasicsFragment, UserBasicsType } from "./UserBasicsFragment";

export interface ConversationUserType {
  id: number;
  isTyping: number;
  lastRead: string;
  userId: number;
  user: UserBasicsType;
}

export const ConversationUserFragment = gql`
  fragment ConversationUser on ConversationMember {
    id
    isTyping
    lastRead
    userId
    user {
      ...UserBasics
    }
  }
  ${UserBasicsFragment}
`;
