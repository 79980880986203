import gql from "graphql-tag";

export const StatusFragment = gql`
  fragment StepStatus on EnrollmentStepType {
    id
    completed
    timestamp
    maxTime
  }
`;
