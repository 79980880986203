import gql from "graphql-tag";

export const CreateVideoResourceMutation = gql`
  mutation CreateVideoResource(
    $videoId: Int!
    $input: CreateVideoResourceInputType!
  ) {
    createVideoResource(videoId: $videoId, input: $input) {
      id
    }
  }
`;

export const UpdateVideoResourceMutation = gql`
  mutation UpdateVideoResource(
    $id: Int!
    $input: UpdateVideoResourceInputType!
    $videoId: Int
  ) {
    updateVideoResource(id: $id, input: $input, videoId: $videoId) {
      id
      title
      description
      meta {
        ... on ResourceLinkMeta {
          url
        }
        ... on ResourceSurveyMeta {
          url
          isSurvey
        }
        ... on ResourceVideoMeta {
          videoId
          video {
            id
            url
          }
        }
        ... on ResourceGalleryMeta {
          galleryId
        }
        ... on ResourceImageMeta {
          imageId
          image {
            id
            public_id
            width
            height
          }
        }
      }
    }
  }
`;

export const CreateVideoMutation = gql`
  mutation CreateVideo($input: CreateVideoInputType!) {
    createVideo(input: $input) {
      id
      duration
    }
  }
`;
