import debug from "debug";

const log = debug("app:push-notify");

/**
 * Request push notification support from the user
 *
 * @export
 * @returns
 */

export function requestNotificationPermission() {
  return new Promise((resolve, reject) => {
    if (!("Notification" in window)) {
      return resolve(false);
    }

    if (window.Notification.permission === "granted") {
      return resolve(true);
    }

    if (window.Notification.permission !== "denied") {
      window.Notification.requestPermission(permission => {
        if (permission === "granted") {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    }
  });
}

/**
 * Use the browser's built in notifications
 *
 * @export
 * @param {*} { title, text, icon, path }
 */

export function notify({ title, body, image, path }) {
  const n = new window.Notification(title, {
    body,
    icon: image
  });

  if (path) {
    n.onclick = e => {
      window.focus();
      if (path) {
        const url =
          window.location.protocol + "//" + window.location.host + path;
        log("on click: %s", url);
        window.location.href = url;
      }

      n.close();
    };
  }

  setTimeout(n.close.bind(n), 5000);
}
