import React from "react";
import { AddGallery } from "./AddGallery";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {
  CreateGalleryMutation,
  UpdateGalleryMutation,
  AddImageToGalleryMutation,
  RemoveImageFromGalleryMutation,
  ChangeOrderOfGalleryImagesMutation
} from "../API/mutations/GalleryMutations";

class AddGalleryFunctions extends React.Component {
  state = {
    gallery: null
  };

  render() {
    return (
      <AddGallery
        courseId={this.props.courseId}
        gallery={this.props.gallery}
        onRequestAdd={this.onRequestAdd}
        onRequestUpdate={this.onRequestUpdate}
        onRequestAddImage={this.onRequestAddImage}
        onRequestRemoveImage={this.onRequestRemoveImage}
        onChangeOrderOfGalleryImages={this.onChangeOrderOfGalleryImages}
      />
    );
  }

  onRequestAdd = async attr => {
    const res = await this.props.add(attr);
    this.props.onAdded(res.data.createGallery);
    console.log("result", res);
  };

  onRequestUpdate = async attr => {
    await this.props.update(attr);
  };

  onRequestAddImage = async (imageId, orderNumber) => {
    await this.props.addImageToGallery({
      variables: {
        id: this.props.gallery.id,
        imageId,
        orderNumber: orderNumber
      }
    });
  };

  onRequestRemoveImage = async imageId => {
    await this.props.removeImageFromGallery({
      variables: {
        id: this.props.gallery.id,
        imageId
      }
    });
    await this.onChangeOrderOfGalleryImages(this.props.galleryImages || []);
  };

  onChangeOrderOfGalleryImages = async images => {
    for (let i = 0; i < images.length; i++) {
      images[i].orderNumber = i;
    }
    let attr = images.map((image) => {
      return {
        imageId: image.imageId,
        imagecollectionId: image.imagecollectionId,
        orderNumber: image.orderNumber
      }
    });
    await this.props.ChangeOrderOfGalleryImages({
      variables: {
        input: { "images": attr, galleryId: this.props.gallery.id }
      }
    });
  }
  
}

const refetch = {
  options: {
    refetchQueries: ["CourseGalleries"]
  }
};

const refetchGallery = {
  options: {
    refetchQueries: ["Gallery"]
  }
};

export default compose(
  graphql(CreateGalleryMutation, { name: "add", ...refetch }),
  graphql(UpdateGalleryMutation, { name: "update", ...refetch }),
  graphql(AddImageToGalleryMutation, {
    name: "addImageToGallery",
    ...refetchGallery
  }),
  graphql(RemoveImageFromGalleryMutation, {
    name: "removeImageFromGallery",
    ...refetchGallery
  }),
  graphql(ChangeOrderOfGalleryImagesMutation, {
    name: "ChangeOrderOfGalleryImages",
    ...refetchGallery
  })
)(AddGalleryFunctions);
