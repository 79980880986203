import gql from "graphql-tag";

export interface RecentConversationType {
  conversations: Array<number>;
}

export const RecentConversationsQuery = gql`
  query RecentConversations {
    conversations
  }
`;
