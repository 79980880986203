import React from "react";
import { ImageQuery } from "../API/queries/ImageQuery";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import { withLoading, LayerLoading, Card, Image } from "../Kit";
import PropTypes from "prop-types";

class LoadImage extends React.Component {
  static propTypes = {
    imageId: PropTypes.number.isRequired
  };

  render() {
    const { imageId, layer, ...other } = this.props;

    const Component = layer ? Card.Image : Image;

    if (!this.props.data.image) {
      return null;
    }

    return <Component img={this.props.data.image} {...other} />;
  }
}

const withImage = graphql(ImageQuery, {
  options: props => ({
    variables: { id: props.imageId }
  })
});

const LoadingComponent = withLoading(LoadImage, LayerLoading);

export default compose(withImage)(LoadingComponent);
