import gql from "graphql-tag";

export interface ImageType {
  id: number;
  public_id: string;
}

export const ImageFragment = gql`
  fragment Image on Image {
    id
    public_id
  }
`;
