import React from "react";
import PropTypes from "prop-types";
import { Box } from "../Kit";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import styled from "styled-components/macro";
import { UsersQuery } from "../API/queries/UsersQuery";

class ListUsers extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.data.loading) {
      return false;
    }
    return true;
  }

  render() {
    if (!this.props.data.users) {
      return null;
    }

    return (
      <ScrollBox flex={1}>{this.props.render(this.props.data.users)}</ScrollBox>
    );
  }
}

const ScrollBox = styled(Box)`
  overflow: scroll;
`;

const withUsers = graphql(UsersQuery, {
  options: props => ({
    variables: {
      query: props.query
    }
  })
});

export default compose(withUsers)(ListUsers);
