import gql from "graphql-tag";
import {
  ConversationUserFragment,
  ConversationUserType
} from "./ConversationUserFragment";

export interface ConversationType {
  id: number;
  unreadCount: number;
  members: Array<ConversationUserType>;
}

export const ConversationFragment = gql`
  fragment Conversation on Conversation {
    id
    unreadCount
    members {
      id
      ...ConversationUser
    }
  }
  ${ConversationUserFragment}
`;
