import React from "react";
import { Flex, Avatar, Text, themeGet } from "../Kit";
import AddIcon from "@atlaskit/icon/glyph/add";
import PropTypes from "prop-types";
import CheckIcon from "@atlaskit/icon/glyph/check";
import { css } from "styled-components/macro";
import styled from "styled-components/macro";

export const UserRowStyled = styled(Flex)`
  display: flex;
  align-items: center;
  background: none;
  flex-direction: row;
  outline: none;
  cursor: pointer;
  padding: 8px 0;
  border: none;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  justify-content: space-between;
  text-decoration: none;
  padding-right: ${themeGet("space.1")}px;
  :hover {
    background-color: ${themeGet("colors.gray.2")};
  }
  padding-left: ${themeGet("space.1")}px;
  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;
        opacity: 0.7;
      `;
    }
  }};
`;

export default class UserRow extends React.Component {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    saved: false
  };

  render() {
    const { user } = this.props;
    const { loading, saved } = this.state;
    return (
      <UserRowStyled
        as="a"
        href="#"
        disabled={loading || saved}
        onClick={this.add}
      >
        <Flex alignItems="center" ml={1}>
          <Avatar img={user.photo} />
          <Flex
            flexDirection="column"
            pl={2}
            align="flex-start"
            justify="flex-start"
          >
            <Text ellipsis compressed>
              {user.name}
            </Text>
            {user.email && (
              <Text pt="4px" fontSize={1} faded ellipsis compressed>
                {user.email}
              </Text>
            )}
          </Flex>
        </Flex>
        <Text fontSize={1} color={saved ? "green.20" : "blue.20"} pr={1}>
          {saved ? (
            <CheckIcon label="User added" />
          ) : (
            <AddIcon label="Add user" />
          )}
        </Text>
      </UserRowStyled>
    );
  }

  add = e => {
    const { user } = this.props;
    e.preventDefault();
    this.setState({ loading: true });
    this.props.onAdd(user, err => {
      if (err) {
        return;
      }

      this.setState({
        loading: false,
        saved: true
      });
    });
  };
}
