import * as React from "react";
import Box from "./Kit/Box";
import Nav from "./Nav";
import NavContents2 from "./Nav/NavContents2";
import SideNav from "./Nav/SideNav";
import loadable from "react-loadable";

const ChatAsync = loadable({
  loader: () => import("./Chat"),
  loading: () => null
});

class AppLayout extends React.Component {
  state = {
    showMenu: window.localStorage.getItem("showMenu")
  };

  closeMenu = () => {
    window.localStorage.removeItem("showMenu");
    this.setState({ showMenu: undefined });
  };

  showMenu = () => {
    window.localStorage.setItem("showMenu", true);
    this.setState({ showMenu: true });
  };

  toggleMenu = () => {
    if (this.state.showMenu) {
      this.closeMenu();
    } else {
      this.showMenu();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Nav>
          <NavContents2
            toggleMenu={this.toggleMenu}
            auth={this.props.auth}
            title="Watershed"
          />
        </Nav>

        <SideNav
          onRequestClose={this.closeMenu}
          onRequestShow={this.showMenu}
          showMenu={this.state.showMenu}
        />

        <Box pt="8rem">{this.props.children}</Box>
        {this.props.auth && <ChatAsync />}
      </React.Fragment>
    );
  }
}

export default AppLayout;
