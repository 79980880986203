import React, { PureComponent } from "react";
import Box from "./Box";
import MDSpinner from "react-md-spinner";

// type Props = {
//   size: number,
//   delay: number,
//   fade: boolean
// };

// type State = {
//   show: boolean
// };

export default class Spinner extends PureComponent {
  // timer: TimeoutID;

  static defaultProps = {
    size: 40,
    delay: 200,
    fade: true
  };

  constructor(props) {
    super(props);
    this.state = {
      show: !props.delay || false
    };
  }

  componentDidMount() {
    if (this.props.delay) {
      this.timer = setTimeout(() => {
        this.setState({ show: true });
      }, this.props.delay);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    const { size, fade, delay, ...props } = this.props;
    const { show } = this.state;

    // todo: need to properly support mounting / unmounting animations
    const transition = fade ? { transition: "opacity 0.15s ease-in-out" } : {};
    const opacity = show ? { opacity: 1 } : { opacity: 0 };
    const style = { ...transition, ...opacity };

    return (
      <Box
        key="spinner"
        role="alert"
        aria-busy={show}
        aria-label="Loading"
        width={size}
        height={size}
        {...props}
        style={style}
      >
        <MDSpinner />
      </Box>
    );
  }
}
