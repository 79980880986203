import React from "react";
import { groupBy, filter, orderBy } from "lodash";
import { Box, Text, UppercaseLabel } from "../Kit";
import { cl } from "../Kit/Image";
import styled, { css } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import { Play } from "../Kit/Icon";
import EditorSearchIcon from "@atlaskit/icon/glyph/editor/search";
import ResourceVideo from "./ResourceVideo";
import ImageIcon from "@atlaskit/icon/glyph/image";
import Thumbnail from "../Kit/Thumbnail";
import { GalleryWithData } from "./ResourceGallery";
import LinkIcon from "@atlaskit/icon/glyph/link";

// export type ResourceType = {
//   id: string,
//   __typename: string,
//   title: string,
//   description?: string
// };

// export type ResourcesType = Array<ResourceType>;

// type Props = {
//   inverted: boolean,
//   enableFilter: boolean,
//   onSelect?: () => void,
//   details?: boolean,
//   compressed?: boolean,
//   resources: ResourcesType,
//   renderActions?: ResourceType => React.Element<any>
// };

// type State = {
//   query: string
// };

const labels = {
  ResourceVideoMeta: "Videos",
  ResourceLinkMeta: "Links",
  ResourceSurveyMeta: "Surveys",
  ResourceGalleryMeta: "Galleries",
  ResourceImageMeta: "Documents"
};

export const icons = {
  ResourceGalleryMeta: item => (
    <ImageIcon iconWidth={20} iconHeight={20} color="black" />
  ),
  ResourceVideoMeta: item => (
    <Play iconWidth={20} iconHeight={20} color="blue.20" />
  ),
  ResourceLinkMeta: item => (
    <Box color="green.20">
      <LinkIcon label="Link" iconWidth={26} iconHeight={26} color="green.20" />
    </Box>
  ),
  ResourceSurveyMeta: item => (
    <Box color="green.20">
      <LinkIcon label="Link" iconWidth={26} iconHeight={26} color="green.20" />
    </Box>
  ),
  ResourceImageMeta: item => <Thumbnail size="medium" img={item.meta.image} />
};

class ResourceList extends React.Component {
  state = {
    query: ""
  };

  static defaultProps = {
    enableFilter: true,
    inverted: false,
    showDescription: true
  };

  render() {
    const {
      compressed,
      resources,
      inverted,
      showDescription,
      details,
      renderActions,
      enableFilter
    } = this.props;
    const { query } = this.state;

    // filter our resources & group by type
    const filtered = !query
      ? resources
      : filter(
          resources,
          resource =>
            resource.title.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            resource.meta.__typename
              .toLowerCase()
              .indexOf(query.toLowerCase()) > -1
        );

    const ordered = orderBy(filtered, "title");
    const groups = groupBy(ordered, resource => resource.meta.__typename);
    const keys = Object.keys(groups);

    const results = keys.map((key, i) => {
      const items = groups[key];
      return (
        <ResourceGroup
          compressed={compressed}
          inverted={inverted}
          showBorder={i > 0}
          key={key}
          showLabel={!this.props.details}
          label={labels[key]}
        >
          {items.map(item => {
            if (item.meta.__typename === "ResourceGalleryMeta") {
              return (
                <ResourceGalleryWrapper
                  key={item.id}
                  details={details}
                  resource={item}
                >
                  {renderActions}
                </ResourceGalleryWrapper>
              );
            }

            return (
              <ResourceItem
                compressed={compressed}
                disabled={this.props.disabled}
                resource={item}
                inverted={inverted}
                icon={icons[key](item)}
                title={item.title}
                description={showDescription ? item.description : null}
                onEdit={() => console.log("EDIT")}
                key={item.id}
              >
                {renderActions}
              </ResourceItem>
            );
          })}
        </ResourceGroup>
      );
    });

    return (
      <Box bg={inverted ? "#092844" : null}>
        {enableFilter && (
          <Box position="relative">
            <HiddenInput
              placeholder="Search resources"
              type="search"
              inverted={inverted}
              aria-label="Search resources"
              value={query}
              onChange={e => {
                this.setState({ query: e.target.value });
              }}
            />
            <Box
              position="absolute"
              left="16px"
              color="rgba(0,0,0,0.5)"
              top="50%"
              style={{ transform: "translateY(-50%)" }}
            >
              <EditorSearchIcon label="Search" />
            </Box>
          </Box>
        )}
        <Box>
          {results.length > 0 ? (
            results
          ) : (
            <Box p={2}>
              <Text faded>
                {query ? "No matches found." : "No resources are listed."}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

const HiddenInput = styled.input`
  width: 100%;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  transition: background 0.25s ease-in-out;
  border: none;
  border-bottom: 1px solid;
  border-color: ${themeGet("colors.borderColor")};
  padding: 1rem 1rem;
  padding-left: 60px;
  ::placeholder {
    color: ${themeGet("colors.gray.5")};
  }
  :focus {
    z-index: 2;
    background-color: rgba(255, 255, 255, 1);
    outline: none;
  }
  ${props => {
    if (props.inverted) {
      return css`
        color: white;
        border-color: rgba(255, 255, 255, 0.1);
        ::placeholder {
          color: rgba(255, 255, 255, 0.8);
        }
        :focus {
          background-color: rgba(255, 255, 255, 0.05);
        }
      `;
    }
  }};
`;

// type ResourceGroupProps = {
//   label: string,
//   compressed?: boolean,
//   showLabel?: boolean,
//   inverted?: boolean
// };

const ResourceGroup = ({
  label,
  inverted,
  showLabel,
  showBorder = true,
  compressed,
  children
}) => {
  return (
    <Box my={compressed ? 0 : 2}>
      {!compressed && showLabel && (
        <Box py={1} pl={2}>
          <UppercaseLabel>{label}</UppercaseLabel>
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export const itemStyles = css`
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
  :hover {
    background-color: ${themeGet("colors.blue.5")};
  }
  :last-child .ResourceLabel {
    border-bottom: none;
  }
  ${props => {
    if (props.inverted) {
      return css`
        background-color: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.1);
        :hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      `;
    }
  }};

  ${props => {
    if (props.compressed) {
      return css`
        height: 40px;
        border-bottom: none;
      `;
    }
  }};
`;

const LinkElement = styled.a`
  display: block;
  outline: none;
  ${itemStyles};
`;

const ExternalLinkWrapper = ({ compressed, inverted, resource, children }) => (
  <LinkElement
    compressed={compressed}
    inverted={inverted}
    href={resource.meta && resource.meta.url}
    target="_blank"
  >
    {children}
  </LinkElement>
);

export const Label = styled.div.attrs({
  className: "ResourceLabel"
})`
  font-weight: 500;
  color: #8ea6bb;
  flex: 1;
  align-self: stretch;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  padding-right: 24px;
  overflow: hidden;
  border-color: ${themeGet("colors.borderColor")};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  margin-left: 16px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
`;

const ResourceVideoElement = styled.div`
  ${itemStyles};
`;

class ResourceVideoWrapper extends React.Component {
  state = {
    showing: false
  };

  render() {
    const { showing } = this.state;
    const { compressed, resource, inverted } = this.props;

    return (
      <ResourceVideoElement
        compressed={compressed}
        inverted={inverted}
        style={this.props.style}
        onClick={() => {
          this.setState({ showing: true });
        }}
      >
        {this.props.children}
        {showing && (
          <ResourceVideo
            video={resource.meta.video}
            resource={resource}
            onRequestEnd={() => {
              this.setState({ showing: false });
            }}
          />
        )}
      </ResourceVideoElement>
    );
  }
}

export class ResourceGalleryWrapper extends React.Component {
  state = {
    showing: false
  };

  render() {
    const { extra, resource, details } = this.props;

    return (
      <Box>
        <GalleryWithData
          extra={extra}
          details={details}
          style={this.props.style}
          description={resource.description}
          title={resource.title}
          ref={gallery => (this.gallery = gallery)}
          galleryId={resource.meta.galleryId}
        >
          {this.props.children && this.props.children(resource)}
        </GalleryWithData>
      </Box>
    );
  }
}

class ResourceImageWrapper extends React.Component {
  render() {
    const { compressed, resource, inverted } = this.props;

    const link = cl(resource.meta.image.public_id);

    return (
      <LinkElement
        target="_blank"
        style={this.props.style}
        href={link}
        compressed={compressed}
        inverted={inverted}
      >
        {this.props.children}
      </LinkElement>
    );
  }
}

const elements = {
  ResourceLinkMeta: ExternalLinkWrapper,
  ResourceSurveyMeta: ExternalLinkWrapper,
  ResourceVideoMeta: ResourceVideoWrapper,
  ResourceGalleryMeta: ResourceGalleryWrapper,
  ResourceImageMeta: ResourceImageWrapper
};

// props: {
//   icon: any,
//   inverted: boolean,
//   description?: string,
//   compressed?: boolean,
//   disabled: boolean,
//   resource: ResourceType,
//   onEdit?: () => void,
//   style?: Object
// }

export const ResourceItem = props => {
  const ContainerElement = elements[props.resource.meta.__typename];

  return (
    <ContainerElement
      inverted={props.inverted}
      compressed={props.compressed}
      style={props.disabled ? { pointerEvents: "none" } : { ...props.style }}
      resource={props.resource}
    >
      <React.Fragment>
        <IconWrapper>{props.icon}</IconWrapper>
        <Label>
          <Text
            flex="0 1 auto"
            ellipsis
            color={props.inverted ? "white" : "black"}
          >
            {props.title}
          </Text>
          {props.description && (
            <Text ellipsis fontWeight="400" ml={2} faded>
              {props.description}
            </Text>
          )}
        </Label>
      </React.Fragment>

      {props.children && props.children(props.resource)}
    </ContainerElement>
  );
};

export default ResourceList;
