// managing subscriptions to things like user typing, last read

import * as React from "react";
import debug from "debug";
import { Subscription } from "react-apollo";
import { ConversationMemberSubscription } from "../API/subscriptions/ConversationMemberSubscription";

const log = debug("app:ChatStatusSubscription");

export default class ChatStatusSubscription extends React.Component {
  render() {
    return (
      <Subscription
        subscription={ConversationMemberSubscription}
        variables={{
          conversationId: this.props.conversationId
        }}
      >
        {({ data, loading, error }) => {
          return (
            <ChatDetailHandler
              conversationMember={data ? data.conversationMember : null}
            />
          );
        }}
      </Subscription>
    );
  }
}

class ChatDetailHandler extends React.Component {
  componentDidUpdate(next) {
    if (this.props.conversationMember) {
      if (this.props.conversationMember !== next.conversationMember) {
        this.onMemberUpdate(this.props.conversationMember);
      }
    }
  }

  onMemberUpdate = member => {
    log("member update: %o", member);
  };

  render() {
    return null;
  }
}
