import gql from "graphql-tag";
import { ImageFragment } from "../fragments/ImageFragment";
import { UserBasicsFragment } from "../fragments/UserBasicsFragment";

export const SideNavQuery = gql`
  query SideNav {
    viewer {
      id
      isAdmin
      publishers {
        id
        publisher {
          id
          slug
          tagline
          avatar {
            ...Image
          }
          members {
            id
            user {
              ...UserBasics
            }
          }
          name
        }
      }
      coaching(order: "reverse:createdAt") {
        id
        isDeleted
        section {
          id
          title
          slug
          publisher {
            id
            slug
          }
          image {
            ...Image
          }
        }
      }
      enrollments(order: "reverse:createdAt") {
        id
        groupId
        isDeleted
        disabled
        subscription {
          id
          cancelled_at
        }
        group {
          id
          enrollments {
            id
            user {
              ...UserBasics
            }
          }
        }
        section {
          id
          title
          publisherId
          publisher {
            id
            name
          }
          image {
            ...Image
          }
        }
      }
    }
  }
  ${ImageFragment}
  ${UserBasicsFragment}
`;
