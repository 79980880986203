import styled, { css } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import Box from "./Box";
import Text from "./Text";
import React from "react";

export const TableContainer = styled(Box)`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.div`
  width: 100%;
  min-width: ${props => props.minWidth};
`;

export const TableContent = styled.div`
  width: 100%;
`;

export const TableHeader = styled(Box)`
  background-color: ${themeGet("colors.white")};
  transform: translateZ(0);
  position: relative;
  display: flex;
  transition: background-color 0.15s ease;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${themeGet("colors.borderColor")};
`;

export const TableCellStyled = styled(Box)`
  padding: 16px 10px;
  font-weight: 400;
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${themeGet("colors.black")};
  position: relative;
  white-space: nowrap;
  :first-child {
    padding-left: 16px;
  }
  :last-child {
    padding-right: 16px;
  }
  ${props => {
    if (props.compressed) {
      return css`
        padding: 10px;
      `;
    }
  }};
`;

export const TableCell = ({ children, ...other }) => (
  <TableCellStyled {...other}>
    {typeof children === "string" ? (
      <Text fontSize={2}>{children}</Text>
    ) : (
      children
    )}
  </TableCellStyled>
);

export const TableRow = styled(Box).attrs({
  role: "row"
})`
  position: relative;
  transition: background-color 0.15s ease;
  text-decoration: none;
  align: center;
  display: flex;
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${themeGet("colors.borderColor")};
  :hover {
    background-color: ${themeGet("colors.gray.3")};
  }
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: ${themeGet("radii.0")}px;

    border-bottom-right-radius: ${themeGet("radii.0")}px;
  }

  ${props => {
    if (props.disabled) {
      return css`
        :hover {
          background-color: transparent;
        }
      `;
    }
  }};
`;

export const TableHeaderCell = styled(TableCellStyled)`
  font-size: ${themeGet("fontSizes.1")}px;
  // text-transform: uppercase;
  font-weight: 500;
  height: 30px;
`;
