import React from "react";
import GalleryList from "./GalleryList";
import { Modal, Text, Heading, Button, Layer, Box, Flex } from "../Kit";
import AddGalleryFunctions from "./AddGalleryFunctions";
import { graphql } from "react-apollo";
import { CourseGalleriesQuery } from "../API/queries/CourseGalleriesQuery";

// type Props = {
//   courseId: string,
//   onSelect: () => void
// };

class AddOrSelectGallery extends React.Component {
  state = {
    modal: false,
    gallery: null
  };

  render() {
    const course = this.props.data.course || {};
    const galleries = course.galleries || [];

    const close = {
      onRequestClose: () => {
        this.setState({ modal: false, gallery: null });
      }
    };

    return (
      <Layer elevation={1}>
        <Modal
          isOpen={this.state.modal}
          {...close}
          contentLabel="Add a gallery"
        >
          <Modal.Header {...close} title="Add a gallery" />
          <AddGalleryFunctions
            courseId={this.props.courseId}
            onAdded={gallery => {
              this.setState({ gallery });
            }}
            gallery={this.state.gallery}
          />
        </Modal>
        <Box width={1}>
          <Flex
            alignItems="flex-end"
            justifyContent="space-between"
            p={2}
            borderBottom="1px solid"
            borderColor="borderColor"
          >
            <Heading fontSize={3}>Galleries</Heading>
            <Button onClick={this.showModal} appearance="success">
              New gallery
            </Button>
          </Flex>
          <Box position="relative" width={1}>
            {!this.props.data.loading && (
              <Box>
                {!this.props.data.error && galleries.length === 0 && (
                  <Box p={2}>
                    <Text faded>No galleries are listed.</Text>
                  </Box>
                )}

                {this.props.data.error && (
                  <Text ellipsis p={2} color="red.20">
                    A loading error has occurred.
                  </Text>
                )}
                <GalleryList
                  selected={this.props.selected}
                  galleries={galleries}
                  onSelect={this.onSelect}
                  onRequestEdit={this.edit}
                  onRequestRemove={this.remove}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Layer>
    );
  }

  showModal = e => {
    e.preventDefault();
    this.setState({ modal: true });
  };

  onSelect = gallery => {
    console.log("select");
    this.props.onSelect(gallery);
  };

  remove = () => {
    console.log("what");
  };

  edit = gallery => {
    this.setState({
      modal: true,
      gallery
    });
  };
}

const withGalleries = graphql(CourseGalleriesQuery, {
  options: props => ({
    variables: { id: props.courseId }
  })
});

export default withGalleries(AddOrSelectGallery);
