import gql from "graphql-tag";

export const UserPresenceSubscription = gql`
  subscription UserPresenceSubscription($userId: Int!) {
    presence(userId: $userId) {
      id
      isOnline
    }
  }
`;
