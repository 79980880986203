const clientId =
  process.env.NODE_ENV === "production"
    ? "ca_Cc8nI0qKo8arUYv7NeEbRH7Yjcv9R8FP"
    : "ca_Cc8nXiTn20eYQR2DCJa0eJKf7TSIKP1k";

const shared = {
  stripeRedirectURL: window.location.origin + "/stripe",
  stripeAuthorizeURL: publisherId =>
    `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&state=${publisherId}`
};

const production = {
  ...shared,
  stripePublishableKey: "pk_live_i0bMnxDUXYbh6ezJGyee0msd",
  stripeClientId: clientId
};

const development = {
  ...shared,
  stripePublishableKey: "pk_test_J2g8XIvwwjyMN9b9jAm2fJeJ",
  stripeClientId: clientId
};

const config = process.env.NODE_ENV === "production" ? production : development;

export default config;
