import gql from "graphql-tag";
import { ImageFragment } from "../fragments/ImageFragment";

export const ViewerQuery = gql`
  query Viewer {
    viewer {
      id
      name
      notificationCount
      isAdmin
      photo {
        ...Image
      }
    }
  }
  ${ImageFragment}
`;
