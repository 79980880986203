import React, { PureComponent } from "react";
import Routes from "./Routes";
import GlobalFonts from "./fonts/fonts";
import { GoogleOAuthProvider } from '@react-oauth/google';


class App extends PureComponent {
  render() {
    return (
      <div className="App">
        <GoogleOAuthProvider clientId="486566210825-d8kih3q2saljkvssbife920jfd2ck2h5.apps.googleusercontent.com">
          <GlobalFonts />
          <Routes auth={this.props.auth} />
        </GoogleOAuthProvider>;
      </div>
    );
  }
}

export default App;
