import gql from "graphql-tag";

export const MembershipEventFragment = gql`
  fragment MembershipEvent on Membership {
    id
    createdAt

    publisher {
      id
      name
      permalink
      tagline
      avatar {
        id
        public_id
      }
      slug
      image {
        id
        public_id
      }
      members {
        id
        user {
          id
          name
          photo {
            id
            public_id
          }
        }
      }
    }
  }
`;
