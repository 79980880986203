import React, { Component } from "react";
import Flex from "../Kit/Flex";
import Box from "../Kit/Box";
import styled from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import Debug from "debug";
import search from "./algolia";
import Text from "../Kit/Text";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import UserList from "./UserList";
import moment from "moment";
import { Avatar } from "../Kit";

const log = Debug("app:Search");

// type Props = {
//   data?: {
//     viewer: any
//   },
//   value: string,
//   onRequestClose: () => void
// };

// type State = {
//   results: Array<ResultType>,
//   error: null | Error
// };

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      results: []
    };
  }

  componentDidMount() {
    this.search(this.props.value);
  }

  componentWillUnmount() {}

  render() {
    const { value } = this.props; // query term
    const query = value.toLowerCase();
    let publishers = [];
    let enrollments = [];
    let coaching = [];
    let viewer = null;

    if (this.props.data) {
      viewer = this.props.data.viewer || {};
      publishers = (viewer.publishers || []).filter(
        publisher =>
          !query || publisher.publisher.name.toLowerCase().indexOf(query) > -1
      );
      enrollments = (viewer.enrollments || []).filter(
        enrollment =>
          !query || enrollment.section.title.toLowerCase().indexOf(query) > -1
      );
      coaching = (viewer.coaching || []).filter(
        coaching =>
          !query || coaching.section.title.toLowerCase().indexOf(query) > -1
      );
    }

    return (
      <SearchResults
        publishers={publishers}
        enrollments={enrollments}
        coaching={coaching}
        query={value}
        viewer={viewer}
        results={this.state.results}
        error={this.state.error}
        searchError={this.state.searchError}
      />
    );
  }

  search = async v => {
    if (!v) {
      this.setState({ results: [], searchError: null });
      return;
    }
    try {
      const results = await search(v);
      log("results %o", results);
      this.setState({ results: results.hits, searchError: null });
    } catch (err) {
      console.error(err);
      this.setState({ searchError: err });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.search(nextProps.value);
    }
  }
}

const SearchResults = ({
  viewer,
  enrollments,
  coaching,
  publishers,
  results,
  error,
  searchError,
  query,
  auth
}) => {
  if (
    results.length === 0 &&
    !query &&
    !enrollments.length &&
    !coaching.length &&
    !publishers.length
  ) {
    return (
      <Box p={2}>
        <Text faded>
          Enter a search term to find a course or school that might interest
          you.
        </Text>
      </Box>
    );
  }

  if (
    results.length === 0 &&
    query &&
    !enrollments.length &&
    !coaching.length &&
    !publishers.length
  ) {
    return (
      <Box p={2}>
        <Text faded>No results found.</Text>
      </Box>
    );
  }

  const pubEls = publishers.map(({ publisher }) => {
    const users = publisher.members.map(member => member.user);
    return (
      <SearchResult
        url={`/admin/schools/${publisher.slug}`}
        key={publisher.id}
        title={publisher.name}
        subtitle={publisher.tagline}
        image={publisher.image}
      >
        <UserList users={users} exclude={viewer} limit={8} />
      </SearchResult>
    );
  });

  const enrollEls = enrollments.map(result => {
    const users = result.group.enrollments.map(enrollment => enrollment.user);

    return (
      <SearchResult
        key={result.id}
        url={`/learn/${result.groupId}/${result.id}`}
        title={result.section.title}
        subtitle={result.section.publisher.name}
        image={result.section.image}
      >
        <UserList users={users} exclude={viewer} limit={8} />
      </SearchResult>
    );
  });

  const coachEls = coaching.map(result => (
    <SearchResult
      key={result.id}
      url={`/coach/${result.section.slug}`}
      title={result.section.title}
      subtitle={moment(result.section.createdAt).format("MMMM Do YYYY")}
      image={result.section.image}
    />
  ));

  const els = results.map(result => (
    <SearchResult
      url={`/schools/${result.publisher.slug}/${result.slug}`}
      key={result.id}
      title={result.title}
      image={result.image}
      subtitle={result.shortDescription}
    />
  ));

  return (
    <Box width={1}>
      {enrollEls.length > 0 ? (
        <Section title="Your courses">{enrollEls}</Section>
      ) : null}

      {pubEls.length > 0 ? (
        <Section title="Your Schools">{pubEls}</Section>
      ) : null}

      {coachEls.length > 0 ? (
        <Section title="Coaching">{coachEls}</Section>
      ) : null}

      {els.length > 0 ? (
        <Section title="Featured products and services">{els}</Section>
      ) : null}

      {searchError && (
        <Box p={2} mt={1}>
          <Text faded textAlign="left">
            We are having trouble fetching additional results right now. Please
            check your internet connection and try again.
          </Text>
        </Box>
      )}
    </Box>
  );
};

const Section = ({ title, children }) => (
  <Box width={1} borderTop="1px solid" bg="gray.0" borderColor="borderColor">
    <Box px={2} pt={2}>
      <SectionLabel color="gray.10">{title}</SectionLabel>
    </Box>
    <Box bg="white" borderTop="1px solid" borderColor="borderColor">
      {children}
    </Box>
  </Box>
);

const activeClassName = "nav-item-active";

const SearchLink = styled(NavLink).attrs({
  activeClassName
})`
  // border-bottom: 1px solid;
  // border-color: ${themeGet("colors.borderColor")};
  background-color: transparent;
  padding-right: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 54px;
  cursor: pointer;
  :hover {
    background-color: ${themeGet("colors.gray.2")};
  }
  :last-child {
    border-bottom: none;
    & .Search-result-label {
      border-bottom: none;
    }
  }
  &.${activeClassName} {
    background: ${themeGet("colors.gray.2")};
  }

`;

const Label = styled.div`
  font-weight: 500;
  color: #8ea6bb;
  flex: 1;
  height: 100%;
  text-overflow: ellipsis;
  margin-left: 8px;
  display: flex;
  padding-right: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
`;

export const SearchResult = ({
  title,
  children,
  subtitle,
  style,
  labelStyle,
  px = 0,
  ml = 1,
  url = "/",
  image
}) => (
  <SearchLink style={style} className="trigger-close" to={`${url}`}>
    <Flex flex="0 0 40px" mr={1} ml={ml} align="center">
      <Avatar
        size="medium"
        img={image}
        ariaHidden
        showText={false}
        rounded={false}
      />
    </Flex>
    <Label style={labelStyle} className="Search-result-label">
      <Box flex="1 1 100%" style={{ overflow: "hidden" }}>
        <Text ellipsis>{title}</Text>
        <Box alignItems="center" display="flex">
          {subtitle && (
            <Text flex="1" fontWeight="400" faded ellipsis>
              {subtitle}
            </Text>
          )}
          <Box flex="0 0 auto">{children}</Box>
        </Box>
      </Box>
    </Label>
  </SearchLink>
);

const SectionLabel = styled(Text)`
  text-transform: uppercase;
  font-size: 11px;
  display: block;

  margin-bottom: 8px;
`;

const SEARCH_QUERY = gql`
  query SearchQuery {
    viewer {
      id
      publishers {
        id
        publisher {
          id
          slug
          tagline
          members {
            id
            user {
              id
              name
              photo {
                id
                public_id
              }
            }
          }
          image {
            id
            public_id
          }
          name
        }
      }
      coaching {
        id
        section {
          id
          title
          createdAt
          slug
          publisher {
            id
            slug
          }
          image {
            id
            public_id
          }
        }
      }
      enrollments {
        id
        groupId
        group {
          id
          enrollments {
            id
            user {
              id
              name
              photo {
                id
                public_id
              }
            }
          }
        }
        section {
          id
          title
          description
          publisher {
            id
            name
          }
          image {
            id
            public_id
          }
        }
      }
    }
  }
`;

const withSearchQuery = graphql(SEARCH_QUERY, {
  skip: ownProps => !ownProps.auth
});

export default compose(withSearchQuery)(Search);
