import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const textColor = "#2f2f2f";
const activeLink = "#4DBDEC";
const panelBackground = "white";
export const panelSpacing = "50px";
export const MAX_WIDTH = "1200px";

export const onPhone = "@media (max-width:800px)";
export const onSmallDesktop = "@media (max-width: 1200px)";
export const onSmallScreen = "@media (max-width: 1100px)";

export const font = "poppins-regular";
export const bubbleFont = "latin-modern-mono";

export const NavLink = styled(Link)`
  font-size: 16px;
  line-height: 15px;
  color: ${(props) => (props.login ? "#fff" : props.color)};
  font-family: "poppins-regular";
  text-decoration: none;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${onSmallDesktop} {
    font-size: 14px;
  }
`;

export const NavLinkPlain = styled.div`
  font-size: 16px;
  line-height: 15px;
  // color: #fff;
  color: #515151;
  font-family: "poppins-regular";
  text-decoration: none;
  // padding: 2rem 0;
  cursor: pointer;
  ${onSmallDesktop} {
    font-size: 14px;
  }
`;

export const NavLinkHref = styled.a`
  font-size: 16px;
  line-height: 15px;
  // color: #fff;
  color: ${(props) => (props.login ? "#fff" : props.color)};
  font-family: "poppins-regular";
  text-decoration: none;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${onSmallDesktop} {
    font-size: 14px;
  }
`;

export const UppercaseLabel = styled.div`
  font-size: 18px;
  letter-spacing: 1.8;
  text-transform: uppercase;
  line-height: 1.8;
  font-family: ${font};
  color: #2f2f2f;
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: 1.8;
  color: #2f2f2f;
  font-family: ${font};
`;

export const Header = styled.div`
  font-size: 36px;
  line-height: 1.4;
  font-family: ${font};
  font-size: 42px;
  color: #575757;
  margin-bottom: 2rem;
`;

export const NewHeader = styled.div`
  font-size: 36px;
  line-height: 1.4;
  font-family: ${font};
  font-weight: bold;
  color: ${textColor};
`;

export const GreyPanel = styled.div`
  padding: ${panelSpacing} 1rem;
  & > *:first-child {
    max-width: ${MAX_WIDTH};
    display: flex;
    max-width: ${MAX_WIDTH};
    padding: 0 32px;
    overflow: hidden;
    align-items: center;
    margin: 0 auto;
  }

  ${onPhone} {
    display: block;
    padding: 16px 1rem;

    & > *:first-child {
      display: block;
      max-width: none;
      padding: 24px 12px;
    }
  }
`;

export const WhitePanel = styled.div`
  padding: ${panelSpacing} 1rem;
  background: white;
  & > *:first-child {
    max-width: ${MAX_WIDTH};
    display: flex;
    max-width: ${MAX_WIDTH};
    padding: 0 32px;
    overflow: hidden;
    align-items: center;
    margin: 0 auto;
  }

  ${onPhone} {
    display: block;
    padding: 16px 1rem;

    & > *:first-child {
      display: block !important;
      max-width: none;
      padding: 24px 12px;
    }
  }
`;

export const TopLeftBorderSVG = (props) => {
  let breadth = "10";
  return (
    <svg
      width={props.dimension}
      height={props.dimension}
      xmlns="http://www.w3.org/2000/svg"
      opacity="0.5"
    >
      <g>
        {/* <!-- top left --> */}
        <rect
          id="svg_6"
          height={props.dimension}
          width={breadth}
          x="0"
          y="0"
          stroke={props.color}
          fill={props.color}
        />
        <rect
          id="svg_8"
          height={breadth}
          width={props.dimension}
          y="0"
          x="0"
          stroke={props.color}
          fill={props.color}
        />
      </g>
    </svg>
  );
};

export const BottomRightBorderSVG = (props) => {
  let breadth = "10";
  return (
    <svg
      width={props.dimension}
      height={props.dimension}
      xmlns="http://www.w3.org/2000/svg"
      opacity="0.5"
    >
      <g>
        {/* <!-- bottom right --> */}
        <rect
          id="svg_6"
          height={props.dimension}
          width={breadth}
          x={props.dimension - 10}
          y="0"
          stroke={props.color}
          fill={props.color}
        />
        <rect
          id="svg_8"
          height={breadth}
          width={props.dimension}
          x="0"
          y={props.dimension - 10}
          stroke={props.color}
          fill={props.color}
        />
      </g>
    </svg>
  );
};

/**
 * Returns a rectangular box with only corner borders
 * @param {*} props {height, width, topLeftColor, topRightColor, bottomLeftColor, bottomRightColor}
 * @returns
 */
export const RectBoxSVG = (props) => {
  let length = `30`;
  let breadth = "10";

  let coordinate1 = `${props.width - breadth}`;
  let coordinate2 = `${props.width - length}`;

  let coordinate3 = `${props.height - breadth}`;
  let coordinate4 = `${props.height - length}`;

  return (
    <svg
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      opacity="0.7"
    >
      <g>
        {/* <!-- top left --> */}
        <rect
          id="svg_6"
          height={length}
          width={breadth}
          x="0"
          y="0"
          stroke={props.topLeftColor}
          fill={props.topLeftColor}
        />
        <rect
          id="svg_8"
          height={breadth}
          width={length}
          y="0"
          x="0"
          stroke={props.topLeftColor}
          fill={props.topLeftColor}
        />
        {/* <!-- top right --> */}
        <rect
          id="svg_1"
          height={length}
          width={breadth}
          y="0"
          x={coordinate1}
          stroke={props.topRightColor}
          fill={props.topRightColor}
        />
        <rect
          id="svg_2"
          height={breadth}
          width={length}
          y="0"
          x={coordinate2}
          stroke={props.topRightColor}
          fill={props.topRightColor}
        />
        {/* <!-- bottom left --> */}
        <rect
          id="svg_6"
          height={length}
          width={breadth}
          x="0"
          y={coordinate4}
          stroke={props.bottomLeftColor}
          fill={props.bottomLeftColor}
        />
        <rect
          id="svg_8"
          height={breadth}
          width={length}
          x="0"
          y={coordinate3}
          stroke={props.bottomLeftColor}
          fill={props.bottomLeftColor}
        />
        {/* <!-- bottom right --> */}
        <rect
          id="svg_1"
          height={length}
          width={breadth}
          y={coordinate4}
          x={coordinate1}
          stroke={props.bottomRightColor}
          fill={props.bottomRightColor}
        />
        <rect
          id="svg_2"
          height={breadth}
          width={length}
          y={coordinate3}
          x={coordinate2}
          stroke={props.bottomRightColor}
          fill={props.bottomRightColor}
        />
      </g>
    </svg>
  );
};

/**
 * Returns a box with only corner borders
 * @param {*} props {dimension, topLeftColor, topRightColor, bottomLeftColor, bottomRightColor}
 * @returns
 */
export const BoxSVG = (props) => {
  let length = `${0.24 * props.dimension}`;
  let breadth = "10";

  let coordinate1 = `${props.dimension - breadth}`;
  let coordinate2 = `${props.dimension - length}`;
  return (
    <svg
      width={props.dimension}
      height={props.dimension}
      xmlns="http://www.w3.org/2000/svg"
      opacity="0.7"
    >
      <g>
        {/* <!-- top left --> */}
        <rect
          id="svg_6"
          height={length}
          width={breadth}
          x="0"
          y="0"
          stroke={props.topLeftColor}
          fill={props.topLeftColor}
        />
        <rect
          id="svg_8"
          height={breadth}
          width={length}
          y="0"
          x="0"
          stroke={props.topLeftColor}
          fill={props.topLeftColor}
        />
        {/* <!-- top right --> */}
        <rect
          id="svg_1"
          height={length}
          width={breadth}
          y="0"
          x={coordinate1}
          stroke={props.topRightColor}
          fill={props.topRightColor}
        />
        <rect
          id="svg_2"
          height={breadth}
          width={length}
          y="0"
          x={coordinate2}
          stroke={props.topRightColor}
          fill={props.topRightColor}
        />
        {/* <!-- bottom left --> */}
        <rect
          id="svg_6"
          height={length}
          width={breadth}
          x="0"
          y={coordinate2}
          stroke={props.bottomLeftColor}
          fill={props.bottomLeftColor}
        />
        <rect
          id="svg_8"
          height={breadth}
          width={length}
          x="0"
          y={coordinate1}
          stroke={props.bottomLeftColor}
          fill={props.bottomLeftColor}
        />
        {/* <!-- bottom right --> */}
        <rect
          id="svg_1"
          height={length}
          width={breadth}
          y={coordinate2}
          x={coordinate1}
          stroke={props.bottomRightColor}
          fill={props.bottomRightColor}
        />
        <rect
          id="svg_2"
          height={breadth}
          width={length}
          y={coordinate1}
          x={coordinate2}
          stroke={props.bottomRightColor}
          fill={props.bottomRightColor}
        />
      </g>
    </svg>
  );
};

export const VTSLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="289"
    height="188"
    viewBox="0 0 289 188"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#231F20" transform="translate(-165 -176)">
        <g transform="translate(165 176)">
          <path d="M0 0.652515723L0 51.6525157 3.64619615 51.6525157 3.64619615 4.37434398 51 4.37434398 51 0.652515723z" />
          <path d="M285.353766 136.652516L285.353766 183.930648 238 183.930648 238 187.652516 289 187.652516 289 136.652516z" />
          <path d="M37 35.6525157L42.3774587 35.6525157 49.4997447 59.4321259 56.67003 35.6525157 62 35.6525157 51.9221798 67.6525157 47.1258196 67.6525157z" />
          <g transform="translate(40 34.653)">
            <path d="M26.923 33.185h4.653v-21.17h-4.653v21.17zM32.35 3.447c0 1.677-1.322 2.947-3.1 2.947-1.688 0-3.147-1.315-3.147-2.947 0-1.632 1.46-2.946 3.146-2.946 1.78 0 3.101 1.269 3.101 2.946z" />
            <path d="M43.113 24.027c-4.424-1.223-6.204-3.4-5.884-6.89.137-1.587.912-2.9 2.417-3.943 1.505-1.089 3.238-1.632 5.2-1.632 4.195 0 7.525 2.04 7.752 6.255h-4.469c-.182-1.496-1.505-2.267-3.101-2.267-1.231 0-2.6.454-3.01 1.18-.365.589-.41 1.223-.183 1.903.229.68 1.095 1.224 2.6 1.633l3.284.815c3.693.906 5.564 2.946 5.564 6.165 0 3.99-3.512 6.392-8.483 6.392-2.144 0-4.013-.634-5.655-1.858-1.642-1.27-2.463-2.947-2.508-5.077h4.332c.228 1.858 1.778 3.037 4.196 3.037 2.188 0 3.785-.952 3.785-2.539 0-1.224-1.003-2.04-2.964-2.493l-2.873-.68z" />
            <path d="M72.8 12.014h4.696v21.17H72.8v-3.082c-1.14 2.221-3.921 3.536-6.34 3.536-5.243 0-8.3-3.355-8.254-9.293v-12.33h4.698V23.8c0 3.4 1.825 5.304 4.424 5.304 3.101 0 5.472-1.769 5.472-5.712V12.014z" />
            <path d="M98.565 17.862c-1.323-1.178-2.828-1.768-4.561-1.768-1.824 0-3.375.59-4.652 1.814-1.232 1.178-1.87 2.72-1.87 4.624 0 1.903.638 3.49 1.87 4.714 1.277 1.224 2.828 1.859 4.652 1.859 3.65 0 6.568-2.584 6.568-6.573 0-1.95-.685-3.536-2.007-4.67zm2.052 15.322v-3.037c-1.323 2.176-4.378 3.49-7.16 3.49-2.964 0-5.519-1.042-7.662-3.082-2.098-2.04-3.147-4.714-3.147-7.978 0-3.264 1.049-5.893 3.147-7.933 2.143-2.04 4.698-3.083 7.662-3.083 2.782 0 5.746 1.225 7.16 3.445v-2.992h4.651v21.17h-4.651z" />
            <path d="M111.832776 33.1845354L116.530966 33.1845354 116.530966 0.500629921 111.832776 0.500629921z" />
            <path d="M36.0442585 52.3799717L36.0442585 47.8925071 59.4856554 47.8925071 59.4856554 52.3799717 50.2277095 52.3799717 50.2277095 79.1259244 45.3022044 79.1259244 45.3022044 52.3799717z" />
            <path d="M68.741 79.126h-4.698V46.442h4.698v14.733c.547-1.179 1.505-2.085 2.827-2.72 1.323-.635 2.646-.952 3.922-.952 5.427 0 8.62 3.536 8.62 9.564v12.059h-4.697V67.339c0-3.217-2.007-5.257-4.743-5.257-3.147 0-5.929 1.859-5.929 5.665v11.379z" />
            <path d="M90.401 79.126h4.652v-21.17h-4.652v21.17zm5.427-29.738c0 1.678-1.323 2.947-3.1 2.947-1.689 0-3.148-1.315-3.148-2.947 0-1.631 1.46-2.947 3.147-2.947 1.778 0 3.101 1.27 3.101 2.947z" />
            <path d="M106.316 79.126h-4.697v-21.17h4.697v3.174c1.14-2.313 3.967-3.627 6.521-3.627 5.154 0 8.118 3.31 8.118 9.293v12.33h-4.697V67.339c0-3.308-1.733-5.257-4.47-5.257-3.1 0-5.472 1.813-5.472 5.665v11.379z" />
            <path d="M141.019303 79.125978L131.943962 69.289537 131.943962 79.125978 127.292096 79.125978 127.292096 46.441537 131.943962 46.441537 131.943962 64.937537 138.69337 57.9560252 144.986532 57.9560252 135.91173 67.1585134 147.039364 79.125978z" />
            <path d="M150.366 79.126h4.652v-21.17h-4.652v21.17zm5.427-29.738c0 1.678-1.322 2.947-3.1 2.947-1.688 0-3.148-1.315-3.148-2.947 0-1.631 1.46-2.947 3.147-2.947 1.779 0 3.101 1.27 3.101 2.947z" />
            <path d="M166.282 79.126h-4.698v-21.17h4.698v3.174c1.14-2.313 3.967-3.627 6.521-3.627 5.153 0 8.118 3.31 8.118 9.293v12.33h-4.698V67.339c0-3.308-1.732-5.257-4.469-5.257-3.1 0-5.472 1.813-5.472 5.665v11.379z" />
            <path d="M197.246 62.036c-1.825 0-3.376.635-4.607 1.86-1.23 1.223-1.87 2.764-1.87 4.668 0 1.95.64 3.49 1.87 4.714 1.231 1.225 2.782 1.814 4.607 1.814 1.687 0 3.237-.59 4.56-1.768 1.322-1.224 2.006-2.81 2.006-4.76 0-4.034-2.873-6.528-6.566-6.528zm-6.43 19.765c1.094 2.176 3.602 3.536 5.973 3.536 4.743 0 7.114-2.765 7.114-8.341v-1.088c-1.23 2.312-4.241 3.672-7.205 3.672-2.965 0-5.518-.998-7.616-3.037-2.098-2.04-3.147-4.67-3.147-7.934 0-3.309 1.05-5.983 3.147-8.023 2.098-2.04 4.651-3.083 7.616-3.083 2.964 0 5.929 1.36 7.205 3.581v-3.128h4.607v18.042c0 9.112-3.922 13.69-11.72 13.69-4.151 0-7.845-2.175-9.852-5.937l1.916-.998 1.961-.952z" />
            <path d="M4.944 116.001c.046 1.315.685 2.539 1.824 3.581 1.14 1.043 2.645 1.541 4.424 1.541 3.511 0 5.564-1.858 5.928-4.26.137-2.585-1.504-4.262-4.97-5.078l-3.968-.997c-5.2-1.405-7.799-4.17-7.799-8.386 0-2.585 1.004-4.715 3.056-6.393 2.052-1.676 4.47-2.538 7.297-2.538 2.964 0 5.381.862 7.296 2.538 1.962 1.678 2.92 3.9 2.92 6.71h-4.835c0-2.856-2.189-4.714-5.472-4.714-2.965 0-5.427 1.767-5.427 4.306 0 1.994 1.414 3.354 4.195 4.08l4.013 1.042c6.203 1.632 8.985 5.123 8.483 9.702-.228 2.493-1.368 4.487-3.33 6.074-1.96 1.542-4.422 2.312-7.387 2.312-3.193 0-5.838-.952-7.936-2.81C1.16 120.851.11 118.584.11 116h4.834z" />
            <path d="M33.3993851 95.8739496L33.3993851 103.897414 37.9144314 103.897414 37.9144314 107.750926 33.3993851 107.750926 33.3993851 125.067367 28.701733 125.067367 28.701733 107.750926 24.8713232 107.750926 24.8713232 103.897414 28.701733 103.897414 28.701733 95.8739496z" />
            <path d="M47.034 125.068h-4.697v-21.17h4.697v4.85c1.049-3.536 2.964-5.303 5.838-5.303 1.687 0 2.964.271 3.785.816l-.775 4.306c-.958-.499-2.007-.726-3.147-.726-3.831 0-5.701 3.673-5.701 9.61v7.617z" />
            <path d="M74.624 109.746c-1.323-1.179-2.828-1.768-4.56-1.768-1.825 0-3.375.589-4.652 1.813-1.232 1.178-1.87 2.72-1.87 4.624 0 1.903.638 3.49 1.87 4.714 1.277 1.225 2.827 1.859 4.651 1.859 3.65 0 6.568-2.585 6.568-6.573 0-1.95-.685-3.536-2.007-4.67zm2.052 15.321v-3.037c-1.322 2.176-4.378 3.491-7.16 3.491-2.964 0-5.518-1.043-7.661-3.083-2.098-2.04-3.148-4.714-3.148-7.978 0-3.264 1.05-5.893 3.148-7.933 2.143-2.04 4.697-3.082 7.661-3.082 2.782 0 5.747 1.224 7.16 3.445v-2.992h4.652v21.17h-4.652z" />
            <path d="M94.2773683 95.8739496L94.2773683 103.897414 98.791876 103.897414 98.791876 107.750926 94.2773683 107.750926 94.2773683 125.067367 89.5791776 125.067367 89.5791776 107.750926 85.7493064 107.750926 85.7493064 103.897414 89.5791776 103.897414 89.5791776 95.8739496z" />
            <path d="M118.538 112.737c-.41-3.264-2.827-5.213-6.065-5.213-3.329 0-5.883 1.994-6.293 5.213h12.358zm4.561 3.4h-16.92c.411 3.264 3.056 5.304 6.522 5.304 2.417 0 4.697-1.088 5.792-3.038l3.83 1.678c-1.823 3.581-5.609 5.44-9.85 5.44-3.102 0-5.7-1.043-7.798-3.083-2.098-2.039-3.147-4.714-3.147-8.023 0-3.31 1.049-5.939 3.147-7.934 2.097-2.039 4.696-3.036 7.798-3.036 3.101 0 5.655.997 7.662 3.036 2.006 1.995 3.01 4.624 3.01 7.934 0 .861 0 1.405-.046 1.722z" />
            <path d="M138.1 107.978c-1.823 0-3.374.635-4.605 1.859-1.232 1.223-1.87 2.764-1.87 4.668 0 1.95.638 3.49 1.87 4.715 1.231 1.224 2.782 1.814 4.606 1.814 1.687 0 3.238-.59 4.56-1.769 1.323-1.224 2.007-2.81 2.007-4.76 0-4.034-2.874-6.527-6.567-6.527zm-6.43 19.764c1.095 2.177 3.604 3.536 5.975 3.536 4.743 0 7.114-2.764 7.114-8.34v-1.089c-1.231 2.313-4.241 3.672-7.205 3.672-2.965 0-5.518-.997-7.616-3.037-2.099-2.04-3.147-4.67-3.147-7.933 0-3.31 1.048-5.984 3.147-8.024 2.098-2.04 4.651-3.082 7.616-3.082 2.964 0 5.928 1.36 7.205 3.58v-3.127h4.606v18.042c0 9.112-3.921 13.69-11.72 13.69-4.15 0-7.844-2.176-9.85-5.938l1.915-.998 1.96-.952z" />
            <path d="M155.93 125.068h4.652v-21.17h-4.652v21.17zm5.427-29.738c0 1.678-1.323 2.947-3.1 2.947-1.689 0-3.148-1.315-3.148-2.947 0-1.632 1.46-2.947 3.147-2.947 1.778 0 3.1 1.27 3.1 2.947z" />
            <path d="M182.79 112.737c-.41-3.264-2.827-5.213-6.066-5.213-3.328 0-5.883 1.994-6.293 5.213h12.36zm4.561 3.4h-16.92c.41 3.264 3.056 5.304 6.522 5.304 2.416 0 4.697-1.088 5.791-3.038l3.831 1.678c-1.823 3.581-5.609 5.44-9.85 5.44-3.102 0-5.701-1.043-7.799-3.083-2.097-2.039-3.146-4.714-3.146-8.023 0-3.31 1.049-5.939 3.146-7.934 2.098-2.039 4.697-3.036 7.798-3.036 3.102 0 5.656.997 7.662 3.036 2.007 1.995 3.01 4.624 3.01 7.934 0 .861 0 1.405-.045 1.722z" />
            <path d="M197.337 115.91c-4.424-1.224-6.204-3.4-5.884-6.89.137-1.587.912-2.9 2.417-3.944 1.505-1.088 3.238-1.631 5.199-1.631 4.196 0 7.525 2.04 7.753 6.255h-4.469c-.182-1.496-1.505-2.267-3.101-2.267-1.232 0-2.6.454-3.01 1.18-.365.589-.41 1.223-.183 1.903.229.68 1.095 1.224 2.6 1.632l3.284.816c3.693.906 5.564 2.946 5.564 6.165 0 3.99-3.513 6.392-8.483 6.392-2.144 0-4.013-.635-5.655-1.858-1.642-1.27-2.463-2.947-2.508-5.078h4.332c.228 1.86 1.778 3.037 4.196 3.037 2.188 0 3.785-.952 3.785-2.538 0-1.224-1.003-2.04-2.965-2.493l-2.872-.68z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LogoSmall = ({ to, color }) => (
  <Link to={to}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="16"
      viewBox="0 0 120 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-32 -33)">
          <g transform="translate(32 33)">
            <path
              fill={color}
              d="M.027.92C-.082.432.137.155.66.155h.764c.437 0 .699.192.808.617L4.786 11.16c.196.808.284 1.593.284 1.593h.043s.131-.764.371-1.593L8.344.751c.109-.404.37-.596.807-.596h.612c.436 0 .698.192.785.595l2.86 10.411c.24.829.349 1.593.349 1.593h.044s.109-.785.306-1.593L16.79.771c.11-.424.371-.616.808-.616h.742c.502 0 .742.277.633.765l-3.689 13.64c-.109.404-.371.595-.808.595h-1.2c-.415 0-.677-.212-.786-.595L9.959 5.424C9.675 4.468 9.48 3.3 9.48 3.3h-.066s-.218 1.168-.48 2.124L6.401 14.56c-.109.383-.37.595-.808.595h-1.2c-.437 0-.699-.212-.808-.616L.027.92z"
            />
            <path
              fill={color}
              d="M26.152 9.1L24.57 4.34c-.244-.764-.549-2.124-.549-2.124h-.04s-.325 1.36-.568 2.125L21.809 9.1h4.343zM22.763.73c.122-.404.366-.574.751-.574h.974c.366 0 .63.17.751.574l4.709 13.619c.162.489-.061.807-.548.807h-.69c-.386 0-.63-.19-.751-.573l-1.279-3.74h-5.398l-1.259 3.74c-.101.382-.365.573-.73.573h-.69c-.508 0-.71-.318-.548-.807L22.763.728z"
            />
            <path
              fill={color}
              d="M34.002 1.982h-4.323c-.453 0-.679-.254-.679-.7V.855c0-.467.226-.7.68-.7H40.34c.433 0 .659.233.659.7v.425c0 .447-.226.701-.659.701H36.02v12.472c0 .468-.247.701-.68.701h-.679c-.432 0-.658-.233-.658-.7V1.981z"
            />
            <path
              fill={color}
              d="M44 .857c0-.468.232-.702.695-.702h7.251c.464 0 .696.234.696.702v.424c0 .447-.232.702-.696.702h-5.88v4.695h4.658c.442 0 .695.234.695.701v.425c0 .468-.253.702-.695.702h-4.658v4.822h6.239c.463 0 .695.234.695.701v.425c0 .468-.232.701-.695.701h-7.61c-.463 0-.695-.233-.695-.7V.856z"
            />
            <path
              fill={color}
              d="M59.861 7.358c1.56 0 2.552-1.105 2.552-2.783 0-1.084-.445-1.934-1.337-2.337-.364-.17-.79-.255-1.701-.255h-2.39v5.375h2.876zM55 .857c0-.468.223-.702.669-.702h3.848c1.397 0 2.086.128 2.674.404 1.357.638 2.248 2.018 2.248 3.931 0 1.912-.972 3.57-2.552 4.122v.042s.182.213.425.68l2.573 4.993c.263.488.06.828-.446.828h-.81c-.385 0-.628-.148-.79-.488l-2.795-5.482h-3.059v5.27c0 .467-.223.7-.668.7h-.648c-.446 0-.669-.233-.669-.7V.856z"
            />
            <g transform="translate(67 .155)">
              <path d="M0.129875166 0.00431407407L9.93859574 0.00431407407 9.93859574 15.4073458 0.129875166 15.4073458z" />
              <path
                fill={color}
                d="M.323 12.897l.372-.507c.262-.338.568-.38.983-.126.546.38 1.726 1.202 3.365 1.202 1.64 0 2.71-.844 2.71-2.13 0-3.293-7.43-2.554-7.43-7.196C.323 1.629 2.508.004 5.328.004c1.835 0 3.277.697 3.867 1.14.372.253.416.57.219.95l-.306.527c-.262.443-.612.4-.983.19-.59-.338-1.53-.866-2.841-.866-1.596 0-2.776.802-2.776 2.09 0 3.207 7.43 2.341 7.43 7.131 0 2.343-1.813 4.241-4.895 4.241-2.23 0-3.934-.991-4.633-1.54-.284-.253-.415-.548-.087-.97"
              />
            </g>
            <path
              fill={color}
              d="M80 .857c0-.468.234-.702.703-.702h.683c.469 0 .703.234.703.702v5.906h7.822V.857c0-.468.256-.702.703-.702h.704c.448 0 .682.234.682.702v13.597c0 .468-.234.701-.682.701h-.704c-.447 0-.703-.233-.703-.7V8.568h-7.822v5.885c0 .468-.234.701-.703.701h-.683c-.469 0-.703-.233-.703-.7V.856z"
            />
            <path
              fill={color}
              d="M94 1.31c0-.756.411-1.155 1.19-1.155h7.316c.78 0 1.191.399 1.191 1.154v.84c0 .755-.411 1.153-1.19 1.153h-4.719V6.03h3.528c.78 0 1.19.398 1.19 1.153v.84c0 .755-.41 1.154-1.19 1.154h-3.528v2.831h5.022c.779 0 1.19.399 1.19 1.154v.84c0 .755-.411 1.153-1.19 1.153h-7.62c-.779 0-1.19-.398-1.19-1.154V1.31z"
            />
            <path
              fill={color}
              d="M112.154 12.008c2.525 0 4.07-1.51 4.07-4.384 0-2.853-1.608-4.322-4.07-4.322h-1.502v8.706h1.502zM107 1.31c0-.755.396-1.154 1.148-1.154h4.152c4.695 0 7.7 2.749 7.7 7.469 0 4.72-3.005 7.531-7.7 7.531h-4.152c-.752 0-1.148-.398-1.148-1.154V1.31z"
            />
          </g>
        </g>
      </g>
    </svg>
  </Link>
);

export const Video = styled.img`
  width: 100%;
  max-width: 425px;
  display: block;
  margin: 2rem auto;
  height: auto;
`;

const SecondaryImage = styled.div`
  ${onPhone} {
    display: none;
  }
`;

const PrimaryImage = styled.img`
  ${onPhone} {
    width: 100% !important;
    height: auto !important;
    transform: none !important;
    max-width: 100% !important;
  }
`;

export const ImageWithShadow = ({
  src,
  color = "#8BC79E",
  children,
  style,
  ...other
}) => (
  <div
    style={{
      margin: "0 auto",
      display: "flex",
    }}
  >
    <PrimaryImage
      src={src}
      style={{
        flexBasis: "0 0 350px",
        position: "relative",
        zIndex: 10,
        boxShadow: `${color} 12px -12px 0 0`,
        maxWidth: "85%",
        width: "350px",
        transform: "translateX(30px)",
        ...style,
      }}
      {...other}
    />
    <SecondaryImage>{children}</SecondaryImage>
  </div>
);

export const AboutSVG = ({ color = "#606060" }) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
      fill={color}
    />
  </svg>
);

export const HomeSVG = ({ color = "#606060" }) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z" fill={color} />
  </svg>
);

export const ProgramSVG = ({ color = "#606060" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.06994 8.3208L6.68377 8.42789C6.29805 8.45771 5.95452 8.65986 5.74121 8.98251L4.14257 11.4004C3.98079 11.645 3.95507 11.9513 4.07373 12.2195C4.19241 12.4878 4.43632 12.6747 4.72616 12.7196L5.99537 12.9162C6.29227 11.3406 7.00302 9.76882 8.06994 8.3208V8.3208Z"
        fill={color}
      />
      <path
        d="M11.084 18.0043L11.2806 19.2735C11.3255 19.5633 11.5124 19.8072 11.7806 19.9259C11.8922 19.9752 12.0104 19.9996 12.1279 19.9996C12.293 19.9996 12.4569 19.9515 12.5998 19.857L15.0177 18.2584C15.3404 18.0451 15.5425 17.7015 15.5723 17.3159L15.6794 15.9297C14.2313 16.9966 12.6596 17.7074 11.084 18.0043V18.0043Z"
        fill={color}
      />
      <path
        d="M10.6063 17.1246C10.6504 17.1246 10.6947 17.121 10.7389 17.1136C11.399 17.0033 12.0353 16.8173 12.6422 16.5739L7.42568 11.3574C7.18231 11.9643 6.99638 12.6006 6.886 13.2607C6.8425 13.5209 6.92978 13.786 7.11631 13.9726L10.027 16.8833C10.1819 17.0382 10.3909 17.1246 10.6063 17.1246Z"
        fill={color}
      />
      <path
        d="M18.724 11.0938C20.0005 8.62587 20.0478 6.01789 19.9811 4.79478C19.9583 4.37568 19.6241 4.04156 19.205 4.01872C19.0059 4.00784 18.7698 4 18.5038 4C17.1361 4 14.972 4.20715 12.906 5.2758C11.2641 6.12508 9.11106 7.99509 7.84082 10.4526C7.85582 10.4644 7.87048 10.4768 7.88429 10.4906L13.5092 16.1156C13.5231 16.1294 13.5355 16.144 13.5472 16.159C16.0047 14.8887 17.8747 12.7357 18.724 11.0938V11.0938ZM13.3028 7.38247C14.2166 6.4687 15.7035 6.46861 16.6173 7.38247C17.06 7.82513 17.3038 8.41372 17.3038 9.03974C17.3038 9.66577 17.06 10.2544 16.6173 10.697C16.1605 11.1539 15.5602 11.3823 14.9601 11.3824C14.3598 11.3824 13.7598 11.154 13.3028 10.697C12.8601 10.2544 12.6163 9.66577 12.6163 9.03974C12.6163 8.41372 12.8601 7.82513 13.3028 7.38247V7.38247Z"
        fill={color}
      />
      <path
        d="M13.9661 10.0342C14.5144 10.5825 15.4065 10.5825 15.9548 10.0342C16.2204 9.76859 16.3667 9.41546 16.3667 9.03984C16.3667 8.66421 16.2204 8.31109 15.9548 8.0455C15.6807 7.77134 15.3206 7.63428 14.9604 7.63428C14.6004 7.63428 14.2402 7.77134 13.9661 8.0455C13.7005 8.31109 13.5542 8.66421 13.5542 9.03984C13.5542 9.41546 13.7005 9.76862 13.9661 10.0342Z"
        fill={color}
      />
      <path
        d="M4.47804 17.1852C4.59801 17.1852 4.71798 17.1395 4.80948 17.0479L6.33989 15.5175C6.52295 15.3344 6.52295 15.0376 6.33989 14.8546C6.15686 14.6715 5.86004 14.6715 5.67698 14.8546L4.14657 16.385C3.96351 16.5681 3.96351 16.8648 4.14657 17.0479C4.23811 17.1394 4.35807 17.1852 4.47804 17.1852Z"
        fill={color}
      />
      <path
        d="M7.74255 16.2574C7.55952 16.0744 7.2627 16.0744 7.07964 16.2574L4.1373 19.1998C3.95423 19.3828 3.95423 19.6796 4.1373 19.8627C4.22883 19.9542 4.3488 20 4.46877 20C4.58873 20 4.7087 19.9542 4.8002 19.8626L7.74252 16.9203C7.92561 16.7373 7.92561 16.4405 7.74255 16.2574V16.2574Z"
        fill={color}
      />
      <path
        d="M8.48212 17.6598L6.95175 19.1902C6.76869 19.3732 6.76869 19.67 6.95175 19.8531C7.04328 19.9446 7.16325 19.9904 7.28319 19.9904C7.40312 19.9904 7.52312 19.9446 7.61462 19.8531L9.14503 18.3227C9.32809 18.1396 9.32809 17.8428 9.14503 17.6598C8.962 17.4767 8.66518 17.4767 8.48212 17.6598Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);

export const DonateSVG = ({ color = "#606060" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8769 10.7399C15.6731 11.4152 15.7742 11.5091 16.1246 11.8108C16.3814 11.5906 16.6016 11.3903 17.5354 10.6073C19.2491 9.16831 20.4872 8.12932 20.4872 6.59455C20.4872 5.13962 19.4184 4 18.054 4C17.1375 4 16.5294 4.51711 16.1475 5.04379C15.786 4.51551 15.198 4 14.2789 4C12.9145 4 11.8462 5.13966 11.8462 6.59455C11.8462 8.16822 13.0519 9.19121 14.8769 10.7399Z"
      fill={color}
    />
    <path
      d="M6.1193 19.7057C7.54805 18.735 9.11136 18.3642 10.8128 18.6478L13.5394 19.1167C14.5865 19.2913 15.5462 19.2367 16.3751 18.964C18.0899 18.3925 19.8065 16.5926 20.2615 15.9102C20.8941 14.9614 21.2416 13.9252 21.4598 13.4236C21.667 12.9437 21.667 12.6056 21.4488 12.4093C21.198 12.1802 20.7726 12.3002 20.4563 12.4747C19.9547 12.7583 19.4217 13.5763 18.8763 14.3615C18.1674 15.3867 16.9532 15.9975 15.7098 15.9975H11.7616V14.9068H13.3976C16.2987 14.9068 17.5966 14.3615 17.5966 13.8162C17.5966 13.3581 16.6914 13.1509 16.3859 13.0855C15.1972 12.8128 12.5141 12.6819 10.6819 11.8639C10.3514 11.7194 9.97683 11.6382 9.58037 11.6349C8.95878 11.6296 8.28323 11.8233 7.63891 12.322C7.62807 12.322 7.62807 12.322 7.61713 12.3329C7.19177 12.6601 6.61009 13.14 5.82482 13.8271C5.15949 14.416 4.51604 15.1685 3.39263 16.1284L3 16.4665L5.68304 20.0002L6.1193 19.7057Z"
      fill={color}
    />
    <path
      d="M18.6843 8.11486L18.8813 8.38769L19.0856 8.12038C19.2959 7.84534 19.612 7.59668 20.0849 7.59668C20.8014 7.59668 21.3636 8.16109 21.3636 8.89244C21.3636 9.26869 21.2069 9.59581 20.9019 9.94457C20.5908 10.3002 20.1475 10.6542 19.6032 11.0875C19.1987 11.4092 19.0055 11.5673 18.8726 11.6767C18.8721 11.6763 18.8716 11.6759 18.8712 11.6755C18.742 11.5692 18.6071 11.4582 18.2454 11.1673C17.6659 10.701 17.2104 10.3334 16.8958 9.96936C16.5884 9.61376 16.4346 9.28282 16.4346 8.89244V8.89244C16.4346 8.16104 16.9965 7.59668 17.713 7.59668C18.1861 7.59668 18.488 7.84289 18.6843 8.11486Z"
      fill={color}
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export const ContactSVG = ({ color = "#606060" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0869 16.252V19.4784C10.0869 19.7038 10.2316 19.9035 10.4459 19.9744C10.4994 19.9918 10.5544 20.0001 10.6087 20.0001C10.7714 20.0001 10.9287 19.9236 11.0288 19.7873L12.9161 17.2189L10.0869 16.252Z"
      fill={color}
    />
    <path
      d="M20.4767 4.09676C20.3167 3.98337 20.1066 3.96806 19.9327 4.05919L4.28038 12.2332C4.09534 12.3299 3.98612 12.5274 4.00142 12.7354C4.01742 12.9441 4.15586 13.1222 4.35273 13.1897L8.70407 14.677L17.9709 6.75348L10.8001 15.3929L18.0927 17.8854C18.1469 17.9035 18.204 17.9132 18.261 17.9132C18.3556 17.9132 18.4495 17.8875 18.5323 17.8374C18.6645 17.7567 18.7542 17.621 18.7772 17.4687L20.6903 4.59902C20.7188 4.40424 20.6367 4.21085 20.4767 4.09676V4.09676Z"
      fill={color}
    />
  </svg>
);
