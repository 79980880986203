import React from "react";
import Box from "./Kit/Box";
import { ErrorHandler } from "./Kit/ErrorCapture";
import Flex from "./Kit/Flex";
import Spinner from "./Kit/Spinner";

const LoadingIndicator = ({ pastDelay, timedOut, error }) => {
  if (error) {
    window.location.reload(true);
  }

  if (error || timedOut) {
    return (
      <Box p={3}>
        <ErrorHandler
          title="A loading error has occurred."
          message="Please try reloading."
          error={error}
        />
      </Box>
    );
  }

  if (pastDelay) {
    return (
      <Flex width={1} justifyContent="center" alignItems="center">
        <Spinner
          m={4}
          delay={400}
          alignItems="center"
          alignSelf="center"
          justifyContent="center"
        />
      </Flex>
    );
  }

  return null;
};

export default LoadingIndicator;
