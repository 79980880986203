import gql from "graphql-tag";

export const UsersQuery = gql`
  query Users($email: String!) {
    users(email: $email) {
      email
    }
  }
`;



// query Users($query: String) {
//   users(query: $query) {
//     id
//     name
//     email
//     photo {
//       id
//       public_id
//       __typename
//     }
//     __typename
//   }
// }


