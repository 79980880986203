import gql from "graphql-tag";
import { EpisodeStepDetailsFragment } from "./EpisodeStepDetailsFragment";

export const EpisodeDetailsFragment = gql`
  fragment EpisodeDetails on Episode {
    id
    title
    orderNumber
    steps {
      id
      __typename
      ...EpisodeStepDetails
    }
  }
  ${EpisodeStepDetailsFragment}
`;
