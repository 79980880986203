import gql from "graphql-tag";
import { StepResourcesFragment } from "../fragments/StepResourcesFragment";

export const RemoveResourceMutation = gql`
  mutation RemoveResource($id: Int!) {
    deleteResource(id: $id) {
      id
    }
  }
`;

export const RemoveResourceFromStepMutation = gql`
  mutation RemoveResourceFromStep($stepId: Int!, $resourceId: Int!) {
    removeResourceFromStep(stepId: $stepId, resourceId: $resourceId) {
      id
      ...StepResources
    }
  }
  ${StepResourcesFragment}
`;

export const AddResourceToStepMutation = gql`
  mutation AddResourceToStep($stepId: Int!, $resourceId: Int!) {
    addResourceToStep(stepId: $stepId, resourceId: $resourceId) {
      id
      ...StepResources
    }
  }
  ${StepResourcesFragment}
`;
