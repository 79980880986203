import gql from "graphql-tag";

export const CoachEventFragment = gql`
  fragment CoachEvent on Coach {
    id
    createdAt
    section {
      id
      title
      slug
      enrollmentStarts
      shortDescription
      image {
        id
        public_id
      }
      publisher {
        id
        name
        slug
        avatar {
          id
          public_id
        }
        image {
          id
          public_id
        }
      }
    }
  }
`;
