import * as React from "react";
import { Query } from "react-apollo";
import Box from "../Kit/Box";
import Text from "../Kit/Text";
import moment from "moment";
import styled from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import Body from "../Input/Message";
import ChatStatusSubscription from "./ChatStatusSubscription";
import TypingIndicator, { isTyping } from "./TypingIndicator";
import debug from "debug";
import ChatAvatarGroup from "./ChatAvatarGroup";
import { ConversationSummary } from "../API/queries/ConversationSummary";

const log = debug("app:ChatListItem");

const ListButton = styled(Box)`
  background: white;
  border: none;
  outline: none;
  cursor: pointer;
  :hover {
    background: ${themeGet("colors.gray.2")};
  }
  & .MessageParagraph {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-size: ${themeGet("fontSizes.0")}px;
    overflow: hidden;
    color: ${props =>
      props.hasUnread ? props.theme.colors.black : props.theme.colors.faded};
    font-weight: ${props => (props.hasUnread ? "bold" : 400)};
  }
`;

const ChatPerson = ({ data, onRequestOpen }) => {
  const firstPerson = data.conversation.members[0];
  if (firstPerson) {
    const participants = data.conversation.members;

    const { edges } = data.conversation.replies;
    const lastEdge = edges[edges.length - 1] || {};
    const node = lastEdge.node;

    const hasUnread = data.conversation.unreadCount > 0;

    const typer = isTyping(participants);

    return (
      <ListButton
        height="72px"
        width="100%"
        pl={1}
        pr={0}
        alignItems="center"
        onClick={() => {
          onRequestOpen(
            data.conversation.id,
            participants.map(participant => participant.userId)
          );
        }}
        display="flex"
        as="button"
        hasUnread={hasUnread}
      >
        <Box mr={2} flex="0 0 auto">
          <ChatAvatarGroup members={data.conversation.members} />
        </Box>
        <Box
          py="4px"
          pr={2}
          flex="1"
          overflow="hidden"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          height="72px"
          borderBottom="1px solid"
          borderColor="borderColor"
        >
          <Box alignItems="center" textAlign="left" width={1} display="flex">
            <Text
              fontSize={1}
              flex="1"
              fontWeight={hasUnread ? "bold" : 500}
              textAlign="left"
              pr={1}
              ellipsis
            >
              {participants.map((part, i) => {
                const isLast = i === participants.length - 1;
                return part.user.name + (isLast ? "" : ", ");
              })}
            </Text>
            {node && (
              <Text
                flex="0 0 auto"
                fontSize={0}
                color={hasUnread ? "blue.20" : "faded"}
              >
                {moment(node.createdAt).calendar()}
              </Text>
            )}
          </Box>
          {typer ? (
            <TypingIndicator size="small" members={participants} />
          ) : (
            node && <Body message={node.content} showTruncateMore={false} />
          )}
        </Box>
      </ListButton>
    );
  }

  return null;
};

export const ListItemLoading = () => (
  <Box pl={1} display="flex" alignItems="center" height="72px">
    <Box borderRadius="50%" bg="gray.2" mr={2} height="56px" width="56px" />
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      flex="1"
      height="72px"
      pr={2}
      borderBottom="1px solid"
      borderColor="borderColor"
    >
      <Box height={"12px"} bg="gray.2" borderRadius="3px" />
      <Box mt={"6px"} height={"12px"} bg="gray.2" borderRadius="3px" />
    </Box>
  </Box>
);

class ChatListItem extends React.Component {
  render() {
    return (
      <Query
        query={ConversationSummary}
        variables={{
          id: this.props.conversationId,
          first: 1
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <ListItemLoading />;
          if (error) return null;
          if (!data.conversation) {
            return null;
          }

          return (
            <React.Fragment>
              <UpdateUnread
                chats={this.props.chats}
                conversationId={this.props.conversationId}
                updateUnread={this.props.updateUnread}
                data={data}
              />

              <ChatStatusSubscription
                conversationId={this.props.conversationId}
              />

              <ChatPerson
                onRequestOpen={this.props.onRequestOpen}
                data={data}
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default ChatListItem;

class UpdateUnread extends React.Component {
  componentDidMount() {
    const unreadNow = this.hasUnread(this.props);
    log("unread now on mount %s", unreadNow);
    if (unreadNow > 0) {
      this.props.chats.updateUnreadCount(this.props.conversationId, unreadNow);
    }
  }

  componentDidUpdate(prevProps) {
    const unreadNow = this.hasUnread(this.props);
    // const unreadPrev = this.hasUnread(prevProps);
    log("unread now?: %s, %s", unreadNow);
    this.props.chats.updateUnreadCount(this.props.conversationId, unreadNow);

    // if (unreadNow !== unreadPrev) {
    //   this.props.updateUnread(this.props.conversationId, unreadNow);
    // }
  }

  hasUnread = props => {
    return props.data.conversation.unreadCount;
  };

  render() {
    return null;
  }
}
