import gql from "graphql-tag";
import { CommentFragment } from "../fragments/CommentFragment";

export const UpdateAnnotationCommentMutation = gql`
  mutation UpdateAnnotationComment($input: UpdateCommentInputType!, $id: Int!) {
    updateAnnotationComment(input: $input, id: $id) {
      id
      ...Comment
    }
  }
  ${CommentFragment}
`;

export const CreateAnnotationCommentMutation = gql`
  mutation CreateAnnotationComment($input: CreateCommentInputType!) {
    createAnnotationComment(input: $input) {
      id
      ...Comment
    }
  }
  ${CommentFragment}
`;

export const LikeCommentMutation = gql`
  mutation LikeComment($commentId: Int!) {
    likeComment(commentId: $commentId) {
      id
      ...Comment
    }
  }
  ${CommentFragment}
`;

export const UnlikeCommentMutation = gql`
  mutation UnlikeComment($commentId: Int!) {
    unlikeComment(commentId: $commentId) {
      id
      ...Comment
    }
  }
  ${CommentFragment}
`;
