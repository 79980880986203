import React from "react";
import EventFeed from "../EventFeed";
import { Box, Heading, NavLink } from "../Kit";
import { on, off } from "delegated-events";

// type Props = {
//   viewer: {
//     id: number
//   }
// };

class EventDropdown extends React.Component {
  componentDidMount() {
    on("click", ".trigger-close", this.close);
  }

  componentWillUnmount() {
    off("click", ".trigger-close", this.close);
  }

  close = () => {
    this.props.close();
  };

  render() {
    return (
      <Box
        width={1}
        minHeight={["400px", null, "none"]}
        maxHeight="500px"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="borderColor"
          bg="gray.3"
          borderRadius="4px 4px 0 0"
          px={2}
          py={2}
        >
          <Heading fontSize={0}>Recent events</Heading>
          <NavLink onClick={this.close} fontSize={0} to="/">
            See all
          </NavLink>
        </Box>
        <Box id="event-dropdown" flex="1" overflow="scroll">
          <EventFeed
            customScrollContainer="#event-dropdown"
            popup
            first={10}
            excludeViewer
            viewer={this.props.viewer}
          />
        </Box>
      </Box>
    );
  }
}

export default EventDropdown;
