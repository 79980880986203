import * as React from "react";
import { Box } from "./Kit";
import SideNav from "./Nav/SideNav";

class SidebarLayout extends React.Component {
  render() {
    return (
      <Box width={1} display="flex">
        {/* <SideNav inline /> */}
        <Box width={1} overflow="hidden">
          {this.props.children}
        </Box>
      </Box>
    );
  }
}

export default SidebarLayout;
