import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, split } from "apollo-link";
import auth from "../Auth/auth";
import { onError } from "apollo-link-error";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import extractedQueries from "./extracted_queries.json";
import debug from "debug";

const log = debug("app:apollo-client");

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

// Http middleware

const wsURL =
  process.env.NODE_ENV === "production"
    ? "wss://" + window.location.host + "/subscriptions"
    : "ws://localhost:3001/subscriptions";

export const createClient = (uri = "/api") => {
  // support websocket subscriptions
  const wsLink = new WebSocketLink({
    uri: wsURL,
    options: {
      lazy: true,
      reconnect: true,
      inactivityTimeout: 30000 // if a user logs out, cancel their subscription
    }
  });

  // support regular http

  const httpLink = createHttpLink({
    uri,
    credentials: "include"
  });

  const persistOptions = {
    disable: () => false,
    generateHash: query => {
      let hash = "";

      query.definitions.some(def => {
        if (def.kind === "OperationDefinition") {
          hash = extractedQueries[def.name.value];
          return true;
        }
      });

      return hash;
    }
  };

  const persistedQueryLink = createPersistedQueryLink(persistOptions).concat(
    httpLink
  );
  const persistedWsLink = createPersistedQueryLink(persistOptions).concat(
    wsLink
  );

  // determine which one to use
  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    persistedWsLink,
    persistedQueryLink
  );

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: auth.token ? `Bearer ${auth.token}` : null
      }
    });
    return forward(operation);
  });

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      console.log("Network error");
      console.error(networkError);
    }

    // this should only really occur if we try to fetch a session
    // from our server, but it doesn't exist. In this case, we call our
    // clientside logout function which should reset everything for us.
    // this is a bit of a hack and really should never happen
    if (graphQLErrors) {
      console.log("GraphQL Errors");
      const hasAuthenticationError = graphQLErrors.some(err => {
        if (err.message === "You are not authenticated") return true;
        return false;
      });

      if (hasAuthenticationError) {
        console.log("Authentication error");
        // auth.signout();
      }
      log("GraphQL Errors: %o", graphQLErrors);
    }
  });

  // apollo client
  const client = new ApolloClient({
    link: middlewareLink.concat(errorLink).concat(link),
    cache: new InMemoryCache({ fragmentMatcher })
  });

  return client;
};

export default createClient();
