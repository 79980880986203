import React from "react";
import linkStrategy from "draft-js-linkify-plugin/lib/linkStrategy";

// import type ContentBlock from "draft-js/lib/ContentBlock";
// import type { ComponentType, Node } from "react";

// type DecoratorComponentProps = {
//   decoratedText: string,
//   children?: Node
// };

// export type LinksDecoratorComponentProps = {
//   href: string,
//   children?: Node
// };

let i = 0;
const createLinksDecorator = Component => ({
  strategy: (contentBlock, callback) => {
    if (contentBlock.type === "code-block") return;

    linkStrategy(contentBlock, callback);
  },
  component: ({ decoratedText, children }) => (
    <Component
      href={decoratedText}
      children={children}
      /* NOTE(@mxstbr): This is super hacky, but I couldn't find a way to give two URLs in the same message a different key. (i.e. "I am on https://github.com https://github.com is great" would only show the link once) */
      key={`link-${i++}`}
    />
  )
});

export default createLinksDecorator(props => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
));
