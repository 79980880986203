import gql from "graphql-tag";
import { ResourceFragment } from "./ResourceFragment";

export const StepResourcesFragment = gql`
  fragment StepResources on Step {
    id
    resources {
      id
      ...Resource
    }
  }
  ${ResourceFragment}
`;
