import React from "react";
import debug from "debug";
import { graphql } from "react-apollo";
import moment from "moment";
import { UpdateNotificationsReadMutation } from "../API/mutations/UserMutations";

const log = debug("app:EventFeed");

class UpdateRead extends React.Component {
  componentDidMount() {
    this.updateRead();
  }

  updateRead = async () => {
    try {
      log("update read notifications date");
      await this.props.updateNotification({
        variables: {
          id: this.props.viewer.id,
          input: {
            lastReadNotifications: moment.utc().format()
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    return null;
  }
}

export const withUpdateNotification = graphql(UpdateNotificationsReadMutation, {
  name: "updateNotification"
});

export default withUpdateNotification(UpdateRead);
