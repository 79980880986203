import React from "react";
import { Box, Input, Alert, Modal, Button, LayerLoading } from "../../Kit";
import * as yup from "yup";
import Raven from "raven-js";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { defaults } from "lodash";
import debug from "debug";
import { withFormik } from "formik";
import AddOrSelectGallery from "../../Galleries/AddOrSelectGallery";
import {
  UpdateGalleryResourceMutation,
  CreateGalleryResourceMutation
} from "../../API/mutations/GalleryMutations";
const log = debug("app:AddVideo");

const GalleryForm = ({
  props,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  isSubmitting
}) => (
  <div>
    <Modal.Body>
      <form id="formId" onSubmit={handleSubmit}>
        <Input
          label="Title *"
          name="title"
          onChange={handleChange}
          error={errors.title}
          onBlur={handleBlur}
          value={values.title}
        />
        <Input
          label="Short description *"
          name="description"
          onChange={handleChange}
          error={errors.description}
          onBlur={handleBlur}
          value={values.description}
        />
      </form>

      <AddOrSelectGallery
        courseId={values.courseId}
        selected={values.galleryId}
        onSelect={gallery => {
          setFieldValue("galleryId", gallery.id);
          if (!values.title) {
            setFieldValue("title", gallery.title);
          }
          if (!values.description) {
            setFieldValue("description", gallery.description);
          }
        }}
      />

      {errors.general && (
        <Box my={2}>
          <Alert
            type="danger"
            title={
              errors.general ||
              "An unexpected error occurred. Please try again."
            }
          />
        </Box>
      )}
    </Modal.Body>

    <Modal.Footer>
      <Button
        onClick={handleSubmit}
        appearance="primary"
        mb={0}
        type="submit"
        saving={isSubmitting}
      >
        Save
      </Button>
    </Modal.Footer>
    <LayerLoading loading={isSubmitting} />
  </div>
);

const formConfig = {
  mapPropsToValues: props => {
    let existing = props.resource ? props.resource : {};
    if (existing.meta) {
      existing = {
        ...existing,
        ...existing.meta
      };
    }

    return defaults(existing, {
      courseId: props.courseId,
      title: "",
      description: "",
      galleryId: ""
    });
  },
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: yup.object().shape({
    title: yup
      .string()
      .trim()
      .required("A title is required."),
    description: yup
      .string()
      .trim()
      .required("A description is required."),
    galleryId: yup
      .string()
      .trim()
      .required("A gallery is required.")
  }),
  handleSubmit: async (
    values,
    { resetForm, props, setSubmitting, setErrors }
  ) => {
    const attr = {
      input: {
        title: values.title,
        description: values.description
      },
      galleryId: values.galleryId
    };

    // handle updates
    if (props.resource) {
      attr.id = props.resource.id;
    } else {
      attr.input.courseId = props.courseId;
    }

    log("saving: %o", attr);

    try {
      const res = await props.mutate({ variables: attr });
      log("mutation response: %o", res);

      resetForm();
      if (props.onRequestClose) {
        props.onRequestClose();
      }
    } catch (err) {
      console.error(err.stack);
      setErrors({ general: err.message });
      Raven.captureException(err);
    }

    setSubmitting(false);
  }
};

const AddGalleryFormik = withFormik(formConfig)(GalleryForm);

const withAddGallery = graphql(CreateGalleryResourceMutation, {
  options: { refetchQueries: ["CourseResources"] }
});

export default compose(withAddGallery)(AddGalleryFormik);

const withUpdateGallery = graphql(UpdateGalleryResourceMutation);

export const UpdateGallery = compose(withUpdateGallery)(AddGalleryFormik);
