import React from "react";
import {
  Label,
  Alert,
  Input,
  Button,
  Layer,
  LayerLoading,
  Box,
  Flex
} from "../../Kit";
import Raven from "raven-js";

import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import Video from "./Video";
import debug from "debug";
import getYoutubeId from "get-youtube-id";
import youtubeURL from "youtube-url";
import { toSeconds, parse } from "iso8601-duration";
import { CreateVideoMutation } from "../../API/mutations/VideoMutations";

const log = debug("app:Admin:SelectVideo");

const VIMEO_ROOT = "https://vimeo.com/api/oembed.json";
const GOOGLE_API_KEY = "AIzaSyD1MtgM5FNgwjzmAEJ9vgDb0OFioAcWkvM";

const fetchVimeo = async url => {
  const res = await window.fetch(`${VIMEO_ROOT}?url=${url}&width=600`);
  if (!res.ok) {
    throw new Error(res.message);
  }
  const data = await res.json();
  return data;
};

const fetchYoutube = async url => {
  const id = getYoutubeId(url);
  const api = `https://www.googleapis.com/youtube/v3/videos?id=${id}&part=contentDetails&key=${GOOGLE_API_KEY}`;
  const res = await window.fetch(api);
  if (!res.ok) {
    throw new Error(res.message);
  }
  const data = await res.json();
  if (data.items[0]) {
    return data.items[0];
  }

  throw new Error("Item not found");
};

class SelectVideo extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    videoId: PropTypes.number
  };

  static defaultProps = {
    label: "Video *"
  };

  state = {
    url: "",
    loadingError: false,
    loading: false
  };

  saveVideo = async state => {
    // throw our errors
    const { video_id, duration, url } = state;

    const input = {
      url,
      uid: video_id,
      duration
    };

    const video = await this.props.createVideo({
      variables: {
        input
      }
    });

    return video;
  };

  save = async (url, video_id, duration) => {
    try {
      const video = await this.saveVideo({
        duration,
        video_id: String(video_id),
        url
      });

      log("response: %O", video);
      this.props.onChange(video.data.createVideo.id);
    } catch (err) {
      Raven.captureException(err);
      console.error(err);
      throw err;
    }
  };

  loadVideo = async e => {
    e.preventDefault();

    // handle different videos here.
    this.setState({ loadingError: false, loading: true });

    try {
      // Is youtube?
      if (youtubeURL.valid(this.state.url)) {
        log("Loading youtube URL: %s", this.state.url);
        const data = await fetchYoutube(this.state.url);
        log("Youtube API response: %O", data);
        const { id, contentDetails } = data;
        await this.save(
          this.state.url,
          id,
          Math.round(toSeconds(parse(contentDetails.duration)))
        );
      }

      // is Vimeo?
      if (this.state.url.indexOf("vimeo") > -1) {
        const data = await fetchVimeo(this.state.url);
        const { duration, video_id } = data;
        await this.save(this.state.url, video_id, duration);
      }

      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
      this.setState({ loadingError: err, loading: false });
    }
    // Assume it's just an mp4 video
  };

  render() {
    return (
      <Box>
        <Label>{this.props.label}</Label>
        <Layer elevation={1} mb={2}>
          <Box p={2} pb={0} width={1}>
            <LayerLoading loading={this.state.loading} />
            {(this.props.data && this.props) ||
              (this.state.loadingError && (
                <Alert
                  type="danger"
                  mb={2}
                  title={
                    "We were unable to load this video. Please ensure you have a valid URL."
                  }
                />
              ))}
            {this.props.videoId && (
              <Box mb={2}>
                <Video videoId={this.props.videoId} />
                <Button onClick={() => this.props.onChange(null)}>
                  Remove Video
                </Button>
              </Box>
            )}
            {!this.props.videoId && (
              <Flex width={1} mt={2}>
                <Input
                  flex={1}
                  label="Video URL"
                  onChange={e => this.setState({ url: e.target.value })}
                  placeholder="Vimeo, Youtube, or MP4 URL"
                  helpText="Enter a URL for a Vimeo, Youtube, or Mp4 formatted video and press 'Load' to verify it."
                  hideLabel
                  value={this.state.url}
                />
                <Box>
                  <Button
                    ml={2}
                    size="large"
                    disabled={!this.state.url}
                    onClick={this.loadVideo}
                    appearance="success"
                  >
                    Load
                  </Button>
                </Box>
              </Flex>
            )}
          </Box>
        </Layer>
      </Box>
    );
  }
}

const withCreateVideo = graphql(CreateVideoMutation, {
  name: "createVideo"
});

export default compose(withCreateVideo)(SelectVideo);
