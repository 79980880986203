import gql from "graphql-tag";
import {
  ConversationReplyFragment,
  ConversationReplyType
} from "../fragments/ConversationReplyFragment";
import {
  ConversationFragment,
  ConversationType
} from "../fragments/ConversationFragment";
import {
  UserBasicsFragment,
  UserBasicsType
} from "../fragments/UserBasicsFragment";

export const ConversationQuery = gql`
  query Conversation($id: Int, $userIds: [Int], $first: Int!, $after: String) {
    viewer {
      ...UserBasics
    }
    conversation(id: $id, userIds: $userIds) {
      id
      ...Conversation
      replies(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            ...ConversationReply
          }
        }
      }
    }
  }
  ${ConversationReplyFragment}
  ${ConversationFragment}
  ${UserBasicsFragment}
`;

export interface ConversationQueryType {
  viewer: UserBasicsType;
  conversation: ConversationType & {
    id: number;
    replies: Array<Replies>;
  };
}

interface Replies {
  pageInfo: {
    hasNextPage: boolean;
    endCursor: string;
  };
  edges: Array<Node>;
}

interface Node {
  node: ConversationReplyType & {
    id: number;
  };
}
