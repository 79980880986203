import gql from "graphql-tag";
import { AnnotationFragment } from "../fragments/AnnotationFragment";

export const CreateAnnotationMutation = gql`
  mutation CreateAnnotation($input: CreateStepannotationInputType!) {
    createAnnotation(input: $input) {
      id
      ...Annotation
    }
  }
  ${AnnotationFragment}
`;

export const UpdateAnnotationMutation = gql`
  mutation UpdateAnnotation($input: UpdateStepannotationInputType!, $id: Int!) {
    updateAnnotation(input: $input, id: $id) {
      id
      ...Annotation
    }
  }
  ${AnnotationFragment}
`;

export const LikeAnnotationMutation = gql`
  mutation LikeAnnotation($annotationId: Int!) {
    likeAnnotation(annotationId: $annotationId) {
      id
      ...Annotation
    }
  }
  ${AnnotationFragment}
`;

export const UnlikeAnnotationMutation = gql`
  mutation UnlikeAnnotation($annotationId: Int!) {
    unlikeAnnotation(annotationId: $annotationId) {
      id
      ...Annotation
    }
  }
  ${AnnotationFragment}
`;
