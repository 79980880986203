import color from "color";
import { themeGet } from "@styled-system/theme-get";
import { css } from "styled-components/macro";
import queryString from "query-string";
import debug from "debug";

const log = debug("app:Kit:util");

export const boxShadow = (c, alpha = 0.5) => {
  const fn = themeGet(c);

  return props => {
    const clr = color(fn(props))
      .alpha(alpha)
      .string();
    return `0 0 0 0.2rem ${clr}`;
  };
};

export const transparent = (c, alpha = 0.5) => {
  const fn = themeGet(c);
  return props => {
    return color(fn(props))
      .alpha(alpha)
      .string();
  };
};

export const media = (index, args) => {
  return props => {
    const { breakpoints } = props.theme;

    return css`
      @media screen and (min-width: ${breakpoints[index]}em) {
        ${css(...args)};
      }
    `;
  };
};

export const border = side => {
  return props => {
    const { colors } = props.theme;
    return css`
      border-${side}-width: 1px;
      border-${side}-style: solid;
      border-${side}-color: ${colors.borderColor};
    `;
  };
};

export const fetchAndReplaceQS = (name, props, fn, val, other) => {
  const { pathname, search } = window.location;
  let qs = queryString.parse(search);
  if (qs[name]) {
    if (fn) fn(qs[name]);
  }

  if (val) {
    qs[name] = val;
  } else {
    delete qs[name];
  }

  if (other) {
    log("with other: %o", other);
    qs = {
      ...qs,
      ...other
    };
  }

  log("replace qs");

  const sep = Object.keys(qs).length > 0 ? "?" : "";

  window.history.replaceState(
    null,
    null,
    pathname + sep + queryString.stringify(qs)
  );
};
