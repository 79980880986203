import gql from "graphql-tag";

export const CreateGalleryResourceMutation = gql`
  mutation CreateGalleryResource(
    $galleryId: Int!
    $input: CreateGalleryResourceInputType!
  ) {
    createGalleryResource(galleryId: $galleryId, input: $input) {
      id
    }
  }
`;

export const UpdateGalleryResourceMutation = gql`
  mutation UpdateGalleryResource(
    $id: Int!
    $input: UpdateGalleryResourceInputType!
    $galleryId: Int
  ) {
    updateGalleryResource(id: $id, input: $input, galleryId: $galleryId) {
      id
      title
      description
      meta {
        ... on ResourceLinkMeta {
          url
        }
        ... on ResourceSurveyMeta {
          url
          isSurvey
        }
        ... on ResourceVideoMeta {
          videoId
          video {
            id
            url
          }
        }

        ... on ResourceGalleryMeta {
          galleryId
        }
        ... on ResourceImageMeta {
          imageId
          image {
            id
            public_id
            width
            height
          }
        }
      }
    }
  }
`;

export const CreateGalleryMutation = gql`
  mutation CreateGallery($input: CreateImagecollectionInputType!) {
    createGallery(input: $input) {
      id
    }
  }
`;

export const UpdateGalleryMutation = gql`
  mutation UpdateGallery($id: Int!, $input: UpdateImagecollectionInputType!) {
    updateGallery(id: $id, input: $input) {
      id
    }
  }
`;

export const AddImageToGalleryMutation = gql`
  mutation AddImageToGallery($id: Int!, $imageId: Int!, $orderNumber: Int!) {
    addImageToGallery(id: $id, imageId: $imageId, orderNumber: $orderNumber) {
      id
      images {
        id
        public_id
        width
        height
      }
      galleryImages {
        image {
          id
          public_id
          width
          height
        }
        orderNumber
        imagecollectionId
      }
    }
  }
`;

export const ChangeOrderOfGalleryImagesMutation = gql`
  mutation ChangeOrderOfGalleryImages($input: GalleryImagesOrderInputType!) {
    changeOrderOfGalleryImages(input: $input) {
      id
      images {
        id
        public_id
        width
        height
      }
      galleryImages {
        image {
          id
          public_id
          width
          height
        }
        orderNumber
        imagecollectionId
      }
    }
  }
`

export const RemoveImageFromGalleryMutation = gql`
  mutation RemoveImageFromGallery($id: Int!, $imageId: Int!) {
    removeImageFromGallery(id: $id, imageId: $imageId) {
      id
      images {
        id
        public_id
        width
        height
      }
      galleryImages {
        image {
          id
          public_id
          width
          height
        }
        imagecollectionId
        orderNumber
      }
    }
  }
`;
