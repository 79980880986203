import React, { Component } from "react";
import { Position } from "./Positions";
import Box from "./Box";
import debug from "debug";
import Transition from "react-transition-group/Transition";
import "./Popover.css";

const log = debug("app:popover");

export default class Popover extends Component {
  static defaultProps = {
    position: Position.BOTTOM,
    isShown: false,
    minWidth: 200,
    minHeight: 40,
    animationDuration: 300,
    zIndex: 40,
    onOpen: () => {},
    onClose: () => {},
    onOpenComplete: () => {},
    onCloseComplete: () => {},
    bringFocusInside: true
  };

  constructor(props) {
    super(props);
    this.state = {
      isShown: props.isShown
    };
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.onBodyClick, false);
    document.body.removeEventListener("keydown", this.onEsc, false);
  }

  /**
   * Methods borrowed from BlueprintJS
   * https://github.com/palantir/blueprint/blob/release/2.0.0/packages/core/src/components/overlay/overlay.tsx
   */
  bringFocusInside = () => {
    if (!this.props.bringFocusInside) return;

    // Always delay focus manipulation to just before repaint to prevent scroll jumping
    return requestAnimationFrame(() => {
      // Container ref may be undefined between component mounting and Portal rendering
      // activeElement may be undefined in some rare cases in IE

      if (
        this.popoverNode == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null || // eslint-disable-line eqeqeq, no-eq-null
        !this.props.isShown
      ) {
        return;
      }

      const isFocusOutsideModal = !this.popoverNode.contains(
        document.activeElement
      );

      log("is focus outside modal? %s", isFocusOutsideModal);
      if (isFocusOutsideModal) {
        // Element marked autofocus has higher priority than the other clowns
        const autofocusElement = this.popoverNode.querySelector("[autofocus]");
        const wrapperElement = this.popoverNode.querySelector("[tabindex]");
        const buttonElement = this.popoverNode.querySelector("button");

        if (autofocusElement) {
          autofocusElement.focus();
        } else if (wrapperElement) {
          wrapperElement.focus();
        } else if (buttonElement) {
          buttonElement.focus();
        }
      }
    });
  };

  bringFocusBackToTarget = () => {
    return requestAnimationFrame(() => {
      if (
        this.popoverNode == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null // eslint-disable-line eqeqeq, no-eq-null
      ) {
        return;
      }

      const isFocusInsideModal = this.popoverNode.contains(
        document.activeElement
      );

      // Bring back focus on the target.
      if (
        this.targetRef &&
        (document.activeElement === document.body || isFocusInsideModal)
      ) {
        this.targetRef.focus();
      }
    });
  };

  onBodyClick = e => {
    log(
      "click body with targetRef: %o, and target: %o",
      this.targetRef,
      e.target
    );

    if (!this.targetRef) {
      return;
    }

    // Ignore clicks on the popover or button
    if (this.targetRef === e.target || this.targetRef.contains(e.target)) {
      return;
    }

    if (
      this.popoverNode &&
      (this.popoverNode === e.target || this.popoverNode.contains(e.target))
    ) {
      return;
    }

    this.close();
  };

  onEsc = e => {
    // Esc key
    if (e.keyCode === 27) {
      this.close();
    }
  };

  toggle = () => {
    log("currently shown?", this.state.isShown);

    if (this.state.isShown) {
      log("close");
      this.close();
    } else {
      log("open");
      this.open();
    }
  };

  open = () => {
    if (this.state.isShown) {
      return;
    }

    this.setState({ isShown: true });
    document.body.addEventListener("click", this.onBodyClick, false);
    document.body.addEventListener("keydown", this.onEsc, false);

    this.props.onOpen();
  };

  close = () => {
    if (!this.state.isShown) {
      return;
    }

    this.setState({ isShown: false });
    document.body.removeEventListener("click", this.onBodyClick, false);
    document.body.removeEventListener("keydown", this.onEsc, false);

    this.bringFocusBackToTarget();

    this.props.onClose();
  };

  handleOpenComplete = () => {
    this.bringFocusInside();
    this.props.onOpenComplete();
  };

  handleCloseComplete = () => {
    this.props.onCloseComplete();
  };

  renderTarget = ({ isShown }) => {
    const { children } = this.props;

    const getTargetRef = ref => {
      this.targetRef = ref;
    };

    if (typeof children === "function") {
      return children({
        toggle: this.toggle,
        getRef: getTargetRef,
        isShown
      });
    }

    return React.cloneElement(children, {
      onClick: this.toggle,
      ref: getTargetRef,
      role: "button",
      "aria-expanded": isShown,
      "aria-haspopup": true
    });
  };

  render() {
    const { content, children, ...other } = this.props;
    const { isShown } = this.state;

    return (
      <React.Fragment>
        {this.renderTarget({ isShown })}
        <Transition
          in={isShown}
          timeout={200}
          onEnter={this.handleEnter}
          onEntered={this.props.onOpenComplete}
          onExited={this.handleExited}
          unmountOnExit
        >
          {state => (
            <Box
              className="Popover"
              role="dialog"
              bg="white"
              boxShadow={3}
              data-state={state}
              position="absolute"
              overflow="hidden"
              borderRadius={[0, null, "4px"]}
              innerRef={ref => {
                this.popoverNode = ref;
              }}
              {...other}
            >
              {typeof content === "function"
                ? content({ state, isShown, close: this.close })
                : content}
            </Box>
          )}
        </Transition>
      </React.Fragment>
    );
  }
}
