import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Raven from "raven-js";

import { Helmet } from "react-helmet";

import auth from "../../Auth/auth";

import {
  Navbar,
  FooterComponent,
  FlexButtons,
  FlexButton,
  FlexButtonsBottom,
  FlexButtonBottom,
} from "../index";
import { onPhone, onSmallScreen, onSmallDesktop } from "../Elements";

import GLOBAL_CONSTANTS from "../../constants/config";

const Container = styled.div`
  max-width: 1440px;
  // padding: 64px 32px;
  margin: 0 auto;

  ${onPhone} {
    // padding: 32px 16px;
  }
  ${onSmallDesktop} {
    max-width: 1100px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

const CardsContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  background: #c4c4c41a;
`;

const GroupContainer = styled(Flex)`
  justify-content: start;
  max-width: 1200px;
  margin: 2rem auto;
  width: 100%;
  height: 46rem;
  text-align: center;
  border-radius: 5px;
  flex-direction: column;
  border: 2px solid #f9f9f9;
  background: white;
  box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 7%);
  ${onPhone} {
    margin: 20px;
    width: 80%;
    height: 100%;
  }
  ${onSmallScreen} {
    margin: 20px;
    width: 80%;
    height: 100%;
  }
`;

const ProgramCard = styled.div`
  width: 26rem;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 13%);
  padding: 2rem;
  height: 37rem;
  text-align: center;
  border-radius: 5px;
  background: #ffffff;
  ${onPhone} {
    margin: 20px;
  }
  ${onSmallScreen} {
    margin: 20px;
  }
`;

const GroupText = styled.div`
  font-size: 20px;
  font-family: "poppins-bold";
  padding: 2rem 0;
  color: #f5821e;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const Price = styled.div`
  font-family: "poppins-bold";
  font-size: 30px;
`;

const PriceDesc = styled.div`
  font-style: italic;
`;

const ProgramDesc = styled.div``;

const HorizontalLine = styled.hr`
  margin: 2rem 0;
  height: 2px;
  color: ${(props) => props.color};
  border: none;
`;

const ProgramBeneficiaries = styled.div`
  font-size: 12px;
  color: #51515194;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 200px;
  justify-content: start;
  margin: 0 auto;
  text-align: start;
`;

const CenterButton = styled.div`
  margin: 30px 0;
  background: ${(props) => props.color};
  padding: 10px;
  font-family: "poppins-bold";
  color: #575757;
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 40%);
  }
`;

const FirstSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  height: 35rem;
  ${onPhone} {
    flex-direction: column;
    height: 100%;
  }
  ${onSmallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

const FirstSectionText = styled.div`
  font-size: 20px;
  padding: 0 4rem;
    width: 100%;
  color: #000000;
  ${onPhone} {
    width: 100%;
    padding: 2rem 4rem;
  }
  ${onSmallScreen} {
    width: 100%;
    padding: 2rem 4rem;
  }
`;

const PlayBtn = styled.img`
  max-height: 8rem;
  position: relative;
  top: -19rem;
  left: 20rem;
  opacity: 0.8;
  ${onPhone} {
    top: -14rem;
    left: 10rem;
  }
`;

const ReviewButton = styled.div`
  color: white;
  background: #f5821e;
  padding: 20px 30px;
  border-radius: 40px;
  font-size: 24px;
  width: fit-content;
  margin-top: 3rem;
  cursor: pointer;
  text-align: center;
  a {
    text-decoration: none;
    color: white;
  }
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 40%);
  }
`;

const WatchNowSection = styled(Flex)`
  ${onPhone} {
    display: none;
  }
`;

const WatchNow = styled.div`
  margin-right: -30px;
  font-size: 12px;
  color: black;
  font-weight: bold;
`;

const WhyVTSImage = styled.img`
  border-radius: 10px;
  max-width: 650px;
  box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 7%);
  ${onPhone} {
    max-width: 100%;
  }
`;

const PointsMarker = styled.div`
  height: 22px;
  width: 3px;
  border-right: 3px solid #f5821e;
  margin-right: 1rem;
`;

export default function FourCComponent(props) {
  let [redirectToReferer, setRedirectToReferer] = useState(false);
  let [websiteURLs, setWebsiteURLs] = useState({
    program_page_videos: {},
    typeform_urls: {},
  });
  useEffect(() => {
    fetch(GLOBAL_CONSTANTS.WEBSITE_URLS)
      .then((res) => res.json())
      .then((result) => {
        setWebsiteURLs(result);
      });
  }, []);

  let login = (provider) => {
    return async () => {
      const subscribe = false;

      try {
        await auth.authenticate(provider, subscribe);
        setRedirectToReferer(true);
      } catch (err) {
        if (err && err.error && err.error.code === "cancelled") {
        }

        Raven.captureException(err);
        console.error("Error", err);
      }
    };
  };

  if (redirectToReferer) {
    return <Redirect to="/" />;
  }

  function programClicked() {
    window.dataLayer.push({
      event: "event",
      eventProps: {
        category: "4C page",
        action: "Inquiry button clicked",
        label: `Inquiry button clicked`,
        value: "true",
      },
    });
  }

  return (
    <div
      className="App"
      style={{
        background: "white",
        fontFamily: "poppins-regular",
        color: "#515151",
      }}
    >
      <Helmet title="VTS K12 School program | Visual Thinking Strategies curriculum and professional development">
        <meta name="title" content="VTS K12 School program" />
        <meta
          name="description"
          content="Visual Thinking Strategies curriculum and professional development"
        />
      </Helmet>
      <Navbar login={login} {...props} />
      {/* <div style={{maxWidth: "1200px", margin: "3rem auto 1rem"}}>
        <div style={{fontSize: "40px", fontFamily: "poppins-bold"}}>Teacher and School Subscriptions</div>
        <div style={{fontSize: "25px"}}>Enrich every classroom with rich discussion and responsive instruction through Visual Thinking Strategies.</div>
      </div> */}
      <div style={{ background: "rgba(159,226,214,0.3)", padding: "3rem 0" }}>
        <Container>
          <FirstSection>
            <FirstSectionText>
              <div style={{ color: "#575757", fontSize: "42px" }}>
                <b>VTS 4C</b>
                <br />
                <div style={{ fontSize: "30px" }}>
                  <i>
                    Team-building sessions for collaborative, small and medium
                    sized teams
                  </i>
                </div>
              </div>
              <div style={{ padding: "10px 0" }}>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Conversation{" "}
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Collaboration
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Community
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Culture
                </div>
              </div>
            </FirstSectionText>
            <div style={{width: "100%"}}>
              <img
                style={{ maxHeight: "100%", maxWidth: "100%", borderRadius: "10px", boxShadow: "1px 1px 10px 1px rgb(0 0 0 / 7%)" }}
                src={require("../images/4cImage.jpg")}
              />
            </div>
          </FirstSection>
        </Container>
      </div>
      <Flex style={{ maxWidth: "1200px", margin: "0 auto", padding: "4rem 0" }}>
        <div style={{ width: "75%" }}>
          <div style={{ fontSize: "18px", color: "#000" }}>
            <div>
              Would you like your team to communicate more effectively? To
              engage in more critical, civil, and overall constructive
              conversation? Would you like to encourage more collaborative
              problem-solving, open risk-taking, and honest reflection across
              your team or organization?
            </div>
            <div style={{ marginTop: "1.5rem" }}>
              VTS 4C offers companies, leaders, and teams a fun and powerful
              experience that can serve as a healthy antidote to the typical day
              at work or compliance-based training. Invest in stronger
              conversation, collaboration, community, and culture through VTS 4C
              team-building sessions for small- and medium-sized teams, groups,
              and operations.
            </div>
            <div style={{ marginTop: "1.5rem" }}>
              In just 60-90 minutes, a VTS 4C facilitator will engage teams of
              up to 20 in an open dialogue about a work (or two) of art, their
              shared work and objectives, and their team dynamics - in a manner
              that feels simultaneously challenging, fun, and valuable.
            </div>
          </div>
          <Flex>
            <ReviewButton>
              <a
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "4C page",
                      action: "Schedule a meeting clicked",
                      label: "Schedule a meeting clicked",
                      value: "true",
                    },
                  });
                  window.Calendly.initPopupWidget({
                    url: websiteURLs.calendly_url,
                  });
                  return false;
                }}
                style={{ textDecoration: "none" }}
              >
                Schedule a meeting
              </a>
            </ReviewButton>
          </Flex>
        </div>
      </Flex>

      <Flex style={{ background: "#FFF8F4" }}>
        <Flex style={{ maxWidth: "1200px", margin: "3rem 1rem" }}>
          <div style={{ padding: "3rem 0", fontSize: "16px" }}>
            <div style={{ fontSize: "18px" }}>Possible session themes</div>
            <ul>
              <li style={{ marginBottom: "1rem" }}>
                How can discussing art - together - unlock new ways of
                collaborating and strengthen equity, efficiency, and innovation
                across a team or company?
              </li>
              <li style={{ marginBottom: "1rem" }}>
                What does diversity and inclusion look like on a personal and
                operational level?
              </li>
              <li style={{ marginBottom: "1rem" }}>
                How can improving listening and speaking skills foster a more
                collaborative environment?
              </li>
            </ul>
          </div>
          <div style={{ paddingLeft: "2rem" }}>
            <WhyVTSImage src={require("../images/4cImage2.jpg")} />
          </div>
        </Flex>
      </Flex>
      <Flex style={{ margin: "3rem 0" }}>
        <ProgramCard>
          <div style={{ height: "75%" }}>
            <Header>VTS 4C Sessions</Header>
            <div style={{ margin: "30px 0" }}>
              <Price>$1,500</Price>
            </div>
            {/* <ProgramDesc>Best for individual beginners as well as veterans of VTS</ProgramDesc> */}
            <ProgramBeneficiaries>
              <div>Includes:</div>
              <div>
                - One 60-90 minute live session for up to 20 participants on
                Zoom
              </div>
              <div>
                - One planning meeting with team leaders and a VTS 4C
                facilitator
              </div>
              <div>
                - Invitation for participants to follow VTS 4C Weekly Image feed
              </div>
            </ProgramBeneficiaries>
          </div>
          <div>
            <a
              style={{ color: "#575757", textDecoration: "none" }}
              onClick={() => programClicked()}
              href={`mailto:hello@watershed-ed.org?bcc=bcc@nutshell.com&subject=I'm interested in VTS 4C`}
              target={"_blank"}
            >
              <CenterButton color="#9FE2D6">Submit an inquiry</CenterButton>
            </a>
          </div>
        </ProgramCard>
      </Flex>
      <FooterComponent {...props} />
    </div>
  );
}
