import React, { PureComponent } from "react";
import Transition from "react-transition-group/Transition";
import Portal from "../Portal";
import getPosition from "./getPosition";
import Position from "./Position";
import "./Positions.css";

export { default as Position } from "./Position";

const initialState = () => ({
  top: null,
  left: null,
  transformOrigin: null
});

export default class Positioner extends PureComponent {
  static defaultProps = {
    position: Position.BOTTOM,
    zIndex: 40,
    bodyOffset: 6,
    targetOffset: 6,
    initialScale: 0.9,
    inline: false,
    animationDuration: 200,
    innerRef: () => {},
    onOpenComplete: () => {},
    onCloseComplete: () => {}
  };

  constructor(props, context) {
    super(props, context);
    this.state = initialState();
  }

  getTargetRef = ref => {
    this.targetRef = ref;
  };

  getRef = ref => {
    this.positionerRef = ref;
    this.props.innerRef(ref);
  };

  handleEnter = () => {
    this.update();
  };

  getTargetRect = () => this.targetRef.getBoundingClientRect();

  update = () => {
    if (!this.props.isShown || !this.targetRef || !this.positionerRef) return;

    if (this.props.inline) {
      // const position = getPositionInline(this.props.position)
      return this.setState({
        left: null,
        right: 0,
        top: "100%",
        transformOrigin: "middle"
      });
    }

    const targetRect = this.getTargetRect();
    const viewportHeight =
      document.documentElement.clientHeight + window.scrollY;
    const viewportWidth = document.documentElement.clientWidth + window.scrollX;

    const position = getPosition({
      position: this.props.position,
      targetRect,
      targetOffset: this.props.targetOffset,
      dimensions: {
        height: this.positionerRef.offsetHeight,
        width: this.positionerRef.offsetWidth
      },
      viewport: {
        width: viewportWidth,
        height: viewportHeight
      },
      viewportOffset: this.props.bodyOffset
    });

    this.setState(
      {
        left: position.left,
        top: position.top,
        transformOrigin: position.transformOrigin
      },
      () => {
        if (!this.props.inline) {
          window.requestAnimationFrame(() => {
            this.update();
          });
        }
      }
    );
  };

  handleExited = () => {
    this.setState(
      () => {
        return {
          ...initialState()
        };
      },
      () => {
        this.props.onCloseComplete();
      }
    );
  };

  render() {
    const {
      inline,
      zIndex,
      target,
      isShown,
      children,
      animationDuration
    } = this.props;

    const { left, top, right, transformOrigin } = this.state;

    const Component = inline ? React.Fragment : Portal;

    return (
      <React.Fragment>
        {target({ getRef: this.getTargetRef, isShown })}
        <Component>
          <Transition
            in={isShown}
            timeout={animationDuration}
            onEnter={this.handleEnter}
            onEntered={this.props.onOpenComplete}
            onExited={this.handleExited}
            unmountOnExit
          >
            {state =>
              children({
                top,
                left,
                state,
                zIndex,
                className: "Positions",
                style: {
                  transformOrigin,
                  left,
                  top,

                  right
                },
                getRef: this.getRef,
                animationDuration
              })
            }
          </Transition>
        </Component>
      </React.Fragment>
    );
  }
}
