import Text from "./Text";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components/macro";

export const UppercaseLabel = styled(Text)`
  text-transform: uppercase;
  font-size: ${themeGet("fontSizes.0")}px;
  letter-spacing: 0.01rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-weight: 500;
  color: ${themeGet("colors.faded")};
`;
