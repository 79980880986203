import React from "react"; // eslint-disable-line
import {
  flex,
  width,
  space,
  display,
  flexWrap,
  flexDirection,
  justifyContent,
  alignItems,
  textAlign,
  alignSelf,
  borderRadius,
  boxShadow,
  color,
  maxHeight,
  minHeight,
  borderColor,
  minWidth,
  position,
  overflow,
  top,
  right,
  height,
  maxWidth,
  bottom,
  left,
  borders,
  background,
  MaxWidthProps,
  FlexProps,
  DisplayProps,
  MinWidthProps,
  WidthProps,
  MaxHeightProps,
  MinHeightProps,
  HeightProps,
  SizeProps,
  AlignItemsProps,
  AlignContentProps,
  FlexWrapProps,
  FlexDirectionProps,
  JustifyContentProps,
  SpaceProps
} from "styled-system";
import styled from "styled-components/macro";

import { style } from "styled-system";

interface BoxProps
  extends MaxWidthProps,
    FlexProps,
    DisplayProps,
    SpaceProps,
    JustifyContentProps,
    WidthProps,
    HeightProps,
    AlignItemsProps {}

export const Box = styled("div")<BoxProps>`
  display: block;
  ${maxHeight};
  ${minHeight};
  ${minWidth};
  ${flex};
  ${width};
  ${height};
  ${space};
  ${color};
  ${textAlign};
  ${maxWidth};
  ${alignSelf};
  ${borderRadius};
  ${borders};
  ${borderColor};
  ${boxShadow};
  ${background};
  ${position};
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${display};
  ${flexWrap};
  ${flexDirection};
  ${justifyContent};
  ${alignItems};
  ${overflow};
`;

export default Box;
