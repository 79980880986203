import * as React from "react";
import CourseResources from "../CourseAdmin/CourseResources";

// type Props = {
//   onRequestClose: () => void,
//   onSelectAttachment: () => void,
//   courseId: number
// };

class SelectAttachment extends React.Component {
  render() {
    return (
      <CourseResources
        courseId={this.props.courseId}
        showTitle={false}
        onSelect={this.props.onSelectAttachment}
        enableNew={false}
      />
    );
  }
}

export default SelectAttachment;
