import React from "react";
import styled, { css } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import Spinner from "./Spinner";
import Box from "./Box";

const getBoxShadow = props => {
  return props.theme.elevations[props.elevation];
};

const Layer = styled(Box)`
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
  border-radius: ${themeGet("radii.1")}px;
  box-shadow: ${getBoxShadow};
  position: relative;
  background-color: white;
  display: ${props => props.display || "flex"};
`;

Layer.defaultProps = {
  elevation: 0,
  type: "default"
};

export default Layer;

const loadingStyles = props => {
  if (props.loading) {
    return css`
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      transition: none;
    `;
  } else {
    return css`
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: opacity 0.3s linear, visibility 0.3s linear;
    `;
  }
};

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: ${themeGet("radii.1")}px;
  display: flex;
  justify-content: center;

  align-items: center;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.8);
  ${loadingStyles};
`;

export const LayerLoading = ({ loading, style }) => (
  <LoadingContainer loading={loading} style={style}>
    <Spinner delay={0} />
  </LoadingContainer>
);

LayerLoading.defaultProps = {
  loading: true
};
