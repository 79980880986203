import React from "react"; // eslint-disable-line
import {
  color,
  fontSize,
  display,
  lineHeight,
  maxWidth,
  fontWeight,
  space,
  textAlign,
  flex
} from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import styled, { css } from "styled-components/macro";
import { Link as RouterLink } from "react-router-dom";

const sharedStyles = css`
  font-size: ${props =>
    props.lead ? props.theme.fontSizes[4] + "px" : "1rem"};
  line-height: ${props => (props.compressed ? 1 : "1.5")};
  font-family: ${themeGet("bodyFont")};
  font-weight: ${props => (props.lead ? 300 : null)};
  color: ${props =>
    props.faded ? props.theme.colors.faded : props.theme.colors.black};
  ${color};
  ${flex};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${space};
  ${textAlign};
  ${display};
  ${maxWidth};
  ${props => {
    if (props.ellipsis) {
      return css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      `;
    }
  }};
`;

export const Paragraph = styled.p`
  ${sharedStyles};
  margin-bottom: 1.5rem;
  line-height: 1.5;
  font-size: ${props => props.theme.paragraphFontSize};
`;

export const Text = styled.span`
  ${sharedStyles};
`;

export default Text;

export const EmbedLink = styled.a`
  color: ${themeGet("colors.blue.20")};
  text-decoration: none;
  :hover {
    color: ${themeGet("colors.blue.17")};
    text-decoration: underline;
  }
  :active {
    color: ${themeGet("colors.blue.23")};
    text-decoration: none;
  }
`;

export const LinkStyled = styled(Text)`
  text-decoration: none;
  ${props => {
    const { color } = props;
    const { colors } = props.theme;

    const c = color || "blue";

    if (props.type === "primary") {
      return css`
        color: ${colors[c][20]};
        :hover {
          color: ${colors[c][17]};
          text-decoration: underline;
        }
        :active {
          color: ${colors[c][23]};
          text-decoration: none;
        }
      `;
    }
  }} ${props => {
    if (props.hidden) {
      return css`
        display: block;
        text-decoration: none;
        color: inherit;
        :hover {
          text-decoration: none;
        }
      `;
    }
  }};
`;

export const Link = props => <LinkStyled as="a" {...props} />;

Link.defaultProps = {
  type: "primary"
};

export const NavLink = props => <LinkStyled as={RouterLink} {...props} />;
