import React, { Component } from "react";
import { themeGet } from "@styled-system/theme-get";
import { Link } from "react-router-dom";
import Text from "./Text";
import styled from "styled-components/macro";

export class Button extends Component {
  static defaultProps = {
    appearance: "default",
    disabled: false,
    size: "medium"
  };

  state = {
    active: false,
    focus: false,
    hover: false
  };

  getComponent = appearance => {
    switch (appearance) {
      case "default":
        return DefaultButton;
      case "primary":
        return PrimaryButton;
      case "resource":
        return ResourcesButton;
      case "success":
        return SuccessButton;
      case "danger":
        return DangerButton;
      case "ghost":
        return GhostButton;
      case "link":
        return LinkButton;
      default:
        return DefaultButton;
    }
  };

  render() {
    const {
      withIconAfter,
      withIconBefore,
      children,
      block,
      style = {},
      ...other
    } = this.props;
    const Component = this.getComponent(this.props.appearance);

    return (
      <Component
        as={this.props.as || (this.props.to ? Link : "button")}
        role={other.to ? "button" : null}
        style={{
          width: block ? "100%" : null,
          ...style
        }}
        {...other}
      >
        {withIconBefore &&
          React.cloneElement(withIconBefore, {
            style: {
              ...(withIconBefore.style || {}),
              position: "absolute",
              left: "16px",
              top: "50%",
              transform: "translateY(-50%)"
            }
          })}
        {children}
      </Component>
    );
  }
}

const getFontSize = props => {
  if (props.size === "tiny") return props.theme.fontSizes[0];
  if (props.size === "large") return props.theme.fontSizes[2];
  if (props.size === "small") return props.theme.fontSizes[1];
  if (props.size === "giant") return props.theme.fontSizes[3];
  return props.theme.fontSizes[1];
};

const getPadding = props => {
  if (props.size === "tiny") return "0.1rem 0.5rem";
  if (props.size === "large") return "0.6rem 2rem";
  if (props.size === "small") return "0.25rem 1rem";
  if (props.size === "giant") return "0.8rem 2.2rem";
  return "0.5rem 1.7rem";
};

const getDisplay = props => (props.block ? "block" : "inline-block");

const ButtonBase = styled(Text)`
  box-sizing: border-box;
  text-decoration: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: ${props => props.block && "100%"};
  cursor: pointer;
  font-weight: ${props => (props.size === "giant" ? "bold" : "500")};
  display: ${getDisplay};
  padding: ${getPadding};
  font-size: ${getFontSize}px;
  position: relative;
  transition: box-shadow 0.15s ease-in-out;
  line-height: 1.5;
  border-radius: ${themeGet("radii.0")}px;
  opacity: ${props => (props.disabled ? 0.35 : 1)};
`;

const gradient = (start, end) => {
  const startColor = themeGet(start);
  const endColor = themeGet(end);

  return props => {
    return `linear-gradient(to top, ${startColor(props)}, ${endColor(props)});`;
  };
};

const insetShadow = (color, color2) => {
  const fn = themeGet(color);
  const fn2 = themeGet(color2);
  return props =>
    `inset 0 0 0 1px ${fn(props)}, inset 0 -1px 1px 0 ${fn2(props)}`;
};

export const focusShadow = (color, color2, color3) => {
  const fn = themeGet(color);
  const fn2 = themeGet(color2);
  const fn3 = themeGet(color3);
  return props =>
    `0 0 0 3px ${fn(props)}, inset 0 0 0 1px ${fn2(
      props
    )}, inset 0 -1px 1px 0 ${fn3(props)}`;
};

export const DefaultButton = styled(ButtonBase)`
  background-color: white;
  position: relative;
  background-image: ${gradient("colors.grayAlpha.1", "colors.whiteAlpha.1")};
  box-shadow: ${insetShadow("colors.grayAlpha.5", "colors.grayAlpha.3")};
  :hover {
    background-image: ${gradient("colors.grayAlpha.2", "colors.grayAlpha.0")};
    box-shadow: ${insetShadow("colors.grayAlpha.5", "colors.grayAlpha.4")};
  }
  :focus {
    z-index: 2;
    box-shadow: ${focusShadow(
      "colors.blueAlpha.5",
      "colors.grayAlpha.7",
      "colors.grayAlpha.3"
    )};
  }
  :active {
    color: ${themeGet("colors.blue.13")};
    background-image: none;
    background-color: ${themeGet("colors.blue.3")};
    box-shadow: ${insetShadow("colors.grayAlpha.5", "colors.grayAlpha.3")};
  }
`;

export const PrimaryButton = styled(ButtonBase)`
  background-color: ${themeGet("colors.blue.20")};
  color: white;
  background-image: ${gradient("colors.blue.21", "colors.blue.19")};
  box-shadow: ${insetShadow("colors.grayAlpha.6", "colors.grayAlpha.6")};
  :hover {
    background-image: ${gradient("colors.blue.22", "colors.blue.20")};
  }
  :focus {
    z-index: 2;
    box-shadow: ${focusShadow(
      "colors.blueAlpha.8",
      "colors.grayAlpha.6",
      "colors.grayAlpha.6"
    )};
  }
  :active {
    box-shadow: ${insetShadow("colors.alphaGray.6", "colors.alphaGray.6")};
    background-image: ${gradient("colors.blue.23", "colors.blue.24")};
  }
`;

export const ResourcesButton = styled(ButtonBase)`
  background-color: ${themeGet("colors.pink.20")};
  color: white;
  background-image: ${gradient("colors.pink.21", "colors.pink.19")};
  box-shadow: ${insetShadow("colors.grayAlpha.6", "colors.grayAlpha.6")};
  :hover {
    background-image: ${gradient("colors.pink.22", "colors.pink.20")};
  }
  :focus {
    z-index: 2;
    box-shadow: ${focusShadow(
      "colors.pinkAlpha.8",
      "colors.grayAlpha.6",
      "colors.grayAlpha.6"
    )};
  }
  :active {
    box-shadow: ${insetShadow("colors.alphaGray.6", "colors.alphaGray.6")};
    background-image: ${gradient("colors.pink.23", "colors.pink.24")};
  }
`;

const DangerButton = styled(ButtonBase)`
  background-color: ${themeGet("colors.red.20")};
  color: white;
  background-image: ${gradient("colors.red.21", "colors.red.20")};
  box-shadow: ${insetShadow("colors.grayAlpha.6", "colors.grayAlpha.6")};
  :hover {
    background-image: ${gradient("colors.red.22", "colors.red.21")};
  }
  :focus {
    z-index: 2;
    box-shadow: ${focusShadow(
      "colors.redAlpha.13",
      "colors.grayAlpha.6",
      "colors.grayAlpha.6"
    )};
  }
  :active {
    box-shadow: ${insetShadow("colors.alphaGray.6", "colors.alphaGray.6")};
    background-image: ${gradient("colors.red.23", "colors.red.24")};
  }
`;

const SuccessButton = styled(ButtonBase)`
  background-color: ${themeGet("colors.green.20")};
  color: white;
  background-image: ${gradient("colors.green.21", "colors.green.20")};
  box-shadow: ${insetShadow("colors.grayAlpha.6", "colors.grayAlpha.6")};
  :hover {
    background-image: ${gradient("colors.green.22", "colors.green.21")};
  }
  :focus {
    z-index: 2;
    box-shadow: ${focusShadow(
      "colors.redAlpha.13",
      "colors.grayAlpha.6",
      "colors.grayAlpha.6"
    )};
  }
  :active {
    box-shadow: ${insetShadow("colors.alphaGray.6", "colors.alphaGray.6")};
    background-image: ${gradient("colors.green.23", "colors.green.24")};
  }
`;

const GhostButton = styled(ButtonBase)`
  background-color: transparent;
  :hover {
    background-color: ${themeGet("colors.grayAlpha.2")};
  }
  :focus {
    zindex: 2;
    box-shadow: ${props => `0 0 0 3px ${props.theme.colors.blueAlpha[8]}`};
  }
  :active {
    color: ${themeGet("colors.blue.20")};
    box-shadow: none;
    background-color: ${themeGet("colors.blueAlpha.4")};
  }
`;

const LinkButton = styled(ButtonBase)`
  background: none;
  border: none;
  padding: 0;
  color: ${themeGet("colors.blue.20")};
  :hover {
    color: ${themeGet("colors.blue.17")};
    text-decoration: underline;
  }
  :active {
    color: ${themeGet("colors.blue.23")};
    text-decoration: none;
  }
`;

export default Button;
