import { ResourceFragment } from "./ResourceFragment";
import gql from "graphql-tag";

export const StepQuestionBasicsFragment = gql`
  fragment StepQuestionBasics on StepQuestion {
    id
    beginsAt
    content
    type
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
  }
  ${ResourceFragment}
`;
