import React from "react";
import Annotation from "./Annotation";
import { AvatarContainer } from "../Kit/Avatar";
import QuestionIcon from "@atlaskit/icon/glyph/question";
import EditorEditIcon from "@atlaskit/icon/glyph/editor/edit";
import EmojiObjectsIcon from "@atlaskit/icon/glyph/emoji/objects";
import EditorNoteIcon from '@atlaskit/icon/glyph/editor/note';
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list';

const getName = step => {
  if (step.type === "practice") return "Practice";
  if (step.type === "reflect") return "Reflect";
  if (step.type === "plan") return "Plan";
  if (step.type === "note") return "Note";
  return "Question";
};

const getIcon = step => {
  if (step.type === "practice") return EditorEditIcon;
  if (step.type === "reflect") return EmojiObjectsIcon;
  if (step.type === "plan") return BulletListIcon;
  if (step.type === "note") return EditorNoteIcon;
  return QuestionIcon;
};

class Question extends React.Component {
  static defaultProps = {
    coach: {
      id: "sdafdsaf",
      name: "Question"
    },
    showTimestamp: true
  };

  render() {
    const {
      question,
      viewer,
      section,
      mode,
      groupId,
      showCommentId,
      enrollmentId,
      episodeId,
      showTimestamp,
      step
    } = this.props;

    const Icon = getIcon(question);

    return (
      <Annotation
        courseId={this.props.courseId}
        showDate={false}
        enableLike={false}
        icon={
          <AvatarContainer
            style={{
              backgroundColor: "#eff2f5"
            }}
            size={34}
          >
            <div
              style={{
                color: "black",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translateX(-50%) translateY(-50%)"
              }}
            >
              <Icon size="medium" />
            </div>
          </AvatarContainer>
        }
        theme="gray.3"
        commentable="stepquestion"
        enrollmentId={enrollmentId}
        groupId={groupId}
        role={this.props.role}
        showCommentId={showCommentId}
        annotation={question}
        step={step}
        section={section}
        mode={mode}
        showTimestamp={showTimestamp}
        episodeId={episodeId}
        viewer={viewer}
        postedBy={{
          id: "bleh",
          name: getName(question)
        }}
      />
    );
  }
}

export default Question;
