import * as React from "react";
import { Mutation, withApollo } from "react-apollo";
import Input from "../Input";
import Raven from "raven-js";
import Editor from "../Kit/RichText";
import debug from "debug";
import { updateUnreadStatus } from "./ChatSubscriptionHandler";
import { focusEmitter } from "./focusEmitter";
import updateReadStatus from "./update-read-status";
import { CreateConversationReply } from "../API/mutations/CreateConversationReply";
import { ConversationQuery } from "../API/queries/ConversationQuery";

const log = debug("app:ChatInputBox");

class ChatInputBox extends React.Component {
  state = {
    state: Editor.createEmpty()
  };

  componentDidMount() {
    focusEmitter.on(this.props.uid, this.focus);
  }

  componentWillUnmount() {
    focusEmitter.removeListener(this.props.uid, this.focus);
  }

  focus = () => {
    if (this.input) {
      this.input.triggerFocus();
    }
  };

  render() {
    const { queryVariables, data } = this.props;
    return (
      <Mutation mutation={CreateConversationReply}>
        {sendReply => (
          <Input
            file={null}
            enableExpand={false}
            rounded={false}
            enableSubmit
            autoClear
            ref={input => (this.input = input)}
            autoFocus
            placeholder="Type a message..."
            state={this.state.state}
            onStartTyping={this.startTyping}
            onEndTyping={this.endTyping}
            networkDisabled={false}
            onChange={this.onStateChange}
            onSubmit={async (val, attachments) => {
              try {
                await sendReply({
                  update: (store, { data: { createConversationReply } }) => {
                    try {
                      updateUnreadStatus(store, createConversationReply, true);
                    } catch (err) {
                      log("failed to update unread status");
                      console.error(err);
                    }

                    const data = store.readQuery({
                      query: ConversationQuery,
                      variables: queryVariables
                    });

                    data.conversation.replies.edges.push({
                      __typename: "conversationRepliesConnection",
                      node: createConversationReply
                    });

                    store.writeQuery({
                      data,
                      query: ConversationQuery,
                      variables: queryVariables
                    });
                  },
                  variables: {
                    input: {
                      userId: data.viewer.id,
                      content: val,
                      conversationId: data.conversation.id,
                      imageId: attachments.image ? attachments.image.id : null
                    }
                  },
                  optimisticResponse: {
                    __typename: "Mutation",
                    createConversationReply: {
                      __typename: "ConversationReply",
                      id: Math.random(),
                      content: val,
                      conversationId: data.conversation.id,
                      userId: data.viewer.id,
                      resourceId: null,
                      imageId: null,
                      createdAt: new Date(),
                      image: attachments.image
                        ? {
                            id: attachments.image.id,
                            __typename: "Image",
                            url: attachments.image.url,
                            original_filename:
                              attachments.image.original_filename
                          }
                        : null,
                      isOptimistic: true
                    }
                  }
                });
              } catch (err) {
                log("error sending reply");
                console.error(err);
                Raven.captureException(err);
              }
            }}
          />
        )}
      </Mutation>
    );
  }

  onStateChange = state => {
    this.setState({ state });
  };

  startTyping = () => {
    log("start typing");
    updateReadStatus(this.props.client, this.props.data.conversation.id, true);
  };

  endTyping = () => {
    log("end typing");
    updateReadStatus(this.props.client, this.props.data.conversation.id, false);
  };
}

export default withApollo(ChatInputBox);
