import gql from "graphql-tag";
import { EpisodeAdminFragment } from "../fragments/EpisodeAdminFragment";

export const CreateEpisodeMutation = gql`
  mutation CreateEpisode(
    $courseId: Int!
    $orderNumber: Int!
    $input: CreateEpisodeInputType!
  ) {
    createEpisode(
      courseId: $courseId
      orderNumber: $orderNumber
      input: $input
    ) {
      id
      ...EpisodeAdmin
    }
  }
  ${EpisodeAdminFragment}
`;

export const UpdateEpisodeMutation = gql`
  mutation UpdateEpisode($id: Int!, $input: UpdateEpisodeInputType!) {
    updateEpisode(id: $id, input: $input) {
      id
      ...EpisodeAdmin
    }
  }
  ${EpisodeAdminFragment}
`;

export const UpdateEpisodeOrderMutation = gql`
  mutation UpdateEpisodeOrder(
    $id: Int!
    $courseId: Int!
    $previous: Int!
    $next: Int!
  ) {
    updateOrderOfEpisode(
      id: $id
      courseId: $courseId
      previous: $previous
      next: $next
    ) {
      id
      episodes {
        ...EpisodeAdmin
      }
    }
  }
  ${EpisodeAdminFragment}
`;

export const DeleteEpisodeMutation = gql`
  mutation DeleteEpisode($id: Int!, $courseId: Int!) {
    deleteEpisode(id: $id, courseId: $courseId) {
      id
      episodes {
        ...EpisodeAdmin
      }
    }
  }
  ${EpisodeAdminFragment}
`;

export const RemoveLinkFromEpisodeMutation = gql`
  mutation RemoveLinkFromEpisode($id: Int!, $episodeId: Int!) {
    removeLink(id: $id, episodeId: $episodeId) {
      id
    }
  }
`;

export const RemoveVideoFromEpisodeMutation = gql`
  mutation RemoveVideoFromEpisode($id: Int!, $episodeId: Int!) {
    removeVideoFromEpisode(id: $id, episodeId: $episodeId) {
      id
    }
  }
`;
