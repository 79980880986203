import React from "react";
import Text from "./Text";
import Box from "./Box";

const Switch = ({
  helpText,
  label,
  id,
  checked,
  type = "checkbox",
  disabled,
  onChange,
  ...other
}) => (
  <div>
    <label htmlFor={id}>
      <input
        disabled={disabled}
        id={id}
        type={type}
        checked={checked}
        onChange={onChange}
      />

      <Text ml={1}>{label}</Text>
    </label>
    {helpText && (
      <Box my={1}>
        <Text lineHeight="1.5" fontSize={1} faded>
          {helpText}
        </Text>
      </Box>
    )}
  </div>
);

export default Switch;
