import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import boot from "./Boot";
import client from "./API/apollo-client";
import { ErrorBoundary } from "./Kit/ErrorCapture";

import Raven from "raven-js";

const RAVEN_DSN = "https://1c4c79a729254a1889b81c78209f2c6b@sentry.io/245996";

Raven.config(RAVEN_DSN, {
  release: "2.0.0",
  shouldSendCallback: () => {
    return process.env.NODE_ENV === "production";
  }
}).install();

const Boot = boot(client);

const container = document.getElementById("root")

const root = ReactDOM.createRoot(container)

Raven.context(function() {
  root.render(
    <Boot>
      {auth => (
        <ErrorBoundary>
          <App auth={auth} />
        </ErrorBoundary>
      )}
    </Boot>
  );
});

// registerServiceWorker();
