import * as React from "react";
import Raven from "raven-js";
import ChatManager from "./ChatManager";

class Chat extends React.Component {
  state = {
    error: false
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) {
      return null;
    }

    return <ChatManager />;
  }
}

export default Chat;
