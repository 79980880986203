import debug from "debug";
import Raven from "raven-js";
import queryString from "query-string";

const log = debug("app:auth");

/**
 * Send a google token to our server to
 * authorize
 */

const authorizeGoogle = async (token) => {
  const res = await fetch(`/auth/google/token?access_token=${token}` , {
    method: "post",
    credentials: "include",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const data = await res.json();

  log("Google server response: %o", data);
  return data;
};

/**
 * Use the facebook sdk to login
 */

const loginWithFacebook = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(response => {
      if (response.authResponse) {
        return resolve(response.authResponse.accessToken);
      } else {
        return reject();
      }
    });
  });
};

/**
 * Send a facebook token to our server to
 * authenticate our user
 */

const authorizeFacebook = async subscribe => {
  const accessToken = await loginWithFacebook();
  const query = queryString.stringify(
    subscribe
      ? {
          subscribe: true
        }
      : {}
  );
  const res = await window.fetch("/auth/facebook/token?" + query, {
    method: "post",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-Requested-With": "XMLHttpRequest"
    }
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const data = await res.json();

  return data;
};

const userLogin = async (email) => {
  const res = await fetch(`/auth/${email}` , {
    method: "post",
    credentials: "include",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const data = await res.json();

  log("Server response: %o", data);
  return data;
};

class Auth {
  constructor() {
    this.fns = [];
    const user = this.currentUser;
    // if we already have a user make sure
    // we record that with raven
    if (user) {
      log("set raven user context: %s", user.name);
      Raven.setUserContext({
        name: user.name,
        id: user.id,
        email: user.email
      });
    }
  }

  onChange = fn => {
    this.fns.push(fn);
  };

  emitChange = () => {
    this.fns.forEach(fn => {
      fn();
    });
  };

  unsubscribe = fn => {
    const i = this.fns.indexOf(fn);
    if (i >= 0) {
      this.fns.splice(i, 1);
    }
  };

  async authenticate(provider = "google", userName) {
    log("start %s authentication", provider);

    let user;
    if (provider === 'google') {
      user = await authorizeGoogle(userName);
    } else {
      user = await userLogin(userName);
    }

    // save our user context for debugging
    log("set raven user context: %s", user.name);
    Raven.setUserContext({
      name: user.name,
      id: user.id,
      email: user.email
    });

    this.saveUser(user);
    this.emitChange();

    log("fetched user: %o", user);

    return user;
  }

  async signout(fn) {
    log("sign out");

    const user = this.currentUser;

    // remove user from localstorage
    localStorage.removeItem("current_user");

    try {
      // we should probably track which service
      // we are actually logged in with, but for now
      // it's easier to just log out of both.
      if (user.facebookId) {
        window.FB.logout();
      }

      if (user.googleId) {
        // TODO: implement signout
      }
    } catch (err) {
      console.error(err);
    }

    if (fn) {
      fn();
    }

    log("calling logout");

    try {
      const response = await fetch("/auth/logout", {
        method: "post",
        credentials: "include",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      });

      log("logout response: %o", response);

      window.location.href = "/";
    } catch (err) {
      console.error(err);
    }
  }

  async deactivateAccount(fn) {
    try {
      const response = await fetch("/auth/deactivate", {
        method: "post",
        credentials: "include",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      });

      log("logout response: %o", response);
      this.signout()
    } catch (err) {
      console.error(err);
    }
  }

  saveUser(user) {
    localStorage.setItem("current_user", JSON.stringify(user));
  }

  get currentUser() {
    const user = localStorage.getItem("current_user");
    if (user) {
      try {
        return JSON.parse(user);
      } catch (err) {
        console.error(err);
        return null;
      }
    }

    return null;
  }

  get isAuthenticated() {
    return !!this.currentUser;
  }
}

// export a singleton b/c laziness
export default new Auth();
