import gql from "graphql-tag";

export const VideoQuery = gql`
  query Video($id: Int!) {
    video(id: $id) {
      id
      uid
      url
      id
      duration
    }
  }
`;
