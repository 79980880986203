import React from "react";
import Spinner from "./Spinner";
import PropTypes from "prop-types";
import Flex from "./Flex";
import { ErrorHandler } from "./ErrorCapture";

export const Loading = ({ text, delay = 350 }) => (
  <Flex
    width={"100%"}
    textAlign="center"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <Spinner
      delay={delay}
      m={4}
      mb={2}
      width={"100%"}
      alignItems="center"
      alignSelf="center"
    />
    {text}
  </Flex>
);

export class WithLoading extends React.Component {
  render() {
    const { loading, error } = this.props.data;

    if (loading) return <Loading />;

    if (error) {
      return (
        <ErrorHandler
          error={error}
          message="A loading error has occurred. Try reloading."
        />
      );
    }

    return this.props.component
      ? React.cloneElement(this.props.component, this.props)
      : this.props.render(this.props);
  }
}

WithLoading.propTypes = {
  data: PropTypes.object.isRequired,
  component: PropTypes.element,
  render: PropTypes.func
};

/**
 *
 * As a higher order component
 */

export const withLoading = (Component, loader) => {
  class WithLoadingHOC extends React.Component {
    render() {
      const { loading, error } = this.props.data;

      if (loading) return loader || <Loading />;

      if (error) {
        return (
          <ErrorHandler
            error={error}
            message="A loading error has occurred. For now, try reloading."
          />
        );
      }

      return <Component {...this.props} />;
    }
  }

  WithLoadingHOC.propTypes = {
    ...withLoading.propTypes
  };

  return WithLoadingHOC;
};

export const queryHandler = fn => ({ data, loading, error }) => {
  if (loading) return <Loading />;
  if (error)
    return (
      <ErrorHandler
        error={error}
        message="A loading error has occurred. For now, try reloading."
      />
    );
  return fn(data);
};
