import gql from "graphql-tag";
import { NotificationObjectFragment } from "./NotificationObjectFragment";

export const NotificationFragment = gql`
  fragment Notification on Notification {
    id
    createdAt
    notificationObject {
      id
      ...NotificationObject
    }
  }
  ${NotificationObjectFragment}
`;
