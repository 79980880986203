// allows a user to either select or upload an image, and used for things like
// course creation, and such.
import React from "react";
import ImageBrowser from "./ImageBrowser";
import {
  Box,
  ImageUpload,
  TabList,
  TabButton,
  Layer,
  Embed,
  Button,
  TabContent
} from "../Kit";
import PropTypes from "prop-types";
import LoadImage from "./LoadImage";
import debug from "debug";
import { SIZES } from "../Kit/Image";
const log = debug("app:Admin:LoadImage");

class SelectImage extends React.Component {
  static propTypes = {
    imageId: PropTypes.number,
    prompt: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    showSelect: PropTypes.bool
  };

  static defaultProps = {
    showSelect: false,
    dimensions: SIZES.landscape,
    layer: true,
    ...SIZES.landscape
  };

  state = {
    upload: true
  };

  select = upload => {
    return e => {
      e.preventDefault();
      this.setState({ upload });
    };
  };

  removeImage = () => {
    this.props.onChange(null);
  };

  render() {
    const { dimensions, width, height, layer } = this.props;

    const Wrapper = layer ? Layer : Box;
    const wrapperProps = layer
      ? {
          elevation: 1,
          mb: 2
        }
      : { mb: 2 };

    return (
      <Wrapper {...wrapperProps}>
        <Box width={1}>
          {this.props.imageId ? (
            <div>
              <Embed dimensions={dimensions}>
                <LoadImage
                  layer={layer}
                  responsive
                  width={width}
                  height={height}
                  imageId={this.props.imageId}
                />
              </Embed>
              <Button m={2} onClick={this.removeImage}>
                Remove
              </Button>
            </div>
          ) : (
            <Box p={0} pt={1} width={1}>
              <Box px={3} borderBottom="1px solid" borderColor="borderColor">
                <TabList bordered>
                  <TabButton
                    id="upload"
                    onClick={this.select(true)}
                    active={this.state.upload}
                  >
                    Upload
                  </TabButton>
                  {this.props.showSelect && (
                    <TabButton
                      id="select"
                      onClick={this.select(false)}
                      active={!this.state.upload}
                    >
                      Select
                    </TabButton>
                  )}
                </TabList>
              </Box>
              {this.state.upload ? (
                <TabContent>
                  <Box>
                    <ImageUpload
                      prompt={this.props.prompt}
                      accept={this.props.accept}
                      publisherId={this.props.publisherId}
                      afterUpload={this.afterUpload}
                    />
                  </Box>
                </TabContent>
              ) : (
                <TabContent>
                  <Box>
                    <ImageBrowser
                      publisherId={this.props.publisherId}
                      onSelect={this.selectFromBrowser}
                    />
                  </Box>
                </TabContent>
              )}
            </Box>
          )}
        </Box>
      </Wrapper>
    );
  }

  selectFromBrowser = image => {
    log("select image: %o", image);
    this.props.onChange(image.id);
  };

  afterUpload = image => {
    this.props.onChange(image.id);
  };
}

export default SelectImage;
