import React from "react";
import { Flex, Box, Text, Button, Layer } from "../Kit";
import moment from "moment";
import { Link as NavLink } from "react-router-dom";
import { themeGet } from "@styled-system/theme-get";
import styled, { css } from "styled-components/macro";
import Media from "react-media";
import GenericCard, { Attribution } from "../Cards/GenericCard";
import Annotation from "../ViewEpisode/Annotation";
import Question from "../ViewEpisode/Question";
import ViewEpisodeRouteTrigger from "../ViewEpisode/ViewEpisodeRouteTrigger";
import UserList from "../Search/UserList";
import AvatarWithPresence from "../Chat/AvatarWithPresence";

const Link = styled(NavLink)`
  font-weight: 600;
  text-decoration: none;
  color: ${themeGet("colors.black")};
  :hover {
    color: #016cd1;
  }
`;

// Most events have a standard type
// They have an actor:
// They have an entity:
// They have a verb:
// They may also have a target, and a secondary verb.

// Eg. Ben invited you to administer VTS
// Ben posted an annotation

// type EntityType =
//   | "publisherinvitation:user"
//   | "enrollment:from-admin"
//   | "coach:from-admin"
//   | "annotation:add";

// type User = {
//   name: string,
//   id: number,
//   photo: {
//     public_id: string
//   }
// };

// type NotificationObject = {
//   entityType: EntityType,
//   meta: object,
//   notificationChange?: {
//     user: User
//   }
// };

// type Props = {
//   notificationObject: NotificationObject,
//   viewer: Object
// };

const getEntityLink = obj => {
  switch (obj.entityType) {
    case "annotation:add":
      const { viewerEnrollment } = obj.meta.annotation.group;
      // it could be the case that the viewer is not enrolled (coach, publisher)
      return (
        <ViewEpisodeRouteTrigger>
          {trigger => (
            <Button
              size="small"
              onClick={() =>
                trigger({
                  annotationId: obj.meta.annotation.id,
                  groupId: obj.meta.annotation.group.id,
                  stepId: obj.meta.annotation.step.id,
                  enrollmentId: viewerEnrollment
                    ? viewerEnrollment.id
                    : obj.meta.annotation.enrollmentId
                })
              }
            >
              View annotation
            </Button>
          )}
        </ViewEpisodeRouteTrigger>
      );
    case "stepquestion-comment:add":
      return (
        <ViewEpisodeRouteTrigger>
          {trigger => (
            <Button
              size="small"
              onClick={() =>
                trigger({
                  commentId: obj.meta.comment.id,
                  stepQuestionId: obj.meta.comment.question.id,
                  groupId: obj.meta.comment.group.id,
                  stepId: obj.meta.comment.question.stepId,
                  enrollmentId: obj.meta.comment.group.viewerEnrollment
                    ? obj.meta.comment.group.viewerEnrollment.id
                    : obj.meta.comment.enrollmentId
                })
              }
            >
              View comment
            </Button>
          )}
        </ViewEpisodeRouteTrigger>
      );
    case "annotation-comment:add":
      return (
        <ViewEpisodeRouteTrigger>
          {trigger => (
            <Button
              size="small"
              onClick={() =>
                trigger({
                  commentId: obj.meta.comment.id,
                  annotationId: obj.meta.comment.annotation.id,
                  groupId: obj.meta.comment.annotation.group.id,
                  stepId: obj.meta.comment.annotation.step.id,
                  enrollmentId: obj.meta.comment.annotation.group
                    .viewerEnrollment
                    ? obj.meta.comment.annotation.group.viewerEnrollment.id
                    : obj.meta.comment.enrollmentId
                })
              }
            >
              View comment
            </Button>
          )}
        </ViewEpisodeRouteTrigger>
      );

    default:
      return null;
  }
};

export const getEntitySummary = obj => {
  try {
    switch (obj.entityType) {
      case "stepquestion-comment:add":
        return {
          message: "answered a",
          name: "question",
          url: null
        };
      case "annotation-comment:add":
        return {
          message: "replied to an",
          name: "annotation",
          url: obj.meta.comment.annotation.permalink
        };
      case "publisherinvitation:user":
        return obj.meta.membership
          ? {
              message: "invited you to administer",
              name: obj.meta.membership.publisher.name,
              url: obj.meta.membership.publisher.permalink
            }
          : null;
      case "enrollment":
        return obj.meta.enrollment
          ? {
              message: "enrolled in",
              name: obj.meta.enrollment.section.title,
              url: obj.meta.enrollment.permalink
            }
          : null;
      case "enrollment:from-admin":
        return obj.meta.enrollment
          ? {
              message: "enrolled you in",
              name: obj.meta.enrollment.section.title,
              url: obj.meta.enrollment.permalink
            }
          : null;
      case "coach:from-admin":
        return obj.meta.coach
          ? {
              message: "invited you to instruct",
              name: obj.meta.coach.section.title,
              url: obj.meta.coach.permalink
            }
          : null;
      case "annotation:add":
        return obj.meta.annotation
          ? {
              message: "posted an annotation in",
              name: obj.meta.annotation.step.title,
              url: obj.meta.annotation.permalink
            }
          : null;
      default:
        console.error("entity type not found");
        throw new Error("Event type unsupported");
    }
  } catch (err) {
    return null;
  }
};

const EntityBox = styled(Box)`
  @media screen and (min-width: 52em) {
    ${props => {
      if (!props.popup) {
        return css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 8px;
        `;
      }
    }};
  }
`;

const EntityLink = ({ entity }) => {
  return getEntityLink(entity);
};

const isValidEvent = o => {
  switch (o.entityType) {
    case "stepquestion-comment:add":
    case "annotation-comment:add":
      return !!o.meta.comment;
    case "annotation:add":
      return !!o.meta.annotation;
    case "enrollment":
    case "enrollment:from-admin":
      return !!o.meta.enrollment;
    case "coach:from-admin":
      return !!o.meta.coach;
    case "publisherinvitation:user":
      return !!o.meta.membership;
  }

  return true;
};

const getName = (actor, viewer) => {
  if (actor.id === viewer.id) {
    return "You";
  }
  return actor.name;
};
const Entity = ({ actor, popup, entity, viewer }) => {
  const { name, url, message } = getEntitySummary(entity);

  return (
    <EntityBox popup={popup}>
      <Text fontSize={popup ? 0 : 1} fontWeight="600">
        {getName(actor, viewer)}{" "}
      </Text>
      <Text fontSize={popup ? 0 : 1}>{message}</Text>{" "}
      {!popup && url ? (
        <Link fontSize={popup ? 0 : 1} to={url}>
          {name}
        </Link>
      ) : (
        <Text fontSize={popup ? 0 : 1} fontWeight="500">
          {name}
        </Text>
      )}
    </EntityBox>
  );
};

const Actor = ({ user }) => {
  return (
    <Box>
      <AvatarWithPresence
        userId={user.id}
        size="medium"
        ariaHidden
        name={user.name}
        img={user.photo}
      />
    </Box>
  );
};

const hasDetails = entity => {
  const { meta } = entity;
  if (meta.__typename === "EnrollmentEventType") {
    return false;
  }
  return true;
};

const EntityDetails = ({ entity, viewer }) => {
  const { meta } = entity;

  const getElement = () => {
    switch (meta.__typename) {
      case "StepQuestionCommentEventType":
        return (
          <Box mt={2} px={2}>
            <Question
              question={meta.comment.question}
              viewer={viewer}
              showCommentId={meta.comment.id}
              groupId={meta.comment.groupId}
              role={meta.comment.group.role}
              courseId={meta.comment.group.section.courseId}
              showTimestamp={false}
              section={meta.comment.group.section}
              enrollmentId={
                meta.comment.group.viewerEnrollment
                  ? meta.comment.group.viewerEnrollment.id
                  : null
              }
            />
          </Box>
        );
      case "AnnotationCommentEventType":
      case "AnnotationEventType":
        let annotation =
          meta.__typename === "AnnotationCommentEventType"
            ? meta.comment.annotation
            : meta.annotation;

        return (
          <Box mt={2} px={2}>
            <Annotation
              annotation={annotation}
              viewer={viewer}
              showCommentId={meta.comment ? meta.comment.id : null}
              section={annotation.group.section}
              step={annotation.step}
              showTimestamp={false}
              courseId={annotation.group.section.courseId}
              groupId={annotation.group.id}
              role={annotation.group.role}
              enrollmentId={
                annotation.group.viewerEnrollment
                  ? annotation.group.viewerEnrollment.id
                  : null
              }
            />
          </Box>
        );

      case "MembershipEventType":
        return meta.membership ? (
          <Box m={2}>
            <GenericCard
              to={meta.membership.publisher.permalink}
              cost={null}
              title={meta.membership.publisher.name}
              image={meta.membership.publisher.image}
              subtitle={meta.membership.publisher.tagline}
              footer={
                <UserList
                  users={meta.membership.publisher.members.map(
                    member => member.user
                  )}
                />
              }
            />
          </Box>
        ) : null;
      case "CoachEventType":
        return meta.coach ? (
          <Box m={2}>
            <GenericCard
              to={`/coach/${meta.coach.section.slug}`}
              cost={null}
              title={meta.coach.section.title}
              image={meta.coach.section.image}
              subtitle={meta.coach.section.shortDescription}
              attribution={
                <Attribution
                  name={meta.coach.section.publisher.name}
                  image={meta.coach.section.publisher.avatar}
                />
              }
            />
          </Box>
        ) : null;
      case "EnrollmentEventType":
        return null;
      // return meta.enrollment ? (
      //   <Box m={2}>
      //     <GenericCard
      //       to={`/learn/${meta.enrollment.groupId}/${meta.enrollment.id}`}
      //       cost={null}
      //       title={meta.enrollment.section.title}
      //       image={meta.enrollment.section.image}
      //       subtitle={meta.enrollment.section.shortDescription}
      //       attribution={
      //         <Attribution
      //           name={meta.enrollment.section.publisher.name}
      //           image={meta.enrollment.section.publisher.avatar}
      //         />
      //       }
      //     />
      //   </Box>
      // ) : null;
      default:
        return null;
    }
  };
  try {
    const el = getElement();
    if (el) {
      return el;
    }
  } catch (err) {
    return null;
  }
  return null;
};

const StyledBox = styled(Box)`
  :last-child {
    border-bottom: none;
  }
`;

const StyledBoxLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  padding: 0;
  padding-top: 8px;
  border-bottom: 1px solid;
  border-color: ${themeGet("colors.borderColor")};
  :hover {
    background-color: ${themeGet("colors.blue.1")};
  }
  :last-child {
    border-bottom: none;
  }
`;

// type State = {
//   error: boolean
// };

class Event extends React.Component {
  state = {
    error: false
  };

  componentDidCatch(err, errorInfo) {
    this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return null;
    }

    const { notificationObject, popup } = this.props;

    // This feels terribly hacky, but sometimes the notification
    // object meta will be deleted. Don't render our notification in this case.
    const isValid = isValidEvent(notificationObject);

    if (!isValid) {
      return null;
    }

    const user = notificationObject.notificationChange
      ? notificationObject.notificationChange.user
      : { name: "Someone" };

    const hasSummary = getEntitySummary(notificationObject);

    if (!hasSummary) {
      return null;
    }

    const details = hasDetails(notificationObject);

    return (
      <Box my={popup ? 0 : 2}>
        <Media query="(min-width: 52rem)">
          {matches => {
            const Component = popup ? StyledBoxLink : StyledBox;
            const Wrapper = popup ? Box : Layer;
            return (
              <Wrapper style={{ overflow: "hidden" }}>
                <Box width={1}>
                  <Component
                    className="trigger-close"
                    pt={popup ? 0 : 0}
                    px={popup ? 0 : 0}
                    pb={0}
                    to={popup ? getEntitySummary(notificationObject).url : null}
                    borderBottom="1px solid"
                    borderColor="borderColor"
                  >
                    <Flex>
                      <Box width={1}>
                        <Box
                          display="flex "
                          px={2}
                          borderBottom={details ? "1px solid" : "none"}
                          borderColor={details ? "borderColor" : "transparent"}
                          justifyContent="space-between"
                          pt={2}
                          pb={2}
                        >
                          <Box pr={2}>
                            <Actor user={user} />
                          </Box>
                          <Box
                            display="block"
                            alignItems="center"
                            style={{ overflow: "hidden" }}
                            py={0}
                            width={1}
                          >
                            <Entity
                              popup={popup}
                              actor={user}
                              viewer={this.props.viewer}
                              entity={notificationObject}
                            />
                            <Text
                              textAlign={popup ? "right" : "left"}
                              faded
                              display={popup ? null : "block"}
                              fontSize={popup ? 0 : 1}
                              ml="auto"
                            >
                              {moment(notificationObject.createdAt).fromNow()}
                            </Text>
                          </Box>
                          <Box display={["none", "block"]} flex="0 0 auto">
                            {!popup && (
                              <EntityLink entity={notificationObject} />
                            )}
                          </Box>
                        </Box>
                        {!popup && (
                          <EntityDetails
                            viewer={this.props.viewer}
                            entity={notificationObject}
                          />
                        )}
                      </Box>
                    </Flex>
                  </Component>
                </Box>
              </Wrapper>
            );
          }}
        </Media>
      </Box>
    );
  }
}

export default Event;
