import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

let portalContainer, appElement;

export default class Portal extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired
  };

  constructor() {
    super();
    if (!portalContainer) {
      portalContainer = document.createElement("div");
      portalContainer.setAttribute("data-portal-container", "");
      document.body.appendChild(portalContainer);
    }

    this.el = document.createElement("div");
  }

  componentDidMount() {
    portalContainer.appendChild(this.el);
  }

  componentWillUnmount() {
    portalContainer.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

Portal.setAppElement = el => {
  appElement = el;
};

Portal.hideApp = () => {
  if (!appElement) {
    console.warn(
      "You have not set an app element. This can cause problems for assistive technologies. Try running Portal.setAppElement(node)"
    );
  }

  appElement.setAttribute("aria-hidden", true);
};

Portal.showApp = () => {
  appElement.setAttribute("aria-hidden", false);
};
