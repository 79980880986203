import React from "react";
import { withFormik } from "formik";
import * as yup from "yup";
import debug from "debug";
import { Input, Box, Alert, LayerLoading, Flex, Button } from "../Kit";
import Raven from "raven-js";
import { defaults } from "lodash";

const log = debug("app:AddGalleryForm");

const AddGalleryForm = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  isSubmitting
}) => (
  <form id="formId" onSubmit={handleSubmit}>
    <Input
      label="Title *"
      name="title"
      onChange={handleChange}
      error={errors.title}
      onBlur={handleBlur}
      value={values.title}
    />
    <Input
      label="Short description *"
      name="description"
      onChange={handleChange}
      error={errors.description}
      onBlur={handleBlur}
      value={values.description}
    />
    {errors.general && (
      <Box my={2}>
        <Alert type="danger">{errors.general}</Alert>
      </Box>
    )}

    <Flex justifyContent="flex-end">
      <Button appearance="primary" type="submit" disabled={isSubmitting}>
        Submit
      </Button>
    </Flex>
    <LayerLoading loading={isSubmitting} />
  </form>
);

const formConfig = {
  mapPropsToValues: props => {
    let existing = props.gallery || {};
    return defaults(existing, {
      title: "",
      description: ""
    });
  },
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: yup.object().shape({
    title: yup
      .string()
      .trim()
      .required("A title is required."),
    description: yup
      .string()
      .trim()
      .required("A description is required.")
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const attr = {
      input: {
        title: values.title,
        description: values.description
      }
    };

    if (props.gallery) {
      attr.id = props.gallery.id;
    } else {
      attr.input.courseId = props.courseId;
    }

    log("saving: %o", attr);

    try {
      const fn = props.gallery ? props.onRequestUpdate : props.onRequestAdd;

      await fn({ variables: attr });
      if (props.onRequestDone) {
        props.onRequestDone();
      }
    } catch (err) {
      console.error(err);
      setErrors({ general: err.message });
      Raven.captureException(err);
    }
  }
};

export default withFormik(formConfig)(AddGalleryForm);
