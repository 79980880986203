import gql from "graphql-tag";

export const ConversationMemberSubscription = gql`
  subscription conversationMember($conversationId: Int!) {
    conversationMember(conversationId: $conversationId) {
      id
      lastRead
      isTyping
    }
  }
`;
