// https://github.com/withspectrum/spectrum/blob/7ec442f860c3657e7503f55558497ca064f8643b/src/components/draft-js-plugins-editor/index.js
import * as React from "react";
import DraftEditor from "draft-js-plugins-editor";
import { debounce } from "lodash";

// import typeof DraftEditorProps from "draft-js/lib/DraftEditorProps";

// type Props = {
//   ...$Exact<DraftEditorProps>,
//   plugins?: Array<mixed>,
//   decorators?: Array<Object>,
//   defaultKeyBindings?: boolean,
//   defaultBlockRenderMap?: boolean,
//   editorRef?: Function
// };

// type FallbackState = {
//   value: string
// };

class AndroidFallbackInput extends React.Component {
  constructor(props) {
    super(props);
    this.syncChanges = debounce(this.syncChanges, 100);
    this.state = {
      value: ""
    };
  }
}

const isAndroid = () => false;

class CustomDraftJSPluginsEditor extends React.Component {
  render() {
    if (!isAndroid()) {
      return <DraftEditor {...this.props} ref={this.props.editorRef} />;
    }

    return <AndroidFallbackInput {...this.props} />;
  }
}

export default CustomDraftJSPluginsEditor;
