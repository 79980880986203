import React from "react";
import Flex from "../Kit/Flex";
import styled from "styled-components/macro";

export const Navbar = styled(Flex)`
  justify-content: flex-start;
  flex-flow: row nowrap;
  /* box-shadow: 0 0 35px 0 rgba(0,0,0,.09); */
  display: flex;
  background: ${props => (props.transparent ? "transparent" : "#ffffff")};
  height: 8rem;
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100vw;
  align-items: center;
  /* box-shadow: 0 10px 40px 0 rgba(62,57,107,0.07), 0 2px 9px 0 rgba(62,57,107,0.06); */
`;

class Nav extends React.Component {
  render() {
    return (
      <Navbar transparent={this.props.transparent}>
        {this.props.children}
      </Navbar>
    );
  }
}

export default Nav;
