import React from "react";
import { Alert, Embed, LayerLoading } from "../../Kit";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import PropTypes from "prop-types";
import Player from "../../Playback/UniversalPlayer";
import { VideoQuery } from "../../API/queries/VideoQuery";

class Video extends React.Component {
  static propTypes = {
    videoId: PropTypes.number.isRequired
  };

  state = {
    loading: true,
    error: false,
    html: null
  };

  render() {
    return (
      <Embed mb={2} dimensions={{ width: 16, height: 9 }}>
        {this.renderContent()}
        <LayerLoading loading={this.state.loading} />
      </Embed>
    );
  }

  renderContent() {
    if (this.props.data && this.props.data.video) {
      return (
        <Player
          controls
          onError={() => {
            this.setState({ error: true });
          }}
          source={this.props.data.video.url}
          onCanPlay={() => this.setState({ loading: false })}
          onTimeUpdate={() => {}}
        />
      );
    } else if (this.state.error) {
      return (
        <Alert
          mb={2}
          type="danger"
          title="An error occurred while embedding this video"
        />
      );
    }
  }
}

const withVideo = graphql(VideoQuery, {
  options: props => {
    return {
      variables: {
        id: props.videoId
      }
    };
  }
});

export default compose(withVideo)(Video);
