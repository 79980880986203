import * as React from "react";
import AvatarWithPresence from "./AvatarWithPresence";
import Box from "../Kit/Box";
import { Tooltip } from "../Kit";

const ChatGroupAvatar = ({ members }) => {
  if (members.length > 1) {
    return (
      <Tooltip
        title={
          <Box textAlign="left">
            {members.map(member => (
              <div key={member.id}>{member.user.name}</div>
            ))}
          </Box>
        }
      >
        <Box height="56px" width="56px" position="relative">
          <Box style={{ zIndex: 2 }} position="absolute" top="3px" left="3px">
            <AvatarWithPresence
              size="small"
              hideTooltip
              userId={members[0].userId}
              img={members[0].user.photo}
              name={members[0].user.name}
            />
          </Box>
          <Box position="absolute" bottom="3px" right="3px">
            <AvatarWithPresence
              size="small"
              hideTooltip
              userId={members[1].userId}
              img={members[1].user.photo}
              name={members[1].user.name}
            />
          </Box>
        </Box>
      </Tooltip>
    );
  }

  return (
    <AvatarWithPresence
      size="large"
      hideTooltip
      userId={members[0].userId}
      img={members[0].user.photo}
      name={members[0].user.name}
    />
  );
};

export default ChatGroupAvatar;
