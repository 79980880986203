import React from "react";
import { Box, TabButton, TabList, TabContent } from "../Kit";
import GalleryForm from "./AddGalleryForm";
import ManageGalleryImages from "./ManageGalleryImages";

// type Props = {
//   courseId?: string,
//   galleryId?: string,
//   onRequestAdd: () => void,
//   onRequestUpdate: () => void,
//   onRequestAddImage: () => void,
//   onRequestRemoveImage: () => void
// };

// type State = {
//   showImages: boolean
// };

export class AddGallery extends React.Component {
  state = {
    showImages: false,
    newGallery: true,
  };

  render() {
    const { gallery } = this.props;

    const disabled = !gallery;

    return (
      <Box pt={2} bg="white">
        <Box px={2} borderBottom="1px solid" borderColor="borderColor">
          <TabList bordered>
            <TabButton
              active={!this.state.showImages}
              disabled = {!this.state.newGallery}
              id="meta"
              onClick={this.showMeta}
            >
              1. Add description
            </TabButton>
            <TabButton
              disabled={disabled}
              active={this.state.showImages}
              onClick={this.showImages}
            >
              2. Manage images
            </TabButton>
          </TabList>
        </Box>

        <Box>
          {!this.state.showImages && (
            <TabContent id="meta">
              <Box p={2}>
                <GalleryForm
                  courseId={this.props.courseId}
                  gallery={this.props.gallery}
                  onRequestAdd={this.props.onRequestAdd}
                  onRequestUpdate={this.props.onRequestUpdate}
                  onRequestDone={() => {
                    this.setState({ newGallery: false, showImages: true });
                  }}
                />
              </Box>
            </TabContent>
          )}

          {this.state.showImages && gallery && (
            <TabContent id="meta">
              <ManageGalleryImages
                galleryId={gallery.id}
                images={gallery ? gallery.images : []}
                galleryImages = {gallery ? gallery.galleryImages : []}
                onRequestAddImage={this.props.onRequestAddImage}
                onRequestRemoveImage={this.props.onRequestRemoveImage}
                onChangeOrderOfGalleryImages={this.props.onChangeOrderOfGalleryImages}
              />
            </TabContent>
          )}
        </Box>
      </Box>
    );
  }

  showMeta = e => {
    e.preventDefault();
    this.setState({ showImages: false });
  };

  showImages = e => {
    e.preventDefault();
    this.setState({ showImages: true });
  };
}
