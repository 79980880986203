import algolia from "algoliasearch";

const client = algolia("V2JIU4Q1HC", "1b884f3fe9b5b5591918a4fe4f85dbae");

const indexName =
  process.env.NODE_ENV === "production" ? "prod_COURSES" : "dev_COURSES";

const courseIndex = client.initIndex(indexName);

const search = term => courseIndex.search(term);

export default search;
