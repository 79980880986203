import * as React from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Avatar, Text, Box } from "../Kit";

const OtherContainer = styled.div`
  margin: 12px 0;
  & .ChatMessageViewer {
    border-radius: 2px 16px 16px 2px;
  }

  & .ChatMessageWrapper:first-child .ChatMessageViewer {
    border-top-left-radius: 16px;
  }

  & .ChatMessageWrapper:last-child .ChatMessageViewer {
    border-bottom-left-radius: 16px;
  }

  & .ChatMessageWrapper {
    display: flex;
    justify-content: flex-start;
    padding-right: 32px;
    padding-left: 0;
  }
`;

const ViewerContainer = styled(OtherContainer)`
  & .ChatMessageViewer {
    border-radius: 16px 2px 2px 16px;

    text-align: left;
  }

  & .ChatMessageWrapper:first-child .ChatMessageViewer {
    border-top-right-radius: 16px;
  }

  & .ChatMessageWrapper:last-child .ChatMessageViewer {
    border-bottom-right-radius: 16px;
  }

  & p {
    color: white;
  }

  & .ChatMessageWrapper {
    display: flex;
    justify-content: flex-end;
    padding-left: 32px;
    padding-right: 0;
  }
`;

const ChatMessageGroup = ({ group, user, children, viewer }) => {
  const isFromViewer = group.userId === viewer.id;
  const Container = isFromViewer ? ViewerContainer : OtherContainer;

  return (
    <Container>
      {group.showDate && (
        <Text display="block" mb={1} textAlign="center" faded fontSize={0}>
          {moment(
            group.messages[group.messages.length - 1].node.createdAt
          ).calendar()}
        </Text>
      )}

      <Box
        width={1}
        px={1}
        justifyContent={isFromViewer ? "flex-end" : "flex-start"}
        display="flex"
      >
        {!isFromViewer &&
          group.showAvatar &&
          user && (
            <Box flex="0 0 auto">
              <Avatar name={user.name} img={user.photo} />
            </Box>
          )}
        <Box ml={1} mr={1}>
          {children}
        </Box>
      </Box>
    </Container>
  );
};

export default ChatMessageGroup;
