import { standardSizes } from "../Kit/Avatar";
import { imageURL } from "../Kit/Image";
import { getEntitySummary } from "../EventFeed/Event";

export const getSummary = event => {
  const { notificationObject } = event;
  const summary = getEntitySummary(notificationObject);

  if (!summary) {
    return null;
  }

  const { message, name, url } = summary;
  const actor = notificationObject.notificationChange.user;
  const body = `${actor.name} ${message} ${name}`;
  const image = imageURL(actor.photo.public_id, standardSizes.medium);
  return {
    title: "Watershed",
    body,
    image,
    url
  };
};
