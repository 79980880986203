import gql from "graphql-tag";
import { NotificationFragment } from "../fragments/NotificationFragment";

export const ViewerEventsQuery = gql`
  query ViewerEvents($first: Int!, $after: String, $excludeViewer: Boolean) {
    viewer {
      id
      photo {
        id
        public_id
      }
      notifications(
        first: $first
        after: $after
        excludeViewer: $excludeViewer
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            ...Notification
          }
        }
      }
    }
  }
  ${NotificationFragment}
`;
