import React from "react";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import {
  Modal,
  notify,
  withLoading,
  Heading,
  Layer,
  Flex,
  Button,
  Box
} from "../../Kit";
import ResourceListAdmin from "../../Resources/ResourceListAdmin";
import ResourcePanel from "../Episodes/ResourcePanel";
import ResourceListSelect from "../../Resources/ResourceListSelect";
import { CourseResourcesQuery } from "../../API/queries/CourseResourcesQuery";
import { RemoveResourceMutation } from "../../API/mutations/ResourceMutations";

class CourseResources extends React.Component {
  state = {
    showModal: false
  };

  static defaultProps = {
    enableNew: true,
    showTitle: true,
    title: "Resource"
  };

  render() {
    const {
      onSelect,
      courseId,
      data: {
        course: { resources }
      }
    } = this.props;

    return (
      <Flex>
        <Box width={1} maxWidth="1000px" m="0 auto">
          <Layer elevation={1}>
            <Box width={1}>
              {this.props.showTitle && (
                <Flex
                  alignItems="flex-end"
                  p={2}
                  justifyContent="space-between"
                  borderBottom="1px solid"
                  borderColor="borderColor"
                >
                  <Heading fontSize={3}>Resources</Heading>
                  {this.props.enableNew && (
                    <Button onClick={this.show} appearance="success">
                      New resource
                    </Button>
                  )}
                </Flex>
              )}
              {onSelect ? (
                <ResourceListSelect resources={resources} onSelect={onSelect} />
              ) : (
                <ResourceListAdmin
                  resources={resources}
                  onRequestEdit={this.onRequestEdit}
                  onRequestRemove={this.onRequestRemove}
                />
              )}
            </Box>
          </Layer>
        </Box>
        <Modal isOpen={this.state.showModal}>
          <Modal.Header title={this.props.title} onRequestClose={this.close} />
          <ResourcePanel
            onRequestClose={this.close}
            courseId={courseId}
            edit={this.state.editResource}
            showSelect={!this.state.editResource}
          />
        </Modal>
      </Flex>
    );
  }

  close = () => {
    this.setState({
      showModal: false
    });
  };

  show = () => {
    this.setState({
      showModal: true,
      editResource: false
    });
  };

  onRequestEdit = resource => {
    this.setState({
      showModal: true,
      editResource: resource
    });
  };

  onRequestRemove = resource => {
    notify.confirmation({
      message: "Are you sure you want to delete this resource?",
      details:
        "This will also delete this resource from other places in which it may appear within this course template.",
      confirmText: "Delete",
      onConfirm: () => {
        this.props.mutate({ variables: { id: resource.id } });
      }
    });
  };

  addResource = resource => {
    console.log("add resource");
  };
}

export default compose(
  graphql(CourseResourcesQuery, {
    options: props => ({
      variables: { id: props.courseId }
    })
  }),
  graphql(RemoveResourceMutation, {
    options: {
      refetchQueries: ["CourseResources"]
    }
  }),
  withLoading
)(CourseResources);
