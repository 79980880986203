import React from "react"; // eslint-disable-line
import Text, { Link } from "./Text";
import { Link as RouterLink } from "react-router-dom";
import { themeGet } from "@styled-system/theme-get";
import Box from "./Box";
import styled from "styled-components/macro";

export const Navbar = styled(Box)`
  justify-content: flex-start;
  flex-flow: row nowrap;
  /* box-shadow: 0 0 35px 0 rgba(0,0,0,.09); */
  display: flex;
  background: white;
  height: 56px;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom-color: ${themeGet("colors.borderColor")};
  border-bottom-width: 1px;
  border-bottom-style: solid;

  /* padding-top: 3rem; */
  align-items: center;
  /* box-shadow: 0 10px 40px 0 rgba(62,57,107,0.07), 0 2px 9px 0 rgba(62,57,107,0.06); */
`;

const NavbarBrandStyled = styled(Text)`
  color: ${themeGet("colors.gray.5")};
  line-height: inherit;
  font-weight: bold;
  font-size: ${themeGet("fontSizes.2")}px;
  white-space: nowrap;
  text-decoration: none;
  background-color: transparent;
  :hover {
    color: ${themeGet("colors.white")};
    text-decoration: none;
  }
`;

export const NavbarBrand = props => (
  <NavbarBrandStyled as={RouterLink} {...props} />
);

export const NavbarContent = styled(Box)`
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  list-style: none;
  margin-top: 0;
`;

export const NavbarLinkStyled = styled(Link)`
  color: ${(props) => props.navbar ? "#575757" : themeGet("colors.gray.5")};
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 500;
  text-decoration: none;
  line-height: 1.25;
  font-family: ${themeGet("sansFont")};
  font-size: ${(props) => props.navbar ? "16" : themeGet("fontSizes.1")}px;
  display: block;
  :hover {
    color: ${(props) => props.navbar ? "#f5821e" : themeGet("colors.blue.0")};
    text-decoration: none;
  }
`;

export const NavbarLink = props => (
  <NavbarLinkStyled as={RouterLink} {...props} />
);


export const NavbarItem = Box;

export default Navbar;
