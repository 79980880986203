import React from "react";
import styled, { keyframes, css } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import Portal from "./Portal";
import Transition from "react-transition-group/Transition";

export const animationEasing = {
  standard: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
  deceleration: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
  acceleration: `cubic-bezier(0.4, 0.0, 1, 1)`,
  sharp: `cubic-bezier(0.4, 0.0, 0.6, 1)`,
  spring: `cubic-bezier(0.175, 0.885, 0.320, 1.175)`
};

const ANIMATION_DURATION = 240;

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOutAnimation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const pointerEvents = p => (p.canClickThrough ? "none" : "initial");

export const animation = props => {
  if (props.state === "entering" || props.state === "entered") {
    return css`${fadeInAnimation} ${ANIMATION_DURATION}ms ${
      animationEasing.deceleration
    } both`;
  }

  if (props.state === "exiting" || props.state === "exited") {
    return css`${fadeOutAnimation} ${ANIMATION_DURATION}ms ${
      animationEasing.acceleration
    } both`;
  }
};

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props.zIndex};
  right: 0;
  bottom: 0;
  :before {
    background: ${themeGet("colors.grayAlpha.18")};
    bottom: 0;
    left: 0;
    content: "";
    pointer-events: ${pointerEvents};
    position: fixed;
    z-index: ${props => props.zIndex - 1};
    right: 0;
    top: 0;
    animation: ${animation};
    ${props => {
      if (props.backgroundColor) {
        return css`
          background: ${props.backgroundColor};
        `;
      }

      if (props.appearance === "white") {
        return css`
          background: rgba(255, 255, 255, 0.98);
        `;
      }
    }};
  }
`;

export default class Overlay extends React.Component {
  static defaultProps = {
    onHide: () => {},
    onExit: () => {},
    onExiting: () => {},
    onExited: () => {},
    onEnter: () => {},
    onEntering: () => {},
    onEntered: () => {},
    zIndex: 999
  };

  constructor(props) {
    super(props);

    this.state = {
      exiting: false,
      exited: !props.isOpen
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState({
        exited: false
      });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.onEsc, false);
  }

  /**
   * Methods borrowed from BlueprintJS
   * https://github.com/palantir/blueprint/blob/release/2.0.0/packages/core/src/components/overlay/overlay.tsx
   */
  bringFocusInsideOverlay = () => {
    // Always delay focus manipulation to just before repaint to prevent scroll jumping
    return requestAnimationFrame(() => {
      // Container ref may be undefined between component mounting and Portal rendering
      // activeElement may be undefined in some rare cases in IE

      if (
        this.containerElement == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null || // eslint-disable-line eqeqeq, no-eq-null
        !this.props.isOpen
      ) {
        return;
      }

      const isFocusOutsideModal = !this.containerElement.contains(
        document.activeElement
      );
      if (isFocusOutsideModal) {
        // Element marked autofocus has higher priority than the other clowns
        const autofocusElement = this.containerElement.querySelector(
          "[autofocus]"
        );
        const wrapperElement = this.containerElement.querySelector(
          "[tabindex]"
        );
        const buttonElement = this.containerElement.querySelector("button");

        if (autofocusElement) {
          autofocusElement.focus();
        } else if (wrapperElement) {
          wrapperElement.focus();
        } else if (buttonElement) {
          buttonElement.focus();
        }
      }
    });
  };

  bringFocusBackToTarget = () => {
    return requestAnimationFrame(() => {
      if (
        this.containerElement == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null // eslint-disable-line eqeqeq, no-eq-null
      ) {
        return;
      }

      const isFocusInsideModal = this.containerElement.contains(
        document.activeElement
      );

      // Bring back focus on the target.
      if (
        this.previousActiveElement &&
        (document.activeElement === document.body || isFocusInsideModal)
      ) {
        this.previousActiveElement.focus();
      }
    });
  };

  onEsc = e => {
    // Esc key
    if (e.keyCode === 27) {
      this.close();
    }
  };

  close = () => {
    this.setState({ exiting: true });
  };

  handleEntering = node => {
    document.body.addEventListener("keydown", this.onEsc, false);
    this.props.onEntering(node);
  };

  handleEntered = node => {
    this.previousActiveElement = document.activeElement;
    this.bringFocusInsideOverlay();
    this.props.onEntered(node);
  };

  handleExiting = node => {
    document.body.removeEventListener("keydown", this.onEsc, false);
    this.bringFocusBackToTarget();
    this.props.onExiting(node);
  };

  handleExited = node => {
    this.setState({ exiting: false, exited: true });
    this.props.onExited(node);
  };

  handleBackdropClick = e => {
    if (e.target !== e.currentTarget) {
      return;
    }

    this.close();
  };

  onContainerRef = ref => {
    this.containerElement = ref;
  };

  render() {
    const {
      backgroundColor,
      canClickThrough,
      isTinted,
      zIndex,
      isOpen,
      style,
      appearance,
      children,
      onExit,
      onEnter
    } = this.props;
    const { exiting, exited } = this.state;
    if (exited) return null;

    return (
      <Portal>
        <Transition
          appear
          unmountOnExit
          timeout={ANIMATION_DURATION}
          in={isOpen && !exiting}
          onExit={onExit}
          onExiting={this.handleExiting}
          onExited={this.handleExited}
          onEnter={onEnter}
          onEntering={this.handleEntering}
          onEntered={this.handleEntered}
        >
          {state => (
            <StyledOverlay
              appearance={appearance}
              zIndex={zIndex}
              backgroundColor={backgroundColor}
              canClickThrough={canClickThrough}
              onClick={this.handleBackdropClick}
              isTinted={isTinted}
              innerRef={this.onContainerRef}
              state={state}
              style={style}
            >
              {typeof children === "function"
                ? children({
                    state,
                    close: this.close,
                    props: {
                      role: "dialog",
                      tabIndex: "-1",
                      position: "relative",
                      style: {
                        zIndex: zIndex + 1
                      }
                    }
                  })
                : children}
            </StyledOverlay>
          )}
        </Transition>
      </Portal>
    );
  }
}
