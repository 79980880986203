import gql from "graphql-tag";
import { ResourceFragment } from "./ResourceFragment";
import { CommentFragment } from "./CommentFragment";

export const QuestionFragment = gql`
  fragment Question on StepQuestion {
    __typename
    id
    content
    beginsAt
    type
    createdAt
    completed(enrollmentId: $enrollmentId)
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
    comments(groupId: $groupId) {
      id
      ...Comment
    }
  }
  ${CommentFragment}
  ${ResourceFragment}
`;

export const QuestionWithoutCompletionFragment = gql`
  fragment QuestionWithoutCompletion on StepQuestion {
    __typename
    id
    content
    beginsAt
    type
    createdAt
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
    comments {
      id
      ...Comment
    }
  }
  ${CommentFragment}
  ${ResourceFragment}
`;
