import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Image, Box, Embed, Flex, themeGet, WithLoading } from "../Kit";
import styled from "styled-components/macro";

const ScrollContainer = styled(Box)`
  overflow: auto;
  background-color: white;
  border-radius: ${themeGet("radii.1")}px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Thumbnail = ({ image, onClick }) => (
  <Box m={1}>
    <Image
      style={{ cursor: "pointer" }}
      tabindex="0"
      role="button"
      img={image}
      width={50}
      height={50}
      onClick={onClick}
    />
  </Box>
);

class ImageBrowser extends React.Component {
  render() {
    return (
      <Embed dimensions={{ width: 16, height: 9 }}>
        <ScrollContainer>
          <Flex>
            <WithLoading
              {...this.props}
              render={props => {
                return props.data.publisher.images.map(image => (
                  <Thumbnail
                    onClick={() => this.props.onSelect(image)}
                    key={image.id}
                    image={image}
                  />
                ));
              }}
            />
          </Flex>
        </ScrollContainer>
      </Embed>
    );
  }
}

const IMAGES = gql`
  query PublisherImages($id: Int!) {
    publisher(id: $id) {
      id
      images {
        id
        public_id
      }
    }
  }
`;

export default compose(
  graphql(IMAGES, {
    options: props => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: props.publisherId
      }
    })
  })
)(ImageBrowser);
