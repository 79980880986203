import React from "react";
import SelectVideo from "./SelectVideo";
import { Box, Input, Alert, Modal, Button, LayerLoading } from "../../Kit";
import * as yup from "yup";
import Raven from "raven-js";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { defaults } from "lodash";
import debug from "debug";
import { withFormik } from "formik";
import {
  CreateVideoResourceMutation,
  UpdateVideoResourceMutation
} from "../../API/mutations/VideoMutations";
const log = debug("app:AddVideo");

const VideoForm = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  isSubmitting
}) => (
  <form id="formId" onSubmit={handleSubmit}>
    <Modal.Body>
      <Input
        label="Title *"
        name="title"
        onChange={handleChange}
        error={errors.title}
        onBlur={handleBlur}
        value={values.title}
      />
      <Input
        label="Short description *"
        name="description"
        onChange={handleChange}
        error={errors.description}
        onBlur={handleBlur}
        value={values.description}
      />

      <SelectVideo
        onChange={v => setFieldValue("videoId", v)}
        videoId={values.videoId}
      />

      {errors.general && (
        <Box my={2}>
          <Alert
            type="danger"
            title={
              errors.general ||
              "An unexpected error occurred. Please try again."
            }
          />
        </Box>
      )}
    </Modal.Body>

    <Modal.Footer>
      <Button appearance="primary" mb={0} type="submit" saving={isSubmitting}>
        Save
      </Button>
    </Modal.Footer>
    <LayerLoading loading={isSubmitting} />
  </form>
);

const formConfig = {
  mapPropsToValues: props => {
    let existing = props.resource ? props.resource : {};
    if (existing.meta) {
      existing = {
        ...existing,
        ...existing.meta
      };
    }

    return defaults(existing, {
      title: "",
      description: "",
      videoId: ""
    });
  },
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: yup.object().shape({
    title: yup
      .string()
      .trim()
      .required("A title is required."),
    description: yup
      .string()
      .trim()
      .required("A description is required."),
    videoId: yup
      .string()
      .trim()
      .required("A video is required.")
  }),
  handleSubmit: async (
    values,
    { resetForm, props, setSubmitting, setErrors }
  ) => {
    const attr = {
      input: {
        title: values.title,
        description: values.description
      },
      videoId: values.videoId
    };

    // handle updates
    if (props.resource) {
      attr.id = props.resource.id;
    } else {
      attr.input.courseId = props.courseId;
    }

    log("saving: %o", attr);

    try {
      const res = await props.mutate({ variables: attr });
      log("mutation response: %o", res);

      resetForm();
      if (props.onRequestClose) {
        props.onRequestClose();
      }
    } catch (err) {
      console.error(err.stack);
      setErrors({ general: err.message });
      Raven.captureException(err);
    }

    setSubmitting(false);
  }
};

const AddVideoFormik = withFormik(formConfig)(VideoForm);

const withAddVideo = graphql(CreateVideoResourceMutation, {
  options: { refetchQueries: ["CourseResources"] }
});

export default compose(withAddVideo)(AddVideoFormik);

const withUpdateVideo = graphql(UpdateVideoResourceMutation);

export const UpdateVideo = compose(withUpdateVideo)(AddVideoFormik);
