import React from "react";

import Layer from "./Layer";
import { Link } from "react-router-dom";
import { themeGet } from "@styled-system/theme-get";
import styled, { css } from "styled-components/macro";
import Box from "./Box";
import Image from "./Image";

const cardStyles = css`
  display: block;
  text-decoration: none !important;
  background-color: white;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: ${themeGet("colors.grayAlpha.1")};
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);

  :active {
    background-color: ${themeGet("colors.gray.0")};
  }
  :hover {
    background-color: ${themeGet("colors.blue.1")};
  }
`;

const CardLayerStyled = styled(Layer)`
  ${cardStyles};
`;

const CardLayer = props => <CardLayerStyled as={Link} {...props} />;

const CardButtonStyled = styled(Layer)`
  ${cardStyles};
  background: white;
  padding: 0;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: ${themeGet("colors.grayAlpha.1")};

  :active {
    background-color: ${themeGet("colors.gray.0")};
  }
`;

const CardButton = props => <CardButtonStyled as="button" {...props} />;

class Card extends React.Component {
  static defaultProps = {
    elevationIn: 3,
    elevationOut: 0,
    border: false
  };

  state = {
    hover: false
  };

  hoverIn = () => {
    this.setState({ hover: true });
  };

  hoverOut = () => {
    this.setState({ hover: false });
  };

  render() {
    const {
      children,
      to,
      elevation,
      elevationIn,
      elevationOut,
      border,
      ...other
    } = this.props;
    const Component = to ? CardLayer : CardButton;

    return (
      <Component to={to} elevation={elevation || elevationOut} {...other}>
        {children}
      </Component>
    );
  }
}

const CardBody = styled(Box)`
  flex: 1 1 auto;
  padding: 1.25rem;
`;

Card.Body = CardBody;

const CardImage = styled(Image)`
  border-top-right-radius: ${themeGet("radii.0")}px;
  border-top-left-radius: ${themeGet("radii.0")}px;
  width: 100%;
  height: auto;
`;

Card.Image = CardImage;

export default Card;

const BorderBottom = styled(Box)`
  border-bottom-width: 1px;
  border-bottom-color: ${themeGet("colors.borderColor")};
  border-bottom-style: solid;
  padding: ${themeGet("space.2")}px;
  display: flex;
`;

export const TableCard = ({
  center,
  head,
  children,
  w,
  style = {},
  ...other
}) => (
  <Layer
    style={{
      ...style,
      overflow: "hidden",
      margin: center ? "0 auto" : null
    }}
    elevation={0}
    width={w}
    {...other}
  >
    <Box width={1}>
      {head && <BorderBottom width={1}>{head}</BorderBottom>}
      <Box width={1}>{children}</Box>
    </Box>
  </Layer>
);
