import gql from "graphql-tag";
import { ConversationReplyFragment } from "../fragments/ConversationReplyFragment";

export const CreateConversationReply = gql`
  mutation CreateConversationReply($input: CreateConversationreplyInputType!) {
    createConversationReply(input: $input) {
      id
      ...ConversationReply
    }
  }
  ${ConversationReplyFragment}
`;
