import gql from "graphql-tag";

export const ViewerNotificationsQuery = gql`
  query ViewerNotifications {
    viewer {
      id
      notificationCount
    }
  }
`;
