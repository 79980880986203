import gql from "graphql-tag";

export const UpdateUserMutation = gql`
  mutation UpdateUser($id: Int!, $input: UpdateUserInputType!) {
    updateUser(id: $id, input: $input) {
      id
      isAdmin
      notificationCount
    }
  }
`;

export const CreateUserMutation = gql`
  mutation CreateUser($input: CreateUser!) {
    createUser(input: $input) {
      name
      email
    }
  }
`;

export const UpdateUserAdminStatusMutation = UpdateUserMutation;
export const UpdateNotificationsReadMutation = UpdateUserMutation;
