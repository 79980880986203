import * as React from "react";
import styled from "styled-components/macro";
import Box from "../Kit/Box";
import Avatar from "../Kit/Avatar";
import "./TypingIndicator.css";
import { Tooltip } from "../Kit";

export const isTyping = members => {
  return members.find(member => member.isTyping);
};

class TypingIndicator extends React.Component {
  render() {
    const { size, members } = this.props;
    let userTyping = isTyping(members);

    if (userTyping) {
      return (
        <RenderIndicator
          size={size}
          userTyping={userTyping}
          key={userTyping.id}
        />
      );
    }

    return null;
  }
}

class RenderIndicator extends React.Component {
  state = {
    showIndicator: true
  };

  componentDidMount() {
    // this.timer = setTimeout(() => {
    //   this.setState({ showIndicator: false });
    // }, 10000);
  }

  componentWillUnmount() {
    // clearTimeout(this.timer);
  }

  render() {
    const { userTyping, size } = this.props;
    const { showIndicator } = this.state;

    if (!showIndicator) {
      return false;
    }

    return (
      <Box
        display="flex"
        px={size === "small" ? 0 : 1}
        pb={size === "small" ? 0 : 2}
      >
        {size !== "small" && (
          <Avatar name={userTyping.user.name} img={userTyping.user.photo} />
        )}
        <Box pr={size === "small" ? 0 : 1} />
        <Box
          style={{
            transform: "scale(0.7)"
          }}
          position="relative"
          height={size === "small" ? "19px" : "35px"}
        >
          <Tooltip title={userTyping.user.name + " is typing"}>
            <Typing size={size}>
              <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
              </div>
            </Typing>
          </Tooltip>
        </Box>
      </Box>
    );
  }
}

const Typing = styled.div`
  background-color: #eff2f5;
  height: 35px;
  display: inline-flex;
  position: absolute;
  left: 0;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 70px;
`;

export default TypingIndicator;
