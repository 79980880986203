import * as React from "react";
import styled from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import Box from "../Kit/Box";

const OnlineIndicator = styled(Box)`
  background-color: ${themeGet("colors.green.20")};
  border-radius: 50%;
  height: 13px;
  border: 2px solid white;
  width: 13px;
`;

export default OnlineIndicator;
