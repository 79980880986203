import Dropzone from "react-dropzone";
import axios from "axios";
import React from "react";
import { defaults } from "lodash";
import debug from "debug";
import PropTypes from "prop-types";
import Text from "./Text";
import Embed from "./Embed";
import Flex from "./Flex";
import auth from "../Auth/auth";

const log = debug("app:Kit:ImageUpload");


export default class ImageUpload extends React.Component {
  static propTypes = {
    afterUpload: PropTypes.func,
    postURL: PropTypes.string,
    prompt: PropTypes.string
  };

  static defaultProps = {
    accept: "image/*",
    postURL: "/upload/images",
    uploadMessage: "Uploading...",
    prompt: "Drop an image here, or click here to select an image to upload."
  };

  constructor(props) {
    super(props);

    this.state = defaults(props, {
      savingImage: false,
      error: false
    });
  }

  render() {
    let msg = this.state.savingImage
      ? this.props.uploadMessage
      : this.props.prompt;
    if (this.state.error) {
      msg = this.state.error.message || "An error occurred. Please try again.";
    }

    return (
      <Embed dimensions={{ width: 16, height: 9 }}>
        <Dropzone 
          multiple={false}
          accept={this.props.accept}
          style={{
            position: "absolute",
            cursor: "pointer",
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            backgroundColor: "white",
            borderColor: "transparent",
            border: "1px solid transparent"
          }}
          activeStyle={{
            borderColor: "#eee"
          }}
          onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <Flex
                alignItems="center"
                justifyContent="center"
                p={[2, 3]}
                style={{ height: "100%" }}
              >
                <Text fontSize={1} alignItems="center" faded>
                  {msg}
                </Text>
              </Flex>
            </div>
          </section>
        )}
      </Dropzone>
      </Embed>
    );
  }

  reportError = (errMessage) => {
    return this.setState({error: {message: errMessage}});
  }

  onDrop = (files, rejected) => {
    if (rejected.length > 0) {
      log("Rejected: %o", rejected);
      return this.reportError("This file type is not supported.");
    }

    const file = files[0];

    const size = file.size / 1024 / 1024;
    if (size > 5) {
      return this.reportError("This image is too large. Ensure that it is less than 5 MB.");
    }

    // upload new file only when no other files are uploaded
    if(!this.state.savingImage){
      this.uploadFile(file);
    }
    
  };

  async uploadFile(file) {
    try {
      log("saving", file);

      const data = new window.FormData();
      data.append("image", file);
      if (this.props.publisherId) {
        data.append("publisherId", this.props.publisherId);
      }

      this.setState({ savingImage: true, error: false });
      const res = await axios.post(this.props.postURL, data, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": file.type
        }
      });

      log("received response: %o", res);

      this.setState({
        error: false
      });

      if (this.props.afterUpload) {
        this.props.afterUpload(res.data);
      }
    } catch (err) {
      console.warn(err);
      this.setState({ error: err });
    }

    this.setState({ savingImage: false });
  }
}
