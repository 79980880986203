import gql from "graphql-tag";
import { AnnotationFragment } from "../fragments/AnnotationFragment";

export const AnnotationQuery = gql`
  query Annotation($id: Int!) {
    annotation(id: $id) {
      id
      ...Annotation
    }
    viewer {
      id
      name
      photo {
        id
        public_id
      }
    }
  }
  ${AnnotationFragment}
`;

export default AnnotationQuery;
