import gql from "graphql-tag";
import { StepResourcesFragment } from "./StepResourcesFragment";
import { StepQuestionBasicsFragment } from "./StepQuestionBasicsFragment";

export const EpisodeStepAdminFragment = gql`
  fragment EpisodeStepAdmin on Step {
    id
    title
    subtitle
    ...StepResources
    questions {
      id
      ...StepQuestionBasics
    }
    meta {
      ... on StepVideoMeta {
        video {
          id
          url
        }
      }
      ... on StepTextMeta {
        text
      }
      ... on StepImageMeta {
        image {
          id
          public_id
          width
          height
        }
      }
    }
  }
  ${StepResourcesFragment}
  ${StepQuestionBasicsFragment}
`;
