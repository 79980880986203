import React from "react";
import { Modal } from "../../Kit";
import {
    FlexButton,
} from "../index";
import GLOBAL_CONSTANTS from "../../constants/config";


class Popover extends React.Component {
    state = {
        showModal: true
    }
    render() {
        return (
            <Modal
                style={{ textAlign: "center" }}
                narrow
                onRequestClose={() => this.setState({ showModal: false })}
                isOpen={this.state.showModal}
            >
                <Modal.Header
                    style={{ textAlign: "center" }}
                    title="Enroll in an upcoming workshop to get started!"
                    onRequestClose={() => this.setState({ showModal: false })}
                />
                <Modal.Body>
                    <div style={{ textAlign: "center", color: "#f5821e", fontSize: "25px", fontWeight: "600" }}>Introduction to VTS Workshops</div>
                    <div style={{ padding: "1rem 3rem 2rem 3rem", fontSize: "18px", fontWeight: "400"}}>
                        November - January Schedule <br /> <br />
                        Nov 7, Nov 17 <br />
                        Dec 2, Dec 7 <br />
                        Jan 2, Jan 11, Jan 26, Jan 29
                    </div>
                    <FlexButton style={{minWidth: "15rem", width: "15rem", margin: "0 auto 10px auto", fontSize: "20px" }}>
                        <a
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "event",
                                    eventProps: {
                                        category: "Home page",
                                        action: "Webinar popup clicked",
                                        label: "Webinar popup",
                                        value: "true",
                                    },
                                });
                            }}
                            style={{ textDecoration: "none" }}
                            href="https://bsgouuxlaee.typeform.com/to/IDzqFaDl?typeform-source=www.watershed-ed.org"
                            target={"_blank"}
                        >
                            Review Details
                        </a>
                    </FlexButton>
                    {/* <FlexButton style={{ maxWidth: "370px", margin: "0 auto", fontSize: "20px" }}>
                        <a
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "event",
                                    eventProps: {
                                        category: "Home page",
                                        action: "Webinar popup clicked",
                                        label: "Webinar popup",
                                        value: "true",
                                    },
                                });
                            }}
                            style={{ textDecoration: "none" }}
                            href={GLOBAL_CONSTANTS.WEBINAR_URL_SEP_8}
                            download
                            target={"_blank"}
                        >
                            RSVP here for September 8th
                        </a>
                    </FlexButton> */}
                </Modal.Body>
            </Modal>
        )
    }
}

export default Popover;