import gql from "graphql-tag";
import { StepResourcesFragment } from "../fragments/StepResourcesFragment";

export const StepResourcesQuery = gql`
  query StepResources($id: Int!) {
    step(id: $id) {
      ...StepResources
    }
  }
  ${StepResourcesFragment}
`;
