import React from "react";
import Overlay from "../Kit/Overlay";
import UniversalPlayer from "../Playback/UniversalPlayer";
import styled from "styled-components/macro";
import { Flex, Text } from "../Kit";
import { CloseButton } from "../Kit/IconButton";

// type VideoType = {
//   id: string,
//   url: string
// };

// type Props = {
//   video: VideoType,
//   onRequestEnd: () => void
// };

// type State = {
//   loading: false,
//   error: boolean
// };

const VideoPlayer = styled.div`
  display: block;
  position: relative;
  padding-top: 64px;
  padding-bottom: 0;
  height: 100vh;
  background-color: black;
  width: 100vw;
`;

class ResourceVideo extends React.Component {
  state = {
    loading: true,
    error: false
  };

  render() {
    const { video, resource } = this.props;
    // const { loading, error } = this.state;

    return (
      <Overlay
        zIndex={6000}
        backgroundColor="rgba(0,0,0,0.9)"
        isOpen
        onClick={this.props.onRequestEnd}
      >
        {({ state, props }) => (
          <Flex
            role="dialog"
            tabIndex="-1"
            position="relative"
            aria-label={resource.title}
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100vw"
            {...props}
          >
            <VideoPlayer>
              <UniversalPlayer
                onError={this.onError}
                source={video.url}
                onEnded={this.onEnd}
                onReady={this.onReady}
                onTimeUpdate={this.onProgress}
              />
            </VideoPlayer>
            <Text
              fontSize={3}
              color="rgba(255,255,255,0.8)"
              style={{
                position: "fixed",
                top: "32px",
                transform: "translateY(-50%)",
                left: "16px",
                paddingRight: "32px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {resource.title}
            </Text>
            <CloseButton
              onClick={e => {
                e.stopPropagation();
                this.props.onRequestEnd();
              }}
              style={{ position: "fixed", top: "16px", right: "16px" }}
            />
          </Flex>
        )}
      </Overlay>
    );
  }

  onError = err => {
    this.setState({ error: err });
  };

  onReady = () => {
    this.setState({ loading: false });
  };

  onProgress = () => {};

  onEnded = () => {
    this.props.onRequestEnd();
  };
}

export default ResourceVideo;
