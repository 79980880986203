import gql from "graphql-tag";
import { StatusFragment } from "./StepStatusFragment";
import { AnnotationFragment } from "./AnnotationFragment";
import { QuestionFragment } from "./QuestionFragment";

export const EpisodeStepDetailsFragment = gql`
  fragment EpisodeStepDetails on Step {
    id
    title
    subtitle
    __typename
    resourceCount
    episodeStep {
      id
      orderNumber
      episode {
        id
        orderNumber
        title
      }
    }
    annotations(groupId: $groupId) {
      id
      ...Annotation
    }
    questions {
      __typename
      id
      ...Question
    }
    meta {
      __typename
      ... on StepVideoMeta {
        video {
          id
          url
          duration
        }
      }
      ... on StepTextMeta {
        text
      }
      ... on StepImageMeta {
        image {
          id
          public_id
          width
          height
        }
      }
    }
    status(enrollmentId: $enrollmentId) {
      id
      ...StepStatus
    }
  }
  ${StatusFragment}
  ${AnnotationFragment}
  ${QuestionFragment}
`;
