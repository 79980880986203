import * as React from "react";
import PreviewWrapper from "./PreviewWrapper";
import { ChatInputContainer, InputWrapper, Form } from "./style";
import linksDecorator from "./links-decorator";
import Input from "./ChatInput";
import { KeyBindingUtil } from "draft-js";
import { clearEditorContent } from "draftjs-utils";
import {
  RichText,
  Box,
  Button,
  IconButton,
  notify,
  Tooltip,
  Modal,
  TabButton,
  TabList
} from "../Kit";
import SelectAttachmentModal from "./SelectAttachment";
import RenderResource from "./RenderResource";
import RenderFile from "./RenderFile";
import axios from "axios";
import auth from "../Auth/auth";
import fileSelect from "file-select";
import debug from "debug";

const log = debug("app:Input:Index");

const CancelToken = axios.CancelToken;

// type State = {
//   isFocused: boolean,
//   mediaPreview: any,
//   showAttachmentPrompt: boolean,
//   selectedResource?: Object,
//   onSubmit: string => void,
//   selectedFile: Object | null,
//   selectedFileLoading: boolean,
//   expanded: boolean,
//   tab: "existing" | "upload",
//   hasText: boolean
// };

// type Props = {
//   networkDisabled: boolean,
//   state: Object,
//   onChange: Function,
//   enableSubmit?: boolean,
//   autoFocus?: boolean,
//   courseId?: number,
//   file?: Object,
//   saveOnReturn: boolean,
//   placeholder: string,
//   enableExpand: boolean,
//   rounded: boolean,
//   autoClear: boolean,
//   customMessage?: React.Element<any>,
//   onStartTyping?: Function,
//   onEndTyping?: Function
// };

export default class ChatInput extends React.Component {
  // editor: any;
  // cancel: Function;

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      isFocused: false,
      mediaPreview: "",
      showAttachmentPrompt: false,
      selectedFile: props.file,
      selectedResource: props.resource,
      tab: this.props.courseId ? "existing" : "upload",
      hasText: false
    };
  }

  static defaultProps = {
    saveOnReturn: true,
    autoClear: true,
    enableExpand: true,
    rounded: false,
    placeholder: "Add annotation..."
  };

  triggerFocus = () => {
    setTimeout(() => {
      this.editor && this.editor.focus();
    }, 0);
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.triggerFocus();
    }
  }

  expand = () => {
    console.log("expand");
  };

  render() {
    const { networkDisabled, state, isFullscreen, rounded } = this.props;
    let {
      selectedFile,
      selectedFileLoading,
      isFocused,
      selectedResource
    } = this.state;

    isFocused = isFocused && !this.state.expanded;

    const closeModal = {
      onRequestClose: () => this.setState({ showAttachmentPrompt: false })
    };

    const closeEditor = {
      onRequestClose: () => this.setState({ expanded: false })
    };

    return (
      <React.Fragment>
        <Modal
          {...closeEditor}
          style={{ zIndex: 5003 }}
          isOpen={this.state.expanded}
        >
          <Modal.Header {...closeEditor} />
          <Modal.Body>
            {this.state.expanded && (
              <ChatInput
                {...this.props}
                state={state}
                saveOnReturn={false}
                isFullscreen
                enableExpand={false}
                networkDisabled={networkDisabled}
                afterSubmit={() => {
                  this.setState({ expanded: false });
                }}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          {...closeModal}
          style={{ zIndex: 8000 }}
          isOpen={this.state.showAttachmentPrompt}
        >
          <Modal.Header
            title={this.props.courseId ? "Select a resource" : "Attach a file"}
            {...closeModal}
          />
          <Box p={2}>
            <Box
              mb={2}
              borderBottom={this.props.courseId ? "1px solid" : "none"}
              borderColor="borderColor"
            >
              <TabList>
                {this.props.courseId && (
                  <TabButton
                    onClick={() => {
                      this.setState({ tab: "existing" });
                    }}
                    active={this.state.tab === "existing"}
                  >
                    Existing resources
                  </TabButton>
                )}
                {this.props.courseId ? (
                  <TabButton
                    active={this.state.tab === "upload"}
                    onClick={() => {
                      this.attachFile();
                    }}
                  >
                    Upload a document
                  </TabButton>
                ) : (
                  <Button
                    appearance="primary"
                    onClick={() => {
                      this.attachFile();
                    }}
                  >
                    Upload a document
                  </Button>
                )}
              </TabList>
            </Box>
            {this.state.tab === "existing" && (
              <SelectAttachmentModal
                {...closeModal}
                courseId={this.props.courseId}
                onSelectAttachment={this.onSelectAttachment}
              />
            )}
          </Box>
        </Modal>
        {(selectedFile || selectedFileLoading) && (
          <PreviewWrapper
            borderRadius={rounded ? "16px 16px 0 0" : 0}
            onRequestDelete={this.removeResource}
          >
            <RenderFile
              rounded={rounded}
              file={selectedFile}
              isLoading={selectedFileLoading}
            />
          </PreviewWrapper>
        )}
        {selectedResource && (
          <PreviewWrapper
            borderRadius={rounded ? "16px 16px 0 0" : 0}
            onRequestDelete={this.removeResource}
          >
            <RenderResource resource={selectedResource} />
          </PreviewWrapper>
        )}
        <ChatInputContainer
          className={isFocused ? "focused" : ""}
          focus={isFocused}
        >
          <InputWrapper style={{ maxHeight: isFullscreen ? "none" : null }}>
            <Form focus={isFocused}>
              {!this.state.expanded && (
                <Input
                  focus={isFocused}
                  placeholder={this.props.placeholder}
                  editorState={state}
                  handleReturn={this.handleReturn}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  code={false}
                  editorRef={editor => (this.editor = editor)}
                  editorKey="chat-input"
                  decorators={[linksDecorator]}
                  networkDisabled={networkDisabled}
                  hasAttachment={!!selectedResource}
                />
              )}
            </Form>
          </InputWrapper>
          <Box width={1} flex="0 0 auto" p={"16px 19px"} pt={0}>
            <Box width={1} display="flex" justifyContent="space-between">
              <Box alignItems="center" display="flex" flex="0 0 auto">
                {this.props.enableExpand && (
                  <Box mr={1}>
                    {/* <Tooltip id="expand" placement="top" title="Expand editor"> */}
                      <IconButton onClick={this.expand} label="Expand">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-maximize"
                        >
                          <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
                        </svg>
                      </IconButton>
                    {/* </Tooltip> */}
                  </Box>
                )}

                {/* <Tooltip
                  placement="top"
                  id="add-resource"
                  title="Attach a resource"
                > */}
                  <IconButton
                    mx={1}
                    onClick={this.attachmentPrompt}
                    label="Attach a resource"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-paperclip"
                    >
                      <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                    </svg>
                  </IconButton>
                {/* </Tooltip> */}

                {this.props.customMessage}
              </Box>
              {this.props.enableSubmit && (
                <Button
                  color="blue.20"
                  size="tiny"
                  disabled={
                    !this.props.state.getCurrentContent().hasText() &&
                    !this.state.selectedResource &&
                    !this.state.selectedFile
                  }
                  onClick={this.submit}
                  appearance="ghost"
                >
                  SUBMIT
                </Button>
              )}
            </Box>
          </Box>
        </ChatInputContainer>
      </React.Fragment>
    );
  }

  expand = () => {
    this.setState({ expanded: true });
  };

  onSelectAttachment = attachment => {
    this.setState({
      selectedFile: null,
      showAttachmentPrompt: false,
      selectedResource: attachment
    });
  };

  attachFile = async () => {
    try {
      const file = await fileSelect({
        maxFileSize: 1024 * 1024 * 10,
        multiple: false
      });

      this.setState({ showAttachmentPrompt: false });

      if (!file) return;
      const size = file.size / 1024 / 1024;

      console.log(size);
      if (size > 5) {
        notify.send({
          message: "Please ensure that the file is less than 3 MB in size",
          type: "danger"
        });
        return;
      }

      this.uploadFile(file);
    } catch (err) {
      console.log("cancelled");
    }
  };

  uploadFile = async file => {
    const data = new window.FormData();
    data.append("file", file);
    const self = this;

    try {
      this.setState({ selectedResource: null, selectedFileLoading: true });
      const res = await axios.post("/upload/documents", data, {
        cancelToken: new CancelToken(function executor(c) {
          self.cancel = c;
        }),
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": file.type
        }
      });

      this.setState({ selectedResource: null, selectedFile: res.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        log("request cancelled");
      } else {
        notify.send({
          message: err.message,
          type: "danger"
        });
      }
    }

    this.setState({ selectedFileLoading: false });
  };

  removeResource = () => {
    if (this.state.selectedFileLoading) {
      if (this.cancel) {
        this.cancel();
      }
      this.setState({ selectedFileLoading: false });
    }

    this.setState({ selectedFile: null, selectedResource: null });
  };

  attachmentPrompt = () => {
    // images, other resources, your own upload
    this.setState({ showAttachmentPrompt: true });
  };

  handleKeyDown = event => {};

  onChange = (state, ...rest) => {
    const { onChange } = this.props;

    const content = state.getCurrentContent();
    const hasText = content.hasText();
    const plainText = content.getPlainText();

    if (this.props.maxLength && plainText.length >= this.props.maxLength) {
      return;
    }

    if (hasText && !this.state.hasText) {
      if (this.props.onStartTyping) {
        this.props.onStartTyping();
      }
      this.setState({ hasText: true });
    } else if (!hasText && this.state.hasText) {
      if (this.props.onEndTyping) {
        this.props.onEndTyping();
      }
      this.setState({ hasText: false });
    }

    // persistContent(state)
    onChange(state, ...rest);
  };

  handleReturn = e => {
    // submit on cmd+enter
    if (KeyBindingUtil.hasCommandModifier(e)) {
      return this.submit(e);
    }

    if (!this.props.saveOnReturn) {
      return "not-handled";
    }

    if (e.shiftKey) {
      return "not-handled";
    }

    return this.submit(e);
  };

  submit = e => {
    if (e) {
      e.preventDefault();
    }

    const { state } = this.props;

    // if the input is empty & we don't have any
    // attached resources, don't do anything
    if (
      !state.getCurrentContent().hasText() &&
      !this.state.selectedResource &&
      !this.state.selectedFile
    ) {
      return "handled";
    }

    if (this.props.onEndTyping) {
      this.props.onEndTyping();
    }

    this.props.onSubmit(RichText.serialize(state), {
      resource: this.state.selectedResource
        ? this.state.selectedResource
        : null,
      image: this.state.selectedFile
    });

    if (this.props.autoClear) {
      setTimeout(() => {
        this.setState({
          hasText: false,
          selectedResource: null,
          selectedFile: null
        });
        this.props.onChange(clearEditorContent(this.props.state));
        this.editor && this.editor.focus();
        if (this.props.afterSubmit) {
          this.props.afterSubmit();
        }
      });
    }

    return "handled";
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  componentWillUnmount() {
    if (this.props.onEndTyping) {
      this.props.onEndTyping();
    }
  }

  onFocus = () => {
    this.setState({
      isFocused: true
    });
  };
}
