import * as React from "react";
import {
  icons,
  ResourceItem,
  ResourceGalleryWrapper
} from "../Resources/ResourceList";

// type Props = {
//   resource: Object
// };

class RenderResource extends React.Component {
  render() {
    if (this.props.resource.meta.__typename === "ResourceGalleryMeta") {
      return (
        <ResourceGalleryWrapper resource={this.props.resource}>
          {() => null}
        </ResourceGalleryWrapper>
      );
    }

    return (
      <ResourceItem
        disabled={false}
        className="RenderResource"
        icon={icons[this.props.resource.meta.__typename](this.props.resource)}
        inverted={false}
        resource={this.props.resource}
        title={this.props.resource.title}
      >
        {() => <div />}
      </ResourceItem>
    );
  }
}

export default RenderResource;
