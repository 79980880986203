import gql from "graphql-tag";

export const EnrollmentEventFragment = gql`
  fragment EnrollmentEvent on Enrollment {
    id
    groupId
    permalink
    createdAt
    section {
      id
      title
      enrollmentStarts
      publisher {
        id
        name
        slug
        avatar {
          id
          public_id
        }
        image {
          id
          public_id
        }
      }

      image {
        id
        public_id
      }
      shortDescription
    }
  }
`;
