import React from "react";
import Box from "../Kit/Box";
import Heading from "../Kit/Heading";
import Button from "../Kit/Button";
import Text, { EmbedLink } from "../Kit/Text";
import { FadeImage } from "../Kit/FadeInImage";
import blur from "./blur.jpg";
import Raven from "raven-js";

// type Props = {
//   fullscreen?: boolean
// };

const NotFound = ({ fullscreen }) => (
  <Box
    display="flex"
    alignItems="center"
    minHeight={fullscreen ? "100vh" : "calc(100vh - 56px)"}
    width={1}
    position="relative"
    textAlign="center"
    p={4}
  >
    <FadeImage opacity={0.7} image={blur} />

    <Box pb={4} width={1} position="relative">
      <Heading mb={2} fontSize={"60px"}>
        404
      </Heading>
      <Box>
        <Box mb={3}>
          <Text fontSize={3} mx="auto" display="block" maxWidth="40rem">
            The page you are looking for doesn't appear to exist. If something
            seems wrong,{" "}
            <EmbedLink
              onClick={e => {
                e.preventDefault();
                Raven.showReportDialog();
              }}
              href="/help"
            >
              click here
            </EmbedLink>{" "}
            to get help.
          </Text>
        </Box>
        <Button mr={1} size="giant" appearance="primary" to="/">
          Take me home
        </Button>
      </Box>
    </Box>
  </Box>
);

export default NotFound;
