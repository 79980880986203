/*

What to do when receiving a message:

1. Is the conversation either not open or the window inactive?
  - Show the message in a dialog

2. Is the conversation already loaded and the conversation is not open?
  - Update the unread count

3. Is the chat open?
  - Add the message to the chat message query

4. Have we not loaded this conversation?
  - Load the conversation
  - Add this conversation to our conversation list


Queries:

1. Query a list of recent conversation ids that the user has been involved in.
2. For each conversation id, query conversation details (including unread count)
3. When viewing a chat, query the replies. 
*/

import * as React from "react";
import { Query, Subscription } from "react-apollo";
import Input from "../Kit/Input";
import ChatListItem from "./ChatListItem";
import Layer from "../Kit/Layer";
import Text from "../Kit/Text";
import { ChatHeadingButton } from "./ChatSummary";
import ChatBox from "./ChatBox";
import Box from "../Kit/Box";
import debug from "debug";
import { themeGet } from "@styled-system/theme-get";
import styled, { keyframes, css } from "styled-components/macro";
import ChatSubscriptionHandler from "./ChatSubscriptionHandler";
import { IconButton, Button } from "../Kit";
import { WithChatState } from "./chat-state";
import ListUsers from "../SelectUser/ListUsers";
import UserRow from "../SelectUser/UserRow";
import { Badge } from "../Kit/Badge";
import { GreenIndicator } from "./AvatarWithPresence";
import { RecentConversationsQuery } from "../API/queries/RecentConversations";
import { ConversationReplySubscription } from "../API/subscriptions/ConversationReplySubscription";

const log = debug("app:ChatManager");

const pulse = keyframes`
  0% {
    background-color: white;
  }
  50% {
    background-color: #eaf5ff;
  }
  100 {
    background-color: white;
  }

`;

const ChatHeadingPrimary = styled(ChatHeadingButton)`
  animation: ${props =>
    props.unread && props.collapsed
      ? css`
          ${pulse} 2s linear infinite
        `
      : "none"};
`;

class ChatHeader extends React.Component {
  state = {
    adding: false,
    query: "",
    addUsers: []
  };

  render() {
    const {
      totalUnread,
      onRequestToggle,
      onToggleAdding,
      isAdding,
      expanded
    } = this.props;

    return (
      <ChatHeadingPrimary
        role="button"
        px={1}
        unread={totalUnread > 0}
        collapsed={!expanded}
        border="none"
        borderBottom="1px solid"
        borderColor={expanded ? "borderColor" : "transparent"}
        onClick={() => {
          if (isAdding) return;
          onRequestToggle();
        }}
      >
        {!isAdding && (
          <GreenIndicator
            style={{
              width: "9px",
              marginLeft: "4px",
              marginRight: "2px",
              height: "9px",
              position: "static"
            }}
          />
        )}

        <Text flex="1" px={1} ellipsis fontWeight="600">
          {isAdding ? "Select users" : "Chat"}
        </Text>

        {!isAdding && totalUnread > 0 && (
          <Badge
            style={{ borderRadius: "2px" }}
            bg="blue.20"
            flex="0 0 auto"
            color="blue.1"
            ml={1}
            mr={1}
          >
            {totalUnread}
          </Badge>
        )}

        {isAdding ? (
          <Button
            onClick={e => {
              e.stopPropagation();
              onToggleAdding(false);
            }}
            size="small"
          >
            Cancel
          </Button>
        ) : (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onToggleAdding(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-plus"
            >
              <line x1="12" y1="5" x2="12" y2="19" />
              <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
          </IconButton>
        )}
      </ChatHeadingPrimary>
    );
  }
}

class ChatManager extends React.Component {
  state = {
    expanded: localStorage.getItem("chat-expanded") ? true : false,
    isAdding: false,
    addUsers: [],
    query: "",
    unreadCount: {}
  };

  toggle = () => {
    const nextExpanded = !this.state.expanded;
    this.setState({ expanded: nextExpanded });
    if (nextExpanded) {
      localStorage.setItem("chat-expanded", true);
    } else {
      localStorage.removeItem("chat-expanded");
    }
  };

  updateUnread = (id, count) => {
    log("update unread: %s, %s", id, count);
    this.setState({
      unreadCount: {
        ...this.state.unreadCount,
        [id]: count
      }
    });
  };

  render() {
    return (
      <WithChatState>
        {chats => {
          const totalUnread = Object.keys(chats.state.unreadChats).reduce(
            (accumulator, currentValue) =>
              accumulator + chats.state.unreadChats[currentValue],
            0
          );

          return (
            <Query query={RecentConversationsQuery}>
              {({ loading, error, data }) => {
                if (loading) {
                  return null;
                }

                if (error) {
                  return null;
                }

                return (
                  <Box
                    position="fixed"
                    bottom="0"
                    left="0"
                    width="100vw"
                    style={{
                      zIndex: 500,
                      pointerEvents: "none"
                    }}
                    flexDirection="row-reverse"
                    alignItems="flex-end"
                    display={["none", "flex"]}
                  >
                    <Layer
                      overflow="hidden"
                      ml={1}
                      width={this.state.expanded ? "350px" : "200px"}
                      elevation={4}
                      mr={3}
                      pt="40px"
                      position="relative"
                      borderRadius="8px 8px 0 0"
                      style={{
                        pointerEvents: "auto",
                        transition: "width 0.25s ease, height 0.25s ease",
                        borderRadius: "8px 8px 0 0"
                      }}
                      height={this.state.expanded ? "300px" : "40px"}
                      display="block"
                    >
                      <Box
                        position="absolute"
                        top="0"
                        style={{ zIndex: 500 }}
                        width={1}
                      >
                        <ChatHeader
                          totalUnread={totalUnread}
                          isAdding={this.state.isAdding}
                          expanded={this.state.expanded}
                          onRequestToggle={this.toggle}
                          onToggleAdding={isAdding => {
                            this.setState({ isAdding, expanded: true });
                          }}
                        />
                      </Box>
                      <Box height="100%">
                        {this.state.isAdding && (
                          <Box
                            bg="white"
                            position="absolute"
                            top="40px"
                            display="flex"
                            flexDirection="column"
                            width={1}
                            bottom="0"
                            style={{ zIndex: 400 }}
                          >
                            <Box position="relative" flex="0 0 auto">
                              <Box
                                pr="140px"
                                p={this.state.addUsers.length > 0 ? 1 : 0}
                                display="flex"
                                flexWrap="wrap"
                              >
                                {this.state.addUsers.map((user, i) => (
                                  <Button
                                    m="2px"
                                    size="tiny"
                                    flex="2"
                                    onClick={() => {
                                      this.setState(state => {
                                        state.addUsers.splice(i, 1);
                                        return state;
                                      });
                                    }}
                                    style={{
                                      minWidth: "70px",
                                      maxWidth: "100px",
                                      paddingRight: "18px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden"
                                    }}
                                    appearance="success"
                                    key={user.id}
                                  >
                                    {user.name}
                                    <svg
                                      style={{
                                        position: "absolute",
                                        right: "3px",
                                        top: "5px"
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-x"
                                    >
                                      <line x1="18" y1="6" x2="6" y2="18" />
                                      <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                  </Button>
                                ))}

                                <Box position="absolute" top="5px" right="8px">
                                  <Button
                                    disabled={this.state.addUsers.length === 0}
                                    appearance="primary"
                                    size="small"
                                    onClick={() => {
                                      chats.openChatWithUserIds(
                                        this.state.addUsers.map(user => user.id)
                                      );
                                      this.setState({
                                        addUsers: [],
                                        isAdding: false
                                      });
                                    }}
                                  >
                                    Create chat
                                  </Button>
                                </Box>
                              </Box>
                              <Input
                                placeholder="Search by name or email"
                                autofocus
                                flex="1"
                                hideLabel
                                label="Filter results"
                                style={{ marginBottom: "0" }}
                                inputStyle={{
                                  margin: 0,
                                  padding: "8px 16px",
                                  boxShadow: "none",
                                  borderRadius: 0,
                                  borderBottom: "1px solid #eee"
                                }}
                                value={this.state.query}
                                onChange={e => {
                                  this.setState({ query: e.target.value });
                                }}
                              />
                            </Box>
                            <Box flex="1" overflow="auto">
                              <ListUsers
                                query={this.state.query}
                                render={users =>
                                  users.map(user => (
                                    <UserRow
                                      key={user.id}
                                      user={user}
                                      onAdd={() => {
                                        // this.setState({ add: false });
                                        // chats.openChatWithUserIds([user.id]);
                                        this.setState(state => {
                                          state.addUsers.push(user);
                                          return state;
                                        });
                                      }}
                                    />
                                  ))
                                }
                              />
                            </Box>
                          </Box>
                        )}
                        <Box height={"100%"} overflow="auto">
                          <React.Fragment>
                            <Subscription
                              subscription={ConversationReplySubscription}
                            >
                              {({ data: subData, loading, error }) => {
                                log("received data: %o", data);
                                return (
                                  <ChatSubscriptionHandler
                                    chatisOpenFn={chats.chatIsOpen}
                                    openChats={chats.state.openChats}
                                    subscribedChatIds={data.conversations}
                                    onRequestShow={conversationId => {
                                      chats.openChatById(conversationId);
                                    }}
                                    conversationReply={
                                      subData ? subData.conversationReply : null
                                    }
                                  />
                                );
                              }}
                            </Subscription>

                            {data.conversations.length === 0 && (
                              <Box style={{ lineHeight: "1.2" }} p={2}>
                                <Text fontSize={0} color="faded">
                                  Chat enables you to have private conversations
                                  with users or groups of users. Try using it to
                                  ask your instructor questions or discuss
                                  topics with course collaborators.
                                </Text>
                                <Box mt={2}>
                                  <Button
                                    onClick={() => {
                                      this.setState({ isAdding: true });
                                    }}
                                    size="small"
                                  >
                                    Create your first chat
                                  </Button>
                                </Box>
                              </Box>
                            )}

                            {data.conversations.map(conversationId => (
                              <ChatListItem
                                key={conversationId}
                                chats={chats}
                                updateUnread={this.updateUnread}
                                conversationId={conversationId}
                                onRequestOpen={(cId, userIds) => {
                                  log("open chat: %s", cId);
                                  chats.openChatById(cId, userIds);
                                }}
                              />
                            ))}
                          </React.Fragment>
                        </Box>
                      </Box>
                    </Layer>

                    {chats.state.openChats.map(
                      ({ isCollapsed, userIds, uid, conversationId }, i) => (
                        <ChatBox
                          onRequestClose={() => chats.closeChatByIndex(i)}
                          key={uid}
                          uid={uid}
                          userIds={userIds}
                          chats={chats}
                          subscribedChatIds={data.conversations}
                          onToggleCollapse={() => {
                            chats.toggleChatCollapseByIndex(i);
                          }}
                          isCollapsed={isCollapsed}
                          conversationId={conversationId}
                        />
                      )
                    )}
                  </Box>
                );
              }}
            </Query>
          );
        }}
      </WithChatState>
    );
  }
}

export default ChatManager;
