import * as React from "react";
import { Text, Box } from "../Kit";
import { itemStyles, Label, IconWrapper } from "../Resources/ResourceList";
import styled from "styled-components/macro";

const Link = styled.a`
  display: block;
  outline: none;
  overflow: hidden;
  border-radius: ${props => props.radius};
  ${itemStyles};
`;

class RenderFile extends React.Component {
  renderLabel() {
    if (this.props.isLoading) {
      return (
        <Box px={2} height="50px" display="flex" alignItems="center">
          <Text faded>Uploading document...</Text>
        </Box>
      );
    }

    return (
      <Box>
        <Link
          rounded={this.props.rounded}
          radius={
            this.props.radius || (this.props.rounded ? "16px 16px 0 0" : 0)
          }
          href={this.props.file.url}
          target="_blank"
        >
          <IconWrapper>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-file"
            >
              <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
              <polyline points="13 2 13 9 20 9" />
            </svg>
          </IconWrapper>
          <Label>
            <Text ellipsis>{this.props.file.original_filename}</Text>
          </Label>
        </Link>
      </Box>
    );
  }

  render() {
    return <Box>{this.renderLabel()}</Box>;
  }
}

export default RenderFile;
