import gql from "graphql-tag";

export interface UserBasicsType {
  id: number;
  name: string;
  photo: {
    id: number;
    public_id: string;
  };
}

export const UserBasicsFragment = gql`
  fragment UserBasics on User {
    id
    name
    photo {
      id
      public_id
    }
  }
`;
