import React from "react";
import { Box, Text } from "../Kit";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { themeGet } from "@styled-system/theme-get";

const activeClassName = "nav-item-active";

const SideNavLink = styled(NavLink).attrs({
  activeClassName
})`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 28px;
  text-decoration: none;
  // border-radius: 8px;
  :hover {
    background: ${themeGet("colors.gray.2")};
  }
  &.${activeClassName} {
    background: ${themeGet("colors.gray.2")};
  }
  &.${activeClassName} > * {
    color: ${themeGet("colors.black")} !important;
    font-weight: 600 !important;
  }
`;

const DashboardNavItem = ({ onClick, extra, icon, title, to, ...other }) => (
  <SideNavLink onClick={onClick} to={to} {...other}>
    <Box
      display="flex"
      justifyContent="center"
      flex="0 0 26px"
      color="black"
      w="26px"
    >
      {icon}
    </Box>
    <Text ellipsis ml={2}>
      {title}
    </Text>
    {extra}
  </SideNavLink>
);

export default DashboardNavItem;
