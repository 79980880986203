import gql from "graphql-tag";
import { ResourceFragment } from "./ResourceFragment";

export const CommentFragment = gql`
  fragment Comment on Comment {
    id
    createdAt
    ratingCount
    likedByViewer
    content
    image {
      id
      original_filename
      url
    }
    resource {
      id
      ...Resource
    }
    createdBy {
      id
      name
      photo {
        id
        public_id
      }
    }
  }
  ${ResourceFragment}
`;
