import * as React from "react";
import Raven from "raven-js";
import { Subscription } from "react-apollo";
import {
  requestNotificationPermission,
  notify as pushNotify
} from "./push-notify";
import debug from "debug";
import { getSummary } from "./event-objects";
import toast from "../Kit/Toast";
import Alert from "../Kit/Alert";
import Theme from "../Kit/Theme";
import styled, { ThemeProvider } from "styled-components/macro";
import { NotificationSubscription } from "../API/subscriptions/NotificationSubscription";

const log = debug("app:PushNotifications");

const StyledLink = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
`;

const AlertWithLink = ({ onClose, url, ...other }) => {
  return (
    <StyledLink onClick={onClose} href={url}>
      <ThemeProvider theme={Theme}>
        <Alert
          onClose={onClose}
          style={{
            maxWidth: "400px"
          }}
          {...other}
        />
      </ThemeProvider>
    </StyledLink>
  );
};

// type Props = {
//   notification?: Object
// };

// type State = {
//   pushEnabled: boolean,
//   visible: boolean
// };

class PushNotification extends React.Component {
  state = {
    pushEnabled: false,
    visible: document ? !!document.hidden : false
  };

  async componentDidMount() {
    log("mounted push notifications: %o", this.props);

    document.addEventListener(
      "visibilitychange",
      this.handleVisiblityChange,
      false
    );

    try {
      const enabled = await requestNotificationPermission();
      log("push notifications enabled?: %s", enabled);
      this.setState({ pushEnabled: enabled });
    } catch (err) {
      console.error(err);
      Raven.captureException(err);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisiblityChange,
      false
    );
  }

  componentDidUpdate(next) {
    if (this.props.notification) {
      if (this.props.notification !== next.notification) {
        this.notify(this.props.notification);
      }
    }
  }

  handleVisiblityChange = () => {
    this.setState({ visible: !!document.hidden });
  };

  notify = note => {
    log("notify user: %o", note);
    let summary = null;

    try {
      summary = getSummary(note);
    } catch (err) {
      console.error(err);
      return;
    }

    if (summary) {
      log("summary: %o", summary);
      if (this.state.visible) {
        toast.notify(
          ({ onClose }) => {
            return (
              <AlertWithLink
                onClose={onClose}
                img={{
                  src: summary.image
                }}
                title={summary.body}
                url={summary.url}
              />
            );
          },
          {
            position: "bottom-left",
            duration: 5000
          }
        );
      } else {
        pushNotify({
          title: summary.title,
          body: summary.body,
          path: summary.url,
          image: summary.image
        });
      }
    }
  };

  render() {
    return null;
  }
}

const PushNotificationWithData = () => (
  <Subscription subscription={NotificationSubscription}>
    {({ data, loading }) => {
      return (
        <PushNotification
          notification={data ? data.notification : null}
          loading={loading}
        />
      );
    }}
  </Subscription>
);

export default PushNotificationWithData;
