import React from "react";
import { ApolloProvider } from "react-apollo";
import Helmet from "react-helmet";
import styled, { ThemeProvider } from "styled-components/macro";
import { Provider } from "unstated";
import Theme from "./Kit/Theme";
import "normalize.css";
import "./Boot.css";
import auth from "./Auth/auth";
import config from "./config";
import debug from "debug";
import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

const log = debug("app:Boot");

if (window.Stripe) {
  window.stripe = window.Stripe(config.stripePublishableKey);
} else {
  console.warn("Global stripe not found.");
}

const SharedStyles = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  & * {
    box-sizing: border-box;
  }
  & *:before {
    box-sizing: border-box;
  }
  & *:after {
    box-sizing: border-box;
  }
`;

if (process.env.NODE_ENV === "development") {
  localStorage.debug = "app:*";
}

export default client => {
  return class Boot extends React.PureComponent {
    state = {
      auth: auth.isAuthenticated
    };

    componentDidMount() {
      auth.onChange(this.onUserChange);
    }

    onUserChange = () => {
      log("on user change: %s", auth.isAuthenticated);
      this.setState({ auth: auth.isAuthenticated });
    };

    componentWillUnmount() {
      auth.unsubscribe(this.onUserChange);
    }

    render() {
      return (
        <SharedStyles>
          <Helmet titleTemplate="%s | Watershed" />
          <Provider>
            <ApolloProvider client={client}>
              <ThemeProvider theme={Theme}>
                <React.Fragment>
                  {this.props.children(this.state.auth)}
                </React.Fragment>
              </ThemeProvider>
            </ApolloProvider>
          </Provider>
        </SharedStyles>
      );
    }
  };
};
