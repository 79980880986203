import * as React from "react";
import { SIZES } from "../Kit/Image";
import { Text, Avatar, Box, Heading, Image, Button } from "../Kit";
import { WithChatState } from "./chat-state";

const UserCard = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <WithChatState>
      {chats => (
        <Box alignItems="center" display="flex" width="100%">
          <Box maxWidth="200px" overflow="hidden" flex="0 0 auto">
            <Avatar
              rounded={false}
              size="giant"
              hideTooltip
              style={{ borderRadius: "8px 0 0 8px" }}
              img={user.photo}
              name={user.name}
            />
          </Box>
          <Box textAlign="left" ml={2} pr={2} alignItems="center">
            <Box>
              <Heading fontSize={4}>{user.name}</Heading>
            </Box>
            <Button
              onClick={e => {
                e.stopPropagation();
                chats.openChatWithUserIds([user.id]);
              }}
              mt={"12px"}
              ml="auto"
              appearance="primary"
            >
              Send message
            </Button>
          </Box>
        </Box>
      )}
    </WithChatState>
  );
};

export default UserCard;
