import React from "react";
import { Box, Flex, Text, TableContainer, Table, UppercaseLabel } from "../Kit";
import DashboardNavItem from "./DashboardNavItem";
import { SearchResult } from "../Search";
import UserList from "../Search/UserList";
import queryString from "query-string";
import moment from "moment";

// type Props = {
//   viewer: Object,
//   onClickBrowse?: Function
// };

const DashboardNav = ({ viewer, onClickBrowse }) => {
  if (!viewer) {
    return null;
  }

  return (
    <Box>
      <DashboardNavItem
        to="/"
        exact
        onClick={onClickBrowse}
        isActive={(match, location) => {
          if (!match) return false;
          const qs = queryString.parse(location.search);
          if (!qs.tab) return true;
        }}
        title="Dashboard"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
            <polyline points="9 22 9 12 15 12 15 22" />
          </svg>
        }
      />
      {/* <DashboardNavItem
        to="/?tab=discover"
        exact
        isActive={(match, location) => {
          const qs = queryString.parse(location.search);
          if (qs.tab === "discover") return true;
        }}
        title="Discover"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-eye"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
            <circle cx="12" cy="12" r="3" />
          </svg>
        }
      /> */}
      {viewer.isAdmin && (
        <DashboardNavItem
          to="/admin"
          exact
          title="Admin"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-lock"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          }
        />
      )}
      <DashboardNavItem
        to="/public"
        exact
        isActive={(match, location) => {
          const qs = queryString.parse(location.search);
          if (qs.tab === "discover") return true;
        }}
        title="Watershed-ed.org"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-eye"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
            <circle cx="12" cy="12" r="3" />
          </svg>
        }
      />
      
      <Box
        mt={2}
        pb={1}
        width={1}
        pl={"18px"}
        borderTop="1px solid"
        borderColor="rgba(0,0,0,0.05)"
        pt={2}
     >
       {/* <DashboardNavItem
        to="/dashboard"
        exact
        isActive={(match, location) => {
          const qs = queryString.parse(location.search);
          if (qs.tab === "discover") return true;
        }}
        title="Your modules"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-lock"
          >
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
            <path d="M7 11V7a5 5 0 0 1 10 0v4" />
          </svg>
        }
      /> */}
        <UppercaseLabel>Your modules</UppercaseLabel>
      </Box>
      <TableContainer>
        <Table>
          {(viewer.enrollments.length === 0 || 
            (viewer.enrollments.length === 1 && 
              viewer.enrollments[0].disabled)) && (
            <Box px={"24px"}>
              <Text faded>
                You are currently not enrolled in any courses. Your courses will
                appear here after enrolling in your first course.
              </Text>
            </Box>
          )}
          {viewer.enrollments
            .filter(enrollment => !enrollment.isDeleted && !enrollment.disabled)
            .filter(enrollment => {
              if (
                enrollment.subscription &&
                enrollment.subscription.cancelled_at
              ) {
                return false;
              }
              return true;
            })
            .map((enrollment, i) => {
              const users = enrollment.group.enrollments.map(
                enrollment => enrollment.user
              );
              return (
                <SearchResult
                  ml={1}
                  style={{
                    paddingLeft: "12px",
                    paddingRight: "12px"
                  }}
                  key={enrollment.id}
                  url={`/learn/${enrollment.groupId}/${enrollment.id}`}
                  title={enrollment.section.title}
                  subtitle={enrollment.section.publisher.name}
                  image={enrollment.section.image}
                >
                  <UserList users={users} exclude={viewer} limit={5} />
                </SearchResult>
              );
            })}
        </Table>
      </TableContainer>

      {viewer.coaching.length > 0 && (
        <Box>
          <Flex
            mt={2}
            pt={2}
            borderTop="1px solid"
            borderColor="rgba(0,0,0,0.05)"
            pl={"18px"}
            pb={1}
            width={1}
            align="flex-end"
            justify="space-between"
          >
            <UppercaseLabel>Instructing</UppercaseLabel>
          </Flex>
          <TableContainer>
            <Table>
              {viewer.coaching
                .filter(coach => !coach.isDeleted)
                .map((result, i) => {
                  // const isLast = i === viewer.coaching.length - 1;
                  return (
                    <SearchResult
                      ml={1}
                      key={result.id}
                      style={{
                        paddingLeft: "12px",
                        paddingRight: "12px"
                      }}
                      url={`/coach/${result.section.slug}`}
                      title={result.section.title}
                      subtitle={moment(result.section.createdAt).format(
                        "MMMM Do YYYY"
                      )}
                      image={result.section.image}
                    />
                  );
                })}
            </Table>
          </TableContainer>
        </Box>
      )}

      {viewer.publishers.length > 0 && (
        <Box>
          <Flex
            mt={2}
            pt={2}
            borderTop="1px solid"
            borderColor="rgba(0,0,0,0.05)"
            pl={"18px"}
            pb={1}
            width={1}
            align="flex-end"
            justify="space-between"
          >
            <UppercaseLabel>Admin</UppercaseLabel>
          </Flex>
          <TableContainer>
            <Table>
              {viewer.publishers.map(({ publisher }, i) => {
                const users = publisher.members.map(member => member.user);
                const isLast = i === viewer.publishers.length - 1;

                return (
                  <SearchResult
                    ml={1}
                    key={publisher.id}
                    style={{
                      paddingLeft: "12px",
                      paddingRight: "12px"
                    }}
                    url={`/admin/schools/${publisher.slug}`}
                    title={publisher.name}
                    subtitle={publisher.tagline}
                    image={publisher.avatar}
                  >
                    <UserList users={users} exclude={viewer} limit={5} />
                  </SearchResult>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default DashboardNav;
