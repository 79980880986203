import * as React from "react";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import DraftEditor from "./draft-js-plugins-editor";
import { InputWrapper } from "./style";
import customStyleMap from "./custom-style-map";
// import type DraftEditorState from "draft-js/lib/EditorState";

// type Props = {
//   editorState: DraftEditorState,
//   onChange: DraftEditorState => void,
//   placeholder: string,
//   className?: string,
//   focus?: boolean,
//   readOnly?: boolean,
//   editorRef?: any => void,
//   networkDisabled: boolean,
//   children?: React.Node,
//   hasAttachment?: boolean
// };

// type State = {
//   plugins: Array<mixed>
// };

class Input extends React.Component {
  // editor: any;
  constructor(props) {
    super(props);
    this.state = {
      plugins: [
        createLinkifyPlugin({
          target: "_blank"
        })
      ]
    };
  }

  setRef = editor => {
    const { editorRef } = this.props;
    this.editor = editor;
    if (editorRef && typeof editorRef === "function") {
      editorRef(editor);
    }
  };

  render() {
    const {
      editorState,
      onChange,
      focus,
      placeholder,
      readOnly,
      editorRef,
      networkDisabled,
      children,
      hasAttachment,
      ...rest
    } = this.props;
    const { plugins } = this.state;

    return (
      <InputWrapper
        hasAttachment={hasAttachment}
        focus={focus}
        networkDisabled={networkDisabled}
      >
        {children}
        <DraftEditor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          editorRef={this.setRef}
          readOnly={readOnly}
          placeholder={!readOnly && placeholder}
          spellCheck={true}
          autoCapitalize="sentences"
          autoComplete="on"
          autoCorrect="on"
          stripPastedStyled={true}
          customStyleMap={customStyleMap}
          {...rest}
        />
      </InputWrapper>
    );
  }
}

export default Input;
