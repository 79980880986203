import * as React from "react";
import styled, { css } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import { Box, Text, Button, Flex, Dropdown, IconButton } from "../Kit";
import { Link } from "react-router-dom";
import MoreVerticalIcon from "@atlaskit/icon/glyph/more-vertical";

// type Gallery = {
//   id: number,
//   title: string,
//   description: string,
//   onSelect: () => void
// };

// type Props = {
//   selected: number,
//   galleries: Array<Gallery>,
//   onRequestEdit: () => void,
//   onRequestRemove: () => void
// };

class GalleryList extends React.Component {
  render() {
    const { selected, galleries, onRequestRemove } = this.props;

    const list = galleries.map(gallery => (
      <GalleryItem
        selected={gallery.id === selected}
        to={`/gallery/${gallery.id}`}
        gallery={gallery}
        key={gallery.id}
      >
        <Flex flex="1 0 auto" justifyContent="flex-end">
          <Button
            style={{
              visibility: selected === gallery.id ? "hidden" : "visible"
            }}
            mr={1}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.select(gallery);
            }}
          >
            Select
          </Button>
          <Dropdown
            trigger={
              <IconButton label="Show options">
                <MoreVerticalIcon label="Show options" />
              </IconButton>
            }
          >
            <Box>
              {this.props.onRequestEdit && (
                <Dropdown.Item
                  onClick={e => {
                    // e.stopPropagation();
                    e.preventDefault();
                    this.edit(gallery);
                  }}
                  href="#"
                >
                  Edit
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={e => {
                  // e.stopPropagation();
                  e.preventDefault();
                  onRequestRemove(gallery);
                }}
                href="#"
              >
                Remove
              </Dropdown.Item>
            </Box>
          </Dropdown>
        </Flex>
      </GalleryItem>
    ));

    return list;
  }

  select = gallery => {
    this.props.onSelect(gallery);
  };

  edit = gallery => {
    this.props.onRequestEdit(gallery);
  };
}

const GalleryItemStyled = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 50px;
  border-bottom: 1px solid;
  border-color: ${themeGet("colors.borderColor")};
  :last-child {
    border-bottom: none;
  }
  text-decoration: none;
  :hover {
    background-color: ${themeGet("colors.blue.1")};
  }
  ${props => {
    if (props.selected) {
      return css`
        background-color: ${themeGet("colors.blue.20")};
        :hover {
          background-color: ${themeGet("colors.blue.20")};
        }
      `;
    }
  }};
`;

const GalleryItem = ({ selected, to, gallery, children }) => (
  <GalleryItemStyled selected={selected} to={to}>
    <Text
      color={selected ? "white" : null}
      pr={1}
      flex="0 0 auto"
      fontWeight={500}
      ellipsis
    >
      {gallery.title}
    </Text>
    <Text pr={1} ellipsis faded>
      {gallery.description}
    </Text>
    {children}
  </GalleryItemStyled>
);

export default GalleryList;
