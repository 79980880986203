import React from "react";
import Text from "./Text";

export const Badge = ({ children, style = {}, ...other }) => (
  <Text
    bg="gray.4"
    borderRadius="10px"
    color="gray.17"
    fontWeight="bold"
    px={"6px"}
    py="1px"
    style={{
      fontSize: "11px",
      lineHeight: "1.5",
      display: "inline-block",
      textTransform: "uppercase",
      borderRadius: "32px",
      ...style
    }}
    {...other}
  >
    {children}
  </Text>
);
