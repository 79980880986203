import React from "react";
import styled from "styled-components/macro";
import { Waypoint } from "react-waypoint";

import { imageURLString } from "./Image";

const getImageURL = (image, other = {}) =>
  imageURLString(image, "cover", {
    effect: "grayscale",
    ...other
  });

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: all 1000ms ease-out;
`;

const Img = styled.div`
  background-size: cover;
  background-position: center center;
  position: absolute;
  transition: transform 1000ms ease-out;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.1;
`;

// type ImageType = {
//   public_id: string
// };

// type Props = {
//   image: ImageType | string,
//   blend: string,
//   enableLeave: boolean,
//   opacity: number
// };

// type State = {
//   loaded: boolean,
//   error?: boolean
// };

export class FadeImage extends React.Component {
  static defaultProps = {
    blend: "rgb(250, 251, 251)",
    opacity: 0.1,
    effects: {},
    enableLeave: false
  };

  state = {
    loaded: false
  };

  onEnter = () => {
    this.setState({ loaded: true });
  };

  onLeave = () => {
    if (this.props.enableLeave) {
      this.setState({ loaded: false });
    }
  };

  render() {
    const { blend, opacity, effects } = this.props;
    const { loaded } = this.state;
    return (
      <Waypoint
        bottomOffset="150px"
        onLeave={this.onLeave}
        onEnter={this.onEnter}
      >
        <Container
          style={{
            background: blend,
            opacity: loaded ? 1 : 0
          }}
        >
          <Img
            style={{
              opacity,
              // transform: loaded ? "scale(1.1)" : "scale(1)",
              backgroundImage:
                typeof this.props.image === "string"
                  ? `url(${this.props.image})`
                  : getImageURL(this.props.image, effects)
            }}
          />
        </Container>
      </Waypoint>
    );
  }
}
