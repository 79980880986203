import * as React from "react";
import styled from "styled-components/macro";
import Card from "../Kit/Card";
import Media from "react-media";
import { Embed, Box, Text, Avatar, NavLink } from "../Kit";
import Image, { SIZES } from "../Kit/Image";
import Cost from "../Kit/Cost";
import { isNumber } from "lodash";

const BorderImage = styled(Image)`
  border-radius: ${props => (props.embed ? "0" : "4px 4px 0 0")};
  display: block;
  @media screen and (min-width: 40em) {
    border-radius: ${props => (props.embed ? "0 0 0 4px 0" : "0 4px 4px 0")};
    width: auto;
  }
`;

export const Attribution = ({ name, image, to, inverted }) => {
  const el = (
    <Box width={1} style={{ maxWidth: "80vw", overflow: "hidden" }}>
      <Box mb={2} alignItems="center" display="flex">
        <Box flex="0 0 auto">
          <Avatar hideTooltip alt={name} name={name} img={image} />
        </Box>
        <Text
          style={{ textDecoration: "none" }}
          color={inverted ? "gray.0" : "black"}
          fontWeight={500}
          ml={1}
          ellipsis
        >
          {name}
        </Text>
      </Box>
    </Box>
  );

  if (to) {
    return (
      <NavLink className="nav-link" style={{ textDecoration: "none" }} to={to}>
        {el}
      </NavLink>
    );
  }
  return el;
};

export const EnrollmentDate = ({ enrollmentEnds, enrollmentStarts }) => {
  if (!enrollmentEnds && !enrollmentStarts) return null;
  return null;
};

// type Props = {
//   to: string,
//   footer?: React.Node,
//   subtitle?: React.Node,
//   attribution?: React.Node,
//   image: string | Image,
//   style?: Object,
//   title: string,
//   subtitle: string,
//   children?: React.Node,
//   cost?: string,
//   embed?: boolean
// };

class GenericCard extends React.Component {
  render() {
    const {
      to,
      footer,
      image,
      title,
      subtitle,
      attribution,
      embed,
      style,
      cost
    } = this.props;

    return (
      <Media query="(min-width: 40em)">
        {match => {
          const dimensions = match ? SIZES.square : SIZES.landscape;

          return (
            <Card
              elevation={0}
              style={{
                overflow: "hidden",

                boxShadow: embed ? "none" : null,

                ...style,
                borderRadius: embed ? "0 0 8px 8px" : null
              }}
              to={to}
            >
              <Box
                position="relative"
                width={1}
                flexDirection="row-reverse"
                display={["block", "flex"]}
              >
                <Box display="flex" flex={[1, "0 0 33.3333%"]}>
                  <Embed dimensions={dimensions}>
                    <BorderImage embed={embed} {...dimensions} img={image} />
                  </Embed>
                </Box>
                <Box
                  alignSelf="stretch"
                  flex={1}
                  display="flex"
                  width={1}
                  style={{ overflow: "hidden" }}
                  flexDirection="column"
                >
                  <Box flex={1} p={2}>
                    {attribution}
                    <Text fontSize={3} compressed fontWeight={500}>
                      {title}
                    </Text>

                    <Text mt={1} faded display="block" fontSize={1}>
                      {subtitle}
                    </Text>
                  </Box>
                  {footer && (
                    <Box alignItems="center" py={2} px={2} display="flex">
                      {footer}
                    </Box>
                  )}
                </Box>

                {cost && (
                  <Box position="absolute" top="16px" right="12px">
                    <Text
                      fontSize={2}
                      bg="white"
                      p={"4px"}
                      style={{ borderRadius: "2px" }}
                      color="blue.22"
                      fontWeight={600}
                    >
                      {cost}
                    </Text>
                  </Box>
                )}
              </Box>
            </Card>
          );
        }}
      </Media>
    );
  }
}

export default GenericCard;
