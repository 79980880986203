import React from "react"; // eslint-disable-line
import { css } from "styled-components/macro";
import Box from "./Box";
import styled from "styled-components";

const EmbedResponsive = styled(Box)`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  & > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  ${props => {
    if (props.dimensions) {
      const percentage =
        (props.dimensions.height / props.dimensions.width) * 100;
      return css`
        :before {
          display: block;
          content: "";
          padding-top: ${percentage}%;
        }
      `;
    }
  }};
`;

export default EmbedResponsive;
