import gql from "graphql-tag";

export const UserPresenceQuery = gql`
  query UserPresence($userId: Int!) {
    user(id: $userId) {
      id
      isOnline
      name
      photo {
        id
        public_id
      }
    }
  }
`;
