import React from "react";
import { Box, Text } from "../Kit";

const OnboardCard = () => (
  <Box>
    <Text faded>
      A summary of relevant activities will appear here once you or your course
      collaborators interact with course content, post annotations, or answer
      questions.
    </Text>
  </Box>
);

export default OnboardCard;
