import React from "react";
import { Subscribe, Container } from "unstated";
import { focusEmitter } from "./focusEmitter";
import { uniqueId, isEqual, isNumber } from "lodash";
import debug from "debug";

const log = debug("app:chat-state");

// type OpenChat = {
//   userIds?: Array<number>,
//   conversationId?: number,
//   isCollapsed?: boolean,
//   uid: number
// };

// type ChatManagerState = {
//   openChats: Array<OpenChat>
// };

const chatAlreadyExistsAtIndex = (chats, attr) => {
  let foundIndex = -1;

  const providedUserIds = (attr.userIds || []).sort();

  chats.some((chat, i) => {
    // the same conversationId exists?
    if (isNumber(chat.conversationId) && isNumber(attr.conversationId)) {
      if (chat.conversationId === attr.conversationId) {
        log("the same conversation id was found");
        foundIndex = i;
        return true;
      }
    }

    const userIds = (chat.userIds || []).sort();

    // the same users exist?
    if (isEqual(userIds, providedUserIds)) {
      log("is equal %o, %o", userIds, providedUserIds);
      foundIndex = i;
      return true;
    }

    return false;
  });

  return foundIndex;
};

export class ChatManagementContainer extends Container {
  state = {
    openChats: [],
    unreadChats: {}
  };

  updateUnreadCount = (chatId, count) => {
    if (this.state.unreadChats[chatId] === count) {
      return;
    }

    this.setState(state => {
      state.unreadChats[chatId] = count;
      return state;
    });
  };

  closeChatByIndex = i => {
    this.setState(state => {
      state.openChats.splice(i, 1);
      return state;
    });
  };

  openChatWithUserIds = userIds => {
    const i = chatAlreadyExistsAtIndex(this.state.openChats, {
      userIds,
      conversationId: null
    });

    if (i > -1) {
      const chat = this.state.openChats[i];
      if (chat.isCollapsed) {
        this.toggleChatCollapseByIndex(i);
      }
      focusEmitter.emit(chat.uid);
      return;
    }

    this.setState(state => {
      if (state.openChats.length > 2) {
        state.openChats.shift();
      }

      const chatState = {
        isCollapsed: false,
        uid: uniqueId(),
        userIds
      };

      log("pushing new chat: %o", chatState);

      state.openChats.push(chatState);

      return state;
    });
  };

  toggleChatCollapseByIndex = i => {
    this.setState(state => {
      state.openChats[i] = {
        ...state.openChats[i],
        isCollapsed: !state.openChats[i].isCollapsed
      };

      return state;
    });
  };

  chatIsOpen = (conversationId, userIds) => {
    const i = chatAlreadyExistsAtIndex(this.state.openChats, {
      conversationId,
      userIds
    });

    return i;
  };

  assignConversationIdToIndex = (conversationId, i) => {
    this.setState(state => {
      state.openChats[i].conversationId = conversationId;
      return state;
    });
  };

  openChatById = (conversationId, userIds) => {
    const { openChats } = this.state;

    const i = chatAlreadyExistsAtIndex(openChats, {
      conversationId,
      userIds
    });

    if (i > -1) {
      const chat = this.state.openChats[i];
      if (chat.isCollapsed) {
        this.toggleChatCollapseByIndex(i);
      }
      focusEmitter.emit(chat.uid);
      return;
    }

    this.setState(state => {
      if (state.openChats.length > 2) {
        log("shift openChats");
        state.openChats.shift();
      }

      state.openChats.push({
        conversationId,
        userIds,
        uid: uniqueId(),
        isCollapsed: false
      });
      return state;
    });
  };
}

export function WithChatState({ children }) {
  return <Subscribe to={[ChatManagementContainer]}>{children}</Subscribe>;
}
