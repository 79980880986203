import debug from "debug";
import Raven from "raven-js";
import { UpdateConversationMember } from "../API/mutations/UpdateConversationMember";
import { ConversationSummary } from "../API/queries/ConversationSummary";
import { ConversationQuery } from "../API/queries/ConversationQuery";

const log = debug("app:update-read-status");

const updateReadStatus = async (client, conversationId, typing) => {
  log("updating read status: %s", typing);

  const args = {
    conversationId
  };

  args.isTyping = typing;

  try {
    client.mutate({
      mutation: UpdateConversationMember,
      variables: args,
      optimisticResponse: {
        __typename: "Mutation",
        updateParticipantStatus: {
          __typename: "ConversationUser",
          lastRead: new Date(),
          isTyping: typing
        }
      },
      update: (store, { data: { updateLastRead } }) => {
        const data = store.readQuery({
          query: ConversationSummary,
          variables: {
            id: conversationId,
            first: 1
          }
        });

        data.conversation.unreadCount = 0;

        store.writeQuery({
          query: ConversationQuery,
          variables: {
            id: conversationId,
            first: 1
          },
          data
        });
      }
    });
  } catch (err) {
    console.error(err);
    Raven.captureException(err);
  }
};

export default updateReadStatus;
