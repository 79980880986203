import React from "react";
import Avatar from "../Kit/Avatar";
import Flex from "../Kit/Flex";
import Box from "../Kit/Box";

const UserList = ({ users, limit, exclude }) => {
  const extras = users.length > limit ? users.slice(limit) : [];

  const els = users
    .slice(0, limit)
    .filter(user => {
      if (exclude && user.id === exclude.id) {
        return false;
      }
      return true;
    })
    .map((user, i) => (
      <Box ml={i === 0 ? "0" : "-8px"} key={user.id}>
        <Avatar border img={user.photo} size="tiny" name={user.name} />
      </Box>
    ));

  const extraHover = extras.length ? (
    <Avatar
      customTooltipContent={
        <div>
          {extras.map(user => (
            <div key={user.id}>{user.name}</div>
          ))}
        </div>
      }
      name={String(extras.length)}
      size="tiny"
    />
  ) : null;

  return (
    <Flex>
      {els}
      {extraHover}
    </Flex>
  );
};

export default UserList;
