import React from "react";
import { Text, Box, Layer, Button } from "../Kit";
import ContentLoader from "react-content-loader";
import { Query } from "react-apollo";
import Event from "./Event";
import OnboardCard from "../Dashboard/OnboardCard";
import debug from "debug";
import Raven from "raven-js";
import Infinite from "../Kit/Infinite";
import { ViewerEventsQuery } from "../API/queries/ViewerEventsQuery";

const log = debug("app:EventFeed");

// type Props = {
//   error?: boolean,
//   data: Object,
//   popup?: boolean,
//   excludeViewer?: boolean,
//   fetchMore: Function,
//   customScrollContainer?: string
// };

// type State = {
//   loadingMore: boolean,
//   loadingMoreError: boolean
// };

class EventFeed extends React.Component {
  state = {
    loadingMore: false,
    loadingMoreError: false
  };

  componentWillUnmount() {
    log("unmounting eventfeed");
  }

  componentDidMount() {
    log("mounting eventfeed");
  }

  render() {
    const { loading, error, data } = this.props;
    if (loading) return 'Loading...'
    const { loadingMore, loadingMoreError } = this.state;
    let viewer = null
    if (data) {
      viewer = data.viewer
    }

    if (!viewer ) {
      return null;
    }

    const { pageInfo, edges } = viewer.notifications;
    const err = error || loadingMoreError;

    return (
      <Box width={1}>
        {edges.length === 0 && !err ? (
          <Box mt={2}>
            {this.props.popup ? (
              <Text px={2} color="faded">
                You have no notifications.
              </Text>
            ) : (
              <OnboardCard />
            )}
          </Box>
        ) : (
          <Box width={1}>
            <Infinite
              cursor={pageInfo.endCursor}
              customScrollContainer={this.props.customScrollContainer}
              loadMore={this.fetchMore}
              hasMore={pageInfo.hasNextPage}
            >
              {edges.map((edge, i) => {
                return this.renderNode(edge.node, i);
              })}
            </Infinite>

            {err && (
              <ErrorNotice
                fetchMore={this.fetchMore}
                popup={this.props.popup}
              />
            )}
            {loadingMore && <LoadingPlaceholder popup={this.props.popup} />}
          </Box>
        )}
      </Box>
    );
  }

  renderNode = (node, i) => {
    return (
      <Event
        viewer={this.props.data.viewer}
        popup={this.props.popup}
        notificationObject={node.notificationObject}
        key={node.id}
      />
    );
  };

  fetchMore = async () => {
    log("call load more");
    if (this.state.loadingMore) return;

    const { viewer } = this.props.data;
    const cursor = viewer.notifications.pageInfo.endCursor;

    log("load more, cursor: %s", cursor);
    this.setState({ loadingMore: true, loadingMoreError: false });

    try {
      await this.props.fetchMore({
        query: ViewerEventsQuery,
        variables: {
          first: 10,
          after: cursor,
          excludeViewer: this.props.excludeViewer
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.viewer.notifications.edges;
          const pageInfo = fetchMoreResult.viewer.notifications.pageInfo;
          if (newEdges.length > 0) {
            const nextNotifications = {
              __typename: previousResult.viewer.notifications.__typename,
              edges: [
                ...previousResult.viewer.notifications.edges,
                ...newEdges
              ],
              pageInfo
            };

            return {
              viewer: {
                ...previousResult.viewer,
                notifications: nextNotifications
              }
            };
          }

          return previousResult;
        }
      });
      log("fetched more");
      this.setState({ loadingMore: false });
    } catch (err) {
      console.error(err);
      Raven.captureException(err);
      this.setState({ loadingMoreError: true, loadingMore: false });
    }
  };
}

export const ErrorNotice = ({ popup, fetchMore }) => (
  <Box textAlign="center" px={popup ? 2 : 0} my={2}>
    <Text faded>We are experiencing difficulties loading your event feed.</Text>
    <Box>
      <Button mt={2} size="small" onClick={fetchMore} appearance="default">
        Retry
      </Button>
    </Box>
  </Box>
);

export const LoadingPlaceholder = ({ popup }) => {
  const LoadingContainer = popup ? Box : Layer;
  return (
    <React.Fragment>
      <Box mb={2}>
        <LoadingContainer>
          <Box p={2} height={popup ? "75px" : "200px"}>
            <ContentLoader
              style={{
                height: "73px",
                paddingLeft: popup ? "16px" : 0
              }}
              height={73}
              width={300}
            >
              <circle cx="20.759999999999998" cy="36.53" r="20.76" />
              <rect x="58" y="32" rx="5" ry="5" width="250" height="10" />
            </ContentLoader>
          </Box>
        </LoadingContainer>
      </Box>
      <Box>
        <LoadingContainer>
          <Box p={2} height={popup ? "75px" : "200px"}>
            <ContentLoader
              style={{
                height: "73px",
                paddingLeft: popup ? "16px" : 0
              }}
              height={73}
              width={300}
            >
              <circle cx="20.759999999999998" cy="36.53" r="20.76" />
              <rect x="58" y="32" rx="5" ry="5" width="250" height="10" />
            </ContentLoader>
          </Box>
        </LoadingContainer>
      </Box>
    </React.Fragment>
  );
};

// type EventFeedQueryProps = {
//   excludeViewer?: boolean,
//   popup?: boolean,
//   first?: number,
//   viewer: Object,
//   customScrollContainer?: string
// };

const EventFeedQuery = ({ first = 1, excludeViewer, ...other }) => {
  return (
    <Query
      query={ViewerEventsQuery}
      fetchPolicy="cache-and-network"
      variables={{
        first,
        excludeViewer
      }}
    >
      {({ data, loading, error, fetchMore }) => (
        <EventFeed
          data={data}
          loading={loading}
          error={error}
          fetchMore={fetchMore}
          excludeViewer={excludeViewer}
          {...other}
        />
      )}
    </Query>
  );
};

export default EventFeedQuery;
