import gql from "graphql-tag";
import {
  ConversationReplyFragment,
  ConversationReplyType
} from "../fragments/ConversationReplyFragment";
import {
  UserBasicsType,
  UserBasicsFragment
} from "../fragments/UserBasicsFragment";

export interface ConversationReplySubscriptionType {
  conversationReply: ConversationReplyType & {
    id: number;
    user: UserBasicsType;
  };
}

export const ConversationReplySubscription = gql`
  subscription conversationReply {
    conversationReply {
      id
      ...ConversationReply
      user {
        ...UserBasics
      }
    }
  }
  ${ConversationReplyFragment}
  ${UserBasicsFragment}
`;
