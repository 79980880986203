import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

class LocalStorageSetter extends Component {
  componentDidMount() {
    const { location, match } = this.props;
    console.log('LocalStorageSetter mounted');
    console.log('Location:', location);
    console.log('Match:', match);

    // Parse the query parameters
    const queryParams = queryString.parse(location.search);
    console.log('queryParams:', queryParams);
    if (queryParams.source === 'email') {

      // Set the source in localStorage
      localStorage.setItem('invitationSource', 'email');
    }

    // Set the key in localStorage
    const key = match.params.key;
    if (key) {
      localStorage.setItem('invitationKey', key);
    }
  }

  render() {
    // Render nothing, just perform side-effects
    return null;
  }
}

export default withRouter(LocalStorageSetter);