import * as React from "react";
import Media from "react-media";
import Tippy from "@tippyjs/react";

import "./Tooltip.css";
import "tippy.js/themes/light.css";

export interface Props {
  id?: number;
  title?: React.ReactElement<any> | string;
  duration?: number;
  arrow?: boolean;
  delay?: number;
  theme?: string;
  interactiveBorder?: number;
  interactive?: boolean;
  children: React.ReactElement<any>;
  disabled?: boolean;
}

export const Tooltip = ({
  id,
  title = "",
  duration = 150,
  arrow = true,
  delay = 100,
  theme = "dark",
  interactive,
  children,
  disabled,
  ...other
}: Props) => (
  <Media query="(min-width: 62rem)">
    {(matches: boolean) => {
      if (!matches) {
        return children;
      }

      if (disabled === true) {
        return children;
      }

      return (
        <p></p>
        // <Tippy
        //   content={title}
        //   theme={theme}
        //   touch={false}
        //   duration={duration}
        //   interactive={interactive}
        //   arrow={arrow}
        //   delay={delay}
        //   animation="shift-away"
        //   {...other}
        // >
        //   {children}
        // </Tippy>
      );
    }}
  </Media>
);

export default Tooltip;
