import * as React from "react";
import { Query, withApollo } from "react-apollo";
import ChatMessages from "./ChatMessages";
import { Box, Layer } from "../Kit";
import ChatInputBox from "./ChatInputBox";
import debug from "debug";
import Raven from "raven-js";
import { LayerLoading } from "../Kit/Layer";
import { ErrorBoundary, ErrorHandler } from "../Kit/ErrorCapture";
import ChatSummary from "./ChatSummary";
import updateReadStatus from "./update-read-status";
import HandleNewlyCreatedChats from "./HandleNewlyCreatedChats";
import { ConversationQuery } from "../API/queries/ConversationQuery";
import { ConversationSummary } from "../API/queries/ConversationSummary";

const log = debug("app:Chat");

// type Props = {
//   userIds?: Array<number>,
//   conversationId?: number,
//   isCollapsed: boolean,
//   client: Object,
//   onToggleCollapse: Function,
//   onRequestClose: Function,
//   updateConversationMember: Function
// };

// type State = {
//   loadingMore: boolean,
//   loadingMoreError: boolean
// };

class Chat extends React.Component {
  // scrollComponent: React.Ref<any>;
  // conversationId: ?number;

  constructor(props) {
    super(props);
    this.scrollComponent = React.createRef();
    this.state = {
      loadingMore: false,
      loadingMoreError: false,
      unreadCount: 0
    };
  }

  componentDidCatch(error, errorInfo) {
    this.props.onRequestClose();
    Raven.captureException(error, { extra: errorInfo });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCollapsed && !this.props.isCollapsed) {
      this.updateLastRead();
    }
  }

  render() {
    let { conversationId, userIds } = this.props;

    const expanded = !this.props.isCollapsed;

    const appearanceStyle = expanded
      ? { transition: "opacity 0.25s ease", opacity: 1, pointerEvents: "auto" }
      : { transition: "opacity 0.25s ease", opacity: 0, pointerEvents: "none" };

    return (
      <Layer
        mx={1}
        overflow="hidden"
        width={expanded ? "350px" : "200px"}
        borderRadius="8px 8px 0 0"
        style={{
          pointerEvents: "auto",
          transition: "width 0.25s ease, height 0.25s ease",
          borderRadius: "8px 8px 0 0",
          zIndex: 500
        }}
        height={expanded ? "500px" : "40px"}
        elevation={2}
      >
        <Box height="100%" position="relative" width={1}>
          <Query
            query={ConversationSummary}
            variables={{
              id: conversationId,
              first: 1,
              userIds: conversationId ? undefined : userIds
            }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <LayerLoading loading />;
              }

              if (error) {
                throw new Error(error);
              }

              if (!data.conversation) {
                throw new Error("Conversation not found");
              }

              const queryVariables = {
                id: data.conversation.id,
                first: 10
              };

              // handle errors, but still reveal some parts of the UI

              return (
                <Box
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  overflow="hidden"
                  pt="40px"
                >
                  <HandleNewlyCreatedChats
                    userIds={userIds}
                    chats={this.props.chats}
                    conversationId={data.conversation.id}
                    subscribedChatIds={this.props.subscribedChatIds}
                  />
                  <ChatSummary
                    expanded={expanded}
                    onRequestClose={this.onRequestClose}
                    unreadCount={data.conversation.unreadCount}
                    onRequestToggle={this.props.onToggleCollapse}
                    participants={data.conversation.members}
                  />
                  <ErrorBoundary>
                    <Query
                      fetchPolicy="cache-and-network"
                      query={ConversationQuery}
                      variables={queryVariables}
                    >
                      {({
                        loading,
                        error,
                        data,
                        subscribeToMore,
                        fetchMore
                      }) => {
                        if (error) {
                          return <ErrorHandler error={error} />;
                        }

                        let particpantsById = {};

                        if (data && data.conversation) {
                          this.conversationId = data.conversation.id;

                          data.conversation.members.forEach(member => {
                            particpantsById[member.user.id] = member.user;
                          });
                        }

                        return (
                          <React.Fragment>
                            <LayerLoading
                              loading={loading && !this.state.loadingMore}
                            />
                            {data && data.conversation && (
                              <React.Fragment>
                                <UpdateReadOnMount
                                  updateRead={this.updateLastRead}
                                />
                                <ChatMessages
                                  id={data.conversation.id}
                                  loadingMore={this.state.loadingMore}
                                  loadingMoreError={this.state.loadingMoreError}
                                  pageInfo={data.conversation.replies.pageInfo}
                                  replies={data.conversation.replies}
                                  appearanceStyle={appearanceStyle}
                                  viewer={data.viewer}
                                  members={data.conversation.members}
                                  participants={particpantsById}
                                  expanded={expanded}
                                  onRequestMore={async () => {
                                    log("call loading more");

                                    if (
                                      !data.conversation.replies.pageInfo
                                        .hasNextPage
                                    )
                                      return;
                                    if (this.state.loadingMore) return;

                                    const cursor =
                                      data.conversation.replies.pageInfo
                                        .endCursor;
                                    this.setState({
                                      loadingMore: true,
                                      loadingMoreError: false
                                    });

                                    try {
                                      await fetchMore({
                                        query: ConversationQuery,
                                        variables: {
                                          ...queryVariables,
                                          first: 20,
                                          after: cursor
                                        },
                                        updateQuery: (
                                          previousResult,
                                          { fetchMoreResult }
                                        ) => {
                                          const newEdges =
                                            fetchMoreResult.conversation.replies
                                              .edges;
                                          const pageInfo =
                                            fetchMoreResult.conversation.replies
                                              .pageInfo;

                                          if (newEdges.length > 0) {
                                            const next = {
                                              __typename:
                                                previousResult.conversation
                                                  .replies.__typename,
                                              edges: [
                                                ...previousResult.conversation
                                                  .replies.edges,
                                                ...newEdges
                                              ],
                                              pageInfo
                                            };

                                            return {
                                              ...previousResult,
                                              conversation: {
                                                ...previousResult.conversation,
                                                replies: next
                                              }
                                            };
                                          }
                                        }
                                      });
                                    } catch (err) {
                                      this.setState({
                                        loadingMoreError: true,
                                        loadingMore: false
                                      });
                                      console.error(err);
                                      Raven.captureException(err);
                                    }

                                    this.setState({
                                      loadingMore: false,
                                      loadingMoreError: false
                                    });
                                  }}
                                />

                                <Box
                                  flex="0 0 auto"
                                  borderTop="1px solid"
                                  borderColor="borderColor"
                                  style={appearanceStyle}
                                >
                                  <ChatInputBox
                                    data={data}
                                    uid={this.props.uid}
                                    queryVariables={queryVariables}
                                  />
                                </Box>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        );
                      }}
                    </Query>
                  </ErrorBoundary>
                </Box>
              );
            }}
          </Query>
        </Box>
      </Layer>
    );
  }

  updateLastRead = () => {
    log("attempting to update last read");
    const conversationId = this.conversationId;

    if (!conversationId) {
      log("no conversation id saved");
      return;
    }

    updateReadStatus(this.props.client, conversationId);
  };

  onRequestClose = () => {
    log("close");
    this.props.onRequestClose();
  };
}

export default withApollo(Chat);

// type UpdateReadProps = {
//   updateRead: Function
// };

class UpdateReadOnMount extends React.Component {
  componentDidMount() {
    this.props.updateRead();
  }
  render() {
    return null;
  }
}
