import React, { Component } from "react";
import Raven from "raven-js";
import Alert from "./Alert";
import Box from "./Box";
import Text, { Link } from "./Text";

const log = require("debug")("app:ErrorCapture");
export class ErrorHandler extends Component {
  static defaultProps = {
    title: "Oh darn! Something went wrong.",
    message: "Our engineers have been notified. For now, try reloading."
  };

  componentDidMount() {
    if (this.props.error) {
      log('Error captured: ', this.props.error);
      console.log(this.props.error)
      Raven.captureException(this.props.error);
    }
  }

  render() {
    return (
      <Box maxWidth="500px" p={1} mx="auto">
        <Alert title={this.props.title} type="danger">
          <Text>
            {this.props.error ? this.props.error : ""}
            {this.props.message} If the problem persists, please help us by{" "}
            <Link onClick={this.onClick} href="#">
              filling out a report.
            </Link>
          </Text>
        </Alert>
      </Box>
    );
  }

  onClick = e => {
    e.preventDefault();
    Raven.lastEventId();
    Raven.showReportDialog();
  };
}

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <Box m={2} mt={4}>
          <ErrorHandler />
        </Box>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export const withErrorBoundary = Component => {
  class WithErrorBoundary extends React.Component {
    render() {
      return (
        <ErrorBoundary>
          <Component {...this.props} />
        </ErrorBoundary>
      );
    }
  }

  return WithErrorBoundary;
};
