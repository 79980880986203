import React from "react";
import { Box, Flex, Avatar, Text, withLoading } from "../Kit";
import SelectImage from "../SelectImage";
import debug from "debug";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import SortableList, { SortableItem } from 'react-easy-sort';
import { CloseButton } from "../Kit/IconButton";
import { GalleryQuery } from "../API/queries/GalleryQuery";
import { arrayMoveMutable, arrayMoveImmutable } from 'array-move';

const log = debug("app:ManageGalleryImages");


const GalleryList = ((props) => {

  return (
    <SortableList
      onSortEnd={props.onSortEnd} className="list"
      style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}
      draggedItemClassName="dragged">
      {props.galleryImages.map((galleryImage) => (
        <SortableItem key={galleryImage}>
          <Box position="relative" key={galleryImage.image.id} mr={"3px"} mb={"3px"}>
            <Avatar
              showText={false}
              rounded={false}
              size="large"
              img={galleryImage.image}
            />
            <CloseButton
              onClick={() => {
                props.onRequestRemoveImage(galleryImage.image.id);
              }}
              style={{
                position: "absolute",
                top: "20%",
                left: "80%",
                transform: "translateX(-50%) translateY(-50%)"
              }}
            />
          </Box>
        </SortableItem>
      ))}
    </SortableList>
  );
});


class ManageGalleryImages extends React.Component {
  static defaultProps = {
    images: [],
    galleryImages: []
  };
  
  render() {
    const {
      data: { gallery }
    } = this.props;

    const galleryImages = (gallery && gallery.galleryImages) ? gallery.galleryImages.sort((a, b) => a.orderNumber - b.orderNumber) : [] ;

    return (
      <Box>
        <Flex flexWrap="wrap" p={2} width={1}>
          {galleryImages && galleryImages.length === 0 && (
            <Text faded>
              No images have been added to this gallery. Either select from
              existing images or upload a new image.
            </Text>
          )}
          {/* TODO: Reorder when image is added or removed. */}
          <GalleryList
            onRequestRemoveImage={this.props.onRequestRemoveImage}
            galleryImages={galleryImages}
            onSortEnd={this.onSortEnd} />
        </Flex>

        <Box p={2} width={1}>
          <SelectImage onChange={this.selectImage} />
        </Box>
      </Box>
    );
  }

  onSortEnd = (oldIndex, newIndex) => {
    const {
      data: { gallery }
    } = this.props;

    const { galleryImages } = gallery;
    let images = JSON.parse(JSON.stringify(galleryImages));
    let finalImages = arrayMoveImmutable(images, oldIndex, newIndex);
    this.props.onChangeOrderOfGalleryImages(finalImages || []);
  }

  selectImage = imageId => {
    const {
      data: { gallery }
    } = this.props;

    const { galleryImages } = gallery;
    log("select image: %s", imageId);
    this.props.onRequestAddImage(imageId, galleryImages.length);
  };
}

const galleryQuery = graphql(GalleryQuery, {
  options: props => ({
    variables: { id: props.galleryId }
  })
})

export default compose(
  galleryQuery,
  // updateGalleryOrder,
  withLoading
)(ManageGalleryImages);