import React from "react";
import ResourceList from "./ResourceList";
import { Box, IconButton, Dropdown } from "../Kit";
import MoreVerticalIcon from "@atlaskit/icon/glyph/more-vertical";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import debug from "debug";
import {
  RemoveLinkFromEpisodeMutation,
  RemoveVideoFromEpisodeMutation
} from "../API/mutations/EpisodeMutations";

const log = debug("app:ResourceListAdmin");

// type Props = {
//   resources: ResourcesType,
//   episodeId: number,
//   compressed?: boolean,
//   enableFilter?: boolean
// };

class ResourceListAdmin extends React.Component {
  render() {
    const {
      compressed,
      resources,
      enableFilter,
      episodeId,
      onRequestRemove
    } = this.props;
    return (
      <Box
        style={
          compressed
            ? {
                marginLeft: "-16px",
                width: "calc(100% + 16px)"
              }
            : null
        }
      >
        <ResourceList
          compressed={compressed}
          enableFilter={enableFilter}
          resources={resources}
          renderActions={resource => {
            return (
              <Dropdown
                trigger={
                  <IconButton
                    style={{ marginRight: "16px" }}
                    label="Show options"
                  >
                    <MoreVerticalIcon label="Show options" />
                  </IconButton>
                }
              >
                <Box>
                  {this.props.onRequestEdit && (
                    <Dropdown.Item
                      onClick={e => {
                        // e.stopPropagation();
                        e.preventDefault();
                        this.edit(resource, episodeId);
                      }}
                      href="#"
                    >
                      Edit
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={e => {
                      // e.stopPropagation();
                      e.preventDefault();
                      onRequestRemove(resource);
                    }}
                    href="#"
                  >
                    Remove
                  </Dropdown.Item>
                </Box>
              </Dropdown>
            );
          }}
        />
      </Box>
    );
  }

  edit = (resource, episodeId) => {
    log("edit %o", resource);
    this.props.onRequestEdit(resource);
  };

  remove = async (resource, episodeId) => {
    log("remove %o", resource);

    // try {
    //   const options = {
    //     variables: {
    //       id: resource.id,
    //       episodeId
    //     }
    //   };

    //   if (resource.__typename === "Link") {
    //     await this.props.removeLink(options);
    //   } else if (resource.__typename === "Video") {
    //     await this.props.removeVideo(options);
    //   }
    // } catch (err) {
    //   console.error(err);
    //   Raven.captureException(err);
    //   notify.send({
    //     message: "An error occurred while remove this resource.",
    //     type: "danger"
    //   });
    // }
  };
}

const options = {
  options: {
    refetchQueries: ["EpisodeResources"]
  }
};

export default compose(
  graphql(RemoveLinkFromEpisodeMutation, {
    name: "removeLink",
    ...options
  }),
  graphql(RemoveVideoFromEpisodeMutation, {
    name: "removeVideo",
    ...options
  })
)(ResourceListAdmin);
