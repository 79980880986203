import React from "react";
import { Query } from "react-apollo";
import { Box, ErrorHandler, Loading, Heading } from "../Kit";
import { CloseButton } from "../Kit/IconButton";
import DashboardNav from "../Dashboard/DashboardNav";
import { on, off } from "delegated-events";
import Sheet from "../Kit/Sheet";
import styled from "styled-components/macro";
import { SideNavQuery } from "../API/queries/SideNavQuery";

const StyledScrollBox = styled(Box)`
  overflow-y: auto;
  // overflow: hidden;

  // :hover {
  //   overflow-y: auto;
  // }

  // ::-webkit-scrollbar-track {
  //   // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: transparent;
  // }

  // ::-webkit-scrollbar {
  //   width: 6px;
  // }

  // :hover::-webkit-scrollbar-thumb {
  //   background-color: #e1e7ed;
  // }
`;

const LoadingPlaceholder = () => <Loading />;

class SideNav extends React.Component {
  static defaultProps = {
    expanded: true
  };

  close = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  componentDidMount() {
    on("click", ".SideNav a", this.close);
  }

  componentWillUnmount() {
    off("click", ".SideNav a", this.close);
  }

  show = () => {
    this.props.onRequestShow();
  };

  render() {
    const { inline } = this.props;

    if (!inline) {
      return (
        <Sheet
          onCloseComplete={this.close}
          w={["100vw", null, "275px"]}
          showing={this.props.showMenu}
        >
          <Box className="SideNav" width={1} minHeight="100vh" bg="gray.1">
            <Box
              display="flex"
              px={3}
              height="56px"
              borderBottom="1px solid"
              alignItems="center"
              justifyContent="space-between"
              borderColor="borderColor"
            >
              <Heading fontSize={3}>Watershed</Heading>
              <CloseButton label="Close" onClick={this.props.onRequestClose} />
            </Box>
            {this.renderContents()}
          </Box>
        </Sheet>
      );
    }

    return (
      <Box
        bg="white"
        minWidth={0}
        flex={["0 0 225px", null, null, "0 0 325px"]}
        width={["225px", null, null, "325px"]}
        display={["none", null, "block"]}
      >
        <StyledScrollBox
          position="sticky"
          top="0"
          width={1}
          borderRight="1px solid"
          py={1}
          borderColor="borderColor"
          height="100vh"
        >
          {this.renderContents()}
        </StyledScrollBox>
      </Box>
    );
  }

  renderContents = () => {
    return (
      <Query query={SideNavQuery}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Box height="40px" width={1}>
                <LoadingPlaceholder />
              </Box>
            );
          }

          if (error) {
            console.error(error);
            return null;
          }

          return (
            <Box pt={1}>
              <DashboardNav viewer={data.viewer} />
            </Box>
          );
        }}
      </Query>
    );
  };
}

export default SideNav;
