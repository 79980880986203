import gql from "graphql-tag";

export const CourseGalleriesQuery = gql`
  query CourseGalleries($id: Int!) {
    course(id: $id) {
      id
      galleries {
        id
        title
        description
        images {
          id
          public_id
        }
        galleryImages {
          image {
            id
            public_id
            width
            height
          }
          imageId
          imagecollectionId
          orderNumber
        }
      }
    }
  }
`;
