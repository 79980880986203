import gql from "graphql-tag";
import { ResourceFragment } from "../fragments/ResourceFragment";

export const CreateLinkResourceMutation = gql`
  mutation CreateLinkResource(
    $url: String!
    $input: CreateLinkResourceInputType!
    $isSurvey: Boolean
  ) {
    createLinkResource(input: $input, url: $url, isSurvey: $isSurvey) {
      id
      ...Resource
    }
  }
  ${ResourceFragment}
`;

export const UpdateLinkResourceMutation = gql`
  mutation UpdateLinkResource(
    $id: Int!
    $input: UpdateLinkResourceInputType!
    $url: String
    $isSurvey: Boolean
  ) {
    updateLinkResource(id: $id, input: $input, url: $url, isSurvey: $isSurvey) {
      id
      ...Resource
    }
  }
  ${ResourceFragment}
`;
