import gql from "graphql-tag";
import { EpisodeStepAdminFragment } from "./EpisodeStepAdminFragment";

export const EpisodeAdminFragment = gql`
  fragment EpisodeAdmin on Episode {
    id
    title
    description
    steps {
      id
      ...EpisodeStepAdmin
    }
    imageId
    image {
      id
      public_id
    }
  }
  ${EpisodeStepAdminFragment}
`;
