import React from "react";
import ResourceList from "./ResourceList";
import { Button } from "../Kit";

// type Props = {
//   resources: ResourcesType,
//   onSelect: ResourceType => void
// };

class ResourceListSelect extends React.Component {
  render() {
    const { resources } = this.props;
    return (
      <ResourceList
        resources={resources}
        renderActions={resource => {
          return (
            <Button
              mr={2}
              ml={1}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                this.props.onSelect(resource);
              }}
            >
              Select
            </Button>
          );
        }}
      />
    );
  }
}

export default ResourceListSelect;
