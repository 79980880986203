import React from "react";
import { Container, Subscribe } from "unstated";
import client from "../API/apollo-client";
import debug from "debug";
import { ViewerNotificationsQuery } from "../API/queries/ViewerNotificationsQuery";

const log = debug("app:UnreadMessages");

const observable = client.watchQuery({
  query: ViewerNotificationsQuery,
  pollInterval: 30000
});

// type UnreadNotificationState = {
//   count: number,
//   subscribed: boolean
// };

class UnreadNotificationsContainer extends Container {
  state = {
    count: 0,
    subscribed: false
  };

  reset() {
    this.setState({ count: 0 });
  }

  unsubscribe() {
    // this.setState({ subscribed: false })
  }

  observe = () => {
    if (this.state.subscribed) {
      return;
    }

    this.setState({ subscribed: true });

    log("subscribing to notification count");

    observable.subscribe({
      next: ({ data }) => {
        log("received: %o", data);
        if (data.viewer) {
          this.setState({ count: data.viewer.notificationCount });
        }
      }
    });
  };
}

// type Props = {
//   children: UnreadNotificationState => any
// };

export default class NotificationsCount extends React.Component {
  render() {
    return (
      <Subscribe to={[UnreadNotificationsContainer]}>
        {notify => (
          <div>
            <SubscribeToMessages
              subscribe={notify.observe}
              unsubscribe={notify.unsubscribe}
            />
            {this.props.children(notify)}
          </div>
        )}
      </Subscribe>
    );
  }
}

class SubscribeToMessages extends React.Component {
  render() {
    return null;
  }

  componentDidMount() {
    this.props.subscribe();
  }

  componentWillUnmount() {
    this.props.unsubscribe();
  }
}
