import gql from "graphql-tag";

export const ImageQuery = gql`
  query Image($id: Int!) {
    image(id: $id) {
      id
      public_id
    }
  }
`;
