import * as React from "react";
import Message from "../Input/Message";
import { BorderedBox } from "../ViewEpisode/Annotation";
import styled from "styled-components/macro";
import RenderFile from "../Input/RenderFile";
import Box from "../Kit/Box";

const Border = styled(BorderedBox)`
  padding: 7px 12px;
`;

const MessageWhite = styled(Message)`
  & p {
    color: white !important;
  }
`;

const ChatMessage = ({ message, viewer }) => {
  const isViewer = message.node.userId === viewer.id;
  const Msg = isViewer ? MessageWhite : Message;

  return (
    <Box>
      <Border
        className={
          "ChatMessage " + isViewer ? "ChatMessageViewer" : "ChatMessageOther"
        }
        bg={isViewer ? "blue.20" : "gray.3"}
      >
        <Msg
          color={isViewer ? "white" : "rgb(22, 23, 26)"}
          truncate={false}
          message={message.node.content}
        />
        {message.node.image && (
          <Box borderRadius="16px" bg="white" my={1}>
            <RenderFile radius="16px" file={message.node.image} />
          </Box>
        )}
      </Border>
    </Box>
  );
};

export default ChatMessage;
