import * as React from "react";
import groupMessages from "./group-messages";
import ChatMessageGroup from "./ChatMessageGroup";
import ChatMessage from "./ChatMessage";
import Box from "../Kit/Box";
import Spinner from "../Kit/Spinner";
import Infinite from "../Kit/Infinite";
import debug from "debug";
import TypingIndicator, { isTyping } from "./TypingIndicator";

const log = debug("app:ChatMessages");

class ChatMessages extends React.Component {
  constructor(props) {
    super(props);
    this.scrollComponent = React.createRef();
    this.state = {
      bindCursor: false
    };
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (
      this.props.replies.edges.length > prevProps.replies.edges.length ||
      (isTyping(this.props.members) && !isTyping(prevProps.members))
    ) {
      const scroller = this.scrollComponent.current;
      return scroller.scrollHeight - scroller.scrollTop;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const scroller = this.scrollComponent.current;
      const newHeight = scroller.scrollHeight - snapshot;
      log(
        "old height: %s, new: %s, new top: %s",
        snapshot,
        scroller.scrollHeight,
        scroller.scrollTop
      );
      log("update scroll to: %s", newHeight);
      scroller.scrollTop = newHeight;
    }
  }

  componentDidMount() {
    this.scrollToBottom();

    this.setState({ bindCursor: true });
  }

  scrollToBottom() {
    const scroller = this.scrollComponent.current;
    scroller.scrollTop = scroller.scrollHeight;
  }

  render() {
    const groups = groupMessages(this.props.replies.edges);

    return (
      <Box
        overflow="auto"
        position="relative"
        flex="1"
        ref={this.scrollComponent}
        id={"scroll" + this.props.id}
        style={this.props.appearanceStyle}
      >
        {this.props.loadingMore && (
          <Box display="flex" justifyContent="center" mt={1}>
            <Spinner delay={false} />
          </Box>
        )}
        <Infinite
          reverseDirection
          threshold={10}
          cursor={this.state.bindCursor ? this.props.pageInfo.endCursor : null}
          customScrollContainer={"#scroll" + this.props.id}
          loadMore={this.props.onRequestMore}
          hasMore={this.props.pageInfo.hasNextPage}
        >
          {this.props.replies.edges.length === 0
            ? null
            : groups.map((group, i) => (
                <ChatMessageGroup
                  user={this.props.participants[group.userId]}
                  viewer={this.props.viewer}
                  group={group}
                  key={i}
                >
                  {group.messages.map(msg => (
                    <Box
                      className="ChatMessageWrapper"
                      my={"2px"}
                      key={msg.node.id}
                    >
                      <ChatMessage viewer={this.props.viewer} message={msg} />
                    </Box>
                  ))}
                </ChatMessageGroup>
              ))}
        </Infinite>
        <TypingIndicator members={this.props.members} />
      </Box>
    );
  }
}

export default ChatMessages;
