import * as React from "react";
import toast from "./Toast";
import Alert from "./Alert";
import Box from "./Box";
import Button from "./Button";
import { ThemeProvider } from "styled-components/macro";
import Theme from "./Theme";

const AlertWithTheme = props => (
  <ThemeProvider theme={Theme}>
    <Alert {...props} />
  </ThemeProvider>
);

const confirmation = ({
  message,
  details,
  onConfirm,
  showCancel = true,
  confirmText = "Confirm"
}) => {
  // toast.success(confirmText, {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   theme: "light",
  // });
  toast.notify(
    ({ onClose }) => (
      <AlertWithTheme type="danger" onClose={onClose} title={message}>
        <Box mt={2} display="flex" justifyContent="flex-end">
          {showCancel && (
            <Button onClick={onClose} appearance="ghost">
              Cancel
            </Button>
          )}
          <Button
            onClick={() => {
              onClose();
              onConfirm();
            }}
            ml="1"
            appearance="danger"
          >
            {confirmText}
          </Button>
        </Box>
      </AlertWithTheme>
    ),
    {
      duration: 100000 // this is a quickfix to make the popup stay till user confirms deletion
    }
  );
};

const send = ({ message, type = "success" }) => {
  message = message.replace("GraphQL error: ", ""); // such a lame hack

  if (type === "success") {
    // toaster.success(message, {
    //   autoClose: 5000,
    // })
    toast.notify(
      ({ onClose }) => {
        return (
          <AlertWithTheme type="success" onClose={onClose} title={message} />
        );
      },
      {
        duration: 5000
      }
    );
  } else if (type === "danger" || type === "error") {
    // toaster.error(message, {
    //   autoClose: 5000,
    // })
    toast.notify(
      ({ onClose }) => (
        <AlertWithTheme type="danger" onClose={onClose} title={message} />
      ),
      { duration: 0 }
    );
  } else {
    // toaster.info(message);
    toast.notify(message);
  }
};

export default {
  confirmation,
  send
};
